<template>
  <div class="app-client-area">  
    <router-view></router-view>
    <ui-blocker></ui-blocker>
  </div>
</template>

<style lang="less">
.Cookie--base {
    background: #18fc8a !important;
    color: #232323;
    padding: 1.250em;
}
.app-client-area{
  height:100%;
  width:100%;
  background-color:#000;
  font-family: 'Manrope', sans-serif;
  color:#000;
  .no-hover:hover{
    background-color: transparent !important;
  }
  .invalid_feedback {
    font-size: 10px;
    color: yellow;
}
  .burger{font-size: 30px;
    margin-left: 10px;
    color: #33297f;
    background-color: transparent;

  }
  .button.primary{
    color:white;
    border-width:0px;
    font-size:14px;
    &:hover,&:focus,&:active{
      background-color:transparent;
      color:#aaa;
      font-size:16px;
      font-weight:600;
      border-width:0px;
      box-shadow: none;
    }
  }

  .page{
    padding:0px;
    margin:0px;
    width:100%;
    height:calc(100% - 100px);
  } 
}

.app-client-area{
  .h-menu{
    height:100px;
    display:flex;
    align-items: center;
    a{
      cursor:pointer;
      &.menu-item{
        color:#33297f;
        font-weight:300;
        margin-right:20px;
        &.active{
          font-weight:800;
        }
      }
    }
  }
  .menu-small{
    display:none;
    @media screen and (max-width:1000px){
      display:block;
    }
  }
  .menu-large{
    @media screen and (max-width:1000px){
      display:none !important;
    }
  }
  .hide-sm{
    @media screen and (max-width:520px){
      display:none !important;
    }
  }
  
  header{
    height:100px;
    // background-color: #ccc;
  }
  .main-content{
    position:relative;
    height: calc(100vh - 164px);
    overflow: auto;
    footer{
      // position:absolute;
      // bottom:0px;
      height:100px;
      background-color: #ccc;
      width:100%;
    }
  }
  
  
}
</style>

<script>
import axios from 'axios';
import _ from 'underscore';
import UIBlocker from './components/common/UIBlocker.vue'
import settings from './settings';
import globals from './globals';

export default {
  components:{   
      'ui-blocker':UIBlocker
  },
  data () {
    return {
      token:localStorage.getItem('token'),
      version:globals.version,
      positions:[],
      position:undefined,
      livemode:false,
      pin:'',
      email:'',
      pinSubmitting:false,
      showLive:false,
      pinErrorMessage:'',
      emailErrorMessage:''
    }
  },
  computed:{
    loggedIn(){
      return this.$store.state.user!=undefined;
    },
    user(){
      return this.$store.state.user;
    }
  },
  mounted(){
    this.livemode=this.$route.query.livemode=="true";
    this.$root.$on('loggedIn',()=>{
      this.token=localStorage.getItem('token');
    });
    this.$nextTick(()=>{
      let offset=100;
      let element=document.querySelector('.home');
      this.positions.push({el:'.home',pos:element.offsetTop});
      element=document.querySelector('.activities');
      this.positions.push({el:'.activities',pos:element.offsetTop+300});
      element=document.querySelector('.program');
      this.positions.push({el:'.program',pos:element.offsetTop+300});
      element=document.querySelector('.faq');
      this.positions.push({el:'.faq',pos:element.offsetTop+900});
      // console.log(this.positions.map(x=>x.el+' '+x.pos));
      element=document.querySelector('.register');
      this.positions.push({el:'.register',pos:element.offsetTop+1000});

      this.position=this.positions[0];
      let containerElement=document.querySelector(".page");
      containerElement.addEventListener('scroll',(ev)=>{
        let currentPos=containerElement.scrollTop;
        let i=0;
        // console.log(currentPos);
        while (i<this.positions.length && currentPos>this.positions[i].pos)
          i++;
        if (i>0)
          this.position=this.positions[i-1];
        else
          this.position=this.positions[0];
        console.log(this.position.el);
      })

    });
  },
  watch: {
    },
  methods:{
    async onPinSubmit(){
      if (!this.pin || this.pin.length<6){
        this.pinErrorMessage='Το password πρέπει να αποτελείται από 6 χαρακτήρες';
        return;
      }
      this.pinErrorMessage='';
      this.pinSubmitting=true;
      let r=await axios.post(`${settings.serviceUrl}cosmote-live`,{pin:this.pin});
      this.pinSubmitting=false;
      if (!r.data.ok){
          this.pinErrorMessage='Το password δεν είναι σωστό.'
      }
      else{
          window.location="https://us02web.zoom.us/j/89250457307"
      }

    },
    async onEmailSubmit(){
      if (!this.email){
        this.emailErrorMessage='Το email δεν πρέπει να είναι κενό';
        return;
      }
      this.emailErrorMessage='';
      this.pinSubmitting=true;
      let r=await axios.post(`${settings.serviceUrl}cosmote-pin-remember`,{email:this.email});
      this.pinSubmitting=false;
      if (!r.data.ok){
          this.emailErrorMessage='Δεν βρέθηκε το συγκεκριμένο email.'
      }
      else{
          this.emailErrorMessage='Ο κωδικός σας στάλθηκε στο email που δώσατε.'
      }

    },
    onLive(){
      if (!this.livemode){
        Metro.dialog.create({
          title: "Το event πλησιάζει!",
          content: "<div>Στις 19:15 πατήστε σε αυτό το κουμπί για να εισάγετε το password σας και να συνδεθείτε στο live!</div>",
          closeButton: true
        });
        return;
      }
      this.showLive=!this.showLive;
    },
    async onLogout(){
      await axios.post(`${settings.authServiceUrl}token/expireToken`, {tokenValue:globals.getToken()});
      globals.removeToken();
      this.$store.commit('setUser',undefined);
    },
    onLogin(){
      this.$router.push('/client-login');
    },
    onRegister(){
      this.$router.push('/client-register')
    },
  }
}
</script>

