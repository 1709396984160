<template>
    <div class="page settings-page">
    </div>
</template>

<script>
import settings from '../../settings.js';

export default{
    components:{
    },
    computed:{
    },
    watch:{
    },
    data(){
        return{
        }
    },
    methods:{
    }
}
</script>

<style lang="less" scoped>
.settings-page{
    display:flex;
    flex-direction: column;
    // justify-content: center;
    .grid{
        display:flex;
        align-items:center;
        .row{
            width:340px;
        }
    }
    .section-header{
        font-size:20px;
        margin-top:10px;
    }

}
</style>