<template>
    <div class="page">
        <div class="grid">
            <div class="container">
                <div data-role="progress" data-type="line" data-small="true" v-if="initializing"></div>
                <div class="tools-area">
                    <div class="row">
                        <div class="cell-full">
                            Παραγωγή αναφοράς για: <br/>
                            <div>
                                <!-- <input value="ORGANIZATION" type="radio" data-role="radio" data-caption="Organization" name="radioReportType" v-model="reportType"> -->
                                <input v-if="user && user.roleId==2" value="DEPARTMENT" type="radio" data-role="radio" data-caption="Τάξη" name="radioReportType" v-model="reportType">
                                <input value="TEAM" type="radio" data-role="radio" data-caption="Τμήμα" name="radioReportType" v-model="reportType">
                                <input value="STUDENT" type="radio" data-role="radio" data-caption="Μαθητή" name="radioReportType" v-model="reportType">
                            </div>
                        </div>

                        <div class="cell-12" v-if="reportType=='ORGANIZATION'">
                            <div class="input" style="width:100%;max-width:600px">  
                                <vue-select  @search="fetchOrganizationOptions" label="name" :reduce="(v)=>v.id" v-model="organizationId" :filterable="false" :options="optionsOrganizations">
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.name }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.name }}
                                        </div>
                                    </template>
                                </vue-select>
                                <div class="prepend">Organization:</div>
                            </div>
                        </div> 
                        <div class="cell-sm-12" v-if="reportType=='DEPARTMENT'">
                            <div class="input" style="width:100%;max-width:600px">  
                                <vue-select label="name" :reduce="(v)=>v.id.toString()" v-model="departmentId" :filterable="false" :options="optionsDepartments">
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.name }} - {{option.organizationName}}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.name }} - {{option.organizationName}}
                                        </div>
                                    </template>
                                </vue-select>
                                <div class="prepend">Τάξη:</div>
                            </div>
                        </div> 
                        <div class="cell-sm-12" v-if="reportType=='TEAM' || reportType=='STUDENT'">
                            <div class="input" style="width:100%;max-width:600px">  
                                <vue-select  label="name" :reduce="(v)=>v.id.toString()" v-model="teamId" :filterable="false" :options="optionTeams">
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.name }} - {{option.departmentName}} - {{option.organizationName}}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.name }} - {{option.departmentName}} - {{option.organizationName}}
                                        </div>
                                    </template>
                                </vue-select>
                                <div class="prepend">Τμήμα:</div>
                            </div>
                        </div> 
                        <div class="cell-sm-12" v-if="reportType=='STUDENT'">
                            <div class="input" style="width:100%;max-width:600px">  
                                <vue-select label="sirname" :reduce="(v)=>v.id.toString()" v-model="studentId" :filterable="false" :options="optionsStudents">
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.sirname }} {{ option.name }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.sirname }} {{ option.name }}
                                        </div>
                                    </template>
                                </vue-select>
                                <div class="prepend">Μαθητής:</div>
                            </div>
                        </div> 
               
                        <div class="cell-sm-full cell-md-6">
                            <div>Από: </div>
                            <input id="from-datepicker" data-role="datepicker" v-model="from">
                        </div>
                        <div class="cell-sm-full cell-md-6">
                            <div>Έως: </div>
                            <input id="to-datepicker" data-role="datepicker" v-model="to">
                        </div>
                        <div class="cell-full">
                            <button class="button primary" @click.prevent="onGenerateReport" :disabled="!studentId && !teamId && !organizationId">
                                Δημιουργία αναφοράς
                            </button>
                            <button class="button primary" @click.prevent="onPrint" :disabled="!reportGenerated">
                                Εκτύπωση αναφοράς
                            </button>
                            <div data-role="progress" data-type="line" data-small="true" v-if="generatingReport" style="margin:10px 0px"></div>
                        </div>
                    </div>
                </div>
                <div id="report" class="report-area" v-if="!initializing && !generatingReport && reportReady" style="width:29cm">
                    <div class="row">
                        <div class="cell-full">
                            <div class="report" style="padding:10px;width:28cm">
                                <div class="report-header">
                                    <div class="logo">
                                        {{organization.name}}<br/>
                                        {{organization.address}}<br/>
                                    </div>
                                    <div class="title">
                                        Έκθεση περιγραφικής αξιολόγησης 
                                        <div style="font-size:12px; font-weight:normal">
                                            {{from | toDateStr}} - {{to | toDateStr}}
                                        </div>
                                        <div style="font-size:12px; font-weight:normal" v-if="reportType=='STUDENT'">
                                            {{student.sirname}} {{student.name}}
                                        </div>
                                    </div>
                                </div>
                                <div class="report-section">
                                    <div style="font-size:24px;font-weight:bold;width:100%;margin-bottom:10px;margin-top:10px">
                                        <span v-if="reportType=='STUDENT'">{{student.sirname}} {{student.name}}</span>
                                        <span v-if="reportType=='TEAM'">{{team.name}}</span>
                                        <span v-if="reportType=='DEPARTMENT'">{{department.name}}</span>
                                    </div>
                                    <div style="display:flex;align-items:center">
                                        <div style="flex:0 0 30px">
                                            <img src="../../assets/admin/absent.png" alt="" style="width:30px">
                                        </div>
                                        <div style="flex:0 0 30px;margin-left:10px;font-size:20px;font-weight:bold;color:#666">
                                            {{absent}}
                                        </div>
                                        <div style="flex:0 0 30px">
                                            <img src="../../assets/admin/like.png" alt="" style="width:30px">
                                        </div>
                                        <div style="flex:0 0 30px;margin-left:10px;font-size:20px;font-weight:bold;color:#666">
                                            {{likes}}
                                        </div>
                                        <div style="flex:0 0 30px">
                                            <img src="../../assets/admin/dislike.png" alt="" style="width:30px">
                                        </div>
                                        <div style="flex:0 0 30px;margin-left:10px;font-size:20px;font-weight:bold;color:#666">
                                            {{dislikes}}
                                        </div>
                                    </div>
                                    <div style="margin-top:10px" v-if="reportType=='STUDENT'">
                                        <div v-for="(sd,idx) in getStudentRemarks('absent')" :key="idx" style="display:flex;align-items:center">
                                            <div style="flex:0 0 30px">
                                                <img src="../../assets/admin/absent.png" alt="" style="width:20px">
                                            </div>
                                            <div style="flex:0 0 100px;margin-left:10px">
                                                {{sd.date | toDateStr}}
                                            </div>
                                            <div style="flex:1 1 auto">
                                                {{sd.remarks}}
                                            </div>
                                        </div>
                                        <div v-for="(sd,idx) in getStudentRemarks('likes')" :key="idx" style="display:flex;align-items:center">
                                            <div style="flex:0 0 30px">
                                                <img src="../../assets/admin/like.png" alt="" style="width:20px">
                                            </div>
                                            <div style="flex:0 0 100px;margin-left:10px">
                                                {{sd.date | toDateStr}}
                                            </div>
                                            <div style="flex:1 1 auto">
                                                {{sd.remarks}}
                                            </div>
                                        </div>
                                        <div v-for="(sd,idx) in getStudentRemarks('dislikes')" :key="idx" style="display:flex;align-items:center">
                                            <div style="flex:0 0 30px">
                                                <img src="../../assets/admin/dislike.png" alt="" style="width:20px">
                                            </div>
                                            <div style="flex:0 0 100px;margin-left:10px">
                                                {{sd.date | toDateStr}}
                                            </div>
                                            <div style="flex:1 1 auto">
                                                {{sd.remarks}}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="course in getAvailableCourses(courses,team.id)" :key="course.id">
                                        <div class="report-section-header">
                                            {{course.name}}
                                        </div>
                                        <div style="text-align: center;font-size: 43px;font-weight: bold;">
                                            {{getStudentScoreInCourse(course)}}/100
                                        </div>
                                        <div>
                                            <div style="width:100%;margin-top:-130px">
                                                <apexchart style="width:100%;" ref="charts" type="radar" :options="getSeries3ChartOptionsForCourse(course.id)" :series="getSeries3DataForCourse(course.id)"></apexchart>
                                                
                                            </div>
                                            <div style="font-size:16px;padding:0px 20px;margin-bottom:20px;margin-top:-130px">
                                                <span v-if="reportType=='STUDENT'">Ο/Η μαθητής(ια) {{student.sirname}} {{student.name}}</span>
                                                <span v-if="reportType=='TEAM'">Το τμήμα {{team.name}}</span>
                                                <span v-if="reportType=='DEPARTMENT'">Η τάξη {{department.name}}</span>
                                                στο μάθημα {{course.name}} καθόλη τη διάρκεια της περιόδου
                                                είχε τις παρακάτω επιδόσεις κατά μέσο όρο: <br/><br/>
                                                <div v-for="rp in course.rubrik.rubrikParts" :key="rp.id">
                                                    <span style="font-weight:bold">{{rp.description}} μ.ο: {{getPartScoreMean(course.scoresByDate,rp.id,rp.steps)}} ({{getPartScoreAmount(course.scoresByDate,rp.id)}} αξιολογήσεις.)</span><br/> 
                                                    <span v-html="getPartScoreMeanLectical(course.scoresByDate,rp.id,rp.steps,rp.stepDescriptions)">

                                                    </span>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        <div style="margin:40px 0px" class="page-break">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="font-size:10px;padding:2px;text-align:right">
                                                            ΚΡΙΤΗΡΙΟ
                                                        </th>
                                                        <th style="font-size:10px;padding:2px;text-align:right">
                                                            ΒΑΡΥΤΗΤΑ
                                                        </th>
                                                        <th v-for="d in course.scoresByDate" :key="d.idx" style="text-align:right;font-size:10px;padding:2px" v-html="toDateStr(d.date)">
                                                        </th>
                                                        <th  style="font-size:10px;padding:2px;text-align:right">
                                                            ΣΥΝΟΛΟ <br/>ΑΞΙΟΛΟΓΗΣΕΩΝ
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="rp in course.rubrik.rubrikParts" :key="rp.id">
                                                        <td style="padding:2px;text-align:right">
                                                            {{rp.name}}
                                                        </td>
                                                        <td style="padding:2px;text-align:right">
                                                            {{rp.weight}}%
                                                        </td>
                                                        <td v-for="d in course.scoresByDate" :key="d.idx" style="padding:2px;text-align:right">
                                                            {{getPartScore(d.scores,rp.id)}}/{{rp.steps}}
                                                        </td>
                                                        <td style="padding:2px;text-align:right">
                                                            {{getPartScoreAmount(course.scoresByDate,rp.id)}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <apexchart  ref="charts" type="line" height="350" :options="getSeries1ChartOptionsForCourse(course.id)" :series="getSeries1DataForCourse(course.id)"></apexchart>
                                        </div>
                                        <div>
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            ΚΡΙΤΗΡΙΟ
                                                        </th>
                                                        <th colspan="2" v-for="s of getMaxSteps(course.rubrik.rubrikParts)" :key="s">
                                                            {{s}}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(rp,idx) of course.rubrik.rubrikParts" :key="idx">
                                                        <td>
                                                            {{rp.description}} 
                                                            <!-- ({{rp.id}}) -->
                                                        </td>
                                                        <template v-for="s of rp.steps">
                                                        <td :key="s">
                                                            {{getPartScoreAmount(course.scoresByDate,rp.id,s)}}
                                                        </td>
                                                        <td  :key="s">
                                                            {{getPartScorePercentage(course.scoresByDate,rp.id,s)=='0%'?'-':getPartScorePercentage(course.scoresByDate,rp.id,s)}}
                                                        </td>
                                                        </template>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div style="display:flex;margin-top:10px;flex-wrap: wrap;">
                                                <div style="flex:0 0 25%" id="chart" v-for="(rp,idx) of course.rubrik.rubrikParts" :key="idx">
                                                    <div style="font-weight:bold;text-align:center;height:40px">
                                                        {{rp.name}}
                                                    </div>
                                                    <apexchart  ref="charts" type="donut" :options="getSeries2ChartOptionsForCourse(course,rp)" :series="getSeries2DataForCourse(course,rp)"></apexchart>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import _ from 'underscore';
import moment from 'moment';


import settings from '../../settings'
import globals from '../../globals'
export default {
    props:{
        id:undefined
    },
    watch:{
        reportType(){
            this.generatingReport=false;
            this.reportReady=false;
        },
        async teamId(){
            this.optionsStudents=[];
            let r=await axios.post(`${settings.serviceUrl}student/getMany`, {tokenValue:globals.getToken(),item:{teamId:this.teamId},sort:[{field:'sirname',direction:'ASC'}]});
            this.optionsStudents=r.data.payload.items;
        }
    },
    computed:{
        user(){
            return this.$store.state.user;
        }
    },
    data(){
        return {
            reportGenerated:false,
            teacherUserToCourse:undefined,
            studentdays:[],
            absent:undefined,
            likes:undefined,
            dislikes:undefined,
            reportType:undefined,
            organizationId:undefined,
            optionsOrganizations:[],
            departmentId:undefined,
            optionsDepartments:[],
            teamId:undefined,
            optionTeams:undefined,
            optionsStudents:[],
            studentId:undefined,
            student:undefined,
            team:undefined,
            department:undefined,
            organization:undefined,
            courses:[],
            from:moment().add(-3,'months').toDate(),
            to:new Date(),
            initializing:true,
            generatingReport:false,
            reportReady:false,
            rubriks:[],
            series1: [],
            chart1Options:[],
            chart1OptionsTemplate: {
                chart: {height: 350,type: 'line'},
                dataLabels: {enabled: false},
                stroke: {curve: 'straight'},
                title: {text: '',align: 'left'},
                grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
                },
                xaxis: {
                    categories: [],
                },
                legend: {
                    position:'right'
                }
            },
            series2: [],
            chart2Options:[],
            chart2OptionsTemplate: {
                chart: {type: 'donut'},
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {width: 200},
                        legend: {position: 'bottom'}
                    }
                }]
            },
            series3:[],
            chart3Options:[],
            chart3OptionsTemplate: {
                chart: {
                    type: 'radar',
                },
                title: {
                text: ''
                },
                xaxis: {
                    categories: []
                },
                yaxis:{
                    max:5,
                    min:0,
                    tickAmount:5  
                }
            },
        }
    },
    filters:{
        toDateStr(v){
            let str=moment(v).format('DD/MM');
            str=`${str}/${moment(v).format('YYYY')}`
            return str
        }
    },
    mounted(){
        const action=async ()=>{
            this.teacherUserToCourse=undefined;
            if (this.user && this.user.roleId==3){
                let r=await axios.post(`${settings.serviceUrl}usertocourse/getMany`, {tokenValue:globals.getToken(),item:{userId:this.user.info.id}});
                let data=r.data.payload.items;
                this.teacherUserToCourse=data;
            }
            if (this.id){
                this.reportType='STUDENT';
                this.studentId=this.id;
                let r=await axios.post(`${settings.serviceUrl}student/get`, {tokenValue:globals.getToken(),id:this.id});
                this.student=r.data.payload;
                this.absent=this.student.absent?this.student.absent:0;
                this.likes=this.student.likes?this.student.likes:0;
                this.dislikes=this.student.dislikes?this.student.dislikes:0;
                this.optionsStudents=[this.student];
                r=await axios.post(`${settings.serviceUrl}studentday/getMany`, {tokenValue:globals.getToken(),item:{from:this.from,to:this.to,studentId:this.id},sort:[{field:'date',direction:'ASC'}]});
                this.studentdays=r.data.payload.items;
            }
            let r=await axios.post(`${settings.serviceUrl}department/getMany`, {tokenValue:globals.getToken(),item:{}});
            this.optionsDepartments=r.data.payload.items;
            let teamIds=undefined
            if (this.teacherUserToCourse)
                teamIds=this.teacherUserToCourse.map(x=>x.teamId);

            r=await axios.post(`${settings.serviceUrl}student/getMany`, {tokenValue:globals.getToken(),item:{teamIds},sort:[{field:'sirname',direction:'ASC'}]});
            this.optionsStudents=r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}team/getMany`, {tokenValue:globals.getToken(),item:{teamIds}});
            this.optionTeams= r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}organization/getMany`, {tokenValue:globals.getToken(),item:{}});
            this.optionsOrganizations= r.data.payload.items;

            this.$nextTick(()=>{setTimeout(()=>{
                Metro.getPlugin('#from-datepicker','datepicker').options.onSet=(v)=>{this.from=v;}
                Metro.getPlugin('#to-datepicker','datepicker').options.onSet=(v)=>{this.to=v;}
            },500)})
            this.initializing=false;
        };
        action();
        
    },
    methods:{
        async onPrint(){
            for (let ref of this.$refs.charts){
                let chartImg=await ref.dataURI();
                ref.$el.innerHTML=`<img src="${chartImg.imgURI}" style="width:100%"/>`
            }
            let garbage=document.querySelectorAll(".resize-triggers");
            for (let g of garbage)
                g.remove();
            this.$htmlToPaper("report");
            
        },
        getAvailableCourses(courses,teamId){
            if (this.teacherUserToCourse==undefined) return courses;
            let courseIds=(_.where(this.teacherUserToCourse,{teamId:parseInt(teamId)})).map(x=>x.courseId);
            let rlist=[];
            for (let c of courses)
                if (courseIds.indexOf(c.id)!=-1)
                    rlist.push(c);
            return rlist;
        },
        getStudentRemarks(mode){
            if (mode=="absent"){
                let entries=_.filter(this.studentdays,(sd)=>{return sd.remarks && sd.absent==1});
                return entries.map(x=>{return {
                    date:x.date,
                    remarks:x.remarks
                }});
            }
            if (mode=="likes"){
                let entries=_.filter(this.studentdays,(sd)=>{return sd.remarks && sd.like==1});
                return entries.map(x=>{return {
                    date:x.date,
                    remarks:x.remarks
                }});
            }
            if (mode=="dislikes"){
                let entries=_.filter(this.studentdays,(sd)=>{return sd.remarks && sd.like==0});
                return entries.map(x=>{return {
                    date:x.date,
                    remarks:x.remarks
                }});
            }
        },
        toDateStr(v){
            let str=moment(v).format('DD/MM');
            str=`${str}<br/>${moment(v).format('YYYY')}`
            return str
        },
        async generateReport(){
            this.reportReady=false;
            this.generatingReport=true;
            this.reportGenerated=false;
            let studentId=undefined;
            let teamId=undefined;
            let departmentId=undefined;
            let organizationId=undefined;

            this.series1=[];
            this.chart1Options=[];
            this.series2=[];
            this.chart2Options=[];
            this.series3=[];
            this.chart3Options=[];
            if (this.reportType=='STUDENT'){
                studentId=this.studentId;
                let r=await axios.post(`${settings.serviceUrl}student/get`, {tokenValue:globals.getToken(),id:studentId});
                this.student=r.data.payload;
                
                r=await axios.post(`${settings.serviceUrl}studentday/getMany`, {tokenValue:globals.getToken(),item:{from:this.from,to:this.to,studentId},sort:[{field:'date',direction:'ASC'}]});
                this.studentdays=r.data.payload.items;
                this.absent=_.filter(this.studentdays,(d)=>d.absent==1).length; 
                this.likes=_.filter(this.studentdays,(d)=>d.like==1).length; 
                this.dislikes=_.filter(this.studentdays,(d)=>d.like==-1).length; 

                // this.absent=this.student.absent?this.student.absent:0;
                // this.likes=this.student.likes?this.student.likes:0;
                // this.dislikes=this.student.dislikes?this.student.dislikes:0;

                // if (!this.student || this.student.id!=this.studentId){
                    
                // }

                teamId=this.teamId;
                r=await axios.post(`${settings.serviceUrl}team/get`, {tokenValue:globals.getToken(),id:teamId});
                this.team=r.data.payload;

                departmentId=this.team.departmentId;
                r=await axios.post(`${settings.serviceUrl}department/get`, {tokenValue:globals.getToken(),id:departmentId});
                this.department=r.data.payload;

                organizationId=this.department.organizationId;
                r=await axios.post(`${settings.serviceUrl}organization/get`, {tokenValue:globals.getToken(),id:organizationId});
                this.organization=r.data.payload;
            }
            if (this.reportType=='DEPARTMENT'){
                departmentId=this.departmentId;
                let r=await axios.post(`${settings.serviceUrl}department/get`, {tokenValue:globals.getToken(),id:departmentId});
                this.department=r.data.payload;
                organizationId=this.department.organizationId;
                r=await axios.post(`${settings.serviceUrl}organization/get`, {tokenValue:globals.getToken(),id:organizationId});
                this.organization=r.data.payload;
            }
            if (this.reportType=='TEAM'){
                teamId=this.teamId;
                let r=await axios.post(`${settings.serviceUrl}team/get`, {tokenValue:globals.getToken(),id:teamId});
                this.team=r.data.payload;

                departmentId=this.team.departmentId;
                r=await axios.post(`${settings.serviceUrl}department/get`, {tokenValue:globals.getToken(),id:departmentId});
                this.department=r.data.payload;

                organizationId=this.department.organizationId;
                r=await axios.post(`${settings.serviceUrl}organization/get`, {tokenValue:globals.getToken(),id:organizationId});
                this.organization=r.data.payload;
            }

            
            let r=await axios.post(`${settings.serviceUrl}course/getMany`, {tokenValue:globals.getToken(),item:{departmentId}});
            this.courses=r.data.payload.items;
            for (let course of this.courses){
                let existingRubrik=_.findWhere(this.rubriks,{id:course.rubrikId});
                if (!existingRubrik){
                    r=await axios.post(`${settings.serviceUrl}rubrik/get`, {tokenValue:globals.getToken(),id:course.rubrikId});
                    existingRubrik=r.data.payload;
                    r=await axios.post(`${settings.serviceUrl}rubrikPart/getMany`, {tokenValue:globals.getToken(),item:{rubrikId:existingRubrik.id}});
                    existingRubrik.rubrikParts=r.data.payload.items;
                    this.rubriks.push(existingRubrik);
                }
                course.rubrik=existingRubrik;
                //TODO: Here I should add the filter for the dates

                if (this.reportType=='STUDENT'){
                    r=await axios.post(`${settings.serviceUrl}rubrikScore/getMany`, {tokenValue:globals.getToken(),sort:[{field:'date',direction:'ASC'}],item:{from:this.from,to:this.to,courseId:course.id,studentId:this.student.id,rubrikId:course.rubrikId}});
                    course.scoresRaw=r.data.payload.items;
                }
                if (this.reportType=='DEPARTMENT'){
                    r=await axios.post(`${settings.serviceUrl}rubrikScore/getMany`, {tokenValue:globals.getToken(),sort:[{field:'date',direction:'ASC'}],item:{from:this.from,to:this.to,courseId:course.id,rubrikId:course.rubrikId}});
                    course.scoresRaw=r.data.payload.items;
                    let r2=await axios.post(`${settings.serviceUrl}student/getMany`, {tokenValue:globals.getToken(),item:{departmentId}});
                    let departmentStudents=r2.data.payload.items;
                    this.absent=departmentStudents.reduce((b,a)=>{return (a.absent?a.absent:0)+b},0);
                    this.likes=departmentStudents.reduce((b,a)=>{return (a.likes?a.likes:0)+b},0);
                    this.dislikes=departmentStudents.reduce((b,a)=>{return (a.dislikes?a.dislikes:0)+b},0);
                }
                if (this.reportType=='TEAM'){
                    r=await axios.post(`${settings.serviceUrl}rubrikScore/getMany`, {tokenValue:globals.getToken(),sort:[{field:'date',direction:'ASC'}],item:{from:this.from,to:this.to,courseId:course.id,rubrikId:course.rubrikId}});
                    let r2=await axios.post(`${settings.serviceUrl}student/getMany`, {tokenValue:globals.getToken(),item:{teamId}});
                    let teamStudents=r2.data.payload.items;
                    this.absent=teamStudents.reduce((b,a)=>{return (a.absent?a.absent:0)+b},0);
                    this.likes=teamStudents.reduce((b,a)=>{return (a.likes?a.likes:0)+b},0);
                    this.dislikes=teamStudents.reduce((b,a)=>{return (a.dislikes?a.dislikes:0)+b},0);

                    let teamStudentIds=teamStudents.map(x=>x.id);
                    course.scoresRaw=_.filter(r.data.payload.items,(s)=>{return teamStudentIds.indexOf(s.studentId)!=-1});
                }

                course.scoresByDate=[];
                let idx=-1;
                for (let sr of course.scoresRaw){
                    let existingDate=_.findWhere(course.scoresByDate,{date:sr.date});
                    if (!existingDate){
                        idx++;
                        existingDate={
                            idx,
                            date:sr.date,
                            scores:[]
                        }
                        course.scoresByDate.push(existingDate);
                    }
                    sr.rubrik=existingRubrik;
                    sr.rubrikPart=_.findWhere(existingRubrik.rubrikParts,{id:sr.rubrikPartId});
                    existingDate.scores.push(sr);
                }
            }
            //Chart creation for each lesson
            for (let course of this.courses){
                if (course.scoresByDate){
                    let xaxisSeries1=course.scoresByDate.map(x=>moment(x.date).format('DD/MM/YYYY'));
                    let rpScoresSeries1=[];
                    for (let rp of course.rubrik.rubrikParts){
                        let rpScore={id:rp.id,name:rp.description,data:[]};
                        for (let sbd of course.scoresByDate){
                            let score=this.getPartScore(sbd.scores,rp.id,true);
                            if (score==undefined) score=this.getPartScoreMean(course.scoresByDate,rp.id);
                            rpScore.data.push(score);
                        }
                        rpScoresSeries1.push(rpScore);
                    }
                    this.series1.push({courseId:course.id,data:rpScoresSeries1});
                    let chart1Options=JSON.parse(JSON.stringify(this.chart1OptionsTemplate));
                    chart1Options.xaxis.categories=xaxisSeries1;
                    this.chart1Options.push({
                        courseId:course.id,
                        data:chart1Options
                    });
                }
            }

            for (let course of this.courses){
                let rubrik=course.rubrik;
                for (let rp of rubrik.rubrikParts){
                    let scorePercentages=[]
                    let chart2Options=JSON.parse(JSON.stringify(this.chart2OptionsTemplate));
                    chart2Options.labels=[];
                    for (let s=0;s<rp.steps;s++){
                        let scorePercentage=parseInt(this.getPartScorePercentage(course.scoresByDate,rp.id,s+1).replace("%",''));
                        if (isNaN(scorePercentage)) scorePercentage=0;
                        scorePercentages.push(scorePercentage);
                        chart2Options.labels.push(s+1);
                    }
                    this.series2.push({
                        courseId:course.id,
                        rpId:rp.id,
                        scorePercentages
                    });
                    this.chart2Options.push({
                        courseId:course.id,
                        rpId:rp.id,
                        chart2Options
                    })
                }
            }

            for (let course of this.courses){
                let rubrik=course.rubrik;
                let chart3Options=JSON.parse(JSON.stringify(this.chart3OptionsTemplate));
                let series={};
                series.name=rubrik.name;
                series.data=[];
                for (let rp of rubrik.rubrikParts){
                    chart3Options.xaxis.categories.push(rp.name);
                    series.data.push(this.getPartScoreMean(course.scoresByDate,rp.id));
                }
                this.series3.push({
                    courseId:course.id,
                    series
                })
                this.chart3Options.push({
                    courseId:course.id,
                    chart3Options
                })
            }
            this.generatingReport=false;
            this.reportReady=true;
            this.reportGenerated=true;
        },
        getPartScore(scores,rpId,onlyValue){
            let score=_.where(scores,{rubrikPartId:rpId});
            if(!score || score.length==0) return onlyValue?undefined:"-";
            if (score.length==1)
                return score[0].score;
            if (score.length>1){
                let mean=0;
                score.forEach((s)=>{
                    mean+=s.score;
                });
                mean=(mean/score.length).toFixed(1);
                return onlyValue?parseFloat(mean):mean+`(${score.length})`;
            }
        },
        getPartScoreAmount(scoresByDay,rpId,scoreFilter){
            let amount=0;
            for (let s of scoresByDay){
                let score=Math.round(this.getPartScore(s.scores,rpId,true));
                if (score!=undefined) {
                    if (scoreFilter==undefined) amount++;
                    else{
                        if (score==scoreFilter) amount++
                    }
                }
            }

            return amount;
        },
        getPartScorePercentage(scoresByDay,rpId,scoreFilter){
            let amount=0;
            let total=0;
            for (let s of scoresByDay){
                let score=Math.round(this.getPartScore(s.scores,rpId,true));
                if (!isNaN(score)) {
                    if (score==scoreFilter) amount++;
                    total++;
                }
            }
            if (total==0) return "-";
            return Math.round((amount/total)*100)+'%';
        },
        getPartScoreMean(scoresByDay,rpId){
            if (!scoresByDay) return 0;
            let amount=0;
            let count=0;
            for (let s of scoresByDay){
                let score=this.getPartScore(s.scores,rpId,true);
                if (score!=undefined) {
                    amount+=score;
                    count++;
                }
            }
            if (count==0) return 0;
            return Math.round(amount/count);
        },
        getStudentScoreInCourse(course){
            let weightedSum=0;
            let totalMaxWeightedSum=0;
            let rubrik=course.rubrik;
            for (let rp of rubrik.rubrikParts){
                let mean=this.getPartScoreMean(course.scoresByDate,rp.id);
                weightedSum+=mean*(rp.weight/100);
                totalMaxWeightedSum+=rp.steps*(rp.weight/100);
            }

            let total=Math.round((weightedSum/totalMaxWeightedSum)*100);
            if (total<40) total=40;

            return total;

        },
        getPartScoreMeanLectical(scoresByDay,rpId,rpMaxScore,stepDescriptions){
            let lecticals=['σπανια','μερικές φορές','συχνα','τις περισσότερες φορές','πάντα'];
            let stepDescriptionsArray=stepDescriptions.split('|');
            if (!scoresByDay) return 0;
            let amount=0;
            let count=0;
            
            let stepCounts=[];
            for (let i=0;i<rpMaxScore;i++){
                stepCounts.push({
                    min:i+1,
                    max:i+2,
                    count:0,
                    stepDescription:stepDescriptionsArray[i]
                });
            }
            for (let s of scoresByDay){
                let score=this.getPartScore(s.scores,rpId,true);
                if (score!=undefined) {
                    count++;
                    let stepCount=_.find(stepCounts,(sc)=>{return score>=sc.min && score<sc.max});
                    stepCount.count++;
                }
            }
            if (count==0) return 'Δεν αξιολογήθηκε ποτέ';

            let returnString='';
            for (let sc of stepCounts){
                sc.percentage=sc.count/count;
                if (sc.percentage<=0.25) sc.lectical=lecticals[0];
                if (sc.percentage>0.25 && sc.percentage<=0.5) sc.lectical=lecticals[1];
                if (sc.percentage>0.5 && sc.percentage<=0.75) sc.lectical=lecticals[2];
                if (sc.percentage>0.75 && sc.percentage<1) sc.lectical=lecticals[3];
                if (sc.percentage==1) sc.lectical=lecticals[4];
                if (sc.percentage>0)
                    returnString+=`${sc.lectical} ${sc.stepDescription} (${(sc.percentage*100).toFixed(2)}%)<br/>`
            }
            

            return returnString;
        },
        getSeries1ChartOptionsForCourse(id){
            return _.findWhere(this.chart1Options,{courseId:id}).data;
        },
        getSeries1DataForCourse(id){
            return _.findWhere(this.series1,{courseId:id}).data;
        },
        getSeries2ChartOptionsForCourse(course,rp){
            return _.find(this.chart2Options,(d)=>{return d.courseId==course.id && d.rpId==rp.id}).chart2Options;
        },
        getSeries2DataForCourse(course,rp){
            return _.find(this.series2,(d)=>{return d.courseId==course.id && d.rpId==rp.id}).scorePercentages;
        },
        getSeries3ChartOptionsForCourse(id){
            return _.findWhere(this.chart3Options,{courseId:id}).chart3Options;
        },
        getSeries3DataForCourse(id){
            return [_.findWhere(this.series3,{courseId:id}).series];
        },
        getMaxSteps(rps){
            let maxSteps=0;
            rps.forEach((rp)=>{
                if (rp.steps>maxSteps) maxSteps=rp.steps;
            })

            return maxSteps;
        },
        onGenerateReport(){
            this.generateReport();
        }
    }
}
</script>
<style lang="less" scoped>
.tools-area{
    width:100%;
    background-color: white;
    padding:10px;
}
.report-area{
    margin-top:20px;
    background-color: white;
    padding:10px;
}
.report{
    .page-break{
        page-break-before: always;
        margin-top:0px !important;
    }
    .report-header{
        display:flex;
        align-items:center;
        border-bottom:1px solid rgb(148, 148, 148);
        padding-bottom:10px;
        .logo{
            flex:0 0 auto;
        }
        .title{
            flex:1 1 auto;
            font-size: 14px;
            font-weight:bold;
            margin-left:20px;
            text-align:right;
        }

    }
    .report-section{
        .report-section-header{
                font-weight: bold;
                font-size: 19px;
                margin-bottom: 13px;
                background-color: #eaeaea;
                padding: 10px;
                text-align: center;
                margin-top: 38px;
        }
    }
}
</style>