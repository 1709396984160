<template>
<div class="ui-blocker" v-show="show">
    <div class="content">
        <img src='../../assets/admin-lgo.png'>
        <span class="title">Connection to server</span> <br/>
        <span class="text">Getting data from server...</span>
    </div>
</div>
</template>

<script>
export default {
    name: 'ui-blocker',
    data() {
        return {
            show: false,
            loadingCounter: 0
        }
    },
    created() {},
    watch: {
        loadingCounter() {
            if (this.loadingCounter == 0)
                this.show = false;
            else
                this.show = true;
        }
    },
    mounted() {
        const that = this;
        this.$root.$on("ui-blocker", (v) => {
            this.loadingCounter=this.loadingCounter+v;
            console.log(`[UIBlocker:loadingCounter]:${this.loadingCounter}`);
            if (this.loadingCounter<0)
                this.loadingCounter=0;
        });
    },
    methods: {
        inc(){
            this.loadingCounter++
        },
        dec(){
            if (this.loadingCounter>0)
                this.loadingCounter--
        }
    }
}
</script>

<style lang="less">
.ui-blocker {
    position: fixed;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    bottom: 0px;
    top: 0px;
    z-index: 10000;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        opacity: 0.7;

        .title {
            font-size: 24px;
            text-align: center;
            display: block;
        }

        .text {
            padding: 0px 10px;
            text-align: center;
            display: block;
            font-size: 16px;
        }

        img {
            display: block;
            // width: 40%;
            margin: 0px auto;
        }
    }
}
</style>
