
<template>
  <div class="widget-container scrollbar-style">
    <div data-role="progress" data-type="line" data-small="true" v-if="loading"></div>
    <div :style="{color:color?color:'#000'}" style="font-size:46px;font-weight:bold;text-align:center">
      {{number}}
      <div style="font-size:10px;margin-top:-12px;color:#000" v-html="label"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "underscore";
import moment from "moment";

import settings from '../../../settings.js'
import globals from '../../../globals.js'

export default {
  props:{
    minValue:undefined,
    maxValue:undefined,
    value:undefined,
    color:undefined,
    type:undefined,
    tableName:undefined,
    sqlQuery:undefined,
    sqlParams:undefined,
    label:undefined
  },
  computed:{
  },
  data() {
    return {
      loading:true,
      ok:null,
      number:0
    };
  },
  mounted() {
    if (this.type=='RANDOM')
      this.number=Math.floor(Math.random()*(this.maxValue-this.minValue)+this.minValue);
    if (this.type=='VALUE')
      this.number=this.value;
    if (this.type=='TABLE_COUNT')
      this.getValueTable();
    if (this.type=='SQL')
      this.getValueSQL();

    this.loading=false;
  },
  methods: {
    async getValueTable() {
      this.loading=true;
      this.lastUpdate=moment().format('DD/MM/YYYY HH:mm:ss');
      axios.post(`${settings.serviceUrl}getCounts`,{tableName:this.tableName}).then((r)=>{
        this.ok=r.data.ok;
        this.loading=false;
        this.number=r.data.payload
      });
    },
    async getValueSQL() {
        this.loading=true;
        this.lastUpdate=moment().format('DD/MM/YYYY HH:mm:ss');
        let r=await axios.post(`${settings.serviceUrl}sql`,{tokenValue:globals.getToken(),
            sqlQuery:this.sqlQuery,
            sqlParams:this.sqlParams
        });
        this.loading=false;
        this.number=r.data.payload[0].absent;
    }
    
  }
};
</script>

<style lang="less" scoped>
.widget-container {
  height: 100%;
  overflow: auto;
}
</style>