var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-server-summary-container scrollbar-style" },
    [
      _vm.dbLoading || _vm.authLoading
        ? _c("div", { staticClass: "progress" }, [
            _c("div", { staticClass: "indeterminate" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "db-status" }, [
        _c("strong", [_vm._v("DB Connection: ")]),
        _vm._v(" "),
        _vm.dbLoading ? _c("span", [_vm._v("checking...")]) : _vm._e(),
        _vm._v(" "),
        !_vm.dbLoading && _vm.dbOk != undefined
          ? _c("span", [
              _vm.dbOk
                ? _c("span", [_vm._v("success")])
                : _c("span", [_vm._v("error")]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.dbLoading && _vm.dbOk == undefined
          ? _c("span", [_vm._v("\n          not started\n      ")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "auth-status" }, [
        _c("strong", [_vm._v("Auth Connection: ")]),
        _vm._v(" "),
        _vm.authLoading ? _c("span", [_vm._v("checking...")]) : _vm._e(),
        _vm._v(" "),
        !_vm.authLoading && _vm.authOk != undefined
          ? _c("span", [
              _vm.authOk
                ? _c("span", [_vm._v("success")])
                : _c("span", [_vm._v("error")]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.authLoading && _vm.authOk == undefined
          ? _c("span", [_vm._v("\n          not started\n      ")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "auth-status" }, [
        _c("strong", [_vm._v("Οργανισμοί: ")]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.organizations))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "auth-status" }, [
        _c("strong", [_vm._v("Τάξεις: ")]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.departments))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "auth-status" }, [
        _c("strong", [_vm._v("Τμήματα: ")]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.teams))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }