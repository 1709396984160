import settings from './settings.js';
import globals from './globals.js';
import store from './store.js';

import "./assets/favicon.png"
import "./assets/logo.png"

import axios from 'axios';
import Vue from 'vue';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (settings.env!=='development'){
  Sentry.init({
    dsn:settings.sentryKey,
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
  });
}

import VueHtmlToPaper from "vue-html-to-paper";

Vue.use(VueHtmlToPaper, {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://cdn.metroui.org.ua/v4.3.2/css/metro-all.min.css",
    // `http://127.0.0.1:18101/public/site/print.css`
    `${settings.serviceUrl}public/site/print.css`
  ]
});

import VueRouter from 'vue-router';
import VueSelect from 'vue-select'
import '../../node_modules/vue-select/dist/vue-select.css'
import dataList from './components/admin/dataList.vue';

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)



import App from './app.vue';

import appRootClient from './app-client.vue';
import clientHome from './views/client/home.vue';
import settingsScreen from './views/client/settings.vue';


import clientLogin from './views/client/clientLogin.vue';
import clientRegister from './views/client/clientRegister.vue';

import appRootAdmin from './app-server.vue';
import adminHome from './views/admin/home.vue';
import login from './views/admin/login.vue';
import users from './views/admin/users.vue';
import user from './views/admin/user.vue';
import organizations from './views/admin/organizations.vue';
import organization from './views/admin/organization.vue';
import teams from './views/admin/teams.vue';
import team from './views/admin/team.vue';
import departments from './views/admin/departments.vue';
import department from './views/admin/department.vue';
import rubriks from './views/admin/rubriks.vue';
import rubrik from './views/admin/rubrik.vue';
import courses from './views/admin/courses.vue';
import course from './views/admin/course.vue';
import students from './views/admin/students.vue';
import student from './views/admin/student.vue';
import report from './views/admin/report.vue';

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo)

Vue.use(VueRouter);
Vue.component('vue-select',VueSelect);
Vue.component("data-list", dataList)

const routes=[
  { path: '/', component: appRootClient, props: true,children:[
    {path:'', component:clientHome},
    {path:'settings', component:settingsScreen,props:true},
    {path:'client-login', component:clientLogin, props: true },
    {path:'client-register', component:clientRegister, props: true },
  ]},
  {
    path:'/admin',component:appRootAdmin,props:true,children:[
      {path:'', component:adminHome,props: true,meta:{auth:true} },
      {path:'signin', component:login,props: true,meta:{auth:false} },
      {path: 'users', component: users, props: true,meta:{auth:true} },
      {path: 'users/:id', component: user, props: true,meta:{auth:true} },
      {path: 'organizations', component: organizations, props: true,meta:{auth:true} },
      {path: 'organizations/:id', component: organization, props: true,meta:{auth:true} },
      {path: 'teams', component: teams, props: true,meta:{auth:true} },
      {path: 'teams/:id', component: team, props: true,meta:{auth:true} },
      {path: 'departments', component: departments, props: true,meta:{auth:true} },
      {path: 'departments/:id', component: department, props: true,meta:{auth:true} },
      {path: 'rubriks', component: rubriks, props: true,meta:{auth:true} },
      {path: 'rubriks/:id', component: rubrik, props: true,meta:{auth:true} },
      {path: 'courses', component: courses, props: true,meta:{auth:true} },
      {path: 'courses/:id', component: course, props: true,meta:{auth:true} },
      {path: 'students', component: students, props: true,meta:{auth:true} },
      {path: 'students/:id', component: student, props: true,meta:{auth:true} },
      {path: 'report/', component: report, props: true,meta:{auth:true} },
      {path: 'report/:id', component: report, props: true,meta:{auth:true} },
      
    ]
  }
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach(async (to, from, next) => {
  let token=globals.getToken();
  if (!to.meta.auth) {
    if (!store.state.user && token && to.fullPath!='/admin/signin'){
      let r=await axios.post(`${settings.authServiceUrl}token/getUserFromToken`, { tokenValue: token });
      if (r.data.code!=-1001){
        let u=r.data.payload;
        r=await axios.post(`${settings.serviceUrl}user/getMany`, {item:{authId:u.id}, tokenValue: token });
        u.info=r.data.payload.items[0];
        store.commit('setUser',u);
      }
    }
    next();
    return;
  }
  
  if (!token){
    next('/admin/signin');
    return;
  }
  let r=await axios.post(`${settings.authServiceUrl}token/tokenExpired`, { tokenValue: token });
  if (r.data.code <0) {
    globals.removeToken();
    next('/admin/signin');
    return;
  }
  if (!store.state.user){
    let r=await axios.post(`${settings.authServiceUrl}token/getUserFromToken`, { tokenValue: token });
    if (r.data.code!=-1001){
      let u=r.data.payload;
      r=await axios.post(`${settings.serviceUrl}user/getMany`, {item:{authId:u.id}, tokenValue: token });
      u.info=r.data.payload.items[0];
      store.commit('setUser',u);
    }
  }
  next();
});

let app=new Vue({
  el: '#app',
  render: h => h(App),
  components: { App },
  router,
  store,
  data: {},
  mounted() {
    Metro.init();
  },
  methods: {
  },
});

axios.interceptors.response.use(function (response) {
  if (response.data.code===-1001 || response.data.code===-1){ //No token found in database
    globals.removeToken();
    app.$store.commit('setUser',undefined);
    app.$router.push('/admin/signin');
  }
  return response;
}, function (error) {
  if (settings.env!=='development'){
    Sentry.captureException(new Error(error));
  }
  return Promise.reject(error);
});