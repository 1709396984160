var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page users-list-page admin-list" }, [
    _c("div", { staticClass: "grid" }, [
      _vm.user && _vm.user.roleId == 0
        ? _c(
            "div",
            { staticClass: "filters-area", staticStyle: { padding: "10px" } },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "cell-12 cell-md-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.queryStringData.search.organizationName,
                        expression: "queryStringData.search.organizationName",
                      },
                    ],
                    attrs: {
                      type: "text",
                      "data-clear-button": "false",
                      "data-role": "input",
                      "data-prepend": "Οργανισμός: ",
                    },
                    domProps: {
                      value: _vm.queryStringData.search.organizationName,
                    },
                    on: {
                      keyup: _vm.keyMonitor,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.queryStringData.search,
                          "organizationName",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "controls-area" }, [
          _c("div", { staticClass: "cell-12" }, [
            _c(
              "button",
              { staticClass: "button primary", on: { click: _vm.onSearch } },
              [_c("span", { staticClass: "mif-refresh mif-lg" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "button primary", on: { click: _vm.onAdd } },
              [_c("span", { staticClass: "mif-add mif-lg" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "button primary", on: { click: _vm.onClear } },
              [_c("span", { staticClass: "mif-cross-light mif-lg" })]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "table-area" }, [
          _c("div", { staticClass: "cell-12" }, [
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("vuetable-pagination", {
                  ref: "pagination",
                  staticStyle: { margin: "0px auto" },
                  attrs: { css: _vm.paginationCss },
                  on: { "vuetable-pagination:change-page": _vm.onChangePage },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.initialized
            ? _c(
                "div",
                { staticClass: "cell-12" },
                [
                  _vm.dataLoading
                    ? _c("div", {
                        attrs: {
                          "data-role": "progress",
                          "data-type": "line",
                          "data-small": "true",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("vuetable", {
                    ref: "vuetable",
                    class: { loading: _vm.dataLoading },
                    attrs: {
                      css: _vm.tableCss,
                      "api-url": _vm.apiURL,
                      "http-fetch": _vm.getData,
                      "http-method": "post",
                      "load-on-start": false,
                      fields: _vm.columns,
                      "sort-order": _vm.queryStringData.sort,
                      "per-page": _vm.dataPerPage,
                      "row-class": _vm.onRowClass,
                      "data-path": "payload.items",
                      "pagination-path": "payload.pagination",
                    },
                    on: {
                      "vuetable:loading": _vm.loading,
                      "vuetable:loaded": _vm.loaded,
                      "vuetable:load-success": _vm.loadSuccess,
                      "vuetable:load-error": _vm.loadError,
                      "vuetable:pagination-data": _vm.onPaginationData,
                      "VuetableRowAction:action-item": _vm.onRowAction,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }