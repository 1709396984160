var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters-area" }, [
    _c(
      "div",
      {
        staticClass: "row",
        staticStyle: {
          "background-color": "#0366d6",
          color: "#fff",
          "box-shadow":
            "0 4px 8px rgb(90 97 105 / 12%), 0 10px 10px rgb(90 97 105 / 6%), 0 7px 70px rgb(90 97 105 / 10%)",
        },
        on: {
          click: function () {
            this$1.hidden = !this$1.hidden
          },
        },
      },
      [
        _c("div", { staticClass: "cell-12" }, [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                cursor: "pointer",
                padding: "5px",
              },
            },
            [
              _vm.filtersText
                ? _c("div", {
                    staticStyle: {
                      flex: "1 1 auto",
                      "font-size": "14px",
                      "font-weight": "bold",
                      color: "#fff",
                    },
                    domProps: { innerHTML: _vm._s(_vm.filtersText) },
                  })
                : _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "1 1 auto",
                        "font-size": "14px",
                        "font-weight": "bold",
                        color: "#fff",
                      },
                    },
                    [_vm._v("\n                    ΦΙΛΤΡΑ\n                ")]
                  ),
              _vm._v(" "),
              !_vm.hidden
                ? _c(
                    "div",
                    {
                      staticStyle: { flex: "0 0 40px", "font-weight": "bold" },
                    },
                    [_c("span", { staticClass: "mif-chevron-thin-up mif-2x" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hidden
                ? _c(
                    "div",
                    {
                      staticStyle: { flex: "0 0 40px", "font-weight": "bold" },
                    },
                    [
                      _c("span", {
                        staticClass: "mif-chevron-thin-down mif-2x",
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.queryStringData && !_vm.initializing && !_vm.hidden
      ? _c(
          "div",
          {
            staticClass: "row",
            staticStyle: {
              padding: "10px",
              border: "1px #ccc solid",
              margin: "0px !important",
            },
          },
          [
            _c("div", { staticClass: "cell-sm-12 cell-md-6" }, [
              _c(
                "div",
                { staticClass: "input" },
                [
                  _c("vue-select", {
                    attrs: {
                      label: "name",
                      reduce: function (v) {
                        return v.id.toString()
                      },
                      filterable: false,
                      options: _vm.optionTeams,
                      disabled: _vm.rubrikMode == "true" || _vm.rubrikMode,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "option",
                          fn: function (option) {
                            return [
                              _c("div", { staticClass: "d-center" }, [
                                _vm._v(
                                  "\n                           " +
                                    _vm._s(option.name) +
                                    " - " +
                                    _vm._s(option.departmentName) +
                                    " - " +
                                    _vm._s(option.organizationName) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "selected-option",
                          fn: function (option) {
                            return [
                              _c("div", { staticClass: "selected d-center" }, [
                                _vm._v(
                                  "\n                           " +
                                    _vm._s(option.name) +
                                    " - " +
                                    _vm._s(option.departmentName) +
                                    " - " +
                                    _vm._s(option.organizationName) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3891353668
                    ),
                    model: {
                      value: _vm.queryStringData.search.teamId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryStringData.search, "teamId", $$v)
                      },
                      expression: "queryStringData.search.teamId",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "prepend" }, [_vm._v("Τμήμα:")]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cell-sm-12 cell-md-6" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.queryStringData.search.sirname,
                    expression: "queryStringData.search.sirname",
                  },
                ],
                attrs: {
                  type: "text",
                  "data-clear-button": "false",
                  "data-role": "input",
                  "data-prepend": "Όνομα: ",
                },
                domProps: { value: _vm.queryStringData.search.sirname },
                on: {
                  keyup: _vm.keyMonitor,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.queryStringData.search,
                      "sirname",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.queryStringData.search.teamId
              ? _c("div", { staticClass: "cell-sm-12 cell-md-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.queryStringData.search.rubrikMode,
                        expression: "queryStringData.search.rubrikMode",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      "data-role": "checkbox",
                      "data-caption": "Κατάσταση βαθμολόγησης",
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.queryStringData.search.rubrikMode
                      )
                        ? _vm._i(_vm.queryStringData.search.rubrikMode, null) >
                          -1
                        : _vm.queryStringData.search.rubrikMode,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.queryStringData.search.rubrikMode,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.queryStringData.search,
                                "rubrikMode",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.queryStringData.search,
                                "rubrikMode",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.queryStringData.search,
                            "rubrikMode",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.queryStringData.search.whoToReview,
                        expression: "queryStringData.search.whoToReview",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      "data-role": "checkbox",
                      "data-caption": "Ποιόν να εξετάσω;",
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.queryStringData.search.whoToReview
                      )
                        ? _vm._i(_vm.queryStringData.search.whoToReview, null) >
                          -1
                        : _vm.queryStringData.search.whoToReview,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.queryStringData.search.whoToReview,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.queryStringData.search,
                                "whoToReview",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.queryStringData.search,
                                "whoToReview",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.queryStringData.search,
                            "whoToReview",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.queryStringData.search.rubrikPartsGroupView,
                        expression:
                          "queryStringData.search.rubrikPartsGroupView",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      "data-role": "checkbox",
                      "data-caption": "Προβολή ανά κριτήριο",
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.queryStringData.search.rubrikPartsGroupView
                      )
                        ? _vm._i(
                            _vm.queryStringData.search.rubrikPartsGroupView,
                            null
                          ) > -1
                        : _vm.queryStringData.search.rubrikPartsGroupView,
                    },
                    on: {
                      change: function ($event) {
                        var $$a =
                            _vm.queryStringData.search.rubrikPartsGroupView,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.queryStringData.search,
                                "rubrikPartsGroupView",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.queryStringData.search,
                                "rubrikPartsGroupView",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.queryStringData.search,
                            "rubrikPartsGroupView",
                            $$c
                          )
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("br"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.rubrikMode
              ? _c("div", { staticClass: "cell-sm-12 cell-md-9" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("vue-select", {
                        attrs: {
                          label: "name",
                          reduce: function (v) {
                            return v.id.toString()
                          },
                          filterable: false,
                          options: _vm.optionsCourses,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("div", { staticClass: "d-center" }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(option.name) +
                                        " - " +
                                        _vm._s(option.departmentName) +
                                        "\n                        "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "selected d-center" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(option.name) +
                                          " - " +
                                          _vm._s(option.departmentName) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3774877924
                        ),
                        model: {
                          value: _vm.queryStringData.search.courseId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryStringData.search,
                              "courseId",
                              $$v
                            )
                          },
                          expression: "queryStringData.search.courseId",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "prepend" }, [
                        _vm._v("Μάθημα:"),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.rubrikMode
              ? _c(
                  "div",
                  { staticClass: "cell-sm-12 cell-md-9  offset-md-3" },
                  [
                    _c(
                      "div",
                      { staticClass: "input" },
                      [
                        _c("vue-select", {
                          attrs: {
                            label: "title",
                            reduce: function (v) {
                              return v.id.toString()
                            },
                            filterable: false,
                            options: _vm.optionsLessons,
                          },
                          on: { search: _vm.fetchLessonOptions },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "option",
                                fn: function (option) {
                                  return [
                                    _c("div", { staticClass: "d-center" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(option.title) +
                                          "\n                        "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "selected-option",
                                fn: function (option) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "selected d-center" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(option.title) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3027009796
                          ),
                          model: {
                            value: _vm.queryStringData.search.lessonId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryStringData.search,
                                "lessonId",
                                $$v
                              )
                            },
                            expression: "queryStringData.search.lessonId",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "prepend" }, [
                          _vm._v("Κεφάλαιο:"),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }