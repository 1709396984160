 <template>
    <div class="custom-actions" style="width:80px">
      <button class="button flat-button" @click.prevent="itemAction('delete-item', rowData, rowIndex)"><i class="mif-bin mif-lg"></i></button>
    </div>
 </template>

  <script>
  import axios from 'axios'
  import settings from '../../settings.js';
  import globals from '../../globals.js';
  
  export default {
    props: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    methods: {
      itemAction (action, data, index) {
        debugger;
          let item=data;
          let teamId=this.$parent.$parent.$parent.item.id;
          let teams=item.teamId.split('|').filter(Boolean);
          
          teams.splice(teams.indexOf(teamId),1);
          item.teamId='|'+teams.join('|')+'|';
          if (teams.length==0){
            axios.post(`${settings.serviceUrl}student/remove`, {tokenValue:globals.getToken(),id:item.id});
          }
          else
            axios.post(`${settings.serviceUrl}student/update`, {tokenValue:globals.getToken(),item});

          this.$parent.$parent.onSearch();
          this.$parent.$parent.$parent.loadStudents();
      }
    }
  }
  </script>

  <style <style lang="less" scoped>
 
    .btn{
        padding:0px 5px;
        color:#777;
    }
  </style>