var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: { padding: "10px 0px", "margin-left": "70px" },
        },
        [
          _c("div", { staticClass: "cell-12 cell-md-6" }, [
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("vue-select", {
                  attrs: {
                    label: "name",
                    reduce: function (v) {
                      return v.id
                    },
                    filterable: false,
                    options: _vm.optionsRubriks,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "d-center" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(option.name) +
                                "\n                        "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "selected-option",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "selected d-center" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(option.name) +
                                "\n                        "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.rubrikToCloneId,
                    callback: function ($$v) {
                      _vm.rubrikToCloneId = $$v
                    },
                    expression: "rubrikToCloneId",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "prepend" }, [_vm._v("Rubric:")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell-12 cell-md-6" }, [
            _c(
              "button",
              {
                staticClass: "button primary",
                attrs: {
                  disabled: !_vm.rubrikToCloneId && !_vm.cloneRubrikView,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onCloneSelectedRubrik.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                Δημιουργία νέας από αυτήν\n            "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell-full" }, [
            _c("div", { staticClass: "cell-12 cell-md-6" }, [
              _c("input", {
                attrs: {
                  type: "file",
                  "data-role": "file",
                  "data-prepend": "Upload from file:",
                },
                on: {
                  change: function ($event) {
                    return _vm.processRubrikFile($event)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cell-12 cell-md-4" }, [
              _c(
                "button",
                {
                  staticClass: "button primary",
                  attrs: { disabled: !_vm.selectedRubrikFile },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onUploadFile.apply(null, arguments)
                    },
                  },
                },
                [_c("span", [_vm._v("Upload")])]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.cloneRubrikView && _vm.rubrikToCloneId
            ? _c("div", { staticClass: "cell-12 cell-md-6" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "cell-12 cell-md-8",
                      staticStyle: { "margin-top": "-5px" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.clonedRubrikName,
                            expression: "clonedRubrikName",
                          },
                        ],
                        attrs: {
                          "data-validate": "required minlength=2",
                          type: "text",
                          "data-clear-button": "false",
                          "data-role": "input",
                          "data-prepend": "Name: ",
                        },
                        domProps: { value: _vm.clonedRubrikName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.clonedRubrikName = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "cell-12 cell-md-4",
                      staticStyle: { "margin-top": "-5px" },
                    },
                    [
                      _c("span", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.clonedRubrikName,
                            expression: "clonedRubrikName",
                          },
                        ],
                        staticClass: "button primary mif-checkmark mif-lg",
                        staticStyle: {
                          cursor: "pointer",
                          "font-size": "20px",
                          color: "#fff",
                          "padding-top": "5px",
                        },
                        on: { click: _vm.onSaveCloneRubrik },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "button primary mif-cancel mif-lg",
                        staticStyle: {
                          cursor: "pointer",
                          "font-size": "20px",
                          color: "#fff",
                          "padding-top": "5px",
                        },
                        on: { click: _vm.onCancelCloneRubrik },
                      }),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("data-list", {
        ref: "rubriksList",
        attrs: { settings: _vm.rubriksListSettings },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }