<template>
    <div class="row" v-if="item">
        <div class="cell-sm-full cell-md-6">
            <input data-validate="required minlength=2" type="text" data-clear-button="false" data-role="input" data-prepend="Όνομα: " v-model="item.name" @keyup="keyMonitor">
        </div>  
        <div class="cell-md-6" v-show="!isSubList">
            <div class="input">  
                <vue-select  label="name" :reduce="(v)=>v.id" v-model="item.departmentId" :filterable="false" :options="optionsDepartments">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.name }}
                        </div>
                    </template>
                </vue-select>
                <div class="prepend">Τάξη:</div>
            </div>
        </div>   
        <div class="cell-12 cell-md-6">
            <div class="input">  
                <vue-select  label="name" :reduce="(v)=>v.id" v-model="item.rubrikId" :filterable="false" :options="optionsRubriks">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.name }}
                        </div>
                    </template>
                </vue-select>
                <div class="prepend">Rubric:</div>
            </div>
        </div>
        <div class="cell-12 cell-md-6" v-if="!cloneRubrikView">
            <button class="button primary" :class="{'outline':isSubList}" @click.prevent="onCloneSelectedRubrik" :disabled="!item.rubrikId">
                Δημιουργία νέας από αυτήν
            </button>
        </div>
        <div class="cell-12 cell-md-6" v-if="cloneRubrikView && this.item.rubrikId">
            <div class="row">
                <div class="cell-12 cell-md-8" style="margin-top:-5px">
                    <input data-validate="required minlength=2" type="text" data-clear-button="false" data-role="input" data-prepend="Name: " v-model="clonedRubrikName" @keyup="keyMonitor">
                </div>
                <div class="cell-12 cell-md-4">
                     <span class="mif-checkmark mif-lg" style="cursor:pointer;font-size:20px;color:#aaa" @click="onSaveCloneRubrik" v-show="clonedRubrikName"></span>
                     <span class="mif-cancel mif-lg" style="cursor:pointer;font-size:20px;color:#aaa" @click="onCancelCloneRubrik"></span>
                </div>
            </div>
        </div>
        <div class="cell-full">
            <div class="row" v-for="(depTeam,idx) in departmentTeams" :key="depTeam.id">
                <div class="cell-md-2">
                    {{depTeam.name}}
                </div>
                <div class="cell-md-10">
                        <div class="input">  
                            <vue-select label="name" :reduce="(v)=>v.id" v-model="departmentTeamTeachers[idx]" :filterable="false" :options="optionsUsers">
                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.sirname }} {{ option.name }} ({{option.email}})
                                    </div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.sirname }} {{ option.name }} ({{option.email}})
                                    </div>
                                </template>
                            </vue-select>
                            <div class="prepend">Εκπαιδευτικός:</div>
                        </div>
                    </div>  
            </div>
        </div>
        <div class="cell-12">
            <div class="subListHeader">
                Κεφάλαια
                <span class="mif-add mif-lg" style="cursor:pointer;background-color:green;color:white;width:20px;height:20px;border-radius:15px;text-align:center;padding-top:2px" @click.prevent="onAddCourseSection"></span>
            </div>
            <data-list ref="courseSectionsList" :settings="courseSectionListSettings" @onListUpated="onCourseSectionUpdated"></data-list>
        </div> 
    </div>
</template>
<script>
import axios from 'axios';
import _ from 'underscore';

import settings from '../../settings.js'
import globals from '../../globals.js'

let rubriks=[];

export default {
    props:{
        item:undefined,
        isSubList:undefined
    },
    watch:{
        async departmentId(){
            if (this.departmentId!=undefined && this.departmentId!=-1){
                let r=await axios.post(`${settings.serviceUrl}team/getMany`, {tokenValue:globals.getToken(),item:{departmentId:this.item.departmentId}});
                this.departmentTeams=r.data.payload.items;
                this.departmentTeamTeachers=new Array(this.departmentTeams.length);
                let idx=-1;
                for (let team of this.departmentTeams){
                    idx++;
                    let r = await axios.post(`${settings.serviceUrl}userToCourse/getMany`, {tokenValue:globals.getToken(),item:{teamId:team.id,courseId:this.item.id}});
                    if (r.data.payload.items.length>0)
                        this.departmentTeamTeachers[idx]=r.data.payload.items[0].userId;
                
                }
                r=await axios.post(`${settings.serviceUrl}user/getMany`, {tokenValue:globals.getToken()});
                this.optionsUsers=r.data.payload.items;
            }
        },
        item: {
            immediate: true,
            async handler(newVal, oldVal) {
                // if (this.item.rubrikId && this.item.rubrikId!=-1){
                //     let r=await axios.post(`${settings.serviceUrl}rubrik/get`, {tokenValue:globals.getToken(),id:this.item.rubrikId});
                //     this.optionsRubriks=[r.data.payload];
                // }
                if (this.item.rubrikId==-1) this.item.rubrikId=undefined;

                this.courseSectionListSettings.searchParams={
                    courseId:this.item.id
                };
                this.courseSectionListSettings.defaultNewItem={
                    courseId:this.item.id
                };
                if (this.item.departmentId==-1) this.item.departmentId=undefined;
                if (this.item.departmentId){
                    // let r=await axios.post(`${settings.serviceUrl}department/get`, {tokenValue:globals.getToken(),id:this.item.departmentId});
                    // this.optionsDepartments=[r.data.payload];
                    let r=await axios.post(`${settings.serviceUrl}team/getMany`, {tokenValue:globals.getToken(),item:{departmentId:this.item.departmentId}});
                    this.departmentTeams=r.data.payload.items;
                    this.departmentTeamTeachers=new Array(this.departmentTeams.length);
                    let idx=-1;
                    for (let team of this.departmentTeams){
                        idx++;
                        let r = await axios.post(`${settings.serviceUrl}userToCourse/getMany`, {tokenValue:globals.getToken(),item:{teamId:team.id,courseId:this.item.id}});
                        if (r.data.payload.items.length>0)
                            this.departmentTeamTeachers[idx]=r.data.payload.items[0].userId;
                    
                    }
                    r=await axios.post(`${settings.serviceUrl}user/getMany`, {tokenValue:globals.getToken()});
                    this.optionsUsers=r.data.payload.items;
                }
               
            },
        },
    },
    computed:{
        departmentId(){
            return this.item.departmentId;
        }
    },
    data(){
        return{
            departmentTeams:[],
            departmentTeamTeachers:[],
            optionsDepartments:[],
            optionsRubriks:[],
            optionsUsers:[],
            cloneRubrikView:false,
            clonedRubrikName:'',
            courseSectionListSettings:{
                showCards:true,
                hideFilters:true,
                hideControls:true,
                deleteConfirmationField:"id",
                isSubList:true,
                itemName:'coursesection',
                itemNamePlural:'coursesections',
                dataPerPage:10000,
                searchParams:{
                },
                sortParams:[],
                tableColumns:[
                    {
                        name: '__component:vuetable-raw-actions-edit',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    },
                    {
                        name: 'id',
                        title:'Id',
                        sortField: 'id',
                        titleClass: 'id-column',
                        dataClass: 'id-column',
                        // callback: 'gender'
                    },
                    {
                        name: 'title',
                        title:'Title',
                        sortField: 'title',
                        isCardHeader:true
                    },
                    {
                        name: 'rubrikId',
                        title:'Rubrik',
                        sortField: 'rubrikId',
                        callback:(v)=>{
                            if (!v || v==-1) return '-';
                            let r=_.findWhere(rubriks,{id:v});
                            if (r) return r.name;

                            return '-';
                        }
                    },
                    {
                        name: '__component:vuetable-raw-actions-delete',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    }
                ],
            }
        }
    },
    mounted() {
        const action=async ()=>{
            let r=await axios.post(`${settings.serviceUrl}rubrik/getMany`, {tokenValue:globals.getToken()});
            rubriks= r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}department/getMany`, {tokenValue:globals.getToken()});
            this.optionsDepartments= r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}rubrik/getMany`, {tokenValue:globals.getToken(),item:{}});
            this.optionsRubriks= r.data.payload.items;
            
        };
        action();
    },
    methods:{
        keyMonitor(event){
            this.$emit('keyevent',event);
        },
        onAddCourseSection(){
            this.$refs.courseSectionsList.onAdd();
        },
        async fetchDepartmentOptions (search, loading) {
            if (search.length>2){
                loading(true);
                let r=await axios.post(`${settings.serviceUrl}department/getMany`, {tokenValue:globals.getToken(),item:{name:search}});
                this.optionsDepartments= r.data.payload.items;
                
                loading(false);
            } 
        },
        async onCourseSectionUpdated(){
        },
        async onCloneSelectedRubrik(){
            this.cloneRubrikView=true;
        },
        async onSaveCloneRubrik(){
            if (!this.item.rubrikId){
                Metro.dialog.create({
                    title: "Choose Rubrik",
                    content: "<div>A rubrik must be selected</div>",
                    closeButton: true
                });
            }
            else{
                let r=await axios.post(`${settings.serviceUrl}rubrik/clone`, {tokenValue:globals.getToken(),id:this.item.rubrikId,name:this.clonedRubrikName});
                this.optionsRubriks=[r.data.payload];
                this.item.rubrikId=r.data.payload.id;
            }
        },
        async onPreSave(){
            let idx=-1;
            for (let team of this.departmentTeams){
                idx++;
                let r = await axios.post(`${settings.serviceUrl}userToCourse/getMany`, {tokenValue:globals.getToken(),item:{teamId:team.id,courseId:this.item.id}});
                if (r.data.payload.items.length>0)
                     r = await axios.post(`${settings.serviceUrl}userToCourse/remove`, {tokenValue:globals.getToken(),id:r.data.payload.items[0].id});
                if (this.departmentTeamTeachers[idx])
                    r = await axios.post(`${settings.serviceUrl}userToCourse/add`, {tokenValue:globals.getToken(),item:{teamId:team.id,courseId:this.item.id,userId:this.departmentTeamTeachers[idx]}});
            }
        },
        async onCancelCloneRubrik(){
            this.cloneRubrikView=false;
        }
    }
}
</script>