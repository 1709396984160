<template>
    <data-list :settings="listSettings"></data-list>
</template>

<script>
import _ from 'underscore';
import axios from 'axios';

import globals from '../../globals.js';
import settings from '../../settings.js';

import dataList from '../../components/admin/dataList.vue'

export default {
    data(){
        return {
            optionsDepartments:[],
            optionsRubriks:[],
            listSettings:{
                deleteConfirmationField:"id",
                isSubList:false,
                itemName:'course',
                itemNamePlural:'courses',
                searchParams:{

                },
                sortParams:[],
                tableColumns:[
                    {
                        name: '__component:vuetable-raw-actions-edit',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    },
                    {
                        name: 'id',
                        title:'Id',
                        sortField: 'id',
                        titleClass: 'id-column',
                        dataClass: 'id-column',
                        // callback: 'gender'
                    },
                    {
                        name: 'departmentId',
                        title:'Τάξη',
                        sortField: 'departmentId',
                        callback:((v)=>{
                            if (!v || v==-1) return '-';
                            let o=_.findWhere(this.optionsDepartments,{id:v});
                            if (o) return o.name;
                            return "-";
                        }).bind(this)
                    },
                    {
                        name: 'name',
                        title:'Όνομα',
                        sortField: 'name',
                        isCardHeader:true
                    },
                    {
                        name: 'rubrikId',
                        title:'Rubric',
                        sortField: 'rubrikId',
                        callback:((v)=>{
                            if (!v || v==-1) return '-';
                            let o=_.findWhere(this.optionsRubriks,{id:v});
                            if (o) return o.name;
                            return "-";
                        }).bind(this)
                    },
                    {
                        name: '__component:vuetable-raw-actions-delete',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    }
                ],
                defaultNewItem:undefined
            }
        };
    },
    components:{
        dataList
    },
    computed:{ 
        user(){
            return this.$store.state.user;
        }
    },
    mounted(){
        if (this.user && this.user.roleId==0)
            this.listSettings.tableColumns.push({
                name: 'organizationName',
                title:'Οργανισμός',
                sortField: 'organizationName',
                isCardHeader:false
            });
        const action=async ()=>{
            let r=await axios.post(`${settings.serviceUrl}department/getMany`, {tokenValue:globals.getToken()});
            this.optionsDepartments= r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}rubrik/getMany`, {tokenValue:globals.getToken()});
            this.optionsRubriks= r.data.payload.items;
        };
        action();
    },
    methods:{
    }
       
}
</script>

<style lang="less">
</style>
