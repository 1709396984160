<template>
    <div class="page home-page scrollbar-style">
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import settings from '../../settings.js';

export default {
    data(){
        return {
        }
    },
    components:{
    },
    
    mounted(){
    
        const action=async ()=>{   
            
        };
        action();

    },
    methods:{
    }
    
}
</script>
<style lang="less">
.home-page{
    overflow-y: auto;
    overflow-x:hidden;
    @media screen and (max-width: 768px){
        .hide-sm{
            display:none;
        }
    }

    @media screen and (max-width: 1300px){
        .hide-md{
            display:none;
        }
    }
}
</style>