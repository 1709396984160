<template>
  <div class="app-server-area">
    <header>
      <section class="header-logo">
        <img class="logo-img" src="./assets/admin-lgo.png" />
        <div class="text">
          <div class="version">Ver. {{ version }}</div>
          <div class="version">{{ breadcrumb }}</div>
        </div>
      </section>

      <section class="header-quick-navigation">
        <section class="header-quick-navigation-breadcrumb">
          
        </section>
      </section>

      <section class="header-user-info">
        <strong>{{ userEmail }}</strong
        ><br />
        <div v-if="loggedIn" style="float: right; font-size: 14px; cursor: pointer; color: #1366ad" @click="onLogout">
          logout
        </div>
      </section>
      <section class="header-menu-btn">
        <span class="menu-button mif-menu mif-lg" @click="onOpenMenu"></span>
      </section>
    </header>

    <section class="side-panels-container">
      <side-panel zIndex="1000" title="Navigation">
        <main-menu></main-menu>
      </side-panel>
    </section>
    <section class="main-content">
      <div style="background-color:#a0d8ba;width:100%;height:300px;position:fixed"></div>
      <router-view></router-view>
    </section>
    <ui-blocker></ui-blocker>
  </div>
</template>

<style lang="less">
.app-server-area {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #eaeaea;
  overflow: hidden;
  color:#000;
  header {
    height: 60px;
    width: 100%;
    align-items: center;
    flex: 0 0 auto;
    background: #fff;
    color: #000;
    box-shadow: 0 4px 8px rgba(90, 97, 105, 0.12),0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
    z-index:20000;

    display: flex;
    .header-logo {
      flex: 0 0 auto;
      padding: 10px;
      font-weight: bold;
      display:flex;

      .logo-img {
        width: 60px;
        margin-right:10px
      }
      .text {
        display: flex;
        flex-direction: column;
        color: #1366ad;
        margin-top: 15px;
        .title {
          font-size: 12px;
          text-align: left;
        }
        .version {
          font-size: 12px;
          text-align: left;
        }
      }
    }
    .header-quick-navigation {
      flex: 1 1 auto;
      .header-quick-navigation-breadcrumb {
        font-size: 12px;
        margin-left: 20px;
        color: #fff;
      }
    }
    .header-user-info {
      flex: 0 0 auto;
      color: #1366ad;
      font-size: 14px;
      margin-right: 11px;
      text-align: right;
    }
    .header-menu-btn{
      flex:0 0 60px;
      text-align:right;
      @media screen and (min-width: 600px) {
        display:none;
      }
      .menu-button {
        cursor: pointer;
        margin-right:10px;
      }

    }    
  }
  .main-content {
    flex: 1 1 100%;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size:12px;
    .input,.prepend{
      font-size:12px;
    }
    .admin-list{
      &.sub-list{
        padding:20px;
        margin:10px;
        border: 1px #d9d9d9 solid;
      }
    }
    .page{
      margin:20px;
      padding-left:60px;
      height:calc(100% - 40px);
      width:calc(100% - 40px);
      
      .filters-area,.table-area{
        width:100%;
        background-color:white;
      }
      .controls-area{
        margin:10px 0px;
      }
      .edit-area{
        width:100%;
        background-color:white;
        padding:10px;
      }
      .edit-id{
        border: 1px solid #0366d6;
        color:#0366d6;
        padding:5px;
        width:100px;
        text-align:center;
        font-weight:bold;
        font-size:20px;
      }
    }
    @media screen and (max-width: 600px) {
      .page {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .side-panels-container {
    position: absolute;
    left: 0px;
    height: ~"calc(100% - 60px)";
    top: 60px;
    display: flex;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(90, 97, 105, 0.12),
      0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  }
  .intl {
    float: left;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px 0px;
    margin-bottom: 12px;
    background-color: #f1f1f1;
  }
}
.pagination {
  display: flex;
  margin: 10px 0px 0px 0px;
  justify-content: center;
  .btn-nav,.page-item {
    float: left;
    margin: 0px 0px;
    margin-left:0px;
    margin-right:5px;
    cursor: pointer;
    background-color: #fff;
    /* width: 40px; */
    /* height: 40px; */
    border: 1px solid #ccc;
    color: #333;
    text-align: center;
    padding: 5px 10px;
    font-weight: bold;
    &.active {
      background-color: #0366d6;
      color: #fff;
    }
  }
}
.subListHeader{
  background-color:#e9e9e9;
  padding:10px;
  
}
.dialog-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.33);
  z-index: 10000;
  .dialog-content {
    min-width: 240px;
    text-align: center;
    font-size: 16px;
    margin: 0 20px;
    padding: 20px 30px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    color: #303133;

    header {
      font-weight: bold;
      padding: 10px;
      margin-bottom: 10px;
      height: auto;
      background-color: transparent;
      background: transparent;
      padding: 0px;
    }
  }
}
.v-select {
  margin-top: 0px !important;
  -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    height: 34px;
    padding: 0 .75rem;
    order:2;
  
  &.vs--disabled{
    cursor: not-allowed;
    background-color:transparent;
    opacity:0.5;
    .vs__search{
      background-color:transparent;
    }
  }

  .vs__search {
    position: absolute;
    border-radius:0px;
    border-width:0px;
  }
  .vs__search:focus {
    position: inherit !important;
  }
  .vs__dropdown-toggle {
    border: none !important;
    border-radius: 0px !important;
    height: calc(1.5em + 0.75rem + 2px);
    background-color: transparent !important;
  }
  .vs__selected{
    .selected{
      box-shadow: none !important;
      -webkit-box-shadow:none !important;
    }
    .selected::before{
      display:none;
    }
    .selected::after{
      display:none;
    }
  }
  .vs__selected-options {
    width: ~"calc(100% - 40px)";
  }
  .vs__dropdown-toggle {
    // border-radius: 0px;
    // border: 1px solid #9e9e9e;
    min-height: 36px;
    background-color: white;
  }
  input {
    // border-bottom: none !important;
    height: auto !important;
    width: auto !important;
    // border-radius:0px !important;
  }
  .vs__actions {
    svg {
      cursor: pointer;
    }
  }
}
.input-field .validation-error-message {
  color: red;
}
.admin-list {
  .btn-td{
    width:40px;
  }
  tr {
    &.last-active {
      background-color:#c6f89f !important;
    }
    &:hover{
      background-color:#eaeaea !important;
    }
    td {
      padding: 0px;
      padding-left: 5px;
      vertical-align: middle;
      &.id-column {
        text-align: right;
        padding-right: 10px;
        font-weight: bold;
        color: darkgreen;
        width:100px;
      }
    }

    th {
      padding-left: 5px;

      &.id-column {
        text-align: right;
        padding-right: 10px;
        font-weight: bold;
        width:100px;
      }
    }
  }
}


.rtable {
  //display: inline-block;
  vertical-align: middle;
  margin-top: 40px;
  max-width: 100%;
  /* overflow-x: auto; */
  /* white-space: nowrap; */
  border-collapse: collapse;
  border-spacing: 0;
  tr:hover {
    background-color: #eaeaea;
  }
}

.rtable,
.rtable--flip tbody {
  -webkit-overflow-scrolling: touch;
  background: white;
}

.rtable th {
  font-size: 11px;
  text-align: left;
  background: #007bff;
  color: #fff;
  i.right {
    float: right;
    margin-left: 0px;
    line-height: 0.5;
    font-size: 20px;
    padding-top: 5px;
  }
}

.rtable th,
.rtable td {
  padding: 6px 12px;
  //border: 1px solid #d9d7ce;
}

.rtable--flip {
  display: flex;
  overflow: hidden;
  background: none;
}

.rtable--flip thead {
  display: flex;
  flex-shrink: 0;
  min-width: min-content;
}

.rtable--flip tbody {
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.rtable--flip tr {
  display: flex;
  flex-direction: column;
  min-width: min-content;
  flex-shrink: 0;
}

.rtable--flip td,
.rtable--flip th {
  border-right: 1px solid #d9d7ce;
  display: block;
  min-height: 50px;
  background-image: none !important;
  border-left: 0;
  /* text-align: center; */
  padding: 5px !important;
}

.rtable--flip td {
  background-image: none !important;
  // border-collapse is no longer active
  border-left: 0;
}

// border-collapse is no longer active
.rtable--flip th:not(:last-child),
.rtable--flip td:not(:last-child) {
  border-bottom: 0;
}
</style>

<script>
import axios from "axios";
import _ from "underscore";

import UIBlocker from "./components/common/UIBlocker.vue";

import sidePanel from "./components/admin/sidePanel.vue";
import mainMenu from "./components/admin/side-panels/main-menu.vue";

import settings from "./settings";
import globals from "./globals";
export default {
  components: {
    sidePanel,
    mainMenu,
    "ui-blocker": UIBlocker,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      version: globals.version,
    };
  },
  computed: {
    userEmail() {
      if (this.$store.state.user) return this.$store.state.user.email;

      return "-";
    },
    userRole() {
      if (this.$store.state.user) return this.$store.state.user.roleId;

      return 100;
    },
    loggedIn() {
      return this.$store.state.user;
    },
    breadcrumb() {
      return this.$store.state.breadcrumb;
    },
  },
  mounted() {
    this.$root.$on("loggedIn", () => {
      this.token = localStorage.getItem("token");
    });
    window.onresize = globals.setAdminTablesLayout;
  },
  watch: {},
  methods: {
    onOpenMenu() {
      this.$root.$emit("sidepanel-toggle");
    },
    async onItemClicked(item) {
      if (item === "Logout") {
        await this.onLogout();
      }
    },
    async onLogout() {
      localStorage.removeItem("token");
      await axios.post(`${settings.authServiceUrl}token/expireToken`, {
        tokenValue: this.token,
      });
      this.token = undefined;
      this.$store.commit("setUser", undefined);
      this.$router.push("/admin/signin");
    },
  },
};
</script>

