<template>
<div class="container">
    <form class="row">
        <div class="cell-12">
            <input type="text" data-role="input" data-prepend="Email: " v-model="username">
        </div>
        <div class="cell-12">
            <input type="password" data-role="input" data-prepend="Password: " v-model="password">
        </div>
        <div class="cell-12">
            <button class="button primary" @click.prevent="onLogin">Submit</button>
            <div v-if="!ok" style="color:red">{{message}}</div>
            <div v-else style="">{{message}}</div>
        </div>
    </form>
</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';

import settings from '../../settings.js';
import globals from '../../globals.js';

export default {
    data() {
        return {
            username:'',
            password:'',
            message:'',
            ok:true
        }
    },
    components:{},
    mounted() {
        const self = this;
        
    },
    methods: {
        async onLogin(){
            this.message='Logging in...';
            let r=await axios.post(`${settings.authServiceUrl}user/login`, {email:this.username,password:this.password,app:settings.app});
            this.ok=r.data.ok;
            if (!r.data.ok){
                this.message=r.data.message;
                return;
            }
            if (r.data.code===0){
                this.message=r.data.message;
                return;
            }
            this.message="SUCCESS";
            globals.setToken(r.data.payload.token.value);
            // let u=r.data.payload.user;
            // r=await axios.post(`${settings.serviceUrl}user/getMany`, {item:{authId:u.id}, tokenValue: globals.getToken() });
            // u.info=r.data.payload.items[0];
            // this.$store.commit('setUser',u);
            this.$root.$emit('loggedIn');
            this.$router.push('/admin');
        }
    }
}
</script> 

<style lang="less">

</style>
