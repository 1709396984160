<template>
  <div class="admin-server-summary-container scrollbar-style">
    <!-- <div class="progress" v-if="loading">
      <div class="indeterminate"></div>
    </div> -->
    <apexchart type="bar" width="100%" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import axios from "axios";
import _ from "underscore";
import moment from "moment";


export default {
  props:{
    options:undefined,
    series:undefined
  },
  computed:{
  },
  data() {
    return {
      
    };
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.admin-server-summary-container {
  height: 100%;
  overflow: hidden;
}
</style>