import { render, staticRenderFns } from "./clientRegister.vue?vue&type=template&id=f2760270&scoped=true&"
import script from "./clientRegister.vue?vue&type=script&lang=js&"
export * from "./clientRegister.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2760270",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/da/da.app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f2760270')) {
      api.createRecord('f2760270', component.options)
    } else {
      api.reload('f2760270', component.options)
    }
    module.hot.accept("./clientRegister.vue?vue&type=template&id=f2760270&scoped=true&", function () {
      api.rerender('f2760270', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/webapp/views/client/clientRegister.vue"
export default component.exports