import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    debug: {
      count: 0,
      messages: [],
    },
    adminEditPageId:undefined,
    user:undefined,
    breadcrumb:'',
    sitting:{}
  },
  mutations: {
    setSitting(state,sitting){
      state.sitting=sitting
    },
    setUser(state, user) {
      state.user = user;
    },
    setBreadcrumb(state, value) {
      state.breadcrumb = value;
    },
    setAdminEditPageId(state, id) {
      state.adminEditPageId = id;
    },
    setDebug(state, message) {
      state.debug.count += 1;
      state.debug.messages.splice(0, 0, `${state.debug.count}. ${message}`);
    },
  }
});

export default store;
