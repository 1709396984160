<template>
    <div>
        <div class="row"  style="padding:10px 0px;margin-left:70px">
            <div class="cell-12 cell-md-6">
                <div class="input">  
                    <vue-select label="name" :reduce="(v)=>v.id" v-model="rubrikToCloneId" :filterable="false" :options="optionsRubriks">
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>
                    </vue-select>
                    <div class="prepend">Rubric:</div>
                </div>
            </div>
            <div class="cell-12 cell-md-6">
                <button class="button primary" @click.prevent="onCloneSelectedRubrik" :disabled="!rubrikToCloneId && !cloneRubrikView">
                    Δημιουργία νέας από αυτήν
                </button>
            </div>
            <div class="cell-full">
                 <div class="cell-12 cell-md-6">
                    <input type="file" data-role="file" data-prepend="Upload from file:" @change="processRubrikFile($event)">
                </div>
                <div class="cell-12 cell-md-4">
                    <button class="button primary" @click.prevent="onUploadFile" :disabled="!selectedRubrikFile">
                        <span>Upload</span>
                    </button>
                </div>
            </div>
            <div class="cell-12 cell-md-6" v-if="cloneRubrikView && rubrikToCloneId">
                <div class="row">
                    <div class="cell-12 cell-md-8" style="margin-top:-5px">
                        <input data-validate="required minlength=2" type="text" data-clear-button="false" data-role="input" data-prepend="Name: " v-model="clonedRubrikName">
                    </div>
                    <div class="cell-12 cell-md-4" style="margin-top:-5px">
                        <span class="button primary mif-checkmark mif-lg" style="cursor:pointer;font-size:20px;color:#fff;padding-top:5px" @click="onSaveCloneRubrik" v-show="clonedRubrikName"></span>
                        <span class="button primary mif-cancel mif-lg" style="cursor:pointer;font-size:20px;color:#fff;padding-top:5px" @click="onCancelCloneRubrik"></span>
                    </div>
                </div>
            </div>
        </div>
        <data-list ref="rubriksList" :settings="rubriksListSettings"></data-list>
    </div>
</template>

<script>
import _ from 'underscore';
import axios from 'axios';

import globals from '../../globals.js';
import settings from '../../settings.js';

import dataList from '../../components/admin/dataList.vue'

export default {
    data(){
        return {
            selectedRubrikFile:undefined,
            rubrikToCloneId:undefined,
            cloneRubrikView:false,
            clonedRubrikName:'',
            optionsRubriks:[],
            optionsDepartments:[],
            rubriksListSettings:{
                deleteConfirmationField:"id",
                isSubList:false,
                itemName:'rubrik',
                itemNamePlural:'rubriks',
                searchParams:{

                },
                sortParams:[],
                tableColumns:[
                    {
                        name: '__component:vuetable-raw-actions-edit',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    },
                    {
                        name: 'id',
                        title:'Id',
                        sortField: 'id',
                        titleClass: 'id-column',
                        dataClass: 'id-column',
                        // callback: 'gender'
                    },
                    // {
                    //     name: 'departmentId',
                    //     title:'Department',
                    //     sortField: 'departmentId',
                    //     callback:((v)=>{
                    //         if (!v || v==-1) return '-';
                    //         let o=_.findWhere(this.optionsDepartments,{id:v});
                    //         if (o) return o.name;
                    //         return "-";
                    //     }).bind(this)
                    // },
                    {
                        name: 'name',
                        title:'Όνομα',
                        sortField: 'name',
                    },
                    {
                        name: 'description',
                        title:'Περιγραφή',
                        sortField: 'description',
                    },
                    {
                        name: 'minScore',
                        title:'Score(min)',
                        sortField: 'minScore',
                    },
                    {
                        name: 'maxScore',
                        title:'Score(max)',
                        sortField: 'maxScore',
                    },
                    {
                        name: '__component:vuetable-raw-actions-delete',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    }
                ],
                defaultNewItem:undefined
            }
        };
    },
    components:{
        dataList
    },
    mounted(){
        const action=async ()=>{
            let r=await axios.post(`${settings.serviceUrl}department/getMany`, {tokenValue:globals.getToken()});
            this.optionsDepartments= r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}rubrik/getMany`, {tokenValue:globals.getToken(),item:{}});
            this.optionsRubriks= r.data.payload.items;
        };
        action();
    },
    methods:{
        processRubrikFile(event){
             this.selectedRubrikFile = event.target.files[0];
        },
        async onUploadFile(){
            let formData = new FormData();
            formData.append('file', this.selectedRubrikFile);
            formData.append('action','RUBRIK');
            formData.append('tokenValue',globals.getToken());
            let response=await axios.post(`${settings.serviceUrl}upload/rubrik/`,formData,{headers: {'Content-Type': 'multipart/form-data'}});
            if (response.data.ok){
                this.$refs.rubriksList.onSearch();
            }
        },
        async onCloneSelectedRubrik(){
            this.cloneRubrikView=true;
        },
        async onCancelCloneRubrik(){
            this.cloneRubrikView=false;
        },
        async onSaveCloneRubrik(){
            if (!this.rubrikToCloneId){
                Metro.dialog.create({
                    title: "Choose Rubrik",
                    content: "<div>A rubrik must be selected</div>",
                    closeButton: true
                });
            }
            else{
                let r=await axios.post(`${settings.serviceUrl}rubrik/clone`, {tokenValue:globals.getToken(),id:this.rubrikToCloneId,name:this.clonedRubrikName});
                this.$router.push(`/admin/rubriks/${r.data.payload.id}`);
            }
        },
    }
       
}
</script>

<style lang="less">
</style>
