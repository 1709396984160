var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page users-edit-page" }, [
    _c("div", { staticClass: "edit-area" }, [
      _vm.item
        ? _c(
            "form",
            {
              staticClass: "grid",
              attrs: { "data-interactive-check": "true" },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "edit-id" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.id) +
                        "\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "stub", staticStyle: { width: "120px" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "button primary",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onSave.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", { staticClass: "mif-checkmark mif-lg" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button primary",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onCancel.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", { staticClass: "mif-cancel mif-lg" })]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.name,
                        expression: "item.name",
                      },
                    ],
                    attrs: {
                      "data-validate": "required minlength=6",
                      type: "text",
                      "data-clear-button": "false",
                      "data-role": "input",
                      "data-prepend": "Όνομα: ",
                    },
                    domProps: { value: _vm.item.name },
                    on: {
                      keyup: _vm.keyMonitor,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cell-12" }, [
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("vue-select", {
                        attrs: {
                          disabled: _vm.user.roleId != 0,
                          label: "name",
                          reduce: function (v) {
                            return v.id
                          },
                          filterable: false,
                          options: _vm.optionsOrganizations,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("div", { staticClass: "d-center" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(option.name) +
                                        "\n                            "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "selected d-center" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(option.name) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          88741860
                        ),
                        model: {
                          value: _vm.item.organizationId,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "organizationId", $$v)
                          },
                          expression: "item.organizationId",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "prepend" }, [
                        _vm._v("Οργανισμός:"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cell-12" },
                  [
                    _c("div", { staticClass: "subListHeader" }, [
                      _vm._v(
                        "\n                        Τμήματα\n                        "
                      ),
                      _c("span", {
                        staticClass: "mif-add mif-lg",
                        staticStyle: {
                          cursor: "pointer",
                          "background-color": "green",
                          color: "white",
                          width: "20px",
                          height: "20px",
                          "border-radius": "15px",
                          "text-align": "center",
                          "padding-top": "2px",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onAddTeam.apply(null, arguments)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("data-list", {
                      ref: "teamsList",
                      attrs: { settings: _vm.teamsListSettings },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cell-12" },
                  [
                    _c("div", { staticClass: "subListHeader" }, [
                      _vm._v(
                        "\n                        Μαθήματα\n                        "
                      ),
                      _c("span", {
                        staticClass: "mif-add mif-lg",
                        staticStyle: {
                          cursor: "pointer",
                          "background-color": "green",
                          color: "white",
                          width: "20px",
                          height: "20px",
                          "border-radius": "15px",
                          "text-align": "center",
                          "padding-top": "2px",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onAddCourse.apply(null, arguments)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("data-list", {
                      ref: "coursesList",
                      attrs: { settings: _vm.coursesListSettings },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }