
<template>
  <div class="widget-container scrollbar-style">
    <div data-role="progress" data-type="line" data-small="true" v-if="loading"></div>
    <div>
      <div v-for="i in items" :key="i.courseId">
        <div style="font-weight:bold">{{i.course.name}}</div>
        <div>
          <span>Απουσίες: </span> <span style="font-weight:bold">{{i.absent}}</span><br/>
          <span>Θετικά: </span> <span style="font-weight:bold">{{i.like}}</span><br/>
          <span>Αρνητικά: </span> <span style="font-weight:bold">{{i.dislike}}</span><br/>
        </div>
      </div>
    </div>
    <!-- <div :style="{color:color?color:'#000'}" style="font-size:46px;font-weight:bold;text-align:center">
      {{number}}
      <div style="font-size:10px;margin-top:-12px;color:#000" v-html="label"></div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import _ from "underscore";
import moment from "moment";

import settings from '../../../settings.js'
import globals from '../../../globals.js'

export default {
  props:{
    courses:undefined,
    color:undefined,
    sqlQuery:undefined,
    sqlParams:undefined,
    label:undefined
  },
  computed:{
  },
  data() {
    return {
      loading:true,
      ok:null,
      items:[]
    };
  },
  mounted() {
    this.getData();

    this.loading=false;
  },
  methods: {
    async getData() {
        this.loading=true;
        this.lastUpdate=moment().format('DD/MM/YYYY HH:mm:ss');
        let allCoursesStudents=[];
        for (let c of this.courses)
          allCoursesStudents.push(...c.students.map(x=>x.id));

        let r=await axios.post(`${settings.serviceUrl}sql`,{tokenValue:globals.getToken(),
            sqlQuery:`SELECT * FROM studentday WHERE courseId IN (${this.courses.map((x)=>x.id).join(',')}) AND studentId IN (${allCoursesStudents.join(',')})`,
            sqlParams:undefined
        });
        this.items=[];
        r.data.payload.forEach((d)=>{
          let existingCourse=_.findWhere(this.items,{courseId:d.courseId});
          if (!existingCourse){
            existingCourse={
              courseId:d.courseId,
              course:_.findWhere(this.courses,{id:d.courseId}),
              absent:0,
              like:0,
              dislike:0
            }
            this.items.push(existingCourse);
          }
          if (d.like==1) existingCourse.like++;
          if (d.like==0) existingCourse.dislike++;
          if (d.absent) existingCourse.absent++;
        })
        this.loading=false;
        this.number=r.data.payload[0].absent;
    }
    
  }
};
</script>

<style lang="less" scoped>
.widget-container {
  height: 100%;
  overflow: auto;
}
</style>