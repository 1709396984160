<template>
    <div class="page users-list-page admin-list">
        <div class="grid">
            <div class="filters-area" style="padding:10px">
            <div class="row">
                <div class="cell-12 cell-md-6">
                    <input type="text" data-clear-button="false" data-role="input" data-prepend="Email: " v-model="queryStringData.search.email" @keyup="keyMonitor">
                </div>
                <div class="cell-sm-12 cell-md-6" v-if="user && user.roleId==0">
                    <div class="input" style="width:100%;max-width:600px">  
                        <vue-select label="name" :reduce="(v)=>v.id" v-model="queryStringData.search.organizationId" :filterable="false" :options="organizations">
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.name }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.name }}
                                </div>
                            </template>
                        </vue-select>
                        <div class="prepend">Οργανισμός:</div>
                    </div>
                </div>
            </div>
            </div>
            <div class="row">
                <div class="controls-area">
                    <div class="cell-12">
                        <button class="button primary" @click="onSearch">
                            <span class="mif-refresh mif-lg"></span>
                        </button>
                        <button class="button primary" @click="onAdd">
                            <span class="mif-add mif-lg"></span>
                        </button>
                        <button class="button primary" @click="onClear">
                            <span class="mif-cross-light mif-lg"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="table-area">
                    <div class="cell-12">
                        <div class="pagination">
                            <vuetable-pagination  style="margin:0px auto;" ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="paginationCss">
                            </vuetable-pagination>
                        </div>
                    </div>
                    <div class="cell-12" v-if="initialized" >
                        <div data-role="progress" data-type="line" data-small="true" v-if="dataLoading"></div>
                        <vuetable ref="vuetable" :class="{loading:dataLoading}"
                            :css="tableCss"
                            :api-url="apiURL"
                            :http-fetch="getData"
                            http-method="post"
                            :load-on-start="false"
                            :fields="columns"
                            :sort-order="queryStringData.sort"
                            :per-page="dataPerPage"
                            :row-class="onRowClass"
                            @vuetable:loading="loading"
                            @vuetable:loaded="loaded"
                            @vuetable:load-success="loadSuccess"
                            @vuetable:load-error="loadError"
                            data-path="payload.items"
                            pagination-path="payload.pagination"
                            @vuetable:pagination-data="onPaginationData"
                            @VuetableRowAction:action-item="onRowAction">
                        </vuetable>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';

import _ from 'underscore';
import settings from '../../settings'
import globals from '../../globals';
import Vuetable from '../../../../node_modules/vuetable-2/src/components/Vuetable.vue'
import VuetablePagination from '../../../../node_modules/vuetable-2/src/components/VuetablePagination.vue'
import VuetableRowActionsEdit from '../../components/admin/VuetableRowActionsEdit.vue';
import VuetableRowActionsDelete from '../../components/admin/VuetableRowActionsDelete.vue';

Vue.component('vuetable-raw-actions-edit', VuetableRowActionsEdit)
Vue.component('vuetable-raw-actions-delete', VuetableRowActionsDelete)

let organizationsList=[];

const itemName='user';
const itemNamePlural='users';
const searchParams={
    organizationId:undefined
};
const intialSort=[{
    field:'id',
    direction:'DESC'
}]
const tableColumns=[
    {
        name: '__component:vuetable-raw-actions-edit',  
        title: '',
        titleClass: 'btn-td',
        dataClass: 'btn-td'
    },
    {
        name: 'id',
        title:'Id',
        sortField: 'id',
        titleClass: 'id-column',
        dataClass: 'id-column',
        // callback: 'gender'
    },
    {
        name: 'authId',
        title:'Auth Id',
        sortField: 'authId'
    },
    {
        name: 'username',
        title:'Username',
        sortField: 'username'
    },
    {
        name: 'sirname',
        title:'Επώνυμο',
        sortField: 'sirname',
    },
    {
        name: 'name',
        title:'Όνομα',
        sortField: 'name',
    },
    {
        name: 'address',
        title:'Διεύθυνση',
        sortField: 'address',
    },
    {
        name: 'city',
        title:'Πόλη',
        sortField: 'city',
    },
    {
        name: 'zip',
        title:'ΤΚ',
        sortField: 'zip',
    },
    {
        name: 'phone',
        title:'Τηλέφωνο',
        sortField: 'phone',
    },
    {
        name: 'email',
        title:'Email',
        sortField: 'email',
    },
    {
        name: 'lastLoginDate',
        title:'Τελ. Δραστ',
        sortField: 'lastLoginDate',
        callback:(v)=>{
            return moment(v).format('DD/MM/YYYY HH:mm');
        }
    },
    {
        name: '__component:vuetable-raw-actions-delete',  
        title: '',
        titleClass: 'btn-td',
        dataClass: 'btn-td'
    }
];
const deleteConfirmationField='id';
const defaultNewItem={
}
const queryStringDataExtras={
}
const initializer=async (vm)=>{
    let r=await axios.post(`${settings.serviceUrl}organization/getMany`, {tokenValue:globals.getToken()});
    vm.organizations= r.data.payload.items;
}
const urlProcessorFilterArgExtra=(qArgs,queryStringData)=>{
}
const beforeGetData=(queryStringData)=>{
}

export default {
    computed:{
        user(){
            return this.$store.state.user;
        }
    },
    data(){
        return {
            organizations:[],
            initialized:false,
            roles:_.filter(settings.roles,(r)=>r.app===''),
            routeURL:`/admin/${itemNamePlural}`,
            editURL:`/admin/${itemNamePlural}/`,
            apiURL:`${settings.serviceUrl}${itemName}/getMany`,
            deleteAPIURL:`${settings.serviceUrl}${itemName}/remove`,
            createAPIURL:`${settings.serviceUrl}${itemName}/add`,
            queryStringData:{
                search:searchParams,
                sort:[]
            },
            columns:tableColumns,
            paginationCss:{
                icons:{
                    first: 'mif-first mif-lg',
                    prev: 'mif-previous mif-lg',
                    next: 'mif-next mif-lg',
                    last: 'mif-last mif-lg',
                },
                pageClass: 'page-item',
                activeClass:'active'
            },
            tableCss:{
                tableClass:'table striped',
                ascendingIcon:  {
                    params:['mif-lg right','mif-arrow-drop-up']
                },
                descendingIcon: {
                    params:['mif-lg right','mif-arrow-drop-down']
                },
                renderIcon:function(classes){
                    if (classes[1])
                        return `<span class="${classes[0]} ${classes[1].params[0]} ${classes[1].params[1]}"> </span>`;
                    else
                        return `<span class="${classes[0]} mif-lg"></span>`;
                }
            },
            dataPerPage:settings.dataPerPage,
            dataLoading:false, 
            selectedElementId:undefined,
            currentQueryStringParams:''
        };
    },
    components:{
        Vuetable,
        VuetablePagination
    },
    mounted(){
        if (this.$store.state.user && this.$store.state.user.roleId==0){
            let existing=_.findWhere(tableColumns,{'name':'organizationName'});
            if (!existing){
                tableColumns.push({
                    name: 'organizationName',
                    title:'Όργανισμός',
                    sortField: 'organizationName'
                });
            }
        }
        this.getVuetableParamsFromUrl=async ()=>{
            if (this.$store.state.adminEditPageId){
                this.selectedElementId=this.$store.state.adminEditPageId;
                this.$store.commit('setAdminEditPageId',undefined);
            }
            // Set initial filters from url
            if (this.$route.query.search){
                let searchArgs=this.$route.query.search.split('|');
                for (let sa of searchArgs){
                    if (sa){
                        let qArgs=sa.split(',');
                        this.queryStringData.search[qArgs[0]]=qArgs[1].replace(/;/g,'|'); // For filters that use the | as special character
                        urlProcessorFilterArgExtra(qArgs,this.queryStringData);
                    }
                }
            }
            // Set initial sort from url
            if (this.$route.query.sort){
                let sortArgs=this.$route.query.sort.split('|');
                for (let sa of sortArgs){
                    if (sa){
                        let sArgs=sa.split(',');
                        this.queryStringData.sort.push({
                            field:sArgs[0],
                            direction:sArgs[1]
                        });
                    }
                }
            }
        };
        this.updateURLWithSearchAndSortData=()=>{
            let sort=this.$refs.vuetable.getSortParam();
            let searchParams="";
            for (let s in this.queryStringData.search)
                if (this.queryStringData.search[s])
                    searchParams+=`|${s},${this.queryStringData.search[s].toString().replace(/\|/g,';')}`;
            if (searchParams)
                searchParams=searchParams.substr(0);

            let sortParams="";
            for (let s of this.$refs.vuetable.sortOrder)
                sortParams+=`|${s.field},${s.direction}`;
            if (sortParams)
                sortParams=sortParams.substr(0);
            
            this.currentQueryStringParams=JSON.stringify({search:this.$route.query.search,sort:this.$route.query.sort,page:parseInt(this.$route.query.page)});
            let query={search:searchParams,sort:sortParams,page:this.$refs.vuetable.currentPage};
            if (this.currentQueryStringParams!=JSON.stringify(query)){
                this.$router.push({query})
            }
        };
        const action=async ()=>{
            await initializer(this);
            await this.getVuetableParamsFromUrl();
            this.initialized=true;
            this.$nextTick(()=>{
                if (this.$route.query.page){
                    this.$refs.vuetable.currentPage =parseInt(this.$route.query.page);
                }
                this.$refs.vuetable.reload();
            })
        }
        action();
    },
    methods:{
        keyMonitor(event){
            if(event.key == "Enter")
                this.$refs.vuetable.refresh();
        },
        getData(apiUrl, httpOptions){
            let sort=[];
            for (let s of this.$refs.vuetable.sortOrder)
                sort.push({field:s.field,direction:s.direction});

            beforeGetData(this.queryStringData);
            
            return axios.post(apiUrl, {
                tokenValue:globals.getToken(),
                item:this.queryStringData.search,
                sort,
                itemExtra:{},
                page:{
                    page:httpOptions.params.page,
                    perPage:httpOptions.params.per_page,
                }
            });
        },
        async loadError(r){
            if (!r.data)
                Metro.toast.create(`${r}`, null, null, "alert");
            else
                Metro.toast.create(`${r.data.message}`, null, null, "alert");
        },
        async loadSuccess(r){
            if (r.data.code===-1001)
                this.$router.push('/signin');
        },
        loading(){
            this.dataLoading=true;
        },
        loaded(){
            if (this.selectedElementId){
                if (document.querySelector(".last-active"))
                    document.querySelector(".last-active").scrollIntoView();
            }
            this.dataLoading=false;
            this.updateURLWithSearchAndSortData();
            this.$nextTick(()=>{
                globals.setAdminTablesLayout();
            })
        },
        onRowClass(data,index){
            if (this.selectedElementId){
                if (data.id==this.selectedElementId){
                    return "last-active";
                }
            }
            return "row-hover";
        },
        async onRowAction(action,data){
            const self=this;
            if (data.action==='edit-item'){
                this.$store.commit('setAdminEditPageId',data.data.id);
                this.$router.push(this.editURL+data.data.id);
            }
            if (data.action==='delete-item'){
                Metro.dialog.create({
                    title: "Delete confirmation?",
                    content: `<div>Are you sure you want to delete item  ${data.data[deleteConfirmationField]}?</div>`,
                    actions: [
                        {
                            caption: "Yes",
                            cls: "js-dialog-close alert",
                            onclick: async ()=>{

                                let response=await axios.post(`${this.deleteAPIURL}`,{
                                    tokenValue:globals.getToken(),
                                    id:data.data.id
                                });
                                response=await axios.post(`${settings.authServiceUrl}user/erase`,{
                                    tokenValue:globals.getToken(),
                                    id:data.data.authId
                                });
                                this.$refs.vuetable.refresh();
                            }
                        },
                        {
                            caption: "Cancel",
                            cls: "js-dialog-close",
                            onclick: function(){
                            }
                        }
                    ]
                });
            }
        },
        onPaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage (page) {
            this.$refs.vuetable.changePage(page)
        },
        onSearch(){
            this.$refs.vuetable.refresh();
        },
        onClear(){
            this.queryStringData.search={};
            this.$refs.vuetable.refresh();
        },
        async onAdd(){
            this.$root.$emit('ui-blocker',1);
            let response=await axios.post(`${this.createAPIURL}`,{
                tokenValue:globals.getToken(),
                item:defaultNewItem
            });
            if (!response.data.ok){
                this.$root.$emit('ui-blocker',-1);
                Metro.toast.create(`${response.data.message}`, null, null, "alert");
                return;
            }
            let newId=response.data.payload.id;
            this.$root.$emit('ui-blocker',-1);
            this.$router.push(this.editURL+newId+'?isNew=true');
        }
    }
}
</script>

<style lang="less">
</style>
