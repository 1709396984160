var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "widget-container scrollbar-style" }, [
    _vm.loading
      ? _c("div", {
          attrs: {
            "data-role": "progress",
            "data-type": "line",
            "data-small": "true",
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.items, function (i) {
        return _c("div", { key: i.courseId }, [
          _c("div", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v(_vm._s(i.course.name)),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("span", [_vm._v("Απουσίες: ")]),
            _vm._v(" "),
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(_vm._s(i.absent)),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("span", [_vm._v("Θετικά: ")]),
            _vm._v(" "),
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(_vm._s(i.like)),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("span", [_vm._v("Αρνητικά: ")]),
            _vm._v(" "),
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(_vm._s(i.dislike)),
            ]),
            _c("br"),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }