<template>
<div class="app-area">
  <router-view></router-view>
</div>
  
</template>

<style lang="less">
html,body,.app{
  height:100%;
  font-family: 'Libre Franklin', sans-serif;
  padding:0px;
  margin:0px;
  color:#ffffff;
}
.app-area{
  height:100%;
  padding:0px;
  margin:0px;
  overflow: hidden;
}

.disableSelection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}

input { -webkit-user-select:text;}

.scrollbar-style::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scrollbar-style::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.scrollbar-style::-webkit-scrollbar-thumb
{
	background-color: #ccc;
  // border: 2px solid #555555;
  cursor:pointer;
}

.invalid_feedback {
    font-size: 10px;
    color: #ce352c;
}
.v-select{
  
    font-size: 13px;
    // box-shadow: none !important;
    
  .vs__search{
    position: absolute;
  }
  .vs__search:focus{
    position:inherit !important;
  }
  .vs__selected-options{
    width:~"calc(100% - 40px)"
  }
  .vs__dropdown-toggle{
    border: 1px solid #cacdd0;
    border-radius: .25rem;
    height: calc(1.5em + .75rem + 2px);
    background-color: white;
  }
  input{
    // border-bottom: none !important;
    height: calc(1.5em + .75rem + 2px) !important;
    width: auto !important;
    border-radius: .25rem !important;
    // border-radius:0px !important;
  }
  .vs__actions{
    svg{
      cursor: pointer;
    }
  }
}

</style>

<script>
import axios from 'axios';
import _ from 'underscore';
import UIBlocker from './components/common/UIBlocker.vue'

import settings from './settings';
import globals from './globals';
export default {
  components:{   
      'ui-blocker':UIBlocker
  },
  data () {
    return {
      token:localStorage.getItem('token'),
      version:globals.version
      }
      },
  computed:{
    userEmail(){
      if (this.$store.state.user)
        return this.$store.state.user.email;

      return "-";
    },
    userRole(){
      if (this.$store.state.user)
        return this.$store.state.user.roleId;

      return 100;
    },
    loggedIn(){
      return this.$store.state.user;
    }
  },
  mounted(){
    this.$root.$on('loggedIn',()=>{
      this.token=localStorage.getItem('token');
    });
  },
  watch: {
    },
  methods:{
    async onItemClicked(item){
      if (item==='Logout'){
        await this.onLogout();
      }
    },
    async onLogout(){
      localStorage.removeItem('token');
      await axios.post(`${settings.authServiceUrl}token/expireToken`, {tokenValue:this.token});
      this.token=undefined;
      this.$store.commit('setUser',undefined);
      this.$router.push('/signin');
    }
  }
}
</script>

