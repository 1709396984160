var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page home-page  scrollbar-style" }, [
    _c("div", { staticClass: "widget-container scrollbar-style" }, [
      _c("section", { staticClass: "widget-container-header" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.widgetsAutoArrange,
              expression: "widgetsAutoArrange",
            },
          ],
          attrs: {
            type: "checkbox",
            "data-role": "checkbox",
            "data-caption": "Auto Arrange",
          },
          domProps: {
            checked: Array.isArray(_vm.widgetsAutoArrange)
              ? _vm._i(_vm.widgetsAutoArrange, null) > -1
              : _vm.widgetsAutoArrange,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.widgetsAutoArrange,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.widgetsAutoArrange = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.widgetsAutoArrange = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.widgetsAutoArrange = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _vm.loading
          ? _c("div", {
              attrs: {
                "data-role": "progress",
                "data-type": "line",
                "data-small": "true",
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.user.roleId == 0
        ? _c(
            "section",
            {
              staticClass: "widget-container-widgets",
              class: { "auto-arrange": _vm.widgetsAutoArrange },
            },
            [
              _c(
                "widget",
                {
                  attrs: {
                    id: "3",
                    title: "Active organizations",
                    headerTextColor: "#000",
                  },
                },
                [_c("google-map")],
                1
              ),
              _vm._v(" "),
              _c(
                "widget",
                {
                  attrs: { id: "4", title: "Status", headerTextColor: "#000" },
                },
                [_c("admin-server-summary")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.user.roleId == 3 && !_vm.loading
        ? _c(
            "section",
            {
              staticClass: "widget-container-widgets",
              class: { "auto-arrange": _vm.widgetsAutoArrange },
            },
            [
              _vm._l(_vm.series3, function (data, idx) {
                return _c(
                  "widget",
                  {
                    key: idx,
                    attrs: {
                      id:
                        data.course.id +
                        "_" +
                        data.course.team.id +
                        "_" +
                        data.course.team.departmentId +
                        "_bar",
                      title:
                        data.course.name +
                        " - " +
                        data.course.team.name +
                        " - " +
                        data.course.team.departmentName,
                      headerTextColor: "rgb(107 107 107)",
                      headerBackColor: "#d5deff",
                      menuButtonVisible: false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "footer",
                          fn: function () {
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      "/admin/students?search=%7CteamId," +
                                      data.course.team.id +
                                      "%7CrubrikMode,true%7CcourseId," +
                                      data.course.id +
                                      "%7CrubrikPartsGroupView,true&sort=&page=1",
                                  },
                                },
                                [_vm._v("Μάθημα")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("teacher-team-view", {
                      attrs: {
                        options: _vm.chart3Options[idx].chart3Options,
                        series: [data.series],
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "widget",
                {
                  attrs: {
                    headerTextColor: "rgb(0 0 0)",
                    headerBackColor: "rgb(251 178 104)",
                    title: "ΣΥΓΚΕΝΤΡΩΤΙΚΑ",
                  },
                },
                [_c("teacher-summary", { attrs: { courses: _vm.courses } })],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }