<template>
  <div class="side-panel" :class="{hidden:!visible}" :style="{'z-Index':zIndex}">
    <section class="side-panel-content">
      <slot></slot>
    </section>
    <section class="side-panel-right-bar">
      <section class="side-panel-right-bar-controls">
        <span class="mif-chevron-left mif-lg close-btn" v-if="visible" @click="onHide"></span>
        <span class="mif-chevron-right mif-lg expand-btn" v-if="!visible" @click="onShow"></span>
      </section>
      <section class="side-panel-right-bar-content">
        <slot></slot>
      </section>
      <section class="side-panel-right-bar-footer" @click="onToggle">
        <!-- <span class="rotated-title">{{title}}</span> -->
      </section>
    </section>
  </div>
</template>

<script>
export default {
  props:{
    title:undefined,
    zIndex:undefined
  },
  data() {
    return {
      visible: false
    };
  },
  mounted() {
    this.$root.$on('sidepanel-toggle',(v)=>{
      if (v===undefined)
        this.visible=!this.visible;
      else
        this.visible=v;
    })
  },
  methods: {
    onShow() {
      this.visible = true;
    },
    onHide() {
      this.visible = false;
    },
    onToggle(){
      this.visible=!this.visible;
    }
  }
};
</script> 

<style lang="less" scoped>
@side-panel-size: 500px;
@side-panel-right-bar-size: 50px;

.side-panel {
  width: @side-panel-size;
  height: 100%;
  display: flex;
  background-color:white;
  z-index: 1000;
  &.hidden {
    width: @side-panel-right-bar-size;
    .side-panel-content {
      display: none;
    }
  }
  @media screen and (max-width:600px){
    &.hidden {
      width: 0px;
    }
  }
  .side-panel-content {
    flex: 0 0 @side-panel-size - @side-panel-right-bar-size;
    height: 100%;
    overflow: hidden;
  }
  .side-panel-right-bar {
    flex: 0 0 @side-panel-right-bar-size;
    width:@side-panel-right-bar-size;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .side-panel-right-bar-controls {
    height: 40px;
    flex: 0 0 auto;
    margin-top: 10px;
    text-align:center;
    .close-btn {
      cursor: pointer;
      color: #000;
    }
    .expand-btn {
      cursor: pointer;
      color: #000;
    }
  }
  .side-panel-right-bar-content {
    flex: 1 1 100%;
  }
  .side-panel-right-bar-footer{
    position: relative;
    flex: 0 0 200px;
    .rotated-title {
      display: block;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
      font-size: 17px;
      position: absolute;
      right: -82px;
      top: 76px;
      color: #aaa;
      /* text-align: right; */
      width: 200px;
      font-weight: bold;
    }
  }
}
</style>