var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.name,
                expression: "item.name",
              },
            ],
            attrs: {
              "data-validate": "required minlength=2",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "Name: ",
            },
            domProps: { value: _vm.item.name },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "name", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isSubList,
                expression: "!isSubList",
              },
            ],
            staticClass: "cell-12",
          },
          [
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("vue-select", {
                  attrs: {
                    label: "name",
                    reduce: function (v) {
                      return v.id
                    },
                    filterable: false,
                    options: _vm.optionsDepartments,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            _c("div", { staticClass: "d-center" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(option.name) +
                                  " (" +
                                  _vm._s(option.organizationName) +
                                  ")\n                "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "selected-option",
                        fn: function (option) {
                          return [
                            _c("div", { staticClass: "selected d-center" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(option.name) +
                                  " (" +
                                  _vm._s(option.organizationName) +
                                  ")\n                "
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2891180516
                  ),
                  model: {
                    value: _vm.item.departmentId,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "departmentId", $$v)
                    },
                    expression: "item.departmentId",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "prepend" }, [_vm._v("Τάξη:")]),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.eLearningUrl,
                expression: "item.eLearningUrl",
              },
            ],
            attrs: {
              "data-validate": "",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "E-Learning URL: ",
            },
            domProps: { value: _vm.item.eLearningUrl },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "eLearningUrl", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.facilities,
                expression: "item.facilities",
              },
            ],
            attrs: {
              "data-validate": "",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "Παροχές: ",
            },
            domProps: { value: _vm.item.facilities },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "facilities", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.capacity,
                expression: "item.capacity",
              },
            ],
            attrs: {
              "data-validate": "integer",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "Χωρητικότητα: ",
            },
            domProps: { value: _vm.item.capacity },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "capacity", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-md-6" }, [
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("vue-select", {
                attrs: {
                  label: "name",
                  reduce: function (v) {
                    return v.id
                  },
                  filterable: false,
                  options: _vm.optionsUsers,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "d-center" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(option.sirname) +
                                " " +
                                _vm._s(option.name) +
                                " (" +
                                _vm._s(option.email) +
                                ")\n                "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "selected-option",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "selected d-center" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(option.sirname) +
                                " " +
                                _vm._s(option.name) +
                                "  (" +
                                _vm._s(option.email) +
                                ")\n                "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  103596804
                ),
                model: {
                  value: _vm.item.responsibleUserId,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "responsibleUserId", $$v)
                  },
                  expression: "item.responsibleUserId",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "prepend" }, [_vm._v("Υπεύθυνος:")]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell-12" },
          [
            _c("div", { staticClass: "subListHeader" }, [
              _vm._v("\n            Μαθητές\n            "),
              _c("span", {
                staticClass: "mif-add mif-lg",
                staticStyle: {
                  cursor: "pointer",
                  "background-color": "green",
                  color: "white",
                  width: "20px",
                  height: "20px",
                  "border-radius": "15px",
                  "text-align": "center",
                  "padding-top": "2px",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onAddStudent.apply(null, arguments)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { padding: "10px" } }, [
              _c(
                "div",
                { staticStyle: { "font-weight": "bold", "font-size": "14px" } },
                [_vm._v("ΠΡΟΣΘΗΚΗ ΗΔΗ ΥΠΑΡΧΟΝΤΩΝ ΜΑΘΗΤΩΝ")]
              ),
              _vm._v(" "),
              _vm.loadingStudents
                ? _c("div", {
                    attrs: {
                      "data-role": "progress",
                      "data-type": "line",
                      "data-small": "true",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "max-height": "300px", overflow: "auto" } },
                _vm._l(_vm.students, function (s) {
                  return _c(
                    "div",
                    {
                      key: s.id,
                      staticStyle: {
                        display: "flex",
                        "font-size": "14px",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("div", { staticStyle: { flex: "1 1 auto" } }, [
                        _vm._v(_vm._s(s.sirname) + " " + _vm._s(s.name)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { flex: "0 0 100px" } }, [
                        _vm._v(
                          _vm._s(
                            s.teams
                              .map(function (x) {
                                return x.name
                              })
                              .join(",")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { flex: "0 0 100px" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: s.selected,
                              expression: "s.selected",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            "data-role": "checkbox",
                            "data-caption": "Προσθήκη",
                          },
                          domProps: {
                            checked: Array.isArray(s.selected)
                              ? _vm._i(s.selected, null) > -1
                              : s.selected,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = s.selected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(s, "selected", $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      s,
                                      "selected",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(s, "selected", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "button primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onAddExistingStudents.apply(null, arguments)
                      },
                    },
                  },
                  [_c("span", [_vm._v("Προσθήκη")])]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("data-list", {
              ref: "studentsList",
              attrs: { settings: _vm.studentListSettings },
              on: { onListUpated: _vm.onStudentUpdated },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cell-12 cell-md-6" }, [
          _c("input", {
            attrs: {
              type: "file",
              "data-role": "file",
              "data-prepend": "Upload .csv:",
            },
            on: {
              change: function ($event) {
                return _vm.processCSVFile($event)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-12 cell-md-4" }, [
          _c(
            "button",
            {
              staticClass: "button primary",
              class: { outline: _vm.isSubList },
              attrs: { disabled: !_vm.selectedCSVFile },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onUploadFile.apply(null, arguments)
                },
              },
            },
            [_c("span", [_vm._v("Upload")])]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }