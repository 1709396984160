var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "admin-list",
      class: {
        "sub-list": _vm.settings.isSubList,
        page: !_vm.settings.isSubList,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.editing,
              expression: "!editing",
            },
          ],
          staticClass: "grid",
        },
        [
          !_vm.settings.hideFilters
            ? _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.settings.itemName == "student"
                    ? _c("filters-student", {
                        attrs: { queryStringData: _vm.queryStringData },
                        on: {
                          onRefresh: _vm.onRefresh,
                          onFilterSpecial: _vm.onFilterSpecial,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.settings.itemName == "team"
                    ? _c("filters-team", {
                        attrs: { queryStringData: _vm.queryStringData },
                        on: {
                          onRefresh: _vm.onRefresh,
                          onFilterSpecial: _vm.onFilterSpecial,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.settings.itemName == "course"
                    ? _c("filters-course", {
                        attrs: { queryStringData: _vm.queryStringData },
                        on: {
                          onRefresh: _vm.onRefresh,
                          onFilterSpecial: _vm.onFilterSpecial,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.settings.hideControls
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "controls-area" }, [
                  _c("div", { staticClass: "cell-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button primary",
                        class: { outline: _vm.settings.isSubList },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onSearch.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", { staticClass: "mif-refresh mif-lg" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button primary",
                        class: { outline: _vm.settings.isSubList },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onAdd.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", { staticClass: "mif-add mif-lg" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button primary",
                        class: { outline: _vm.settings.isSubList },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onClear.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", { staticClass: "mif-cross-light mif-lg" })]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "table-area" },
              [
                _c("vuetable-pagination", {
                  ref: "pagination",
                  staticClass: "pagination",
                  attrs: { css: _vm.paginationCss },
                  on: { "vuetable-pagination:change-page": _vm.onChangePage },
                }),
                _vm._v(" "),
                _vm.initialized
                  ? _c(
                      "div",
                      { staticClass: "cell-12" },
                      [
                        _vm.dataLoading
                          ? _c("div", {
                              attrs: {
                                "data-role": "progress",
                                "data-type": "line",
                                "data-small": "true",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.showCards && !_vm.showStudentRubriksTable
                          ? _c("vuetable", {
                              ref: "vuetable",
                              class: { loading: _vm.dataLoading },
                              attrs: {
                                css: _vm.tableCss,
                                "api-url": _vm.apiURL,
                                "http-fetch": _vm.getData,
                                "http-method": "post",
                                "load-on-start": false,
                                fields: _vm.columns,
                                "sort-order": _vm.queryStringData.sort,
                                "per-page": _vm.dataPerPage,
                                "row-class": _vm.onRowClass,
                                "data-path": "payload.items",
                                "pagination-path": "payload.pagination",
                              },
                              on: {
                                "vuetable:loading": _vm.loading,
                                "vuetable:loaded": _vm.loaded,
                                "vuetable:load-success": _vm.loadSuccess,
                                "vuetable:load-error": _vm.loadError,
                                "vuetable:pagination-data":
                                  _vm.onPaginationData,
                                "VuetableRowAction:action-item":
                                  _vm.onRowAction,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showCards && !_vm.showStudentRubriksTable
                          ? _c("cards-table", {
                              ref: "vuetable",
                              class: { loading: _vm.dataLoading },
                              attrs: {
                                css: _vm.tableCss,
                                "api-url": _vm.apiURL,
                                "http-fetch": _vm.getData,
                                "http-method": "post",
                                "load-on-start": false,
                                fields: _vm.columns,
                                "sort-order": _vm.queryStringData.sort,
                                "per-page": _vm.dataPerPage,
                                "row-class": _vm.onRowClass,
                                "data-path": "payload.items",
                                "pagination-path": "payload.pagination",
                              },
                              on: {
                                "vuetable:loading": _vm.loading,
                                "vuetable:loaded": _vm.loaded,
                                "vuetable:load-success": _vm.loadSuccess,
                                "vuetable:load-error": _vm.loadError,
                                "vuetable:pagination-data":
                                  _vm.onPaginationData,
                                "VuetableRowAction:action-item":
                                  _vm.onRowAction,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showStudentRubriksTable
                          ? _c("student-rubriks-table", {
                              ref: "vuetable",
                              class: { loading: _vm.dataLoading },
                              attrs: {
                                css: _vm.tableCss,
                                "api-url": _vm.apiURL,
                                "http-fetch": _vm.getData,
                                "http-method": "post",
                                "load-on-start": false,
                                fields: _vm.columns,
                                "sort-order": _vm.queryStringData.sort,
                                "per-page": _vm.dataPerPage,
                                "row-class": _vm.onRowClass,
                                onAfterDataLoaded:
                                  _vm.settings.onAfterDataLoaded,
                                "data-path": "payload.items",
                                "pagination-path": "payload.pagination",
                                rubrikPartsGroupView:
                                  _vm.settings.searchParams
                                    .rubrikPartsGroupView,
                              },
                              on: {
                                "vuetable:loading": _vm.loading,
                                "vuetable:loaded": _vm.loaded,
                                "vuetable:load-success": _vm.loadSuccess,
                                "vuetable:load-error": _vm.loadError,
                                "vuetable:pagination-data":
                                  _vm.onPaginationData,
                                "VuetableRowAction:action-item":
                                  _vm.onRowAction,
                                onAddScores: _vm.onAddScores,
                                onEditScores: _vm.onEditScores,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.editing
        ? _c(
            "div",
            [
              _c("data-edit", {
                attrs: {
                  isNewItem: _vm.isNew,
                  isSubList: _vm.settings.isSubList,
                  id: _vm.selectedElementId
                    ? _vm.selectedElementId.toString()
                    : "",
                  itemName: _vm.settings.itemName,
                  itemNamePlural: _vm.settings.itemNamePlural,
                },
                on: { finishedEdititng: _vm.onFinishedEditing },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }