import { render, staticRenderFns } from "./departments.vue?vue&type=template&id=bcf686d2&"
import script from "./departments.vue?vue&type=script&lang=js&"
export * from "./departments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/da/da.app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bcf686d2')) {
      api.createRecord('bcf686d2', component.options)
    } else {
      api.reload('bcf686d2', component.options)
    }
    module.hot.accept("./departments.vue?vue&type=template&id=bcf686d2&", function () {
      api.rerender('bcf686d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/webapp/views/admin/departments.vue"
export default component.exports