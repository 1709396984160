var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "widget disableSelection",
      attrs: { id: "widget__" + _vm.id },
    },
    [
      _c(
        "section",
        {
          staticClass: "widget-header",
          style: {
            "background-color": _vm.headerBackColor
              ? _vm.headerBackColor
              : "transparent",
          },
        },
        [
          _c(
            "section",
            {
              staticClass: "widget-header-title",
              style: { color: _vm.headerTextColor ? _vm.headerTextColor : "" },
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._v(" "),
          _c("section", { staticClass: "widget-header-control" }, [
            _c("span", {
              staticClass: "mif-enlarge2 mif-2x",
              staticStyle: { "margin-right": "10px" },
              on: { mousedown: _vm.onStartResize },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "mif-enlarge mif-2x",
              staticStyle: { "margin-right": "10px" },
              on: { mousedown: _vm.onStartMove },
            }),
            _vm._v(" "),
            _vm.menuButtonVisible
              ? _c("span", {
                  staticClass: "mif-equalizer mif-2x",
                  staticStyle: { "margin-right": "10px" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toggleMenu()
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.menuVisible
              ? _c(
                  "section",
                  { staticClass: "widget-menu" },
                  [
                    _vm._t("menu", function () {
                      return [_vm._v("\n          Menu\n        ")]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "widget-content",
          style: {
            "background-color": _vm.bodyBackColor ? _vm.bodyBackColor : "#fff",
          },
        },
        [
          _vm._t("default", function () {
            return [_vm._v("\n      Content here\n    ")]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "widget-footer",
          style: {
            "background-color": _vm.bodyBackColor ? _vm.bodyBackColor : "#fff",
          },
        },
        [
          _c(
            "section",
            { staticClass: "widget-footer-title" },
            [_vm._t("footer")],
            2
          ),
          _vm._v(" "),
          _c("section", { staticClass: "widget-footer-control" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }