var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "widget-container scrollbar-style" }, [
    _vm.loading
      ? _c("div", {
          attrs: {
            "data-role": "progress",
            "data-type": "line",
            "data-small": "true",
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: {
          "font-size": "46px",
          "font-weight": "bold",
          "text-align": "center",
        },
        style: { color: _vm.color ? _vm.color : "#000" },
      },
      [
        _vm._v("\n    " + _vm._s(_vm.number) + "\n    "),
        _c("div", {
          staticStyle: {
            "font-size": "10px",
            "margin-top": "-12px",
            color: "#000",
          },
          domProps: { innerHTML: _vm._s(_vm.label) },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }