var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "grid" }, [
      _c("div", { staticClass: "container" }, [
        _vm.initializing
          ? _c("div", {
              attrs: {
                "data-role": "progress",
                "data-type": "line",
                "data-small": "true",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "tools-area" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "cell-full" }, [
              _vm._v("\n                        Παραγωγή αναφοράς για: "),
              _c("br"),
              _vm._v(" "),
              _c("div", [
                _vm.user && _vm.user.roleId == 2
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reportType,
                          expression: "reportType",
                        },
                      ],
                      attrs: {
                        value: "DEPARTMENT",
                        type: "radio",
                        "data-role": "radio",
                        "data-caption": "Τάξη",
                        name: "radioReportType",
                      },
                      domProps: {
                        checked: _vm._q(_vm.reportType, "DEPARTMENT"),
                      },
                      on: {
                        change: function ($event) {
                          _vm.reportType = "DEPARTMENT"
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reportType,
                      expression: "reportType",
                    },
                  ],
                  attrs: {
                    value: "TEAM",
                    type: "radio",
                    "data-role": "radio",
                    "data-caption": "Τμήμα",
                    name: "radioReportType",
                  },
                  domProps: { checked: _vm._q(_vm.reportType, "TEAM") },
                  on: {
                    change: function ($event) {
                      _vm.reportType = "TEAM"
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.reportType,
                      expression: "reportType",
                    },
                  ],
                  attrs: {
                    value: "STUDENT",
                    type: "radio",
                    "data-role": "radio",
                    "data-caption": "Μαθητή",
                    name: "radioReportType",
                  },
                  domProps: { checked: _vm._q(_vm.reportType, "STUDENT") },
                  on: {
                    change: function ($event) {
                      _vm.reportType = "STUDENT"
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _vm.reportType == "ORGANIZATION"
              ? _c("div", { staticClass: "cell-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input",
                      staticStyle: { width: "100%", "max-width": "600px" },
                    },
                    [
                      _c("vue-select", {
                        attrs: {
                          label: "name",
                          reduce: function (v) {
                            return v.id
                          },
                          filterable: false,
                          options: _vm.optionsOrganizations,
                        },
                        on: { search: _vm.fetchOrganizationOptions },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("div", { staticClass: "d-center" }, [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(option.name) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "selected d-center" },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(option.name) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          423278052
                        ),
                        model: {
                          value: _vm.organizationId,
                          callback: function ($$v) {
                            _vm.organizationId = $$v
                          },
                          expression: "organizationId",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "prepend" }, [
                        _vm._v("Organization:"),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.reportType == "DEPARTMENT"
              ? _c("div", { staticClass: "cell-sm-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input",
                      staticStyle: { width: "100%", "max-width": "600px" },
                    },
                    [
                      _c("vue-select", {
                        attrs: {
                          label: "name",
                          reduce: function (v) {
                            return v.id.toString()
                          },
                          filterable: false,
                          options: _vm.optionsDepartments,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("div", { staticClass: "d-center" }, [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(option.name) +
                                        " - " +
                                        _vm._s(option.organizationName) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "selected d-center" },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(option.name) +
                                          " - " +
                                          _vm._s(option.organizationName) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          359201764
                        ),
                        model: {
                          value: _vm.departmentId,
                          callback: function ($$v) {
                            _vm.departmentId = $$v
                          },
                          expression: "departmentId",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "prepend" }, [_vm._v("Τάξη:")]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.reportType == "TEAM" || _vm.reportType == "STUDENT"
              ? _c("div", { staticClass: "cell-sm-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input",
                      staticStyle: { width: "100%", "max-width": "600px" },
                    },
                    [
                      _c("vue-select", {
                        attrs: {
                          label: "name",
                          reduce: function (v) {
                            return v.id.toString()
                          },
                          filterable: false,
                          options: _vm.optionTeams,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("div", { staticClass: "d-center" }, [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(option.name) +
                                        " - " +
                                        _vm._s(option.departmentName) +
                                        " - " +
                                        _vm._s(option.organizationName) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "selected d-center" },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(option.name) +
                                          " - " +
                                          _vm._s(option.departmentName) +
                                          " - " +
                                          _vm._s(option.organizationName) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2085852900
                        ),
                        model: {
                          value: _vm.teamId,
                          callback: function ($$v) {
                            _vm.teamId = $$v
                          },
                          expression: "teamId",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "prepend" }, [_vm._v("Τμήμα:")]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.reportType == "STUDENT"
              ? _c("div", { staticClass: "cell-sm-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input",
                      staticStyle: { width: "100%", "max-width": "600px" },
                    },
                    [
                      _c("vue-select", {
                        attrs: {
                          label: "sirname",
                          reduce: function (v) {
                            return v.id.toString()
                          },
                          filterable: false,
                          options: _vm.optionsStudents,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("div", { staticClass: "d-center" }, [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(option.sirname) +
                                        " " +
                                        _vm._s(option.name) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "selected d-center" },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(option.sirname) +
                                          " " +
                                          _vm._s(option.name) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1298573636
                        ),
                        model: {
                          value: _vm.studentId,
                          callback: function ($$v) {
                            _vm.studentId = $$v
                          },
                          expression: "studentId",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "prepend" }, [
                        _vm._v("Μαθητής:"),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
              _c("div", [_vm._v("Από: ")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.from,
                    expression: "from",
                  },
                ],
                attrs: { id: "from-datepicker", "data-role": "datepicker" },
                domProps: { value: _vm.from },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.from = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
              _c("div", [_vm._v("Έως: ")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.to,
                    expression: "to",
                  },
                ],
                attrs: { id: "to-datepicker", "data-role": "datepicker" },
                domProps: { value: _vm.to },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.to = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cell-full" }, [
              _c(
                "button",
                {
                  staticClass: "button primary",
                  attrs: {
                    disabled:
                      !_vm.studentId && !_vm.teamId && !_vm.organizationId,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onGenerateReport.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                            Δημιουργία αναφοράς\n                        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button primary",
                  attrs: { disabled: !_vm.reportGenerated },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onPrint.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                            Εκτύπωση αναφοράς\n                        "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.generatingReport
                ? _c("div", {
                    staticStyle: { margin: "10px 0px" },
                    attrs: {
                      "data-role": "progress",
                      "data-type": "line",
                      "data-small": "true",
                    },
                  })
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._v(" "),
        !_vm.initializing && !_vm.generatingReport && _vm.reportReady
          ? _c(
              "div",
              {
                staticClass: "report-area",
                staticStyle: { width: "29cm" },
                attrs: { id: "report" },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "cell-full" }, [
                    _c(
                      "div",
                      {
                        staticClass: "report",
                        staticStyle: { padding: "10px", width: "28cm" },
                      },
                      [
                        _c("div", { staticClass: "report-header" }, [
                          _c("div", { staticClass: "logo" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.organization.name)
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.organization.address)
                            ),
                            _c("br"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n                                    Έκθεση περιγραφικής αξιολόγησης \n                                    "
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  "font-weight": "normal",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm._f("toDateStr")(_vm.from)) +
                                    " - " +
                                    _vm._s(_vm._f("toDateStr")(_vm.to)) +
                                    "\n                                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.reportType == "STUDENT"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      "font-weight": "normal",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.student.sirname) +
                                        " " +
                                        _vm._s(_vm.student.name) +
                                        "\n                                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "report-section" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "24px",
                                  "font-weight": "bold",
                                  width: "100%",
                                  "margin-bottom": "10px",
                                  "margin-top": "10px",
                                },
                              },
                              [
                                _vm.reportType == "STUDENT"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.student.sirname) +
                                          " " +
                                          _vm._s(_vm.student.name)
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.reportType == "TEAM"
                                  ? _c("span", [_vm._v(_vm._s(_vm.team.name))])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.reportType == "DEPARTMENT"
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.department.name)),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _vm._m(0),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      flex: "0 0 30px",
                                      "margin-left": "10px",
                                      "font-size": "20px",
                                      "font-weight": "bold",
                                      color: "#666",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.absent) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._m(1),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      flex: "0 0 30px",
                                      "margin-left": "10px",
                                      "font-size": "20px",
                                      "font-weight": "bold",
                                      color: "#666",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.likes) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._m(2),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      flex: "0 0 30px",
                                      "margin-left": "10px",
                                      "font-size": "20px",
                                      "font-weight": "bold",
                                      color: "#666",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.dislikes) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.reportType == "STUDENT"
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-top": "10px" } },
                                  [
                                    _vm._l(
                                      _vm.getStudentRemarks("absent"),
                                      function (sd, idx) {
                                        return _c(
                                          "div",
                                          {
                                            key: idx,
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _vm._m(3, true),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  flex: "0 0 100px",
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm._f("toDateStr")(
                                                        sd.date
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  flex: "1 1 auto",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(sd.remarks) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.getStudentRemarks("likes"),
                                      function (sd, idx) {
                                        return _c(
                                          "div",
                                          {
                                            key: idx,
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _vm._m(4, true),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  flex: "0 0 100px",
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm._f("toDateStr")(
                                                        sd.date
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  flex: "1 1 auto",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(sd.remarks) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.getStudentRemarks("dislikes"),
                                      function (sd, idx) {
                                        return _c(
                                          "div",
                                          {
                                            key: idx,
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _vm._m(5, true),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  flex: "0 0 100px",
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm._f("toDateStr")(
                                                        sd.date
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  flex: "1 1 auto",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(sd.remarks) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              _vm.getAvailableCourses(_vm.courses, _vm.team.id),
                              function (course) {
                                return _c("div", { key: course.id }, [
                                  _c(
                                    "div",
                                    { staticClass: "report-section-header" },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(course.name) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "font-size": "43px",
                                        "font-weight": "bold",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.getStudentScoreInCourse(course)
                                          ) +
                                          "/100\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          "margin-top": "-130px",
                                        },
                                      },
                                      [
                                        _c("apexchart", {
                                          ref: "charts",
                                          refInFor: true,
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            type: "radar",
                                            options:
                                              _vm.getSeries3ChartOptionsForCourse(
                                                course.id
                                              ),
                                            series: _vm.getSeries3DataForCourse(
                                              course.id
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "16px",
                                          padding: "0px 20px",
                                          "margin-bottom": "20px",
                                          "margin-top": "-130px",
                                        },
                                      },
                                      [
                                        _vm.reportType == "STUDENT"
                                          ? _c("span", [
                                              _vm._v(
                                                "Ο/Η μαθητής(ια) " +
                                                  _vm._s(_vm.student.sirname) +
                                                  " " +
                                                  _vm._s(_vm.student.name)
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.reportType == "TEAM"
                                          ? _c("span", [
                                              _vm._v(
                                                "Το τμήμα " +
                                                  _vm._s(_vm.team.name)
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.reportType == "DEPARTMENT"
                                          ? _c("span", [
                                              _vm._v(
                                                "Η τάξη " +
                                                  _vm._s(_vm.department.name)
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                                            στο μάθημα " +
                                            _vm._s(course.name) +
                                            " καθόλη τη διάρκεια της περιόδου\n                                            είχε τις παρακάτω επιδόσεις κατά μέσο όρο: "
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v(" "),
                                        _vm._l(
                                          course.rubrik.rubrikParts,
                                          function (rp) {
                                            return _c("div", { key: rp.id }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(rp.description) +
                                                      " μ.ο: " +
                                                      _vm._s(
                                                        _vm.getPartScoreMean(
                                                          course.scoresByDate,
                                                          rp.id,
                                                          rp.steps
                                                        )
                                                      ) +
                                                      " (" +
                                                      _vm._s(
                                                        _vm.getPartScoreAmount(
                                                          course.scoresByDate,
                                                          rp.id
                                                        )
                                                      ) +
                                                      " αξιολογήσεις.)"
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.getPartScoreMeanLectical(
                                                      course.scoresByDate,
                                                      rp.id,
                                                      rp.steps,
                                                      rp.stepDescriptions
                                                    )
                                                  ),
                                                },
                                              }),
                                            ])
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "page-break",
                                      staticStyle: { margin: "40px 0px" },
                                    },
                                    [
                                      _c("table", { staticClass: "table" }, [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            [
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "font-size": "10px",
                                                    padding: "2px",
                                                    "text-align": "right",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                        ΚΡΙΤΗΡΙΟ\n                                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "font-size": "10px",
                                                    padding: "2px",
                                                    "text-align": "right",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                        ΒΑΡΥΤΗΤΑ\n                                                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                course.scoresByDate,
                                                function (d) {
                                                  return _c("th", {
                                                    key: d.idx,
                                                    staticStyle: {
                                                      "text-align": "right",
                                                      "font-size": "10px",
                                                      padding: "2px",
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.toDateStr(d.date)
                                                      ),
                                                    },
                                                  })
                                                }
                                              ),
                                              _vm._v(" "),
                                              _vm._m(6, true),
                                            ],
                                            2
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            course.rubrik.rubrikParts,
                                            function (rp) {
                                              return _c(
                                                "tr",
                                                { key: rp.id },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        padding: "2px",
                                                        "text-align": "right",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(rp.name) +
                                                          "\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        padding: "2px",
                                                        "text-align": "right",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(rp.weight) +
                                                          "%\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    course.scoresByDate,
                                                    function (d) {
                                                      return _c(
                                                        "td",
                                                        {
                                                          key: d.idx,
                                                          staticStyle: {
                                                            padding: "2px",
                                                            "text-align":
                                                              "right",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                _vm.getPartScore(
                                                                  d.scores,
                                                                  rp.id
                                                                )
                                                              ) +
                                                              "/" +
                                                              _vm._s(rp.steps) +
                                                              "\n                                                    "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        padding: "2px",
                                                        "text-align": "right",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(
                                                            _vm.getPartScoreAmount(
                                                              course.scoresByDate,
                                                              rp.id
                                                            )
                                                          ) +
                                                          "\n                                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("apexchart", {
                                        ref: "charts",
                                        refInFor: true,
                                        attrs: {
                                          type: "line",
                                          height: "350",
                                          options:
                                            _vm.getSeries1ChartOptionsForCourse(
                                              course.id
                                            ),
                                          series: _vm.getSeries1DataForCourse(
                                            course.id
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("table", { staticClass: "table" }, [
                                      _c("thead", [
                                        _c(
                                          "tr",
                                          [
                                            _c("th", [
                                              _vm._v(
                                                "\n                                                        ΚΡΙΤΗΡΙΟ\n                                                    "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.getMaxSteps(
                                                course.rubrik.rubrikParts
                                              ),
                                              function (s) {
                                                return _c(
                                                  "th",
                                                  {
                                                    key: s,
                                                    attrs: { colspan: "2" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                        " +
                                                        _vm._s(s) +
                                                        "\n                                                    "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          course.rubrik.rubrikParts,
                                          function (rp, idx) {
                                            return _c(
                                              "tr",
                                              { key: idx },
                                              [
                                                _c("td", [
                                                  _vm._v(
                                                    "\n                                                        " +
                                                      _vm._s(rp.description) +
                                                      " \n                                                        "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _vm._l(rp.steps, function (s) {
                                                  return [
                                                    _c("td", { key: s }, [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(
                                                            _vm.getPartScoreAmount(
                                                              course.scoresByDate,
                                                              rp.id,
                                                              s
                                                            )
                                                          ) +
                                                          "\n                                                    "
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", { key: s }, [
                                                      _vm._v(
                                                        "\n                                                        " +
                                                          _vm._s(
                                                            _vm.getPartScorePercentage(
                                                              course.scoresByDate,
                                                              rp.id,
                                                              s
                                                            ) == "0%"
                                                              ? "-"
                                                              : _vm.getPartScorePercentage(
                                                                  course.scoresByDate,
                                                                  rp.id,
                                                                  s
                                                                )
                                                          ) +
                                                          "\n                                                    "
                                                      ),
                                                    ]),
                                                  ]
                                                }),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "margin-top": "10px",
                                          "flex-wrap": "wrap",
                                        },
                                      },
                                      _vm._l(
                                        course.rubrik.rubrikParts,
                                        function (rp, idx) {
                                          return _c(
                                            "div",
                                            {
                                              key: idx,
                                              staticStyle: { flex: "0 0 25%" },
                                              attrs: { id: "chart" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                    "text-align": "center",
                                                    height: "40px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                    " +
                                                      _vm._s(rp.name) +
                                                      "\n                                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("apexchart", {
                                                ref: "charts",
                                                refInFor: true,
                                                attrs: {
                                                  type: "donut",
                                                  options:
                                                    _vm.getSeries2ChartOptionsForCourse(
                                                      course,
                                                      rp
                                                    ),
                                                  series:
                                                    _vm.getSeries2DataForCourse(
                                                      course,
                                                      rp
                                                    ),
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { flex: "0 0 30px" } }, [
      _c("img", {
        staticStyle: { width: "30px" },
        attrs: { src: require("../../assets/admin/absent.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { flex: "0 0 30px" } }, [
      _c("img", {
        staticStyle: { width: "30px" },
        attrs: { src: require("../../assets/admin/like.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { flex: "0 0 30px" } }, [
      _c("img", {
        staticStyle: { width: "30px" },
        attrs: { src: require("../../assets/admin/dislike.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { flex: "0 0 30px" } }, [
      _c("img", {
        staticStyle: { width: "20px" },
        attrs: { src: require("../../assets/admin/absent.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { flex: "0 0 30px" } }, [
      _c("img", {
        staticStyle: { width: "20px" },
        attrs: { src: require("../../assets/admin/like.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { flex: "0 0 30px" } }, [
      _c("img", {
        staticStyle: { width: "20px" },
        attrs: { src: require("../../assets/admin/dislike.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "th",
      {
        staticStyle: {
          "font-size": "10px",
          padding: "2px",
          "text-align": "right",
        },
      },
      [
        _vm._v(
          "\n                                                        ΣΥΝΟΛΟ "
        ),
        _c("br"),
        _vm._v(
          "ΑΞΙΟΛΟΓΗΣΕΩΝ\n                                                    "
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }