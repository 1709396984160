var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bar-area",
      style: { height: _vm.height + "px", width: _vm.width + "px" },
    },
    _vm._l(_vm.values, function (v, idx) {
      return _c(
        "div",
        {
          key: idx,
          staticClass: "bar",
          style: {
            border: _vm.borders,
            height: _vm.stepHeight * v + "px",
            width: _vm.barWidth + "px",
            "background-color": Array.isArray(_vm.colors)
              ? _vm.colors[idx]
              : _vm.colors,
          },
        },
        [
          v === 0
            ? _c(
                "span",
                {
                  staticStyle: { "margin-top": "-15px", display: "block" },
                  style: { "margin-top": -1 * _vm.stepHeight - 5 + "px" },
                },
                [_vm._v("x")]
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }