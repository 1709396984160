var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "cell-sm-full cell-md-2" }, [
          _c("img", {
            staticStyle: { width: "80px", height: "80px" },
            attrs: { src: _vm.toFullUrl(_vm.item.photoURL), alt: "" },
            on: { error: _vm.setAltImg },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-10" }, [
          _c("input", {
            staticClass: "mb-4",
            attrs: {
              type: "file",
              "data-role": "file",
              "data-prepend": "Photo file:",
            },
            on: {
              change: function ($event) {
                return _vm.processFileToUpload($event, "selectedPhotoFile")
              },
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: !_vm.selectedPhotoFile },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onUploadFile("STUDENT_PHOTO", "selectedPhotoFile")
                },
              },
            },
            [_c("span", [_vm._v("Upload Photo")])]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.name,
                expression: "item.name",
              },
            ],
            attrs: {
              "data-validate": "required minlength=3",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "Όνομα: ",
            },
            domProps: { value: _vm.item.name },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "name", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.sirname,
                expression: "item.sirname",
              },
            ],
            attrs: {
              "data-validate": "required minlength=3",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "Επώνυμο: ",
            },
            domProps: { value: _vm.item.sirname },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "sirname", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.fatherName,
                expression: "item.fatherName",
              },
            ],
            attrs: {
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "Όνομα πατρός: ",
            },
            domProps: { value: _vm.item.fatherName },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "fatherName", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cell-sm-full cell-md-6",
            staticStyle: { display: "flex" },
          },
          [
            _c("div", [_vm._v("Ημερομηνία Γέννησης:")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.dob,
                  expression: "item.dob",
                },
              ],
              attrs: { id: "dob-datepicker", "data-role": "datepicker" },
              domProps: { value: _vm.item.dob },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "dob", $event.target.value)
                },
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.emailOfficial,
                expression: "item.emailOfficial",
              },
            ],
            attrs: {
              "data-validate": "required email",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "Επίσημο Email: ",
            },
            domProps: { value: _vm.item.emailOfficial },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "emailOfficial", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.emailPersonal,
                expression: "item.emailPersonal",
              },
            ],
            attrs: {
              "data-validate": "",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "Προσωπικό Email: ",
            },
            domProps: { value: _vm.item.emailPersonal },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "emailPersonal", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-full" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.address,
                    expression: "item.address",
                  },
                ],
                attrs: {
                  "data-validate": "",
                  type: "text",
                  "data-clear-button": "false",
                  "data-role": "input",
                  "data-prepend": "Διεύθυνση: ",
                },
                domProps: { value: _vm.item.address },
                on: {
                  keyup: _vm.keyMonitor,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.item, "address", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.mobile,
                    expression: "item.mobile",
                  },
                ],
                attrs: {
                  "data-validate": "",
                  type: "text",
                  "data-clear-button": "false",
                  "data-role": "input",
                  "data-prepend": "Κινητό: ",
                },
                domProps: { value: _vm.item.mobile },
                on: {
                  keyup: _vm.keyMonitor,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.item, "mobile", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keedy,
                expression: "keedy",
              },
            ],
            attrs: {
              type: "checkbox",
              "data-role": "checkbox",
              "data-caption": "ΚΕΕΔΥ",
            },
            domProps: {
              checked: Array.isArray(_vm.keedy)
                ? _vm._i(_vm.keedy, null) > -1
                : _vm.keedy,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.keedy,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.keedy = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.keedy = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.keedy = $$c
                }
              },
            },
          }),
        ]),
        _vm._v(" "),
        _vm.keedy
          ? _c("div", { staticClass: "cell-full" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.item.keedyRemarks,
                    expression: "item.keedyRemarks",
                  },
                ],
                attrs: {
                  type: "text",
                  "data-clear-button": "false",
                  "data-role": "input",
                  "data-prepend": "Remarks: ",
                },
                domProps: { value: _vm.item.keedyRemarks },
                on: {
                  keyup: _vm.keyMonitor,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.item, "keedyRemarks", $event.target.value)
                  },
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.abilityIssues,
                expression: "abilityIssues",
              },
            ],
            attrs: {
              type: "checkbox",
              "data-role": "checkbox",
              "data-caption": "Κινητικά προβλήματα",
            },
            domProps: {
              checked: Array.isArray(_vm.abilityIssues)
                ? _vm._i(_vm.abilityIssues, null) > -1
                : _vm.abilityIssues,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.abilityIssues,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.abilityIssues = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.abilityIssues = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.abilityIssues = $$c
                }
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.sensitiveGroup,
                expression: "sensitiveGroup",
              },
            ],
            attrs: {
              type: "checkbox",
              "data-role": "checkbox",
              "data-caption": "Ανήκει σε ευπαθής ομάδα",
            },
            domProps: {
              checked: Array.isArray(_vm.sensitiveGroup)
                ? _vm._i(_vm.sensitiveGroup, null) > -1
                : _vm.sensitiveGroup,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.sensitiveGroup,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.sensitiveGroup = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.sensitiveGroup = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.sensitiveGroup = $$c
                }
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.elearningPlatformLink,
                expression: "item.elearningPlatformLink",
              },
            ],
            attrs: {
              "data-validate": "",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "E-learning url: ",
            },
            domProps: { value: _vm.item.elearningPlatformLink },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "elearningPlatformLink", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isSubList,
                expression: "!isSubList",
              },
            ],
            staticClass: "cell-12",
          },
          [
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("vue-select", {
                  attrs: {
                    label: "name",
                    reduce: function (v) {
                      return v.id
                    },
                    filterable: false,
                    options: _vm.optionTeams,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            _c("div", { staticClass: "d-center" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(option.name) +
                                  "\n                    "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "selected-option",
                        fn: function (option) {
                          return [
                            _c("div", { staticClass: "selected d-center" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(option.name) +
                                  "\n                    "
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    566720996
                  ),
                  model: {
                    value: _vm.item.teamId,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "teamId", $$v)
                    },
                    expression: "item.teamId",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "prepend" }, [_vm._v("Τμήμα:")]),
              ],
              1
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }