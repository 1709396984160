<template>
<div class="page edit-page">
    <data-edit :isNewItem="isNew" :isSubList="false" :id="id" itemName="student" itemNamePlural="students" :rootScreen="rootScreen"></data-edit>
</div>
</template>

<script>
import dataEdit from '../../components/admin/dataEdit.vue'

export default {
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.rootScreen = from.fullPath;
        });
    },
    props: {
        id: {
            type: String,
            default: "-"
        },
    },
    watch:{},
    computed:{},
    components: {
        dataEdit
    },
    data() {
        return {
            rootScreen:'',
            isNew:false
        };
    },
    mounted() {
        this.isNew=this.$route.query.isNew=='true';
        const action=async ()=>{
        };
        action();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.rootScreen = from.fullPath;
            if (vm.rootScreen=="/")
                vm.rootScreen=vm.defaultReturnURL;
        });
    },
    methods: {
    }
};
</script>

<style lang="less">
</style>