<template>
  <div class="google-maps-container scrollbar-style">
    <div id="map"></div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "underscore";
import { Loader } from "@googlemaps/js-api-loader"

import settings from '../../../settings.js'
import globals from '../../../globals.js';

export default {
  data() {
    return {
      loading: false,
      list:[],
      markers:[],
      map:undefined
    };
  },
  mounted() {
    const loader = new Loader({
      apiKey: settings.googleMapsAPIKey,
      version: "weekly"
    });
    const action=async ()=>{
      await loader.load();
      
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: settings.googleMapsDefaultView.mapCenter,
        zoom: settings.googleMapsDefaultView.zoom,
      });
      this.getData();
      
    }
    action();

  },
  methods: {
    async getData() {
      this.loading = true;
      let r = await axios.post(`${settings.serviceUrl}organization/getMany`,{tokenValue:globals.getToken()});
      this.list = r.data.payload.items;

      this.list.forEach((li)=>{
        this.markers.push(new google.maps.Marker({position: {lat:li.lat,lng:li.lng},map: this.map}));
      });
      this.loading = false;
    }
  }
};
</script>

<style lang="less" scoped>
.google-maps-container {
  height: 100%;
  overflow: hidden;
  #map{
    width:100%;
    height:100%;
  }
}
</style>