import { render, staticRenderFns } from "./app-client.vue?vue&type=template&id=10068220&"
import script from "./app-client.vue?vue&type=script&lang=js&"
export * from "./app-client.vue?vue&type=script&lang=js&"
import style0 from "./app-client.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/da/da.app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10068220')) {
      api.createRecord('10068220', component.options)
    } else {
      api.reload('10068220', component.options)
    }
    module.hot.accept("./app-client.vue?vue&type=template&id=10068220&", function () {
      api.rerender('10068220', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/webapp/app-client.vue"
export default component.exports