var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page edit-page" },
    [
      _c("data-edit", {
        attrs: {
          isNewItem: _vm.isNew,
          isSubList: false,
          id: _vm.id,
          itemName: "team",
          itemNamePlural: "teams",
          rootScreen: _vm.rootScreen,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }