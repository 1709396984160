<template>
<div style="margin-top:20px">
    <login></login>
</div>

</template>

<style lang="less" scoped>

</style>

<script>
import login from '../../components/admin/login.vue';

export default {
  components:{
    login
  },
  data () {
    return {
    }
  },
  mounted(){
  }
}
</script>