<template>
    <div class="page login-page">
        <div class="container">
            <div class="row">
                <form>
                    <div class="input-field col s12 m6">
                    <input id="email" type="text" v-model="username">
                    <label for="email" class="active">Username</label>
                    </div>
                    <div class="input-field col s12 m6">
                    <input id="password" type="password" v-model="password">
                    <label for="password" class="active">Password</label>
                    </div>
                </form>
                <button class="btn right" @click="onLogin">Submit</button>
                <div class="col s12" v-if="message" style="padding-top:10px">
                    <div class="right" v-if="!ok" style="font-weight:bold;color:red">{{message}}</div>
                    <div class="right" v-else style="font-weight:bold;">{{message}}</div>
                </div>  
            </div>
               
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import globals from '../../globals.js';
import settings from '../../settings.js';

export default {
    data(){
        return {
            username:'',
            password:'',
            message:'',
            ok:true
        }
    },
    components:{

    },
    mounted(){
        const action=async ()=>{
            
        };
        action();
    },
    methods:{
      async onLogin(){
            this.message='Logging in...';
            let r=await axios.post(`${settings.authServiceUrl}user/login`, {email:this.username,password:this.password,app:settings.app});
            this.ok=r.data.ok;
            if (!r.data.ok){
                this.message=r.data.message;
                return;
            }
            if (r.data.code===0){
                this.message=r.data.message;
                return;
            }
            this.message="SUCCESS";
            globals.setToken(r.data.payload.token.value);
            let user=r.data.payload.user;
            r=await axios.post(`${settings.serviceUrl}user/getMany`, {tokenValue:globals.getToken(),item:{authId:user.id}});
            user.info=r.data.payload.items[0];
            this.$store.commit('setUser',user);
            this.$root.$emit('loggedIn');
            this.$router.push('/');
        }
    }
}
</script>
<style lang="less" scoped>
.login-page{
 
}
</style>