<template>
<div class="page users-edit-page">
    <div class="edit-area">
        <form class="grid" v-if="item" data-interactive-check="true"> 
            <div class="row">
                <div class="cell">
                    <div class="edit-id">
                        {{id}}
                    </div>
                </div>
                <div class="stub" style="width: 120px">
                    <button class="button primary" @click.prevent="onSave">
                        <span class="mif-checkmark mif-lg"></span>
                    </button>
                    <button class="button primary" @click.prevent="onCancel">
                        <span class="mif-cancel mif-lg"></span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="cell-sm-full cell-md-3">
                    <input data-validate="required " type="text" data-clear-button="false" data-role="input" data-prepend="AuthId: " v-model="item.authId" @keyup="keyMonitor">
                </div>
            </div>
            <div class="row">
                <div class="cell-sm-full cell-md-6">
                    <input data-validate="required minlength=3" type="text" data-clear-button="false" data-role="input" data-prepend="Name: " v-model="item.name" @keyup="keyMonitor">
                </div>
                <div class="cell-sm-full cell-md-6">
                    <input data-validate="required" type="text" data-clear-button="false" data-role="input" data-prepend="Sirname: " v-model="item.sirname" @keyup="keyMonitor">
                </div>
                <div class="cell-12">
                    <input data-validate="required" type="text" data-clear-button="false" data-role="input" data-prepend="Address: " v-model="item.address" @keyup="keyMonitor">
                </div>
                <div class="cell-12">
                    <input data-validate="required" type="text" data-clear-button="false" data-role="input" data-prepend="City: " v-model="item.city" @keyup="keyMonitor">
                </div>
                <div class="cell-12">
                    <input data-validate="required" type="text" data-clear-button="false" data-role="input" data-prepend="Zip: " v-model="item.zip" @keyup="keyMonitor">
                </div>
                <div class="cell-12">
                    <input data-validate="required" type="text" data-clear-button="false" data-role="input" data-prepend="Phone: " v-model="item.phone" @keyup="keyMonitor">
                </div>
                <div class="cell-12">
                    <input data-validate="required" type="text" data-clear-button="false" data-role="input" data-prepend="Email: " v-model="item.email" @keyup="keyMonitor">
                </div>
                <div class="cell-12">
                    <input data-validate="required" type="text" data-clear-button="false" data-role="input" data-prepend="Username: " v-model="item.username" @keyup="keyMonitor">
                </div>
                <div class="cell-12">
                    <input type="text" data-clear-button="false" data-role="input" data-prepend="Password: " v-model="password" @keyup="keyMonitor">
                </div>      
                <div class="cell-12">
                    <div class="input">
                        <vue-select label="roleName" :reduce="(v)=>v.id" v-model="roleId" :filterable="false" :options="roles" :disabled="!item.authId && !isNew">
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.roleName }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.roleName }}
                                </div>
                            </template>
                        </vue-select>
                        <div class="prepend">Role:</div>
                    </div>
                </div> 
                
                <div class="cell-12">
                    <div class="input">
                        
                    <vue-select :disabled="user.roleId!=0" label="name" :reduce="(v)=>v.id" v-model="item.organizationId" :filterable="false" :options="optionsOrganizations">
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>
                    </vue-select>
                    <div class="prepend">Organization:</div>
                    </div>
                </div>        
                
                <div class="cell-12">
                    <button class="button primary" @click.prevent="onSave">
                        <span class="mif-checkmark mif-lg"></span>
                    </button>
                    <button class="button primary" @click.prevent="onCancel">
                        <span class="mif-cancel mif-lg"></span>
                    </button>
                </div>
                
            </div>
        </form>
    </div>
</div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
import _ from 'underscore';

import globals from '../../globals.js';
import settings from '../../settings.js'
import validator from '../../validator.js';

const itemName='user';
const itemNamePlural='users';

const getItemURL=`${settings.serviceUrl}${itemName}/get`;
const updateItemURL=`${settings.serviceUrl}${itemName}/update`;
const deleteItemURL=`${settings.serviceUrl}${itemName}/remove`;
const defaultReturnURL=`/admin/${itemNamePlural}`;
export default {
    props: {
        id: {
            type: String,
            default: "-"
        },
    },
    watch:{},
    computed:{
        user(){
            return this.$store.state.user;
        }
    },
    components: {

    },
    data() {
        return {
            item: {},
            isNew:false,
            password:'',
            optionsOrganizations:[],
            roles:[],
            roleId:null
        };
    },
    mounted() {
        this.isNew=this.$route.query.isNew=='true';
        this.$store.commit('setBreadcrumb',`${itemNamePlural} (editing: ${this.id})`);
        const action=async ()=>{
            this.$root.$emit('ui-blocker',1);
            let r=await axios.post(`${settings.serviceUrl}organization/getMany`, {tokenValue:globals.getToken()});
            this.optionsOrganizations= r.data.payload.items;

            r=await axios.post(`${settings.authServiceUrl}role/getMany`, {tokenValue:globals.getToken()});
            this.roles=_.where(r.data.payload.items,{app:'descriptive.assesment'});  

            let itemResponse=await axios.post(getItemURL, {tokenValue: globals.getToken(),id: this.id});
            let item=itemResponse.data.payload;
            if (item.authId && item.authId!=-1){
                let userResponse=await axios.post(`${settings.authServiceUrl}user/get`, {tokenValue: globals.getToken(),id: item.authId});
                item.username=userResponse.data.payload.email;
            }
            
            if (item.organizationId==-1)
                item.organizationId=undefined;
            
            if (item.authId){
                let r=await axios.post(`${settings.authServiceUrl}approle/getMany`, {tokenValue:globals.getToken(),item:{userId:item.authId,app:'descriptive.assesment'}});
                let existingRole=r.data.payload.items[0];
                this.roleId=existingRole?existingRole.roleId:null;
            }

            this.$root.$emit('ui-blocker',-1);
            this.item=item;
        };
        action();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.rootScreen = from.fullPath;
            if (vm.rootScreen=="/")
                vm.rootScreen=defaultReturnURL;
        });
    },
    methods: {
        keyMonitor(event){
            validator.validateForm(document.querySelector("form"),false);
        },
        async onCancel() {
            if (this.isNew){
                let response=await axios.post(`${deleteItemURL}`,{tokenValue:globals.getToken(),id:this.item.id});
            }
            else
                this.$store.commit('setAdminEditPageId',this.item.id);
            this.$router.push(this.rootScreen)
        },
        async onSave(d) {
            let validationResult=validator.validateForm(document.querySelector("form"),true);
            if (!validationResult.ok) return;

            if (this.isNew){
                if (!this.password){
                    Metro.toast.create('You must provide an initial password for the registration process', null, null, "alert");
                    return
                }
                else{
                    let r=await axios.post(`${settings.authServiceUrl}user/register`, {
                        email:this.item.username,
                        name:this.item.name,
                        sirname:this.item.sirname,
                        telephone:this.item.phone,
                        password:this.password,
                        app:settings.app,
                        appRoleId:this.roleId,
                        roleId:1
                    });

                    if (!r.data.ok){
                         Metro.toast.create(`${r.data.message}`, null, null, "alert");
                        return;
                    }
                    this.item.authId=r.data.payload.id;
                }
            }
            else{
                if (this.password){
                    let r=await axios.post(`${settings.authServiceUrl}user/user-update-pwd`, {id:this.item.authId,password:this.password});
                }
            }

            let response=await axios.post(updateItemURL, {tokenValue: globals.getToken(),item: this.item});
            let role=this.roleId;
            let r=await axios.post(`${settings.authServiceUrl}approle/getMany`, {tokenValue:globals.getToken(),item:{userId:this.item.authId,app:'descriptive.assesment'}});
            let existingRole=r.data.payload.items[0];
            if (existingRole && existingRole.roleId!=role){
                if (role){
                    existingRole.roleId=role;
                    r=await axios.post(`${settings.authServiceUrl}approle/update`, {tokenValue:globals.getToken(),item:existingRole});
                }
                else
                    r=await axios.post(`${settings.authServiceUrl}approle/remove`, {tokenValue:globals.getToken(),id:existingRole.id});
            }
            else{
                if (!existingRole && role)
                    r=await axios.post(`${settings.authServiceUrl}approle/add`, {tokenValue:globals.getToken(),item:{
                        email:this.item.email,
                        userId:this.item.authId,
                        app:'descriptive.assesment',
                        generalRoleId:1,
                        roleId:role
                    }});
            }
            this.$store.commit('setAdminEditPageId',this.item.id);
            this.$router.push(this.rootScreen)
        }
    }
};
</script>

<style lang="less">
</style>