var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid", class: [_vm.css.tableClass] }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.tableData, function (item, itemIndex) {
        return _c(
          "div",
          {
            key: itemIndex,
            class: [_vm.onRowClass(item, itemIndex), _vm.cardCellClass],
            attrs: { "item-index": itemIndex, render: _vm.onRowChanged(item) },
            on: {
              dblclick: function ($event) {
                return _vm.onRowDoubleClicked(item, $event)
              },
              click: function ($event) {
                return _vm.onRowClicked(item, $event)
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "card",
                style: { opacity: item.disable ? "0.6" : 1 },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "card-header",
                    staticStyle: {
                      "font-size": "14px",
                      padding: "7px",
                      "background-color": "#ccc",
                      color: "black",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.sirname) +
                        " " +
                        _vm._s(item.name) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              item.keedy ||
                              item.sensitiveGroup ||
                              item.abilityIssues,
                            expression:
                              "item.keedy || item.sensitiveGroup || item.abilityIssues",
                          },
                        ],
                        staticStyle: { "font-weight": "bold", color: "red" },
                      },
                      [_vm._v("(!)")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-content p-2" },
                  [
                    _vm._l(_vm.tableFields, function (field, fieldIndex) {
                      return [
                        field.visible
                          ? [
                              _vm.isSpecialField(field.name)
                                ? [
                                    _vm.extractName(field.name) == "__sequence"
                                      ? _c(
                                          "div",
                                          {
                                            key: fieldIndex,
                                            class: [
                                              "vuetable-sequence",
                                              field.dataClass,
                                            ],
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(_vm._s(field.title)),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.renderSequence(itemIndex)
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.extractName(field.name) == "__handle"
                                      ? _c("div", {
                                          key: fieldIndex,
                                          class: [
                                            "vuetable-handle",
                                            field.dataClass,
                                          ],
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.renderIconTag([
                                                "handle-icon",
                                                _vm.css.handleIcon,
                                              ])
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.extractName(field.name) == "__checkbox"
                                      ? _c(
                                          "div",
                                          {
                                            key: fieldIndex,
                                            class: [
                                              "vuetable-checkboxes",
                                              field.dataClass,
                                            ],
                                          },
                                          [
                                            _c("input", {
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: _vm.rowSelected(
                                                  item,
                                                  field.name
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.toggleCheckbox(
                                                    item,
                                                    field.name,
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.extractName(field.name) == "__rubriks"
                                      ? _c(
                                          "div",
                                          {
                                            key: fieldIndex + "|" + item.hash,
                                            class: [
                                              "vuetable-rubriks",
                                              field.dataClass,
                                            ],
                                          },
                                          _vm._l(
                                            item.rubrikScores,
                                            function (rs, idx) {
                                              return _c(
                                                "div",
                                                {
                                                  key: idx,
                                                  staticClass: "rubrik",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "rubrik-header",
                                                      style: {
                                                        backgroundColor:
                                                          _vm.getRubrikHeaderBackground(
                                                            rs.scores.length,
                                                            rs.max
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            rs.info.rubrik.name
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                rs.scores.length
                                                              ) +
                                                              " αξιολογήσεις)"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  !_vm.rubrikPartsGroupView
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "rubrik-scores",
                                                        },
                                                        [
                                                          _vm._l(
                                                            rs.scores,
                                                            function (s, idx2) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    idx +
                                                                    "_" +
                                                                    idx2,
                                                                  staticClass:
                                                                    "rubrik-score",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "score-date",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                        " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "toDate"
                                                                            )(
                                                                              s.date
                                                                            )
                                                                          ) +
                                                                          "\n                                      "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onEditScores(
                                                                              rs.info,
                                                                              item.id,
                                                                              item.sirname +
                                                                                " " +
                                                                                item.name,
                                                                              rs,
                                                                              s,
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "small-bars",
                                                                        {
                                                                          key:
                                                                            "bars" +
                                                                            fieldIndex +
                                                                            "|" +
                                                                            item.hash,
                                                                          attrs:
                                                                            {
                                                                              values:
                                                                                s.scores.map(
                                                                                  function (
                                                                                    x
                                                                                  ) {
                                                                                    return x.score
                                                                                  }
                                                                                ),
                                                                              possibleValues:
                                                                                [
                                                                                  1,
                                                                                  2,
                                                                                  3,
                                                                                  4,
                                                                                  5,
                                                                                ],
                                                                              barAmount: 5,
                                                                              height:
                                                                                "30",
                                                                              width:
                                                                                "50",
                                                                              colors:
                                                                                [
                                                                                  "#f00",
                                                                                  "#0f0",
                                                                                  "#00f",
                                                                                  "#ff0",
                                                                                  "#0ff",
                                                                                ],
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "text-align":
                                                                            "center",
                                                                          "font-size":
                                                                            "10px",
                                                                          "font-weight":
                                                                            "bold",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                s
                                                                                  .finalScore
                                                                                  .incomplete
                                                                                  ? "red"
                                                                                  : "black",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                          " +
                                                                              _vm._s(
                                                                                s
                                                                                  .finalScore
                                                                                  .value
                                                                              ) +
                                                                              "\n                                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "rubrik-score",
                                                              staticStyle: {
                                                                flex: "1 1 auto",
                                                                "align-self":
                                                                  "center",
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "mif-add mif-3x",
                                                                staticStyle: {
                                                                  color: "#999",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onAddScores(
                                                                        rs.info,
                                                                        item.id,
                                                                        item.sirname +
                                                                          " " +
                                                                          item.name,
                                                                        rs,
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "rubrik-scores",
                                                        },
                                                        [
                                                          _vm._l(
                                                            rs.info.rubrik
                                                              .rubrikParts,
                                                            function (s, idx2) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    idx +
                                                                    "_" +
                                                                    idx2,
                                                                  staticClass:
                                                                    "rubrik-score",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "score-date",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                        K" +
                                                                          _vm._s(
                                                                            s.code
                                                                          ) +
                                                                          "\n                                      "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          cursor:
                                                                            "pointer",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "apexchart",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              width:
                                                                                "120",
                                                                              type: "donut",
                                                                              options:
                                                                                _vm.options,
                                                                              series:
                                                                                _vm.getPartScorePercentage(
                                                                                  rs.scores,
                                                                                  s
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "text-align":
                                                                            "center",
                                                                          "font-size":
                                                                            "10px",
                                                                          "font-weight":
                                                                            "bold",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                          " +
                                                                              _vm._s(
                                                                                _vm.getPartFromDates(
                                                                                  s.id,
                                                                                  rs.scores
                                                                                )
                                                                                  .length
                                                                              ) +
                                                                              " αξιολογήσεις\n                                        "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "rubrik-score",
                                                              staticStyle: {
                                                                flex: "1 1 auto",
                                                                "align-self":
                                                                  "center",
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "mif-add mif-3x",
                                                                staticStyle: {
                                                                  color: "#999",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onAddScores(
                                                                        rs.info,
                                                                        item.id,
                                                                        item.sirname +
                                                                          " " +
                                                                          item.name,
                                                                        rs,
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                : [
                                    _vm.hasCallback(field) &&
                                    field.showInRubrikView
                                      ? _c(
                                          "div",
                                          {
                                            key: fieldIndex,
                                            class: field.dataClass,
                                            on: {
                                              click: function ($event) {
                                                return _vm.onCellClicked(
                                                  item,
                                                  field,
                                                  $event
                                                )
                                              },
                                              dblclick: function ($event) {
                                                return _vm.onCellDoubleClicked(
                                                  item,
                                                  field,
                                                  $event
                                                )
                                              },
                                              contextmenu: function ($event) {
                                                return _vm.onCellRightClicked(
                                                  item,
                                                  field,
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(_vm._s(field.title) + ":"),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.callCallback(field, item)
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.hasCallback(field) &&
                                    field.showInRubrikView
                                      ? _c(
                                          "div",
                                          {
                                            key: fieldIndex,
                                            class: field.dataClass,
                                            on: {
                                              click: function ($event) {
                                                return _vm.onCellClicked(
                                                  item,
                                                  field,
                                                  $event
                                                )
                                              },
                                              dblclick: function ($event) {
                                                return _vm.onCellDoubleClicked(
                                                  item,
                                                  field,
                                                  $event
                                                )
                                              },
                                              contextmenu: function ($event) {
                                                return _vm.onCellRightClicked(
                                                  item,
                                                  field,
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(_vm._s(field.title) + ":"),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.getObjectValue(
                                                    item,
                                                    field.name
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                            ]
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "card-footer",
                    staticStyle: { display: "block" },
                  },
                  [
                    _vm.absentLikeModeVisible != itemIndex
                      ? _c(
                          "div",
                          {
                            key: "f" + itemIndex,
                            staticStyle: { width: "100%", display: "flex" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "relative",
                                  flex: "1 1 auto",
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onAbsent(item, itemIndex)
                                      },
                                    },
                                  },
                                  [_vm._v("ΛΕΙΠΕΙ")]
                                ),
                                _vm._v(" "),
                                item.absent
                                  ? _c(
                                      "div",
                                      { staticClass: "like-dislike-absent" },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              item.absent ? item.absent : "-"
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "relative",
                                  flex: "1 1 auto",
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onComments(item, itemIndex)
                                      },
                                    },
                                  },
                                  [_vm._v("ΣΧΟΛΙΑ")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "relative",
                                  flex: "0 0 40px",
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onLike(item, true, itemIndex)
                                      },
                                    },
                                  },
                                  [_c("span", { staticClass: "mif-thumbs-up" })]
                                ),
                                _vm._v(" "),
                                item.likes
                                  ? _c(
                                      "div",
                                      { staticClass: "like-dislike-absent" },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              item.likes ? item.likes : "-"
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "relative",
                                  flex: "0 0 40px",
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onLike(
                                          item,
                                          false,
                                          itemIndex
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "mif-thumbs-down",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                item.dislikes
                                  ? _c(
                                      "div",
                                      { staticClass: "like-dislike-absent" },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              item.dislikes
                                                ? item.dislikes
                                                : "-"
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.absentLikeModeVisible == itemIndex
                      ? _c(
                          "div",
                          {
                            key: "fg" + itemIndex,
                            staticStyle: {
                              width: "100%",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("date-picker", {
                                  attrs: { valueType: "date" },
                                  model: {
                                    value: _vm.absentLikeDate,
                                    callback: function ($$v) {
                                      _vm.absentLikeDate = $$v
                                    },
                                    expression: "absentLikeDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.absentLikeMode.mode == "ABSENT"
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-top": "10px" } },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.isAbsent,
                                          expression: "isAbsent",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        "data-role": "checkbox",
                                        "data-caption": "Απών(ούσα)",
                                      },
                                      domProps: {
                                        checked: Array.isArray(_vm.isAbsent)
                                          ? _vm._i(_vm.isAbsent, null) > -1
                                          : _vm.isAbsent,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.isAbsent,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.isAbsent = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.isAbsent = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.isAbsent = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("br"),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.absentLikeMode.mode == "LIKE"
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-top": "10px" } },
                                  [
                                    _vm._v(
                                      "\n                       " +
                                        _vm._s(
                                          _vm.absentLikeMode.like
                                            ? "Καλή διαγωγή"
                                            : "Απρεπής διαγωγή"
                                        ) +
                                        "\n                     "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  width: "100%",
                                },
                              },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.absentLikeMode.remarks,
                                      expression: "absentLikeMode.remarks",
                                    },
                                  ],
                                  staticStyle: { width: "100%" },
                                  attrs: { "data-role": "textarea" },
                                  domProps: {
                                    value: _vm.absentLikeMode.remarks,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.absentLikeMode,
                                        "remarks",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "10px" } },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSaveAbsentLike(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "mif-checkmark mif-lg",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "button primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onCancelAbsentLike()
                                      },
                                    },
                                  },
                                  [_c("span", { staticClass: "mif-cancel" })]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }