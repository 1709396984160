<template>
    <div class="row" v-if="item">
        <div class="cell-sm-full cell-md-6">
            <input data-validate="required minlength=6" type="text" data-clear-button="false" data-role="input" data-prepend="Name: " v-model="item.name" @keyup="keyMonitor">
        </div>
        <div class="cell-sm-full cell-md-6">
            <input data-validate="required minlength=6" type="text" data-clear-button="false" data-role="input" data-prepend="Code: " v-model="item.code" @keyup="keyMonitor">
        </div>
        <div class="cell-full">
            <input data-validate="required minlength=6" type="text" data-clear-button="false" data-role="input" data-prepend="Description: " v-model="item.description" @keyup="keyMonitor">
        </div> 
        <div class="cell-full">
            <input data-size="100" data-validate="required integer" type="text" data-clear-button="false" data-role="input" data-prepend="Steps: " v-model.number="item.steps" @keyup="keyMonitor">
        </div>
        <div class="cell-full" v-for="(d,idx) in stepDescriptionsArray" :key="idx">
            <input  data-validate="required" type="text" data-clear-button="false" data-role="input" :data-prepend="'Step '+(idx+1)+':' " v-model="stepDescriptionsArray[idx]" @change="onStepDescriptionChanged" @keyup="keyMonitor">
        </div>
         <div class="cell-full">
            <div>Weight:</div>
            <input id="slider" data-role="slider" data-hint-always="true" data-min="0" data-max="100" data-show-min-max="true" data-accuracy="5" :data-value="item.weight" >
            {{item.weight}}
        </div>
        <div class="cell-12" v-show="!isSubList">
            <div class="input">  
            <vue-select  @search="fetchRubrikOptions" label="name" :reduce="(v)=>v.id" v-model="item.rubrikId" :filterable="false" :options="optionsRubriks">
                <template slot="option" slot-scope="option">
                    <div class="d-center">
                        {{ option.name }}
                    </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                        {{ option.name }}
                    </div>
                </template>
            </vue-select>
            <div class="prepend">Rubrik:</div>
            </div>
        </div>   
    </div>
</template>
<script>
import axios from 'axios';

import settings from '../../settings.js'
import globals from '../../globals.js'

export default {
    props:{
        item:undefined,
        isSubList:undefined
    },
    computed:{
        steps(){
            return this.item.steps;
        }
    },
    watch:{
        steps(){
            let previousArray=this.stepDescriptionsArray.slice(0);

            if (this.item.steps && !isNaN(this.item.steps)){
                this.stepDescriptionsArray=new Array(this.item.steps);
            
                if (previousArray.length<this.stepDescriptionsArray.length)
                    for (let i=0;i<previousArray.length;i++)
                        this.stepDescriptionsArray[i]=previousArray[i];

                if (this.stepDescriptionsArray.length<previousArray.length)
                    for (let i=0;i<this.stepDescriptionsArray.length;i++)
                        this.stepDescriptionsArray[i]=previousArray[i];
            }
        },
        item: {
            immediate: true,
            async handler(newVal, oldVal) {
                if (this.item.rubrikId && this.item.rubrikId!=-1){
                    let r=await axios.post(`${settings.serviceUrl}rubrik/get`, {tokenValue:globals.getToken(),id:this.item.rubrikId});
                    this.optionsRubriks=[r.data.payload];
                }
                if (this.item.steps==null)
                    this.item.steps=0;

                if (this.item.rubrikId==-1)
                    this.item.rubrikId=undefined;
                if (this.item.stepDescriptions==null)
                    this.item.stepDescriptions='';
                
                let data=this.item.stepDescriptions.split('|');
                if (data.length==1 && data[0]=='')
                    this.stepDescriptionsArray=[];
                else
                    this.stepDescriptionsArray=data;
            },
        },
    },
    data(){
        return{
            optionsRubriks:[],
            stepDescriptionsArray:[]
        }
    },
    mounted() {
        const action=async ()=>{
            this.$nextTick(()=>{
                Metro.getPlugin('#slider','slider').options.onChange=(v)=>{
                    this.item.weight=v;
                }
            })
        };
        action();
    },
    methods:{
        onStepDescriptionChanged(){
            this.item.stepDescriptions=this.stepDescriptionsArray.join('|');
            console.log('here');
        },
        keyMonitor(event){
            this.$emit('keyevent',event);
        },
        async fetchRubrikOptions (search, loading) {
            if (search.length>2){
                loading(true);
                let r=await axios.post(`${settings.serviceUrl}rubrik/getMany`, {tokenValue:globals.getToken(),item:{name:search}});
                this.optionsRubriks= r.data.payload.items;
                loading(false);
            } 
        },
    }
}
</script>