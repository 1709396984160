<template>
<div class="menu">
    <div class="menu-item" @click="onRefresh">Refresh</div>
    <div class="menu-item" @click="onRefresh">Refresh</div>
    <div class="menu-item" @click="onRefresh">Refresh</div>
</div>    
</template>

<script>
export default {
    methods:{
        onRefresh(){
            this.$root.$emit('refresh-covid');
        }
    }
}
</script>

<style lang="less" scoped>
.menu{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    .menu-item{
        width:100%;
        text-align:left;
        padding:5px;
        border-bottom:1px solid #ccc;
        cursor:pointer;

    }
}
</style>