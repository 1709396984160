<template>
    <div>
        <data-list ref="dataList" :settings="listSettings" @onFilterSpecial="onFilterSpecial" @onAddScores="onAddScores" @onEditScores="onEditScores"></data-list>
        <div @click.self="onHideNewScore" v-if="newScore && showNewScore" style="width:100%;height:100%;position:fixed;top:0px;left:0px,bottom:0px;right:0px;background-color:rgba(255,255,255,0.7);z-index:800">
            <div  class="scrollbar-style" style="margin:70px auto;width:300px;height:620px;border:1px solid #666;overflow-y:auto;overflow-x:hidden;background-color:white">
                <div style="display:flex;padding:10px">
                    <div style="flex:0 0 40px;cursor:pointer" @click="onDeleteScores" v-if="newScore.edit">
                        <span class="mif-bin mif-2x fg-red"></span>
                    </div>
                    <div style="flex:1 1 auto;font-weight:bold;font-size:14px;text-align:center">{{newScore.name}}</div>
                    <div style="flex:0 0 30px;text-align:right;cursor:pointer" @click="onHideNewScore">X</div>
                </div>
                <div>
                    <div class="row" style="padding:10px;">
                        <div class="cell-full">
                            <input id="newScore-datepicker" data-role="datepicker" v-model="newScore.scores[0].date" :disabled="newScore.edit">
                        </div>
                        <div class="cell-full" style="text-align:center;font-weight:bold;">
                            {{newScore.info.rubrik.name}}
                        </div>
                        
                        <div class="cell-full" v-for="(sc,idx) in newScore.info.rubrik.rubrikParts" :key="idx">
                            <div>
                                <div data-role="accordion" data-one-frame="true" data-show-active="false">
                                    <div class="frame">
                                        <div class="heading">{{sc.name}}</div>
                                        <div class="content">
                                            <span v-for="stepIdx in sc.steps" :key='stepIdx'>
                                                <input v-model.number="newScore.scores[0].scores[idx].score" :value="stepIdx" :name="'radio'+sc.id" type="radio" data-role="radio" :data-caption="stepIdx" @change="getStudentScore(newScore.scores[0],newScore.info)">
                                            </span>
                                            <div>
                                                <div v-for="stepDescriptionsIdx in sc.steps" :key="stepDescriptionsIdx" :class="{'selected-part':stepDescriptionsIdx==newScore.scores[0].scores[idx].score}">
                                                    {{stepDescriptionsIdx}}. {{sc.stepDescriptions.split('|')[stepDescriptionsIdx-1]}}
                                                </div>
                                            </div>
                                            <div>
                                                <input type="text" data-role="input" v-model.number="newScore.scores[0].scores[idx].remarks">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="cell-full" style="text-align:center;font-weight:bold" :style="{'color':newScore.scores[0].finalScore.incomplete?'red':'black'}">
                            {{newScore.scores[0].finalScore.value}}
                        </div>
                        <div class="cell-full" >
                            <small-bars style="margin:0px auto" :values="newScore.scores[0].scores.map(x=>x.score)" :possibleValues="[1,2,3,4,5]" :barAmount="5" height="50" width="200" :colors="['#f00','#0f0','#00f','#ff0','#0ff']"></small-bars>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
</template>

<script>
import _ from 'underscore';
import axios from 'axios';
import moment from 'moment';

import globals from '../../globals.js';
import settings from '../../settings.js';

import dataList from '../../components/admin/dataList.vue'
import smallBars from '../../components/admin/smallBars.vue'

let teams=[];

export default {
    data(){
        return {
            rubrikMode:false,
            rubriks:[],
            teamId:0,
            studentRubrikScores:[],
            optionsDepartments:[],
            optionsRubriks:[],
            teams:[],
            listSettings:{
                showCards:false,
                showStudentRubriksTable:false,
                deleteConfirmationField:"id",
                isSubList:false,
                itemName:'student',
                itemNamePlural:'students',
                searchParams:{
                    teamId:undefined,
                    sirname:'',
                    rubrikMode:false,
                    courseId:undefined,
                    lessonId:undefined,
                    whoToReview:false,
                    rubrikPartsGroupView:true
                },
                sortParams:[
                    {
                        field:'sirname',
                        direction:'ASC'
                    }
                ],
                tableColumns:[
                    {
                        name: '__component:vuetable-raw-actions-edit',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td',
                        showInRubrikView:false
                    },
                    {
                        name: '__component:vuetable-raw-actions-report',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td',
                        showInRubrikView:false
                    },
                    {
                        name: 'id',
                        title:'Id',
                        sortField: 'id',
                        titleClass: 'id-column',
                        dataClass: 'id-column',
                        showInRubrikView:false
                    },
                    {
                        name: 'teamId',
                        title:'Τμήματα',
                        isCardHeader:false,
                        showInRubrikView:false,
                        callback:(v)=>{
                            if (!v) return "-";
                            let teamIds=v.split("|").filter(Boolean);
                            let r=[];
                            teamIds.forEach((tId)=>{
                                let team=_.findWhere(teams,{id:parseInt(tId)});
                                if (team) r.push(team);
                            })
                            return r.map(x=>x.name).join(',');
                        }
                    },  
                    {
                        name: 'name',
                        title:'Όνομα',
                        sortField: 'name',
                        isCardHeader:true,
                        showInRubrikView:false
                    },
                    {
                        name: 'sirname',
                        title:'Επώνυμο',
                        sortField: 'sirname',
                        isCardHeader:true,
                        showInRubrikView:false
                    },
                    {
                        name: 'absent',
                        title:'Απουσίες',
                        sortField: 'absent',
                        showInRubrikView:false
                    },
                    {
                        name: 'likes',
                        title:'Θετικά',
                        sortField: 'likes',
                        showInRubrikView:false
                    },
                    {
                        name: 'dislikes',
                        title:'Αρνητικά',
                        sortField: 'dislikes',
                        showInRubrikView:false
                    },
                    {
                        name:'__rubriks:rubriks',
                        title:''
                    },
                    {
                        name: '__component:vuetable-raw-actions-delete',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    }
                ],
                defaultNewItem:undefined,
                dataPerPage:30
            },
            newScore:{},
            showNewScore:false
        };
    },
    components:{
        dataList,
        smallBars
    },
    mounted(){
        if (this.user && this.user.roleId==0)
            this.listSettings.tableColumns.push({
                name: 'organizationName',
                title:'Οργανισμός',
                sortField: 'organizationName',
                isCardHeader:false,
                showInRubrikView:false
            });
        const action=async ()=>{
            this.listSettings.onAfterDataLoaded=this.onAfterDataLoaded;
            let r=await axios.post(`${settings.serviceUrl}team/getMany`, {tokenValue:globals.getToken()});
            this.teams= r.data.payload.items;
            teams=this.teams;
            r=await axios.post(`${settings.serviceUrl}department/getMany`, {tokenValue:globals.getToken()});
            this.optionsDepartments= r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}rubrik/getMany`, {tokenValue:globals.getToken()});
            this.optionsRubriks= r.data.payload.items;
        };
        action();
    },
    computed:{ 
        user(){
            return this.$store.state.user;
        }
    },
    methods:{
        async onDeleteScores(){
            this.newScore.scores[0].scores.forEach(async (s)=>{
                await axios.post(`${settings.serviceUrl}rubrikScore/remove`, {tokenValue:globals.getToken(),id:s.id});
            });
            this.newScore.list.scores.splice(this.newScore.list.scores.indexOf(this.newScore.scores[0]),1);
            this.showNewScore=false;
        },
        async onHideNewScore(){
            let dataChange=false;
            this.newScore.scores[0].scores.forEach(async (s)=>{
                if (s.score!=0)
                    dataChange=true;
            });
                        
            let existingEntry=_.find(this.newScore.list.scores,(d)=>{return moment(d.date).format('DD/MM/YYYY')==moment(this.newScore.scores[0].date).format('DD/MM/YYYY')});
            // if (existingEntry && !this.newScore.edit && dataChange){
            //     Metro.infobox.create("<p>Η ημερομηνία που καταχωρήσατε έχει ήδη βαθμολόγηση</p>", "alert");
            //     return;
            // }

            if (dataChange){
                if (this.newScore.item.hash==undefined) this.newScore.item.hash=1;
                else this.newScore.item.hash++;
            }

            this.showNewScore=false;
            this.newScore.scores[0].scores.forEach(async (s)=>{
                if (s.score!=0){
                    let maxScore=(_.findWhere(this.newScore.info.rubrik.rubrikParts,{id:s.rubrikPartId})).steps;
                    let score=s.score;
                    if (!this.newScore.edit){
                        let r=await axios.post(`${settings.serviceUrl}rubrikScore/add`, {tokenValue:globals.getToken(),item:{
                            studentId:this.newScore.studentId,
                            date:this.newScore.scores[0].date,
                            rubrikId:this.newScore.info.rubrik.id,
                            rubrikPartId:s.rubrikPartId,
                            courseId:this.newScore.info.rubrik.courseId,
                            courseSectionId:this.newScore.info.rubrik.courseSectionId,
                            score:score,
                            remarks:s.remarks
                        }});
                    }
                    else{
                        
                        if (s.id!=-1)
                            await axios.post(`${settings.serviceUrl}rubrikScore/update`, {tokenValue:globals.getToken(),item:s})
                        else
                            await axios.post(`${settings.serviceUrl}rubrikScore/add`, {tokenValue:globals.getToken(),item:{
                                studentId:this.newScore.studentId,
                                date:this.newScore.scores[0].date,
                                rubrikId:this.newScore.info.rubrik.id,
                                rubrikPartId:s.rubrikPartId,
                                courseId:this.newScore.info.rubrik.courseId,
                                courseSectionId:this.newScore.info.rubrik.courseSectionId,
                                score:score,
                                remarks:s.remarks
                            }});
                    }
                }
            });
            if (dataChange){
                if (!this.newScore.edit)
                    this.newScore.list.scores.push(this.newScore.scores[0]);
            }
        },
        async onEditScores(payload){
            if (payload.item.disable){
                Metro.toast.create(`Ο μαθητής απουσιάζει`, null, null, "alert");
                return;
            }
            this.newScore={
                edit:true,
                list:payload.list,
                studentId:payload.studentId,
                name:payload.studentName,
                info:payload.info,
                scores:[payload.score],
                item:payload.item
            };
            this.showNewScore=true;
            this.$nextTick(()=>{
            setTimeout(()=>{
                Metro.getPlugin('#newScore-datepicker','datepicker').options.onSet=(v)=>{this.newScore.scores[0].date=v;}
            },500)})
        },
        async onAddScores(payload){
            if (payload.item.disable){
                Metro.toast.create(`Ο μαθητής απουσιάζει`, null, null, "alert");
                return;
            }
            let studentId=payload.studentId;
            let info=payload.info;
            let scoresForDate={
                date:new Date(),
                scores:[],
                finalScore:{
                    incomplete:true,
                    value:undefined
                }
            };
            info.rubrik.rubrikParts.forEach((rp)=>{
                scoresForDate.scores.push({
                    id:-1,
                    rubrikPartId:rp.id,
                    score:0,
                    rubrikName:rp.name,
                    remarks:''
                    
                });
            });
            this.newScore={
                edit:false,
                list:payload.list,
                studentId,
                name:payload.studentName,
                info,
                scores:[scoresForDate],
                item:payload.item
            };
            this.showNewScore=true;
            this.$nextTick(()=>{
            setTimeout(()=>{
                Metro.getPlugin('#newScore-datepicker','datepicker').options.onSet=(v)=>{this.newScore.scores[0].date=v;}
            },500)})
        },
        getStudentScore(sscd,rubrik){
            let range=rubrik.rubrik.maxScore-rubrik.rubrik.minScore;
            let scoreStep=range/4;
            let totalMax=0;
            let currentScore=0;
            let incomplete=false;


            sscd.scores.forEach((s)=>{
                let rubrikPart=_.findWhere(rubrik.rubrik.rubrikParts,{id:s.rubrikPartId});
                let w=rubrikPart.weight/100;
                if (s.score==0){
                    incomplete=true;
                }
                else{
                    currentScore+=w*(s.score-1)*scoreStep;
                    totalMax+=w*(4*scoreStep);
                }
            });

            let normalizedScore=currentScore/totalMax;
            let finalScore=range*normalizedScore;
            sscd.finalScore={
                value:finalScore+rubrik.rubrik.minScore,
                incomplete
            };
            
        },
        async onAfterDataLoaded(items){
            if (items.length>0 && this.rubriks.length>0){
                let r=await axios.post(`${settings.serviceUrl}rubrikScore/getMany`, {tokenValue:globals.getToken(),item:{studentTeamId:this.teamId},sort:[{field:'A.id',direction:'ASC'}]});
                let allScores=r.data.payload.items;
                for (let rubrik of this.rubriks){
                    let r=await axios.post(`${settings.serviceUrl}studentday/getMany`, {tokenValue:globals.getToken(),item:{courseId:rubrik.scoreId,courseSectionId:rubrik.courseSectionId}});
                    rubrik.studentDays=r.data.payload.items;
                }

                
                items.forEach((student)=>{
                    student.hash=1;
                    student.rubrikScores=[];
                    student.studentDays=[];
                    this.rubriks.forEach((rubrik)=>{
                        let studentDaysRaw=_.filter(rubrik.studentDays,(s)=>s.studentId==student.id);
                        let studentDays={
                            info:rubrik,
                            absent:studentDaysRaw.reduce((s,f)=>{return s+f.absent?1:0},0),
                            likes:studentDaysRaw.reduce((s,f)=>{
                                if (f.like===1) return s+1;
                                return s;
                            },0),
                            dislikes:studentDaysRaw.reduce((s,f)=>{
                                if (f.like===0) return s+1;
                                return s;
                            },0),
                            remarks:(studentDaysRaw.map((s)=>s.remarks)).join('<br/>')
                        };
                        student.studentDays.push(studentDays);

                        let studentScores=[];
                        if (rubrik.courseId)
                            studentScores=_.filter(allScores,(s)=>{return s.studentId==student.id && s.rubrikId==rubrik.rubrik.id && s.courseId==rubrik.courseId});
                        if (rubrik.courseSectionId)
                            studentScores=_.filter(allScores,(s)=>{return s.studentId==student.id && s.rubrikId==rubrik.rubrik.id && s.courseSectionId==rubrik.courseSectionId});

                        studentScores=_.sortBy(studentScores,(s)=>s.date);
                        let studentScoresGroupedByDate=[];
                        let emptyScores=[];
                        rubrik.rubrik.rubrikParts.forEach((rp)=>{
                            emptyScores.push({
                                id:-1,
                                rubrikPartId:rp.id,
                                score:0,
                                remarks:''
                            });
                        })
                        studentScores.forEach((sc)=>{
                            let group=_.findWhere(studentScoresGroupedByDate,{date:sc.date});
                            if (!group){
                                group={
                                    date:sc.date,
                                    scores:JSON.parse(JSON.stringify(emptyScores))
                                };
                                studentScoresGroupedByDate.push(group);
                            }
                            let scoreToUpdate=_.findWhere(group.scores,{rubrikPartId:sc.rubrikPartId})
                            group.scores.splice(group.scores.indexOf(scoreToUpdate),1,sc);
                        });

                        student.rubrikScores.push({
                            info:rubrik,
                            scores:studentScoresGroupedByDate
                        });

                        studentScoresGroupedByDate.forEach((sscd)=>{
                            let range=rubrik.rubrik.maxScore-rubrik.rubrik.minScore;
                            let scoreStep=range/4;
                            let totalMax=0;
                            let currentScore=0;
                            let incomplete=false;

                            sscd.scores.forEach((s)=>{
                                let rubrikPart=_.findWhere(rubrik.rubrik.rubrikParts,{id:s.rubrikPartId});
                                let w=rubrikPart.weight/100;
                                if (s.score==0){
                                    incomplete=true;
                                }
                                else{
                                    currentScore+=w*(s.score-1)*scoreStep;
                                    totalMax+=w*(4*scoreStep);
                                }
                            });

                            let normalizedScore=currentScore/totalMax;
                            let finalScore=range*normalizedScore;
                            sscd.finalScore={
                                value:finalScore+rubrik.rubrik.minScore,
                                incomplete
                            };
                        });

                        
                    })
                });

                this.rubriks.forEach((rubrik)=>{
                    let maxReviews=0;
                    items.forEach((student)=>{
                        let reviews=(_.find(student.rubrikScores,(rs)=>{return rs.info.rubrik.id==rubrik.rubrik.id})).scores.length;
                        if (maxReviews<reviews) maxReviews=reviews;
                    });
                    items.forEach((student)=>{
                        let review=_.find(student.rubrikScores,(rs)=>{return rs.info.rubrik.id==rubrik.rubrik.id});
                        review.max=maxReviews;
                    });

                });                
                if (this.listSettings.searchParams.whoToReview){
                    items=_.sortBy(items,(v)=>v.rubrikScores[0].scores.length);
                }

                return items;
            }
            
            return items;
        },
        async onFilterSpecial(data){
            console.log(data);
            if (data.type=='rubrik-mode'){
                this.rubrikMode=data.payload;
                this.listSettings.showStudentRubriksTable=this.rubrikMode;
                this.$nextTick(()=>{
                    this.$refs.dataList.onRefresh();
                });
            }
            if (data.type=='rubriks-reset'){
                this.rubriks.length=0;
                this.teamId=0;
                this.studentRubrikScores.length=0;
                this.$nextTick(()=>{
                    this.$refs.dataList.onRefresh();
                });
            }
            if (data.type=='course-rubrik-added'){
                this.rubriks.push(data.payload);
                this.teamId=data.teamId;
                this.$nextTick(()=>{
                    this.$refs.dataList.onRefresh();
                });
            }
            if (data.type=='lesson-rubrik-added'){
                this.rubriks.push(data.payload);
                this.teamId=data.teamId;
                this.$nextTick(()=>{
                    this.$refs.dataList.onRefresh();
                });
            }


        }
    }
       
}
</script>

<style lang="less" scoped>
.selected-part{
    font-weight:bold
}
</style>
