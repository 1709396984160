 <template>
    <div class="custom-actions" style="width:80px">
      <button class="button flat-button" @click.prevent="itemAction('report-item', rowData, rowIndex)"><i class="mif-file-text mif-lg"></i></button>
    </div>
  </template>

  <script>
  export default {
    props: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    methods: {
      itemAction (action, data, index) {
          this.$parent.$emit('VuetableRowAction:action-item', 'action', {action: action, data: data,index:index});
      }
    }
  }
  </script>

  <style <style lang="less" scoped>
 
    .btn{
        padding:0px 5px;
        color:#777;
    }
  </style>