<template>
    <div class="admin-list" :class="{'sub-list':settings.isSubList,'page':!settings.isSubList}">
        <div v-show="!editing" class="grid">
            <div class="row" v-if="!settings.hideFilters">
                <filters-student v-if="settings.itemName=='student'" @onRefresh="onRefresh" :queryStringData="queryStringData" @onFilterSpecial="onFilterSpecial"></filters-student>
                <filters-team v-if="settings.itemName=='team'" @onRefresh="onRefresh" :queryStringData="queryStringData" @onFilterSpecial="onFilterSpecial"></filters-team>
                <filters-course v-if="settings.itemName=='course'" @onRefresh="onRefresh" :queryStringData="queryStringData" @onFilterSpecial="onFilterSpecial"></filters-course>
            </div>
            <div class="row" v-if="!settings.hideControls">
                <div class="controls-area">
                    <div class="cell-12">
                        <button class="button primary" :class="{'outline':settings.isSubList}" @click.prevent="onSearch">
                            <span class="mif-refresh mif-lg"></span>
                        </button>
                        <button class="button primary" :class="{'outline':settings.isSubList}" @click.prevent="onAdd">
                            <span class="mif-add mif-lg"></span>
                        </button>
                        <button class="button primary" :class="{'outline':settings.isSubList}" @click.prevent="onClear">
                            <span class="mif-cross-light mif-lg"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="table-area">
                    <vuetable-pagination class="pagination"  ref="pagination" @vuetable-pagination:change-page="onChangePage" :css="paginationCss"></vuetable-pagination>
                    <div class="cell-12" v-if="initialized" >
                        <div data-role="progress" data-type="line" data-small="true" v-if="dataLoading"></div>
                        <vuetable v-if="!showCards && !showStudentRubriksTable" ref="vuetable" :class="{loading:dataLoading}"
                            :css="tableCss"
                            :api-url="apiURL"
                            :http-fetch="getData"
                            http-method="post"
                            :load-on-start="false"
                            :fields="columns"
                            :sort-order="queryStringData.sort"
                            :per-page="dataPerPage"
                            :row-class="onRowClass"
                            @vuetable:loading="loading"
                            @vuetable:loaded="loaded"
                            @vuetable:load-success="loadSuccess"
                            @vuetable:load-error="loadError"
                            data-path="payload.items"
                            pagination-path="payload.pagination"
                            @vuetable:pagination-data="onPaginationData"
                            @VuetableRowAction:action-item="onRowAction">
                        </vuetable>
                        <cards-table v-if="showCards && !showStudentRubriksTable" ref="vuetable" :class="{loading:dataLoading}"
                            :css="tableCss"
                            :api-url="apiURL"
                            :http-fetch="getData"
                            http-method="post"
                            :load-on-start="false"
                            :fields="columns"
                            :sort-order="queryStringData.sort"
                            :per-page="dataPerPage"
                            :row-class="onRowClass"
                            @vuetable:loading="loading"
                            @vuetable:loaded="loaded"
                            @vuetable:load-success="loadSuccess"
                            @vuetable:load-error="loadError"
                            data-path="payload.items"
                            pagination-path="payload.pagination"
                            @vuetable:pagination-data="onPaginationData"
                            @VuetableRowAction:action-item="onRowAction">
                        </cards-table>
                        <student-rubriks-table v-if="showStudentRubriksTable" ref="vuetable" :class="{loading:dataLoading}"
                            :css="tableCss"
                            :api-url="apiURL"
                            :http-fetch="getData"
                            http-method="post"
                            :load-on-start="false"
                            :fields="columns"
                            :sort-order="queryStringData.sort"
                            :per-page="dataPerPage"
                            :row-class="onRowClass"
                            :onAfterDataLoaded="settings.onAfterDataLoaded"
                            @vuetable:loading="loading"
                            @vuetable:loaded="loaded"
                            @vuetable:load-success="loadSuccess"
                            @vuetable:load-error="loadError"
                            data-path="payload.items"
                            pagination-path="payload.pagination"
                            @vuetable:pagination-data="onPaginationData"
                            @VuetableRowAction:action-item="onRowAction"
                            @onAddScores="onAddScores"
                            @onEditScores="onEditScores"
                            :rubrikPartsGroupView="settings.searchParams.rubrikPartsGroupView"
                            >

                        </student-rubriks-table>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="editing">
            <data-edit :isNewItem="isNew" :isSubList="settings.isSubList" :id="selectedElementId?selectedElementId.toString():''" :itemName="settings.itemName" :itemNamePlural="settings.itemNamePlural" @finishedEdititng="onFinishedEditing"></data-edit>
        </div>
    </div>
    
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';

import _ from 'underscore';
import settings from '../../settings'
import globals from '../../globals';
import Vuetable from '../../../../node_modules/vuetable-2/src/components/Vuetable.vue'
import VuetablePagination from '../../../../node_modules/vuetable-2/src/components/VuetablePagination.vue'
import VuetableRowActionsEdit from '../../components/admin/VuetableRowActionsEdit.vue';
import VuetableRowActionsEditRP from '../../components/admin/VuetableRowActionsEditRP.vue';
import VuetableRowActionsDelete from '../../components/admin/VuetableRowActionsDelete.vue';
import VuetableRowActionsRemoveFromTeam from '../../components/admin/VuetableRowActionsRemoveFromTeam.vue';
import VuetableRowActionsReport from '../../components/admin/VuetableRowActionsReport.vue';
import dataEdit from '../../components/admin/dataEdit.vue'
import cardsTable from '../../components/admin/vuetable/cardstable.vue'
import studentRubriksTable from '../../components/admin/vuetable/studentRubriksTable.vue'
import filtersStudent from './filtersStudent.vue';
import filtersTeam from './flitersTeam.vue';
import filtersCourse from './flitersCourse.vue';

Vue.component('vuetable-raw-actions-edit', VuetableRowActionsEdit)
Vue.component('vuetable-raw-actions-edit-rp', VuetableRowActionsEditRP)
Vue.component('vuetable-raw-actions-delete', VuetableRowActionsDelete)
Vue.component('vuetable-raw-actions-report', VuetableRowActionsReport)
Vue.component('vuetable-remove-from-team', VuetableRowActionsRemoveFromTeam)


const defaultNewItem={
}
const queryStringDataExtras={
}
const initializer=async (vm)=>{}
const urlProcessorFilterArgExtra=(qArgs,queryStringData)=>{
}
const beforeGetData=(queryStringData)=>{
}

export default {
    props:{
        settings:undefined
    },
    data(){
        return {
            editing:false,
            initialized:false,
            routeURL:undefined,
            editURL:undefined,
            apiURL:undefined,
            deleteAPIURL:undefined,
            createAPIURL:undefined,
            queryStringData:{
                search:{},
                sort:[]
            },
            columns:[],
            paginationCss:{
                icons:{
                    first: 'mif-first mif-lg',
                    prev: 'mif-previous mif-lg',
                    next: 'mif-next mif-lg',
                    last: 'mif-last mif-lg',
                },
                pageClass: 'page-item',
                activeClass:'active'
            },
            tableCss:{
                tableClass:'table striped',
                ascendingIcon:  {
                    params:['mif-lg right','mif-arrow-drop-up']
                },
                descendingIcon: {
                    params:['mif-lg right','mif-arrow-drop-down']
                },
                renderIcon:function(classes){
                    if (classes[1])
                        return `<span class="${classes[0]} ${classes[1].params[0]} ${classes[1].params[1]}"> </span>`;
                    else
                        return `<span class="${classes[0]} mif-lg"></span>`;
                }
            },
            dataPerPage:this.settings.dataPerPage?this.settings.dataPerPage:settings.dataPerPage,
            dataLoading:false, 
            currentQueryStringParams:'',
            selectedElementId:undefined,
            selectedItem:undefined,
            isNew:false
        };
    },
    components:{
        Vuetable,
        VuetablePagination,
        dataEdit,
        cardsTable,
        filtersStudent,
        filtersTeam,
        filtersCourse,
        studentRubriksTable
    },
    computed:{
        searchParams(){
            return this.settings.searchParams;
        },
        sortParams(){
            return this.settings.sortParams;
        },
        showCards(){
            return this.settings.showCards;
        },
        showStudentRubriksTable(){
            return this.settings.showStudentRubriksTable;
        }
    },
    watch:{
        searchParams(){
            this.queryStringData.search=this.searchParams;
            if (this.$refs.vuetable){
                this.$refs.vuetable.reload();
            }
            
        },
        sortParams(){
            this.queryStringData.sort=this.sortParams;
            if (this.$refs.vuetable){
                this.$refs.vuetable.reload();
            }
        }
    },
    mounted(){
        this.itemName=this.settings.itemName;
        this.itemNamePlueral=this.settings.itemNamePlural;
        this.routeURL=`/admin/${this.settings.itemNamePlural}`;
        this.editURL=`/admin/${this.settings.itemNamePlural}/`;
        this.apiURL=`${settings.serviceUrl}${this.settings.itemName}/getMany`;
        this.deleteAPIURL=`${settings.serviceUrl}${this.settings.itemName}/remove`;
        this.createAPIURL=`${settings.serviceUrl}${this.settings.itemName}/add`;
        this.columns=this.settings.tableColumns;

        this.queryStringData.search=this.searchParams;
        this.queryStringData.sort=this.sortParams;

        this.getVuetableParamsFromUrl=async ()=>{
            
            if (this.$store.state.adminEditPageId){
                this.selectedElementId=this.$store.state.adminEditPageId;
                this.$store.commit('setAdminEditPageId',undefined);
            }
            // Set initial filters from url
            if (this.$route.query.search){
                let searchArgs=this.$route.query.search.split('|');
                for (let sa of searchArgs){
                    if (sa){
                        let qArgs=sa.split(',');
                        this.queryStringData.search[qArgs[0]]=qArgs[1].replace(/;/g,'|'); // For filters that use the | as special character
                        urlProcessorFilterArgExtra(qArgs,this.queryStringData);
                    }
                }
            }
            // Set initial sort from url
            if (this.$route.query.sort){
                let sortArgs=this.$route.query.sort.split('|');
                for (let sa of sortArgs){
                    if (sa){
                        let sArgs=sa.split(',');
                        this.queryStringData.sort.push({
                            field:sArgs[0],
                            direction:sArgs[1]
                        });
                    }
                }
            }
            this.$root.$emit('data-list-filters-from-url',this.queryStringData);
        };
        this.updateURLWithSearchAndSortData=()=>{
            let sort=this.$refs.vuetable.getSortParam();
            let searchParams="";
            for (let s in this.queryStringData.search)
                if (this.queryStringData.search[s])
                    searchParams+=`|${s},${this.queryStringData.search[s].toString().replace(/\|/g,';')}`;
            if (searchParams)
                searchParams=searchParams.substr(0);

            let sortParams="";
            for (let s of this.$refs.vuetable.sortOrder)
                sortParams+=`|${s.field},${s.direction}`;
            if (sortParams)
                sortParams=sortParams.substr(0);
            
            this.currentQueryStringParams=JSON.stringify({search:this.$route.query.search,sort:this.$route.query.sort,page:parseInt(this.$route.query.page)});
            let query={search:searchParams,sort:sortParams,page:this.$refs.vuetable.currentPage};
            if (this.currentQueryStringParams!=JSON.stringify(query)){
                this.$router.push({query})
            }
        };
        const action=async ()=>{
            await initializer(this);
            await this.getVuetableParamsFromUrl();
            this.initialized=true;
            this.$nextTick(()=>{
                if (this.$route.query.page){
                    this.$refs.vuetable.currentPage =parseInt(this.$route.query.page);
                }
                if (!this.settings.isSubList)
                    this.$refs.vuetable.reload();
                
            })
        }
        action();
    },
    methods:{
        onAddScores(info,studentId,studentName,list,item){
            this.$emit('onAddScores',{info,studentId,studentName,list,item});
        },
        onEditScores(info,studentId,studentName,list,score,item){
            this.$emit('onEditScores',{info,studentId,studentName,list,score,item});
        },
        onFilterSpecial(data){
            this.$emit('onFilterSpecial',data);
        },
        keyMonitor(event){
            if(event.key == "Enter")
                this.$refs.vuetable.refresh();
        },
        onRefresh(){
            if (this.$refs && this.$refs.vuetable)
                this.$refs.vuetable.refresh();
        },
        getData(apiUrl, httpOptions){
            let sort=[];
            for (let s of this.$refs.vuetable.sortOrder)
                sort.push({field:s.field,direction:s.direction});

            beforeGetData(this.queryStringData);
            
            return axios.post(apiUrl, {
                tokenValue:globals.getToken(),
                item:this.queryStringData.search,
                sort,
                itemExtra:{},
                page:{
                    page:httpOptions.params.page,
                    perPage:httpOptions.params.per_page,
                }
            });
        },
        async loadError(r,e){
            if (!r){
                Metro.toast.create(`${e}`, null, null, "alert");
            }

            if (!r.data)
                Metro.toast.create(`${r}`, null, null, "alert");
            else
                Metro.toast.create(`${r.data.message}`, null, null, "alert");
        },
        async loadSuccess(r){
            this.$emit('onAfterDataLoaded',r.data.payload.items);
            if (this.settings.afterDataLoaded){
                await this.settings.afterDataLoaded(r.data.payload.items);
            }
            if (r.data.code===-1001)
                this.$router.push('/signin');
        },
        loading(){
            this.dataLoading=true;
        },
        loaded(){
            if (this.selectedElementId){
                if (document.querySelector(".last-active"))
                    document.querySelector(".last-active").scrollIntoView();
            }
            this.dataLoading=false;
            if (!this.settings.isSubList)
                this.updateURLWithSearchAndSortData();
            this.$nextTick(()=>{
                globals.setAdminTablesLayout();
            })
        },
        onRowClass(data,index){
            if (this.selectedElementId){
                if (data.id==this.selectedElementId){
                    return "last-active";
                }
            }
            return "row-hover";
        },
        onFinishedEditing(reason,payload){
            this.editing=false;
            if (reason=='save'){
                this.$emit('onListUpated');
                Object.assign(this.selectedItem,payload);
                if (this.isNew)
                    this.$refs.vuetable.tableData.push(this.selectedItem);
            }
        },
        async onRowAction(action,data){
            const self=this;
            if (data.action==='edit-item'){
                if (this.settings.onPreEdit){
                    let result=this.settings.onPreEdit(data.data);
                    if (!result) return;
                }
                if (!this.settings.isSubList){
                    this.$store.commit('setAdminEditPageId',data.data.id);
                    this.$router.push(this.editURL+data.data.id);
                }
                else{
                    this.isNew=false;
                    this.selectedElementId=data.data.id;
                    this.selectedItem=data.data;
                    this.editing=true;
                }
            }
            if(data.action=='report-item'){
                this.$router.push(`/admin/report/${data.data.id}`)
            }
            if (data.action==='delete-item'){
                if (this.settings.onPreRemove){
                    let result=this.settings.onPreRemove(data.data);
                    if (!result) return;
                }
                Metro.dialog.create({
                    title: "Delete confirmation?",
                    content: `<div>Are you sure you want to delete item  ${data.data[this.settings.deleteConfirmationField]}?</div>`,
                    actions: [
                        {
                            caption: "Yes",
                            cls: "js-dialog-close alert",
                            onclick: async ()=>{
                                let response=await axios.post(`${this.deleteAPIURL}`,{
                                    tokenValue:globals.getToken(),
                                    id:data.data.id
                                });
                                this.$refs.vuetable.refresh();
                            }
                        },
                        {
                            caption: "Cancel",
                            cls: "js-dialog-close",
                            onclick: function(){
                            }
                        }
                    ]
                });
            }
        },
        onPaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage (page) {
            this.$refs.vuetable.changePage(page)
        },
        onSearch(){
            this.$refs.vuetable.refresh();
        },
        onClear(){
            this.queryStringData.search={};
            this.$refs.vuetable.refresh();
        },
        async onAdd(){
            this.$root.$emit('ui-blocker',1);
            let newItemData={};
            Object.assign(newItemData,defaultNewItem);
            Object.assign(newItemData,this.settings.defaultNewItem);

            let response=await axios.post(`${this.createAPIURL}`,{
                tokenValue:globals.getToken(),
                item:newItemData
            });
            if (!response.data.ok){
                this.$root.$emit('ui-blocker',-1);
                Metro.toast.create(`${response.data.message}`, null, null, "alert");
                return;
            }
            let newId=response.data.payload.id;
            this.$root.$emit('ui-blocker',-1);
            if (!this.settings.isSubList)
                this.$router.push(this.editURL+newId+'?isNew=true');
            else{
                
                this.isNew=true;
                this.selectedElementId=newId;
                this.selectedItem=response.data.payload;
                this.editing=true;
            }
        }
    }
}
</script>

<style lang="less">
</style>
