var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-area" }, [
    _vm.item
      ? _c(
          "form",
          { staticClass: "grid", attrs: { "data-interactive-check": "true" } },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "cell" }, [
                !_vm.isSubList
                  ? _c("div", { staticClass: "edit-id" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.id) +
                          "\n                "
                      ),
                    ])
                  : _c("div", [
                      _vm._v(
                        "\n                    Editing Id: " +
                          _vm._s(_vm.id) +
                          "\n                "
                      ),
                    ]),
              ]),
              _vm._v(" "),
              !_vm.isSubList
                ? _c(
                    "div",
                    { staticClass: "stub", staticStyle: { width: "120px" } },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button primary",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onSave.apply(null, arguments)
                            },
                          },
                        },
                        [_c("span", { staticClass: "mif-checkmark mif-lg" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button primary",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onCancel.apply(null, arguments)
                            },
                          },
                        },
                        [_c("span", { staticClass: "mif-cancel mif-lg" })]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.itemName == "team"
              ? _c("data-edit-team", {
                  attrs: { item: _vm.item, isSubList: _vm.isSubList },
                  on: { keyevent: _vm.keyMonitor },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.itemName == "rubrik"
              ? _c("data-edit-rubrik", {
                  attrs: { item: _vm.item, isSubList: _vm.isSubList },
                  on: { keyevent: _vm.keyMonitor },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.itemName == "rubrikPart"
              ? _c("data-edit-rubrik-part", {
                  attrs: { item: _vm.item, isSubList: _vm.isSubList },
                  on: { keyevent: _vm.keyMonitor },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.itemName == "course"
              ? _c("data-edit-course", {
                  ref: "dataEditCourse",
                  attrs: { item: _vm.item, isSubList: _vm.isSubList },
                  on: { keyevent: _vm.keyMonitor },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.itemName == "coursesection"
              ? _c("data-edit-course-section", {
                  attrs: { item: _vm.item, isSubList: _vm.isSubList },
                  on: { keyevent: _vm.keyMonitor },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.itemName == "student"
              ? _c("data-edit-student", {
                  ref: "dataEditStudent",
                  attrs: { item: _vm.item, isSubList: _vm.isSubList },
                  on: { keyevent: _vm.keyMonitor },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isSubList
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "cell-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button primary",
                        class: { outline: _vm.isSubList },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onSave.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", { staticClass: "mif-checkmark mif-lg" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button primary",
                        class: { outline: _vm.isSubList },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onCancel.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", { staticClass: "mif-cancel mif-lg" })]
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }