var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page login-page" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("form", [
          _c("div", { staticClass: "input-field col s12 m6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.username,
                  expression: "username",
                },
              ],
              attrs: { id: "email", type: "text" },
              domProps: { value: _vm.username },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.username = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("label", { staticClass: "active", attrs: { for: "email" } }, [
              _vm._v("Username"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-field col s12 m6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              attrs: { id: "password", type: "password" },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("label", { staticClass: "active", attrs: { for: "password" } }, [
              _vm._v("Password"),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "btn right", on: { click: _vm.onLogin } }, [
          _vm._v("Submit"),
        ]),
        _vm._v(" "),
        _vm.message
          ? _c(
              "div",
              {
                staticClass: "col s12",
                staticStyle: { "padding-top": "10px" },
              },
              [
                !_vm.ok
                  ? _c(
                      "div",
                      {
                        staticClass: "right",
                        staticStyle: { "font-weight": "bold", color: "red" },
                      },
                      [_vm._v(_vm._s(_vm.message))]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "right",
                        staticStyle: { "font-weight": "bold" },
                      },
                      [_vm._v(_vm._s(_vm.message))]
                    ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }