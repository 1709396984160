var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-server-area" },
    [
      _c("header", [
        _c("section", { staticClass: "header-logo" }, [
          _c("img", {
            staticClass: "logo-img",
            attrs: { src: require("./assets/admin-lgo.png") },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [
            _c("div", { staticClass: "version" }, [
              _vm._v("Ver. " + _vm._s(_vm.version)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "version" }, [
              _vm._v(_vm._s(_vm.breadcrumb)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("section", { staticClass: "header-user-info" }, [
          _c("strong", [_vm._v(_vm._s(_vm.userEmail))]),
          _c("br"),
          _vm._v(" "),
          _vm.loggedIn
            ? _c(
                "div",
                {
                  staticStyle: {
                    float: "right",
                    "font-size": "14px",
                    cursor: "pointer",
                    color: "#1366ad",
                  },
                  on: { click: _vm.onLogout },
                },
                [_vm._v("\n        logout\n      ")]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "header-menu-btn" }, [
          _c("span", {
            staticClass: "menu-button mif-menu mif-lg",
            on: { click: _vm.onOpenMenu },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "side-panels-container" },
        [
          _c(
            "side-panel",
            { attrs: { zIndex: "1000", title: "Navigation" } },
            [_c("main-menu")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "main-content" },
        [
          _c("div", {
            staticStyle: {
              "background-color": "#a0d8ba",
              width: "100%",
              height: "300px",
              position: "fixed",
            },
          }),
          _vm._v(" "),
          _c("router-view"),
        ],
        1
      ),
      _vm._v(" "),
      _c("ui-blocker"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "header-quick-navigation" }, [
      _c("section", { staticClass: "header-quick-navigation-breadcrumb" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }