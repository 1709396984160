var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "side-panel",
      class: { hidden: !_vm.visible },
      style: { "z-Index": _vm.zIndex },
    },
    [
      _c(
        "section",
        { staticClass: "side-panel-content" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("section", { staticClass: "side-panel-right-bar" }, [
        _c("section", { staticClass: "side-panel-right-bar-controls" }, [
          _vm.visible
            ? _c("span", {
                staticClass: "mif-chevron-left mif-lg close-btn",
                on: { click: _vm.onHide },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.visible
            ? _c("span", {
                staticClass: "mif-chevron-right mif-lg expand-btn",
                on: { click: _vm.onShow },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "side-panel-right-bar-content" },
          [_vm._t("default")],
          2
        ),
        _vm._v(" "),
        _c("section", {
          staticClass: "side-panel-right-bar-footer",
          on: { click: _vm.onToggle },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }