<template>
    <div class="main-menu-container">
        <div style="display:none">{{userRole}}</div>
        <div class="menu-items">
            <div v-for="mi in menuItems" :key="mi.id" :class="{active:mi.active}" class="menu-item" @click="onMenuItem(mi.path)">
                <div class="menu-item-left">
                     <img v-if="mi.icon" :src="mi.icon"/> 
                     <div v-if="mi.html" v-html="mi.html"></div>
                     <span class="title">{{mi.title}}</span> 
                </div>
                <div class="menu-item-right">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'underscore';
const menuItems=[{
        id:'0',
        title:'Home',
        action:'home',
        active:false,
        path:'/admin/',
        icon:require('../../../assets/admin/menuIcons64x64/home.png')
    }
];

const teacherMenuItems=[
    {
        id:'5',
        title:'Μαθητές',
        action:'students',
        active:false,
        path:'/admin/students',
        icon:require('../../../assets/admin/menuIcons64x64/students.png')
    },
    {
        id:'8',
        title:'Reports',
        action:'reports',
        active:false,
        path:'/admin/report',
        icon:require('../../../assets/admin/menuIcons64x64/reports.png')
    },
];

const organizationAdminMenuItems=[
    {
        id:'1',
        title:'Χρήστες',
        action:'users',
        active:false,
        path:'/admin/users',
        icon:require('../../../assets/admin/menuIcons64x64/users.png')
    },
    {
        id:'3',
        title:'Τάξεις',
        action:'departments',
        active:false,
        path:'/admin/departments',
        icon:require('../../../assets/admin/menuIcons64x64/departments.png')
    },
    {
        id:'4',
        title:'Τμήματα',
        action:'teams',
        active:false,
        path:'/admin/teams',
        icon:require('../../../assets/admin/menuIcons64x64/teams.png')
    },
    {
        id:'6',
        title:'Μαθήματα',
        action:'courses',
        active:false,
        path:'/admin/courses',
        icon:require('../../../assets/admin/menuIcons64x64/courses.png')
    },
    {
        id:'7',
        title:'Rubrics',
        action:'rubriks',
        active:false,
        path:'/admin/rubriks',
        icon:require('../../../assets/admin/menuIcons64x64/rubriks.png')
    }
];

const adminMenuItems=[
    {
        id:'2',
        title:'Οργανισμοί',
        action:'organizations',
        active:false,
        path:'/admin/organizations',
        icon:require('../../../assets/admin/menuIcons64x64/organizations.png')
    },
];
export default {
    watch:{
        '$route': function(to, from) {
            this.setActiveItems(to);
        }
    },
    computed:{
        userRole(){
            let menuItemsTemp=[];
            if (this.$store.state.user){
                menuItemsTemp.push(...menuItems);
                if (this.$store.state.user.roleId==2 || this.$store.state.user.roleId==3 ||this.$store.state.user.roleId==0){
                    menuItemsTemp.push(...teacherMenuItems);
                }
                if (this.$store.state.user.roleId==0){
                    menuItemsTemp.splice(0,0,...adminMenuItems);
                }
                if (this.$store.state.user.roleId==2 ||this.$store.state.user.roleId==0){
                    menuItemsTemp.push(...organizationAdminMenuItems);
                }
                
                this.menuItems=_.sortBy(menuItemsTemp,(mi)=>mi.id);
                return this.$store.state.user.roleId;
            }
            else{
                this.menuItems=[];
            }
            
            return 100;
        },
    },
    data(){
        return{
            menuItems:[]
        }
    },
    mounted(){
    },
    methods:{
        setActiveItems(to){
            let path=to.path;
            let fullPath=to.fullPath;
            let homeMenuItem=_.findWhere(this.menuItems,{path:'/admin/'});
            let activeMenuItems=[];
            if (path=='/admin/'){
                activeMenuItems.push(homeMenuItem);
            }
            else{
                activeMenuItems=_.filter(this.menuItems,p=>path.indexOf(p.path)!=-1 && p.path!='/admin/');
            }
            
            for (let mi of this.menuItems)
                mi.active=false;

            let breadcrumb='';
            for (let ami of activeMenuItems){
                ami.active=true;
                breadcrumb+='>'+ami.title;
            }
            this.$store.commit('setBreadcrumb',breadcrumb.substring(1))
        },
        onMenuItem(path){
            this.$root.$emit('sidepanel-toggle',false);
            this.$router.push(path);
        },
    }
}
</script>

<style lang="less">
.side-panel{
    .side-panel-right-bar{
        .menu-items{
            display:none !important;

        }
    }
    &.hidden{
        .menu-items{
            display:block !important;
            padding-top:0px;
        }
        .menu-item{
            padding:5px 0px !important;
            margin-bottom:10px;
            img{
                margin: 0px auto !important;
                display: block;
            }
            .title{
                font-size: 9px;
                text-align: center;
                display: block;
            }
        }
    }
}
.main-menu-container{
    .menu-items{
        padding-top:40px;
        .menu-item{
            font-size: 16px;
            cursor: pointer;
            padding: 10px;
            color: #999;
            padding-left: 20px;
            display:flex;
            &.active{
                color:#0366d6;
            }
            .menu-item-left{
                flex:1 1 auto;
                img{
                    width:35px;
                    float:left;
                    margin-right:5px;
                    max-width:none;
                }
                div{
                    width:32px;
                    float:left;
                    margin-right:5px;
                }
                .title{
                    margin-top:5px;
                    display:block;
                    
                }
            }
            .menu-item-right{
                flex:0 0 40px;
                img{
                    width:32px;
                }
            }
        }
        .menu-item:hover{
            color:black;
        }
        .menu-item:active{
           color:black;
        }
    }
}
</style>