<template>
<div class="filters-area">
    <div class="row" style="background-color:#0366d6;color:#fff;box-shadow: 0 4px 8px rgb(90 97 105 / 12%), 0 10px 10px rgb(90 97 105 / 6%), 0 7px 70px rgb(90 97 105 / 10%);" @click="()=>{this.hidden=!this.hidden}">
        <div class="cell-12">
            <div style="display:flex;cursor:pointer;padding:5px;">
                <div v-if="filtersText" style="flex:1 1 auto;font-size:14px;font-weight:bold;color:#fff;" v-html="filtersText">
                </div>
                <div v-else style="flex:1 1 auto;font-size:14px;font-weight:bold;color:#fff;">
                    ΦΙΛΤΡΑ
                </div>
                <div v-if="!hidden" style="flex:0 0 40px;font-weight:bold"><span class="mif-chevron-thin-up mif-2x"></span></div>
                <div v-if="hidden" style="flex:0 0 40px;font-weight:bold"><span class="mif-chevron-thin-down mif-2x"></span></div>
            </div>
        </div>
    </div>
    <div class="row" v-if="queryStringData && !initializing && !hidden" style="padding:10px;border: 1px #ccc solid;margin:0px !important">
        <div class="cell-sm-12 cell-md-6">
            <div class="input">  
                <vue-select label="name" :reduce="(v)=>v.id.toString()" v-model="queryStringData.search.teamId" :filterable="false" :options="optionTeams" :disabled="rubrikMode=='true' || rubrikMode">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                           {{ option.name }} - {{option.departmentName}} - {{option.organizationName}}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                           {{ option.name }} - {{option.departmentName}} - {{option.organizationName}}
                        </div>
                    </template>
                </vue-select>
                <div class="prepend">Τμήμα:</div>
            </div>
        </div> 
        <div class="cell-sm-12 cell-md-6">
            <input type="text" data-clear-button="false" data-role="input" data-prepend="Όνομα: " v-model="queryStringData.search.sirname" @keyup="keyMonitor">
        </div>
        
        <div class="cell-sm-12 cell-md-3" v-if="queryStringData.search.teamId">
            <input type="checkbox" data-role="checkbox" data-caption="Κατάσταση βαθμολόγησης" v-model="queryStringData.search.rubrikMode"> <br/>
            <input type="checkbox" data-role="checkbox" data-caption="Ποιόν να εξετάσω;" v-model="queryStringData.search.whoToReview"> <br/>
            <input type="checkbox" data-role="checkbox" data-caption="Προβολή ανά κριτήριο" v-model="queryStringData.search.rubrikPartsGroupView"> <br/>
        </div>
        <div class="cell-sm-12 cell-md-9" v-if="rubrikMode">
            <div class="input">  
                <vue-select label="name" :reduce="(v)=>v.id.toString()" v-model="queryStringData.search.courseId" :filterable="false" :options="optionsCourses">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }} - {{option.departmentName}}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.name }} - {{option.departmentName}}
                        </div>
                    </template>
                </vue-select>
                <div class="prepend">Μάθημα:</div>
            </div>
        </div> 
        <div class="cell-sm-12 cell-md-9  offset-md-3" v-if="rubrikMode">
            <div class="input">  
                <vue-select  @search="fetchLessonOptions" label="title" :reduce="(v)=>v.id.toString()" v-model="queryStringData.search.lessonId" :filterable="false" :options="optionsLessons">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.title }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.title }}
                        </div>
                    </template>
                </vue-select>
                <div class="prepend">Κεφάλαιο:</div>
            </div>
        </div> 
    </div>
</div>
</template>
<script>
import axios from 'axios';
import _ from 'underscore';

import settings from '../../settings.js'
import globals from '../../globals.js'

export default {
    props:{
        queryStringData:undefined,
    },
    computed:{
        rubrikMode(){
            return this.queryStringData.search.rubrikMode;
        },
        courseId(){
            return this.queryStringData.search.courseId;
        },
        lessonId(){
            return this.queryStringData.search.lessonId;
        },
        teamId(){
            if (!this.queryStringData) return undefined;
            return this.queryStringData.search.teamId;
        },
        user(){
            return this.$store.state.user;
        }
    },
    watch:{
        async teamId(){
            if (!this.optionTeams || this.optionTeams.length==0){
                let searchFilters={};
                if (this.user && this.user.roleId!=0){
                    let r=await axios.post(`${settings.serviceUrl}usertocourse/getMany`, {tokenValue:globals.getToken(),item:{userId:this.user.info.id}});
                    let data=r.data.payload.items;
                    this.teacherUserToCourse=data;
                    if (data.length==0){
                        this.optionTeams=[];
                    }
                    else{
                        searchFilters.teamIds=data.map(x=>x.teamId);
                    }
                }
                if (searchFilters.teamIds!=undefined && this.user.roleId!=0){
                    let r=await axios.post(`${settings.serviceUrl}team/getMany`, {tokenValue:globals.getToken(),item:searchFilters});
                    this.optionTeams= r.data.payload.items;
                }
            }
            this.relatedRubriks=[];
            this.$emit('onFilterSpecial',{type:'rubriks-reset',payload:undefined});

            if (!this.teamId){
                this.departmentId=undefined;
                this.queryStringData.search.courseId=undefined;
                this.optionsCourses=[];
                this.queryStringData.search.rubrikMode=false;
                return;
            }
            let team=_.findWhere(this.optionTeams,{id:parseInt(this.teamId)});
            this.filtersText=`${team.name}-${team.departmentName}`
            this.departmentId=team.departmentId;

            let courseIds=undefined;
            if (this.teacherUserToCourse){
                courseIds=(_.where(this.teacherUserToCourse,{teamId:parseInt(this.teamId)})).map(x=>x.courseId);
            }
            let r=await axios.post(`${settings.serviceUrl}course/getMany`, {tokenValue:globals.getToken(),item:{departmentId:this.departmentId,courseIds}});
            this.optionsCourses=r.data.payload.items;
            this.queryStringData.search.rubrikMode=true;
            if (this.tempCourseId){
                this.queryStringData.search.courseId=this.tempCourseId;
                this.tempCourseId=undefined;
            }
            else{
                this.queryStringData.search.courseId=undefined;
            }
            
        },
        async courseId(){
            this.relatedRubriks=[];
            this.$emit('onFilterSpecial',{type:'rubriks-reset',payload:undefined});
            if (!this.courseId){
                this.course=undefined;
            }
            this.course=(_.findWhere(this.optionsCourses,{id:parseInt(this.courseId)}));
            if (this.filtersText.indexOf('<br/>')!=-1)
                this.filtersText=this.filtersText.substr(0,this.filtersText.indexOf('<br/>'));
            
            if (this.course){
                this.filtersText+=`<br/> ${this.course.name}`
                this.queryStringData.search.lessonId=undefined;
                let r=await axios.post(`${settings.serviceUrl}coursesection/getMany`, {tokenValue:globals.getToken(),item:{courseId:this.courseId}});
                this.optionsLessons= r.data.payload.items;
                if (this.course.rubrikId){
                    r=await axios.post(`${settings.serviceUrl}rubrik/get`, {tokenValue:globals.getToken(),id:this.course.rubrikId});
                    if (r.data.payload){
                        let rp=await axios.post(`${settings.serviceUrl}rubrikPart/getMany`, {tokenValue:globals.getToken(),item:{rubrikId:this.course.rubrikId}});
                        r.data.payload.rubrikParts=rp.data.payload.items;
                        r.data.payload.courseId=this.courseId;
                        r.data.payload.courseName=this.course.name;
                        this.relatedRubriks.push(r.data.payload);
                        this.$emit('onFilterSpecial',{type:'course-rubrik-added',payload:{rubrik:r.data.payload,teamId:this.teamId,courseId:this.courseId,courseSectionId:undefined}});
                    }
                }
            }
        },
        async lessonId(){
            if (!this.lessonId){
                this.lessonId=undefined;
            }
            let r=await axios.post(`${settings.serviceUrl}coursesection/get`, {tokenValue:globals.getToken(),id:this.lessonId});
            if (r.data.payload){
                this.lesson=r.data.payload;
                if (this.lesson.rubrikId){
                    r=await axios.post(`${settings.serviceUrl}rubrik/get`, {tokenValue:globals.getToken(),id:this.lesson.rubrikId});
                    if (r.data.payload){
                        let rp=await axios.post(`${settings.serviceUrl}rubrikPart/getMany`, {tokenValue:globals.getToken(),item:{rubrikId:this.lesson.rubrikId}});
                        r.data.payload.rubrikParts=rp.data.payload.items;
                        r.data.payload.courseSectionId=this.lessonId;
                        if (this.relatedRubriks.length==2)
                            this.relatedRubriks.splice(0,1,r.data.payload);
                        else
                            this.relatedRubriks.push(r.data.payload);
                        
                        this.$emit('onFilterSpecial',{type:'lesson-rubrik-added',payload:{rubrik:r.data.payload,teamId:this.teamId,courseId:undefined,courseSectionId:this.lessonId}});
                    }
                }
            }
        },
        
        
        rubrikMode(){
            this.$emit('onFilterSpecial',{type:'rubrik-mode',payload:this.rubrikMode});
        },
        queryStringData: {
            immediate: true,
            async handler(newVal, oldVal) {
                // if (this.queryStringData.search.teamId && this.queryStringData.search.teamId!=-1){
                //     let r=await axios.post(`${settings.serviceUrl}team/get`, {tokenValue:globals.getToken(),id:this.queryStringData.search.teamId});
                //     this.optionTeams=[r.data.payload];
                // }
                
                if (this.queryStringData.search.teamId==-1)
                    this.queryStringData.search.teamId=undefined;
                

            },
        },
    },
    data(){
        return{
            teacherUserToCourse:undefined,
            departmentId:undefined,
            // courseId:undefined,
            course:undefined,
            // lessonId:undefined,
            lesson:undefined,
            optionTeams:[],
            optionsCourses:[],
            optionsLessons:[],
            // rubrikMode:false,
            relatedRubriks:[],
            initializing:true,
            hidden:true,
            filtersText:'',
            tempCourseId:undefined
        }
    },
    mounted() {
        const action=async ()=>{
            if (!this.optionTeams || this.optionTeams.length==0){
                let searchFilters={};
                if (this.user && this.user.roleId!=0){
                    this.hidden=false;
                    let r=await axios.post(`${settings.serviceUrl}usertocourse/getMany`, {tokenValue:globals.getToken(),item:{userId:this.user.info.id}});
                    let data=r.data.payload.items;
                    this.teacherUserToCourse=data;
                    if (data.length==0){
                        this.optionTeams=[];
                    }
                    else{
                        searchFilters.teamIds=data.map(x=>x.teamId);
                    }
                }
                if (searchFilters.teamIds!=undefined && this.user.roleId!=0){
                    let r=await axios.post(`${settings.serviceUrl}team/getMany`, {tokenValue:globals.getToken(),item:searchFilters});
                    this.optionTeams= r.data.payload.items;
                }
                if (this.user.roleId==0){
                    let r=await axios.post(`${settings.serviceUrl}team/getMany`, {tokenValue:globals.getToken()});
                    this.optionTeams= r.data.payload.items;
                }
            }
            this.tempCourseId=this.queryStringData.search.courseId;
            this.queryStringData.search.courseId=undefined;
            this.initializing=false;
            this.$nextTick(()=>{})
        };
        action();
        this.$root.$on('data-list-filters-from-url',async (queryStringData)=>{});
    },
    methods:{
        keyMonitor(event){
            if(event.key == "Enter")
                this.$emit('onRefresh');
        },
    }
}
</script>