var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("form", { staticClass: "row" }, [
      _c("div", { staticClass: "cell-12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.username,
              expression: "username",
            },
          ],
          attrs: {
            type: "text",
            "data-role": "input",
            "data-prepend": "Email: ",
          },
          domProps: { value: _vm.username },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.username = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cell-12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password",
            },
          ],
          attrs: {
            type: "password",
            "data-role": "input",
            "data-prepend": "Password: ",
          },
          domProps: { value: _vm.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cell-12" }, [
        _c(
          "button",
          {
            staticClass: "button primary",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onLogin.apply(null, arguments)
              },
            },
          },
          [_vm._v("Submit")]
        ),
        _vm._v(" "),
        !_vm.ok
          ? _c("div", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(_vm.message)),
            ])
          : _c("div", {}, [_vm._v(_vm._s(_vm.message))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }