var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page users-edit-page" }, [
    _c("div", { staticClass: "edit-area" }, [
      _vm.item
        ? _c(
            "form",
            {
              staticClass: "grid",
              attrs: { "data-interactive-check": "true" },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "edit-id" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.id) +
                        "\n                         "
                    ),
                    _c("img", {
                      staticStyle: { width: "50px", height: "50px" },
                      attrs: { src: _vm.toFullUrl(_vm.item.logo), alt: "" },
                      on: { error: _vm.setAltImg },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "stub", staticStyle: { width: "120px" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "button primary",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onSave.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", { staticClass: "mif-checkmark mif-lg" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button primary",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onCancel.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", { staticClass: "mif-cancel mif-lg" })]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "cell-sm-full cell-md-5" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.name,
                        expression: "item.name",
                      },
                    ],
                    attrs: {
                      "data-validate": "required minlength=6",
                      type: "text",
                      "data-clear-button": "false",
                      "data-role": "input",
                      "data-prepend": "Name: ",
                    },
                    domProps: { value: _vm.item.name },
                    on: {
                      keyup: _vm.keyMonitor,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cell-sm-full cell-md-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.lat,
                        expression: "item.lat",
                      },
                    ],
                    attrs: {
                      type: "text",
                      "data-clear-button": "false",
                      "data-role": "input",
                      "data-prepend": "Lat: ",
                    },
                    domProps: { value: _vm.item.lat },
                    on: {
                      keyup: _vm.keyMonitor,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "lat", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cell-12 cell-md-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.lng,
                        expression: "item.lng",
                      },
                    ],
                    attrs: {
                      type: "text",
                      "data-clear-button": "false",
                      "data-role": "input",
                      "data-prepend": "Lng: ",
                    },
                    domProps: { value: _vm.item.lng },
                    on: {
                      keyup: _vm.keyMonitor,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "lng", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cell-full" }, [
                  _c("div", { staticClass: "row" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "cell-12 cell-md-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.address,
                            expression: "item.address",
                          },
                        ],
                        staticClass: "mb-4",
                        attrs: {
                          type: "text",
                          "data-clear-button": "false",
                          "data-role": "input",
                          "data-prepend": "Address: ",
                        },
                        domProps: { value: _vm.item.address },
                        on: {
                          keyup: _vm.keyMonitor,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "address", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.phone,
                            expression: "item.phone",
                          },
                        ],
                        staticClass: "mb-4",
                        attrs: {
                          type: "text",
                          "data-clear-button": "false",
                          "data-role": "input",
                          "data-prepend": "Phone: ",
                        },
                        domProps: { value: _vm.item.phone },
                        on: {
                          keyup: _vm.keyMonitor,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "phone", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.email,
                            expression: "item.email",
                          },
                        ],
                        staticClass: "mb-4",
                        attrs: {
                          type: "text",
                          "data-clear-button": "false",
                          "data-role": "input",
                          "data-prepend": "Email: ",
                        },
                        domProps: { value: _vm.item.email },
                        on: {
                          keyup: _vm.keyMonitor,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "email", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.website,
                            expression: "item.website",
                          },
                        ],
                        staticClass: "mb-4",
                        attrs: {
                          type: "text",
                          "data-clear-button": "false",
                          "data-role": "input",
                          "data-prepend": "Website: ",
                        },
                        domProps: { value: _vm.item.website },
                        on: {
                          keyup: _vm.keyMonitor,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "website", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "mb-4",
                        attrs: {
                          type: "file",
                          "data-role": "file",
                          "data-prepend": "Logo file:",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.processFileToUpload(
                              $event,
                              "selectedLogoFile"
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button primary",
                          attrs: { disabled: !_vm.selectedLogoFile },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onUploadFile(
                                "ORGANIZATION_LOGO",
                                "selectedLogoFile"
                              )
                            },
                          },
                        },
                        [_c("span", [_vm._v("Upload Logo")])]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
                  _c("h6", [_vm._v("Legal Representative")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.legalName,
                        expression: "item.legalName",
                      },
                    ],
                    staticClass: "mb-4",
                    attrs: {
                      type: "text",
                      "data-clear-button": "false",
                      "data-role": "input",
                      "data-prepend": "Fullname: ",
                    },
                    domProps: { value: _vm.item.legalName },
                    on: {
                      keyup: _vm.keyMonitor,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "legalName", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.legalEmail,
                        expression: "item.legalEmail",
                      },
                    ],
                    staticClass: "mb-4",
                    attrs: {
                      type: "text",
                      "data-clear-button": "false",
                      "data-role": "input",
                      "data-prepend": "Email: ",
                    },
                    domProps: { value: _vm.item.legalEmail },
                    on: {
                      keyup: _vm.keyMonitor,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "legalEmail", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.legalPhone,
                        expression: "item.legalPhone",
                      },
                    ],
                    staticClass: "mb-4",
                    attrs: {
                      type: "text",
                      "data-clear-button": "false",
                      "data-role": "input",
                      "data-prepend": "Phone: ",
                    },
                    domProps: { value: _vm.item.legalPhone },
                    on: {
                      keyup: _vm.keyMonitor,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "legalPhone", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "cell-sm-full cell-md-2" }, [
                      _c("img", {
                        staticStyle: { width: "80px", height: "80px" },
                        attrs: {
                          src: _vm.toFullUrl(_vm.item.legalPhotoUrl),
                          alt: "",
                        },
                        on: { error: _vm.setAltImg },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cell-sm-full cell-md-10" }, [
                      _c("input", {
                        staticClass: "mb-4",
                        attrs: {
                          type: "file",
                          "data-role": "file",
                          "data-prepend": "Photo file:",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.processFileToUpload(
                              $event,
                              "selectedLegalPhotoFile"
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button primary",
                          attrs: { disabled: !_vm.selectedLegalPhotoFile },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onUploadFile(
                                "LEGAL_PHOTO",
                                "selectedLegalPhotoFile"
                              )
                            },
                          },
                        },
                        [_c("span", [_vm._v("Upload Photo")])]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "cell-full" }, [
                      _c("input", {
                        staticClass: "mb-4",
                        attrs: {
                          type: "file",
                          "data-role": "file",
                          "data-prepend": "CV file:",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.processFileToUpload(
                              $event,
                              "selectedLegalCVFile"
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button primary",
                          attrs: { disabled: !_vm.selectedLegalCVFile },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onUploadFile(
                                "LEGAL_CV",
                                "selectedLegalCVFile"
                              )
                            },
                          },
                        },
                        [_c("span", [_vm._v("Upload CV")])]
                      ),
                      _vm._v("   \n                            "),
                      _vm.item.legalCVUrl
                        ? _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.toFullUrl(_vm.item.legalCVUrl),
                              },
                            },
                            [_vm._v("View CV file (opens in new tab)")]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cell-sm-fill cell-md-6" }, [
                  _c("h6", [_vm._v("Organization type")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.category,
                          expression: "item.category",
                        },
                      ],
                      attrs: { id: "category", "data-role": "select" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.item,
                            "category",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [_vm._m(1), _vm._v(" "), _vm._m(2), _vm._v(" "), _vm._m(3)]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.businessCode,
                        expression: "item.businessCode",
                      },
                    ],
                    staticClass: "mt-4 mb-4",
                    attrs: {
                      type: "text",
                      "data-clear-button": "false",
                      "data-role": "input",
                      "data-prepend": "Sector code: ",
                    },
                    domProps: { value: _vm.item.businessCode },
                    on: {
                      keyup: _vm.keyMonitor,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "businessCode", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.socialMedia,
                        expression: "item.socialMedia",
                      },
                    ],
                    attrs: {
                      "data-prepend": "Social: ",
                      "data-role": "textarea",
                      "data-auto-size": "true",
                      "data-max-height": "200",
                    },
                    domProps: { value: _vm.item.socialMedia },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "socialMedia", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "cell-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button primary",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onSave.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", { staticClass: "mif-checkmark mif-lg" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button primary",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onCancel.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", { staticClass: "mif-cancel mif-lg" })]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
      _c("div", { attrs: { id: "map" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Εκπαίδευση" } }, [
      _c("option", { attrs: { value: "Εκπαίδευση Φροντιστήριο" } }, [
        _vm._v("Φροντιστήριο"),
      ]),
      _vm._v(" "),
      _c(
        "option",
        {
          attrs: {
            value: "Εκπαίδευση Δημόσιο/Ιδιωτικό Σχολείο Α/θμιας Εκπ/σης",
          },
        },
        [_vm._v("Δημόσιο/Ιδιωτικό Σχολείο Α/θμιας Εκπ/σης")]
      ),
      _vm._v(" "),
      _c(
        "option",
        {
          attrs: {
            value: "Εκπαίδευση Δημόσιο/Ιδιωτικό Σχολείο Β/θμιας Εκπ/σης",
          },
        },
        [_vm._v("Δημόσιο/Ιδιωτικό Σχολείο Β/θμιας Εκπ/σης")]
      ),
      _vm._v(" "),
      _c(
        "option",
        { attrs: { value: "Εκπαίδευση Εργαστήρια/Κέντρα Εκπαίδευσης" } },
        [_vm._v("Εργαστήρια/Κέντρα Εκπαίδευσης")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Αθλητισμός" } }, [
      _c("option", { attrs: { value: "Αθλητισμός Ακαδημία" } }, [
        _vm._v("Ακαδημία"),
      ]),
      _vm._v(" "),
      _c("option", { attrs: { value: "Αθλητισμός Επαγγελματικό Σωματείο " } }, [
        _vm._v("Επαγγελματικό Σωματείο "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("optgroup", { attrs: { label: "Επιχειρήσεις" } }, [
      _c(
        "option",
        { attrs: { value: "Επιχειρήσεις Προσωπικό Επιχειρήσεων" } },
        [_vm._v("Προσωπικό Επιχειρήσεων")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }