<template>
    <div class="row" v-if="item">
        <div class="cell-sm-full cell-md-6">
            <input data-validate="required minlength=2" type="text" data-clear-button="false" data-role="input" data-prepend="Τίτλος: " v-model="item.title" @keyup="keyMonitor">
        </div>  
        <div class="cell-sm-full cell-md-6">
            <input data-validate="required minlength=2" type="text" data-clear-button="false" data-role="input" data-prepend="Περιγραφή: " v-model="item.description" @keyup="keyMonitor">
        </div>  
        <div class="cell-md-6" v-show="!isSubList">
            <div class="input">  
                <vue-select  @search="fetchCourseOptions" label="name" :reduce="(v)=>v.id" v-model="item.courseId" :filterable="false" :options="optionsCourses">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.name }}
                        </div>
                    </template>
                </vue-select>
                <div class="prepend">Μάθημα:</div>
            </div>
        </div>   
        <div class="cell-12 cell-md-6">
            <div class="input">  
                <vue-select  @search="fetchRubrikOptions" label="name" :reduce="(v)=>v.id" v-model="item.rubrikId" :filterable="false" :options="optionsRubriks">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.name }}
                        </div>
                    </template>
                </vue-select>
                <div class="prepend">Rubrik:</div>
            </div>
        </div>
        <div class="cell-12 cell-md-6" v-if="!cloneRubrikView">
            <button class="button primary" :class="{'outline':isSubList}" @click.prevent="onCloneSelectedRubrik" :disabled="!item.rubrikId">
                Δημιουργία νέας από αυτήν
            </button>
        </div>
        <div class="cell-12 cell-md-6" v-if="cloneRubrikView && this.item.rubrikId">
            <div class="row">
                <div class="cell-12 cell-md-8" style="margin-top:-5px">
                    <input data-validate="required minlength=2" type="text" data-clear-button="false" data-role="input" data-prepend="Name: " v-model="clonedRubrikName" @keyup="keyMonitor">
                </div>
                <div class="cell-12 cell-md-4">
                     <span class="mif-checkmark mif-lg" style="cursor:pointer;font-size:20px;color:#aaa" @click="onSaveCloneRubrik" v-show="clonedRubrikName"></span>
                     <span class="mif-cancel mif-lg" style="cursor:pointer;font-size:20px;color:#aaa" @click="onCancelCloneRubrik"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

import settings from '../../settings.js'
import globals from '../../globals.js'

export default {
    props:{
        item:undefined,
        isSubList:undefined
    },
    watch:{
        item: {
            immediate: true,
            async handler(newVal, oldVal) {
                if (this.item.courseId && this.item.courseId!=-1){
                    let r=await axios.post(`${settings.serviceUrl}course/get`, {tokenValue:globals.getToken(),id:this.item.courseId});
                    this.optionsCourses=[r.data.payload];
                }
                if (this.item.courseId==-1) this.item.courseId=undefined;

                if (this.item.rubrikId && this.item.rubrikId!=-1){
                    let r=await axios.post(`${settings.serviceUrl}rubrik/get`, {tokenValue:globals.getToken(),id:this.item.rubrikId});
                    this.optionsRubriks=[r.data.payload];
                }
                if (this.item.rubrikId==-1) this.item.rubrikId=undefined;
            },
        },
    },
    data(){
        return{
            optionsCourses:[],
            optionsRubriks:[],
            cloneRubrikView:false,
            clonedRubrikName:''
        }
    },
    mounted() {
        const action=async ()=>{
            
        };
        action();
    },
    methods:{
        keyMonitor(event){
            this.$emit('keyevent',event);
        },
        async fetchCourseOptions (search, loading) {
            if (search.length>2){
                loading(true);
                let r=await axios.post(`${settings.serviceUrl}course/getMany`, {tokenValue:globals.getToken(),item:{name:search}});
                this.optionsCourses= r.data.payload.items;
                loading(false);
            } 
        },
        async fetchRubrikOptions (search, loading) {
            if (search.length>2){
                loading(true);
                let r=await axios.post(`${settings.serviceUrl}rubrik/getMany`, {tokenValue:globals.getToken(),item:{name:search}});
                this.optionsRubriks= r.data.payload.items;
                loading(false);
            } 
        },
        async onCourseSectionUpdated(){
        },
        async onCloneSelectedRubrik(){
            this.cloneRubrikView=true;
        },
        async onSaveCloneRubrik(){
            if (!this.item.rubrikId){
                Metro.dialog.create({
                    title: "Choose Rubrik",
                    content: "<div>A rubrik must be selected</div>",
                    closeButton: true
                });
            }
            else{
                let r=await axios.post(`${settings.serviceUrl}rubrik/clone`, {tokenValue:globals.getToken(),id:this.item.rubrikId,name:this.clonedRubrikName});
                this.optionsRubriks=[r.data.payload];
                this.item.rubrikId=r.data.payload.id;
            }
        },
        async onCancelCloneRubrik(){
            this.cloneRubrikView=false;
        }
    }
}
</script>