<template>
  <div class="admin-server-summary-container scrollbar-style">
    <div class="progress" v-if="dbLoading || authLoading">
      <div class="indeterminate"></div>
    </div>
    <div class="db-status">
        <strong>DB Connection: </strong>
        <span v-if="dbLoading">checking...</span>
        <span v-if="!dbLoading && dbOk!=undefined">
            <span v-if="dbOk">success</span>
            <span v-else>error</span>
        </span>
        <span v-if="!dbLoading && dbOk==undefined">
            not started
        </span>
    </div>
    <div class="auth-status">
        <strong>Auth Connection: </strong>
        <span v-if="authLoading">checking...</span>
        <span v-if="!authLoading && authOk!=undefined">
            <span v-if="authOk">success</span>
            <span v-else>error</span>
        </span>
        <span v-if="!authLoading && authOk==undefined">
            not started
        </span>
    </div>
    <div class="auth-status">
        <strong>Οργανισμοί: </strong>
        <span >{{organizations}}</span>
    </div>
    <div class="auth-status">
        <strong>Τάξεις: </strong>
        <span >{{departments}}</span>
    </div>
    <div class="auth-status">
        <strong>Τμήματα: </strong>
        <span >{{teams}}</span>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import _ from "underscore";

import settings from "../../../settings.js";

export default {
  data() {
    return {
      dbLoading:false,
      dbOk:undefined,
      authLoading:false,
      authOk:undefined,
      organizations:'...',
      departments:'...',
      teams:'...'
    };
  },
  mounted() {
    this.testDB();
    this.testAuthConnection();
    this.getData();
    this.$root.$on("refresh-covid", () => {
      this.getData();
    });
  },
  methods: {
    async getData(){
      this.dbLoading=true;
        let r = await axios.post(`${settings.serviceUrl}getCounts`,{tableName:'organizations'});
        this.organizations=r.data.payload;
        r = await axios.post(`${settings.serviceUrl}getCounts`,{tableName:'departments'});
        this.departments=r.data.payload;
        r = await axios.post(`${settings.serviceUrl}getCounts`,{tableName:'teams'});
        this.teams=r.data.payload;
      this.dbLoading=false;
    },
    async testDB() {
      this.dbLoading=true;
      let r = await axios.post(`${settings.serviceUrl}testDBConnection`);
      this.dbOk=r.data.ok;
      this.dbLoading=false;
    },
    async testAuthConnection() {
      this.authLoading=true;
      let r = await axios.post(`${settings.authServiceUrl}echo`,{ok:true});
      this.authOk=r.data.ok;
      this.authLoading=false;
    }
  }
};
</script>

<style lang="less" scoped>
.admin-server-summary-container {
  height: 100%;
  overflow: auto;
}
</style>