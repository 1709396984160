var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "ui-blocker",
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("img", { attrs: { src: require("../../assets/admin-lgo.png") } }),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("Connection to server")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "text" }, [
        _vm._v("Getting data from server..."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }