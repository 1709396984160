<template>
<div class="bar-area" :style="{'height':height+'px','width':width+'px'}">
    <div class="bar" v-for="(v,idx) in values" :key="idx" :style="{'border':borders,'height':stepHeight*v+'px','width':barWidth+'px','background-color':Array.isArray(colors)?colors[idx]:colors}">
        <span v-if="v===0" :style="{'margin-top':(-1*stepHeight-5)+'px'}" style="margin-top:-15px;display:block">x</span>
        <!-- <span v-else>{{v}}</span> -->
    </div>
</div> 
</template>

<script>
export default {
    props:{
        values:undefined,
        height:undefined,
        width:undefined,
        barAmount:undefined,
        possibleValues:undefined,
        values:undefined,
        colors:undefined,
        borders:undefined
    },
    data(){
        return{
            barWidth:10,
            stepHeight:5
        }
    },
    mounted(){
        this.barWidth=Math.floor(this.width/this.barAmount);
        this.stepHeight=Math.floor(this.height/this.possibleValues.length);
    }
}
</script>

<style lang="less" scoped>
.bar-area{
    display:flex;
    align-items: flex-end;
    .bar{
        flex:0 0 auto;
        text-align:center;
        span{
            font-size:10px;
        }
    }
}
</style>