<template>
<div class="page users-edit-page">
    <div class="edit-area">
        <form class="grid" v-if="item" data-interactive-check="true"> 
            <div class="row">
                <div class="cell">
                    <div class="edit-id">
                        {{id}}
                    </div>
                </div>
                <div class="stub" style="width: 120px">
                    <button class="button primary" @click.prevent="onSave">
                        <span class="mif-checkmark mif-lg"></span>
                    </button>
                    <button class="button primary" @click.prevent="onCancel">
                        <span class="mif-cancel mif-lg"></span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="cell-sm-full cell-md-6">
                    <input data-validate="required minlength=6" type="text" data-clear-button="false" data-role="input" data-prepend="Όνομα: " v-model="item.name" @keyup="keyMonitor">
                </div>  
                <div class="cell-12">
                    <div class="input">  
                    <vue-select :disabled="user.roleId!=0" label="name" :reduce="(v)=>v.id" v-model="item.organizationId" :filterable="false" :options="optionsOrganizations">
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>
                    </vue-select>
                    <div class="prepend">Οργανισμός:</div>
                    </div>
                </div>    
                <div class="cell-12">
                    <div class="subListHeader">
                        Τμήματα
                        <span class="mif-add mif-lg" style="cursor:pointer;background-color:green;color:white;width:20px;height:20px;border-radius:15px;text-align:center;padding-top:2px" @click.prevent="onAddTeam"></span>
                    </div>
                    <data-list ref="teamsList" :settings="teamsListSettings"></data-list>
                </div> 
                <div class="cell-12">
                    <div class="subListHeader">
                        Μαθήματα
                        <span class="mif-add mif-lg" style="cursor:pointer;background-color:green;color:white;width:20px;height:20px;border-radius:15px;text-align:center;padding-top:2px" @click.prevent="onAddCourse"></span>
                    </div>
                    <data-list ref="coursesList" :settings="coursesListSettings"></data-list>
                </div> 
                   
                <!-- <div class="cell-12">
                    <button class="button primary" @click.prevent="onSave">
                        <span class="mif-checkmark mif-lg"></span>
                    </button>
                    <button class="button primary" @click.prevent="onCancel">
                        <span class="mif-cancel mif-lg"></span>
                    </button>
                </div> -->
                
                
            </div>
        </form>
    </div>
</div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
import _ from 'underscore';

import globals from '../../globals.js';
import settings from '../../settings.js'
import validator from '../../validator.js';

import dataList from '../../components/admin/dataList.vue'

const itemName='department';
const itemNamePlural='departments';

const getItemURL=`${settings.serviceUrl}${itemName}/get`;
const updateItemURL=`${settings.serviceUrl}${itemName}/update`;
const deleteItemURL=`${settings.serviceUrl}${itemName}/remove`;
const defaultReturnURL=`/admin/${itemNamePlural}`;
export default {
    props: {
        id: {
            type: String,
            default: "-"
        },
    },
    watch:{},
    computed:{
        user(){
            return this.$store.state.user;
        }
    },
    components: {
        dataList
    },
    data() {
        return {
            item: {},
            isNew:false,
            password:'',
            optionsOrganizations:[],
            optionsDepartments:[],
            
            teamsListSettings:{
                showCards:true,
                hideFilters:true,
                hideControls:true,
                deleteConfirmationField:"id",
                isSubList:true,
                itemName:'team',
                itemNamePlural:'teams',
                searchParams:{

                },
                sortParams:[],
                tableColumns:[
                    {
                        name: '__component:vuetable-raw-actions-edit',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    },
                    {
                        name: 'id',
                        title:'Id',
                        sortField: 'id',
                        titleClass: 'id-column',
                        dataClass: 'id-column',
                        // callback: 'gender'
                    },
                    // {
                    //     name: 'departmentId',
                    //     title:'Department',
                    //     sortField: 'departmentId',
                    //     callback:((v)=>{
                    //         if (!v || v==-1) return '-';
                    //         let o=_.findWhere(this.optionsDepartments,{id:v});
                    //         if (o) return o.name;
                    //         return "-";
                    //     }).bind(this)
                    // },
                    {
                        name: 'name',
                        title:'Όνομα',
                        sortField: 'name',
                        isCardHeader:true
                    },
                    {
                        name: '__component:vuetable-raw-actions-delete',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    }
                ],
                defaultNewItem:undefined
            },
            coursesListSettings:{
                showCards:false,
                hideFilters:true,
                hideControls:true,
                deleteConfirmationField:"id",
                isSubList:true,
                itemName:'course',
                itemNamePlural:'courses',
                searchParams:{

                },
                sortParams:[],
                tableColumns:[
                    {
                        name: '__component:vuetable-raw-actions-edit',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    },
                    {
                        name: 'id',
                        title:'Id',
                        sortField: 'id',
                        titleClass: 'id-column',
                        dataClass: 'id-column'
                    },
                    {
                        name: 'name',
                        title:'Όνομα',
                        sortField: 'name',
                        isCardHeader:true
                    },
                    {
                        name: 'rubrikId',
                        title:'Rubrik',
                        sortField: 'rubrikId',
                        callback:((v)=>{
                            if (!v || v==-1) return '-';
                            let o=_.findWhere(this.optionsRubriks,{id:v});
                            if (o) return o.name;
                            return "-";
                        }).bind(this)
                    },
                    {
                        name: '__component:vuetable-raw-actions-delete',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    }
                ],
                defaultNewItem:undefined
            }
        };
    },
    mounted() {
        this.isNew=this.$route.query.isNew=='true';
        this.$store.commit('setBreadcrumb',`${itemNamePlural} (editing: ${this.id})`);
        const action=async ()=>{
            this.$root.$emit('ui-blocker',1);
            let or=await axios.post(`${settings.serviceUrl}organization/getMany`, {tokenValue:globals.getToken()});
            this.optionsOrganizations= or.data.payload.items;
            
            let itemResponse=await axios.post(getItemURL, {tokenValue: globals.getToken(),id: this.id});
            let item=itemResponse.data.payload;

            let r=await axios.post(`${settings.serviceUrl}department/getMany`, {tokenValue:globals.getToken()});
            this.optionsDepartments= r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}rubrik/getMany`, {tokenValue:globals.getToken()});
            this.optionsRubriks= r.data.payload.items;
            this.teamsListSettings.searchParams={
                departmentId:this.id
            };
            this.teamsListSettings.defaultNewItem={
                departmentId:this.id
            };

            this.coursesListSettings.searchParams={
                departmentId:this.id
            };
            this.coursesListSettings.defaultNewItem={
                departmentId:this.id
            };

            
            if (item.organizationId==-1)
                item.organizationId=undefined;
        
            this.$root.$emit('ui-blocker',-1);
            this.item=item;
        };
        action();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.rootScreen = from.fullPath;
            if (vm.rootScreen=="/")
                vm.rootScreen=defaultReturnURL;
        });
    },
    methods: {
        onAddTeam(){
            this.$refs.teamsList.onAdd();
        },
        onAddCourse(){
            this.$refs.coursesList.onAdd();
        },
        keyMonitor(event){
            validator.validateForm(document.querySelector("form"),false);
        },
        async onCancel() {
            if (this.isNew){
                let response=await axios.post(`${deleteItemURL}`,{tokenValue:globals.getToken(),id:this.item.id});
            }
            else
                this.$store.commit('setAdminEditPageId',this.item.id);
            this.$router.push(this.rootScreen)
        },
        async onSave(d) {
            let validationResult=validator.validateForm(document.querySelector("form"),true);
            if (!validationResult.ok) return;

            if (this.isNew){
            }
            else{
            }

            let response=await axios.post(updateItemURL, {tokenValue: globals.getToken(),item: this.item});

            this.$store.commit('setAdminEditPageId',this.item.id);
            this.$router.push(this.rootScreen)
        }
    }
};
</script>

<style lang="less">
</style>