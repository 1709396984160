var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.name,
                  expression: "item.name",
                },
              ],
              attrs: {
                "data-validate": "required minlength=6",
                type: "text",
                "data-clear-button": "false",
                "data-role": "input",
                "data-prepend": "Name: ",
              },
              domProps: { value: _vm.item.name },
              on: {
                keyup: _vm.keyMonitor,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "name", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.code,
                  expression: "item.code",
                },
              ],
              attrs: {
                "data-validate": "required minlength=6",
                type: "text",
                "data-clear-button": "false",
                "data-role": "input",
                "data-prepend": "Code: ",
              },
              domProps: { value: _vm.item.code },
              on: {
                keyup: _vm.keyMonitor,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "code", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell-full" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.description,
                  expression: "item.description",
                },
              ],
              attrs: {
                "data-validate": "required minlength=6",
                type: "text",
                "data-clear-button": "false",
                "data-role": "input",
                "data-prepend": "Description: ",
              },
              domProps: { value: _vm.item.description },
              on: {
                keyup: _vm.keyMonitor,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "description", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cell-full" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.item.steps,
                  expression: "item.steps",
                  modifiers: { number: true },
                },
              ],
              attrs: {
                "data-size": "100",
                "data-validate": "required integer",
                type: "text",
                "data-clear-button": "false",
                "data-role": "input",
                "data-prepend": "Steps: ",
              },
              domProps: { value: _vm.item.steps },
              on: {
                keyup: _vm.keyMonitor,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "steps", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm._l(_vm.stepDescriptionsArray, function (d, idx) {
            return _c("div", { key: idx, staticClass: "cell-full" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.stepDescriptionsArray[idx],
                    expression: "stepDescriptionsArray[idx]",
                  },
                ],
                attrs: {
                  "data-validate": "required",
                  type: "text",
                  "data-clear-button": "false",
                  "data-role": "input",
                  "data-prepend": "Step " + (idx + 1) + ":",
                },
                domProps: { value: _vm.stepDescriptionsArray[idx] },
                on: {
                  change: _vm.onStepDescriptionChanged,
                  keyup: _vm.keyMonitor,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.stepDescriptionsArray,
                      idx,
                      $event.target.value
                    )
                  },
                },
              }),
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "cell-full" }, [
            _c("div", [_vm._v("Weight:")]),
            _vm._v(" "),
            _c("input", {
              attrs: {
                id: "slider",
                "data-role": "slider",
                "data-hint-always": "true",
                "data-min": "0",
                "data-max": "100",
                "data-show-min-max": "true",
                "data-accuracy": "5",
                "data-value": _vm.item.weight,
              },
            }),
            _vm._v("\n        " + _vm._s(_vm.item.weight) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isSubList,
                  expression: "!isSubList",
                },
              ],
              staticClass: "cell-12",
            },
            [
              _c(
                "div",
                { staticClass: "input" },
                [
                  _c("vue-select", {
                    attrs: {
                      label: "name",
                      reduce: function (v) {
                        return v.id
                      },
                      filterable: false,
                      options: _vm.optionsRubriks,
                    },
                    on: { search: _vm.fetchRubrikOptions },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "option",
                          fn: function (option) {
                            return [
                              _c("div", { staticClass: "d-center" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(option.name) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "selected-option",
                          fn: function (option) {
                            return [
                              _c("div", { staticClass: "selected d-center" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(option.name) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4010765028
                    ),
                    model: {
                      value: _vm.item.rubrikId,
                      callback: function ($$v) {
                        _vm.$set(_vm.item, "rubrikId", $$v)
                      },
                      expression: "item.rubrikId",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "prepend" }, [_vm._v("Rubrik:")]),
                ],
                1
              ),
            ]
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }