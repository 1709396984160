<template>
    <div class="row" v-if="item">
        <div class="cell-full">
            <button class="button primary" @click.prevent="onExport">
                Export
            </button>
        </div>  
        <div class="cell-sm-full cell-md-6">
            <input data-validate="required minlength=6" type="text" data-clear-button="false" data-role="input" data-prepend="Όνομα: " v-model="item.name" @keyup="keyMonitor">
        </div>  
        <div class="cell-full">
            <input data-validate="required minlength=6" type="text" data-clear-button="false" data-role="input" data-prepend="Περιγραφή: " v-model="item.description" @keyup="keyMonitor">
        </div> 
        <div class="cell-sm-full cell-md-3">
            <input data-validate="required integer" type="text" data-clear-button="false" data-role="input" data-prepend="Score(min): " v-model="item.minScore" @keyup="keyMonitor">
        </div>
        <div class="cell-sm-full cell-md-3">
            <input data-validate="required integer" type="text" data-clear-button="false" data-role="input" data-prepend="Score(max): " v-model="item.maxScore" @keyup="keyMonitor">
        </div>
        <div class="cell-12">
            <div class="input">  
            <vue-select :disabled="user.roleId!=0" label="name" :reduce="(v)=>v.id" v-model="item.organizationId" :filterable="false" :options="optionsOrganizations">
                <template slot="option" slot-scope="option">
                    <div class="d-center">
                        {{ option.name }}
                    </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                        {{ option.name }}
                    </div>
                </template>
            </vue-select>
            <div class="prepend">Οργανισμός:</div>
            </div>
        </div>  
        <div class="cell-12">
            <div class="subListHeader">
                Κριτήρια
                <span class="mif-add mif-lg" style="cursor:pointer;background-color:green;color:white;width:20px;height:20px;border-radius:15px;text-align:center;padding-top:2px" @click.prevent="onAddRubrikPart"></span>
            </div>
            <data-list ref="rubrikPartsList" :settings="rubrikPartListSettings" @onListUpated="onRubrikPartUpdated"></data-list>
        </div> 
        <div class="cell-12">
            <div class="remark alert" v-if="partsSum!=100">
                {{partsSumError}}
            </div>
            
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import _ from 'underscore';

import settings from '../../settings.js'
import globals from '../../globals.js'

export default {
    props:{
        item:undefined,
        isSubList:undefined
    },
    components:{
    },
    computed:{
        partsSumError(){
            if (this.partsSum!=100)
                return `Warning rubrik parts weights are sumed up to ${this.partsSum}% and not 100%`
            
            return '';
        },
        user(){
            return this.$store.state.user;
        }
    },
    watch:{
        item: {
            immediate: true,
            async handler(newVal, oldVal) {
                this.rubrikPartListSettings.searchParams={
                    rubrikId:this.item.id
                };
                this.rubrikPartListSettings.defaultNewItem={
                    rubrikId:this.item.id
                };
                this.getPartsSum();
            },
        },
    },
    data(){
        return{
            partsSum:0,
            optionsOrganizations:[],
            rubrikPartListSettings:{
                showCards:true,
                hideFilters:true,
                hideControls:true,
                deleteConfirmationField:"id",
                isSubList:true,
                itemName:'rubrikPart',
                itemNamePlural:'rubrikParts',
                dataPerPage:10000,
                searchParams:{
                },
                sortParams:[],
                tableColumns:[
                    {
                        name: '__component:vuetable-raw-actions-edit-rp',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    },
                    {
                        name: 'id',
                        title:'Id',
                        sortField: 'id',
                        titleClass: 'id-column',
                        dataClass: 'id-column',
                    },
                    {
                        name: 'name',
                        title:'Όνομα',
                        sortField: 'name',
                        isCardHeader:true
                    },
                    {
                        name: 'code',
                        title:'Κωδικός',
                        sortField: 'code',
                        isCardHeader:true
                    },
                    {
                        name: 'weight',
                        title:'Βάρος',
                        sortField: 'weight',
                        callback:(v)=>{
                            return v+'%';
                        }
                    },
                    {
                        name: '__component:vuetable-raw-actions-delete',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    }
                ],
            }
        }
    },
    mounted() {
        this.rubrikPartListSettings.onPreEdit=(item)=>{
            // if (item.common==1){
            //     Metro.toast.create(`This rubrik part cannot be edited`, null, null, "alert");
            //     return false;
            // }
            return true;
        }
        this.rubrikPartListSettings.onPreRemove=(item)=>{
            // if (item.common==1){
            //     Metro.toast.create(`This rubrik part cannot be deleted`, null, null, "alert");
            //     return false;
            // }
            return true;
        }
        const action=async ()=>{
            let or=await axios.post(`${settings.serviceUrl}organization/getMany`, {tokenValue:globals.getToken()});
            this.optionsOrganizations= or.data.payload.items;
        };
        action();
    },
    methods:{
        async onExport(){
             let r=await axios.post(`${settings.serviceUrl}rubrik/export`, {tokenValue:globals.getToken(),id:this.item.id});
             let exportedContent=r.data.payload;
             var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(exportedContent));
            element.setAttribute('download', this.item.name+".json");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },
        async getPartsSum(){
            let r=await axios.post(`${settings.serviceUrl}rubrikPart/getMany`, {tokenValue:globals.getToken(),item:{rubrikId:this.item.id}});
            let parts= r.data.payload.items;
            this.partsSum=0;
            parts.forEach((p)=>{
                this.partsSum+=p.weight;
            })
        },
        onAddRubrikPart(){
            this.$refs.rubrikPartsList.onAdd();
        },
        async onRubrikPartUpdated(){
            this.getPartsSum();
        },
        keyMonitor(event){
            this.$emit('keyevent',event);
        }
    }
}
</script>