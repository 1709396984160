import _ from 'underscore';

export default {
  version: '1.0',
  activeLanguage:"gr",
  languages: [{
      id: 1,
      name: 'greek',
      code: 'gr',
      default: false
    },
    {
      id: 2,
      name: 'english',
      code: 'en',
      default: true
    },
    {
      id: 3,
      name: 'german',
      code: 'de',
      default: false
    }
  ],
  units:[
    {name:'quantity'},
    {name:'kilos'}
  ],
  tinyMCEOptions:{
    height: 500,
    plugins: [
"advlist autolink lists link image charmap print preview anchor",
"searchreplace visualblocks code fullscreen",
"insertdatetime media table paste"
],
toolbar: "insertfile undo redo | styleselect | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
    
    file_picker_callback: (callback, value, meta)=> {
      if (meta.filetype == 'image') {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.click();
          input.onchange=function() {
              var file = this.files[0];
              var reader = new FileReader();
              reader.onload = function(e) {
                  var id = 'blobid' + (new Date()).getTime();
                  var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.split(',')[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  callback(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
          };
      }
    },
    images_upload_handler :async function (blobInfo, success, failure) {
        let formData = new FormData();
        let filename=`${new Date().getTime()}.png`;
        formData.append('file',blobInfo.blob(),filename);
        formData.append('tokenValue',localStorage.getItem('token'));
        let response=await axios.post(`${settings.serviceUrl}uploadImage`,formData,{headers: {'Content-Type': 'x-www-form-urlencoded'}});
        if (response.data.ok){
            success(`${settings.serviceUrl}cdn/${filename}`);
        }
    },
    style_formats: [
{
title: 'alignlddeft',
inline: 'span',
styles: {
'float': 'left', 
 'margin': '10px 10px 10px 10px'

}
},
{
title: 'Image Right',
inline: 'span',
styles: {
 'float': 'right', 
 'margin': '10px 10px 10px 10px'
}
}]
},

  euroFormatter: new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  }),
  moneyFormatter: new Intl.NumberFormat('de-DE', {}),
  setAdminTablesLayout() {
    let tbodyElement = document.querySelector(".admin-list table tbody");
    if (tbodyElement)
      tbodyElement.classList.add('scrollbar-style')

    if (window.innerWidth <= 600) {
      let tableElements = document.querySelectorAll(".admin-list table");
      for (let t of tableElements)
        t.classList.add('rtable--flip')
    } else {
      let tableElements = document.querySelectorAll(".admin-list table");
      for (let t of tableElements)
        t.classList.remove('rtable--flip')
    }
  },
  getNamesFromIdCommaSeparatedList(separator, list, value, listProperty) {
    if (!value || value.length == 0)
      return "-";
    let idList = value.split("|");
    idList = _.filter(idList, (v) => v != "");
    for (let i = 0; i < idList.length; i++) {
      let o = _.findWhere(list, {
        id: parseInt(idList[i])
      });
      if (!o)
        idList[i] = 'N/A';
      else
        idList[i] = o[listProperty];
    }

    return idList.join(",");
  },
  setToken(token) {
    //localStorage.setItem('token', token);
    this.setCookie("token",token,1000);
    this.token = token;
  },
  getToken() {
    return this.getCookie('token');
  },
  removeToken() {
    //localStorage.removeItem('token');
    this.eraseCookie('token');
    this.token = undefined;
  },
  logoutUser(store){
    this.eraseCookie('token');
    store.commit('setUser',undefined);
  },
  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  },
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
  }
};