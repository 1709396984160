<template>
    <div class="filters-area row" v-if="queryStringData">
        <div class="cell-sm-12 cell-md-6">
            <div class="input" style="width:100%;max-width:600px">  
                <vue-select label="name" :reduce="(v)=>v.id.toString()" v-model="queryStringData.search.departmentId" :filterable="false" :options="optionsDepartments">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }} - {{option.organizationName}}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.name }} - {{option.organizationName}}
                        </div>
                    </template>
                </vue-select>
                <div class="prepend">Τάξη:</div>
            </div>
        </div>
        <div class="cell-sm-12 cell-md-6" v-if="user && user.roleId==0">
            <div class="input" style="width:100%;max-width:600px">  
                <vue-select label="name" :reduce="(v)=>v.id" v-model="queryStringData.search.organizationId" :filterable="false" :options="optionsOrganizations">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.name }}
                        </div>
                    </template>
                </vue-select>
                <div class="prepend">Οργανισμός:</div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

import settings from '../../settings.js'
import globals from '../../globals.js'

export default {
    props:{
        queryStringData:undefined,
    },
    computed:{
        user(){
            return this.$store.state.user;
        }
    },
    watch:{
        queryStringData: {
            immediate: true,
            async handler(newVal, oldVal) {                

            },
        },
    },
    data(){
        return{
            optionsDepartments:[],
            optionsOrganizations:[]
        }
    },
    mounted() {
        
        const action=async ()=>{
            let r=await axios.post(`${settings.serviceUrl}department/getMany`, {tokenValue:globals.getToken(),item:{}});
            this.optionsDepartments=r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}organization/getMany`, {tokenValue:globals.getToken(),item:{}});
            this.optionsOrganizations= r.data.payload.items;
            this.$nextTick(()=>{
            })
        };
        action();

        this.$root.$on('data-list-filters-from-url',async (queryStringData)=>{
        });
    },
    methods:{
        keyMonitor(event){
            if(event.key == "Enter")
                this.$emit('onRefresh');
        },
    }
}
</script>