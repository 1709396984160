var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.title,
                expression: "item.title",
              },
            ],
            attrs: {
              "data-validate": "required minlength=2",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "Τίτλος: ",
            },
            domProps: { value: _vm.item.title },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "title", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell-sm-full cell-md-6" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.description,
                expression: "item.description",
              },
            ],
            attrs: {
              "data-validate": "required minlength=2",
              type: "text",
              "data-clear-button": "false",
              "data-role": "input",
              "data-prepend": "Περιγραφή: ",
            },
            domProps: { value: _vm.item.description },
            on: {
              keyup: _vm.keyMonitor,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "description", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isSubList,
                expression: "!isSubList",
              },
            ],
            staticClass: "cell-md-6",
          },
          [
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("vue-select", {
                  attrs: {
                    label: "name",
                    reduce: function (v) {
                      return v.id
                    },
                    filterable: false,
                    options: _vm.optionsCourses,
                  },
                  on: { search: _vm.fetchCourseOptions },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            _c("div", { staticClass: "d-center" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(option.name) +
                                  "\n                    "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "selected-option",
                        fn: function (option) {
                          return [
                            _c("div", { staticClass: "selected d-center" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(option.name) +
                                  "\n                    "
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    566720996
                  ),
                  model: {
                    value: _vm.item.courseId,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "courseId", $$v)
                    },
                    expression: "item.courseId",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "prepend" }, [_vm._v("Μάθημα:")]),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cell-12 cell-md-6" }, [
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("vue-select", {
                attrs: {
                  label: "name",
                  reduce: function (v) {
                    return v.id
                  },
                  filterable: false,
                  options: _vm.optionsRubriks,
                },
                on: { search: _vm.fetchRubrikOptions },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "d-center" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(option.name) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "selected-option",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "selected d-center" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(option.name) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  566720996
                ),
                model: {
                  value: _vm.item.rubrikId,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "rubrikId", $$v)
                  },
                  expression: "item.rubrikId",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "prepend" }, [_vm._v("Rubrik:")]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        !_vm.cloneRubrikView
          ? _c("div", { staticClass: "cell-12 cell-md-6" }, [
              _c(
                "button",
                {
                  staticClass: "button primary",
                  class: { outline: _vm.isSubList },
                  attrs: { disabled: !_vm.item.rubrikId },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onCloneSelectedRubrik.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n            Δημιουργία νέας από αυτήν\n        ")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.cloneRubrikView && this.item.rubrikId
          ? _c("div", { staticClass: "cell-12 cell-md-6" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "cell-12 cell-md-8",
                    staticStyle: { "margin-top": "-5px" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.clonedRubrikName,
                          expression: "clonedRubrikName",
                        },
                      ],
                      attrs: {
                        "data-validate": "required minlength=2",
                        type: "text",
                        "data-clear-button": "false",
                        "data-role": "input",
                        "data-prepend": "Name: ",
                      },
                      domProps: { value: _vm.clonedRubrikName },
                      on: {
                        keyup: _vm.keyMonitor,
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.clonedRubrikName = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "cell-12 cell-md-4" }, [
                  _c("span", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.clonedRubrikName,
                        expression: "clonedRubrikName",
                      },
                    ],
                    staticClass: "mif-checkmark mif-lg",
                    staticStyle: {
                      cursor: "pointer",
                      "font-size": "20px",
                      color: "#aaa",
                    },
                    on: { click: _vm.onSaveCloneRubrik },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "mif-cancel mif-lg",
                    staticStyle: {
                      cursor: "pointer",
                      "font-size": "20px",
                      color: "#aaa",
                    },
                    on: { click: _vm.onCancelCloneRubrik },
                  }),
                ]),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }