<template>
<div class="page users-edit-page">
    <div class="edit-area">
        <form class="grid" v-if="item" data-interactive-check="true"> 
            <div class="row">
                <div class="cell">
                    <div class="edit-id">
                        {{id}}
                         <img :src="toFullUrl(item.logo)" alt="" style="width:50px;height:50px;" @error="setAltImg" >
                    </div>
                   
                </div>
                <div class="stub" style="width: 120px">
                    <button class="button primary" @click.prevent="onSave">
                        <span class="mif-checkmark mif-lg"></span>
                    </button>
                    <button class="button primary" @click.prevent="onCancel">
                        <span class="mif-cancel mif-lg"></span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="cell-sm-full cell-md-5">
                    <input data-validate="required minlength=6" type="text" data-clear-button="false" data-role="input" data-prepend="Name: " v-model="item.name" @keyup="keyMonitor">
                </div>
                <div class="cell-sm-full cell-md-2">
                    <input type="text" data-clear-button="false" data-role="input" data-prepend="Lat: " v-model="item.lat" @keyup="keyMonitor">
                </div>
                <div class="cell-12 cell-md-2">
                    <input type="text" data-clear-button="false" data-role="input" data-prepend="Lng: " v-model="item.lng" @keyup="keyMonitor">
                </div>
                <div class="cell-full">
                    <div class="row">
                        <div class="cell-sm-full cell-md-6">
                            <div id="map">

                            </div>
                        </div> 
                        <div class="cell-12 cell-md-6">
                            <input class="mb-4" type="text" data-clear-button="false" data-role="input" data-prepend="Address: " v-model="item.address" @keyup="keyMonitor">
                            <input class="mb-4" type="text" data-clear-button="false" data-role="input" data-prepend="Phone: " v-model="item.phone" @keyup="keyMonitor">
                            <input class="mb-4" type="text" data-clear-button="false" data-role="input" data-prepend="Email: " v-model="item.email" @keyup="keyMonitor">
                            <input class="mb-4" type="text" data-clear-button="false" data-role="input" data-prepend="Website: " v-model="item.website" @keyup="keyMonitor">
                            <input  class="mb-4" type="file" data-role="file" data-prepend="Logo file:" @change="processFileToUpload($event,'selectedLogoFile')">
                            <button class="button primary" @click.prevent="onUploadFile('ORGANIZATION_LOGO','selectedLogoFile')" :disabled="!selectedLogoFile">
                                <span>Upload Logo</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="cell-sm-full cell-md-6">
                    <h6>Legal Representative</h6>
                    <input class="mb-4" type="text" data-clear-button="false" data-role="input" data-prepend="Fullname: " v-model="item.legalName" @keyup="keyMonitor">
                    <input class="mb-4" type="text" data-clear-button="false" data-role="input" data-prepend="Email: " v-model="item.legalEmail" @keyup="keyMonitor">
                    <input class="mb-4" type="text" data-clear-button="false" data-role="input" data-prepend="Phone: " v-model="item.legalPhone" @keyup="keyMonitor">
                    <div class="row">
                        <div class="cell-sm-full cell-md-2">
                            <img :src="toFullUrl(item.legalPhotoUrl)" alt="" style="width:80px;height:80px;" @error="setAltImg" >
                        </div>
                        <div class="cell-sm-full cell-md-10">
                            <input  class="mb-4" type="file" data-role="file" data-prepend="Photo file:" @change="processFileToUpload($event,'selectedLegalPhotoFile')">
                            <button class="button primary" @click.prevent="onUploadFile('LEGAL_PHOTO','selectedLegalPhotoFile')" :disabled="!selectedLegalPhotoFile">
                                <span>Upload Photo</span>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="cell-full">
                            <input  class="mb-4" type="file" data-role="file" data-prepend="CV file:" @change="processFileToUpload($event,'selectedLegalCVFile')">
                            <button class="button primary" @click.prevent="onUploadFile('LEGAL_CV','selectedLegalCVFile')" :disabled="!selectedLegalCVFile">
                                <span>Upload CV</span>
                            </button> &nbsp;&nbsp;
                            <a v-if="item.legalCVUrl" target="_blank" :href="toFullUrl(item.legalCVUrl)">View CV file (opens in new tab)</a>
                        </div>
                    </div>
                    
                </div>
                <div class="cell-sm-fill cell-md-6">
                    <h6>Organization type</h6>
                    <select id="category" data-role="select" v-model="item.category">
                        <optgroup label="Εκπαίδευση">
                            <option value="Εκπαίδευση Φροντιστήριο">Φροντιστήριο</option>
                            <option value="Εκπαίδευση Δημόσιο/Ιδιωτικό Σχολείο Α/θμιας Εκπ/σης">Δημόσιο/Ιδιωτικό Σχολείο Α/θμιας Εκπ/σης</option>
                            <option value="Εκπαίδευση Δημόσιο/Ιδιωτικό Σχολείο Β/θμιας Εκπ/σης">Δημόσιο/Ιδιωτικό Σχολείο Β/θμιας Εκπ/σης</option>
                            <option value="Εκπαίδευση Εργαστήρια/Κέντρα Εκπαίδευσης">Εργαστήρια/Κέντρα Εκπαίδευσης</option>
                        </optgroup>
                        <optgroup label="Αθλητισμός">
                            <option value="Αθλητισμός Ακαδημία">Ακαδημία</option>
                            <option value="Αθλητισμός Επαγγελματικό Σωματείο ">Επαγγελματικό Σωματείο </option>
                        </optgroup>
                        <optgroup label="Επιχειρήσεις">
                            <option value="Επιχειρήσεις Προσωπικό Επιχειρήσεων">Προσωπικό Επιχειρήσεων</option>
                        </optgroup>
                    </select>
                    <input class="mt-4 mb-4" type="text" data-clear-button="false" data-role="input" data-prepend="Sector code: " v-model="item.businessCode" @keyup="keyMonitor">
                    <textarea v-model="item.socialMedia" data-prepend="Social: " data-role="textarea" data-auto-size="true" data-max-height="200"></textarea>
                </div>
                
                <div class="cell-12">
                    <button class="button primary" @click.prevent="onSave">
                        <span class="mif-checkmark mif-lg"></span>
                    </button>
                    <button class="button primary" @click.prevent="onCancel">
                        <span class="mif-cancel mif-lg"></span>
                    </button>
                </div>
                
            </div>
        </form>
    </div>
</div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
import _ from 'underscore';
import { Loader } from "@googlemaps/js-api-loader"

import globals from '../../globals.js';
import settings from '../../settings.js'
import validator from '../../validator.js';

const itemName='organization';
const itemNamePlural='organizations';

const getItemURL=`${settings.serviceUrl}${itemName}/get`;
const updateItemURL=`${settings.serviceUrl}${itemName}/update`;
const deleteItemURL=`${settings.serviceUrl}${itemName}/remove`;
const defaultReturnURL=`/admin/${itemNamePlural}`;

export default {
    props: {
        id: {
            type: String,
            default: "-"
        },
    },
    watch:{},
    computed:{
    },
    components: {

    },
    data() {
        return {
            item: {},
            isNew:false,
            selectedLogoFile:'',
            selectedLegalPhotoFile:'',
            selectedLegalCVFile:''
        };
    },
    mounted() {
        this.isNew=this.$route.query.isNew=='true';
        this.$store.commit('setBreadcrumb',`${itemNamePlural} (editing: ${this.id})`);
        const loader = new Loader({
            apiKey: settings.googleMapsAPIKey,
            version: "weekly"
        });
        const action=async ()=>{
            this.$root.$emit('ui-blocker',1);
            let itemResponse=await axios.post(getItemURL, {tokenValue: globals.getToken(),id: this.id});
            let item=itemResponse.data.payload;
            this.$root.$emit('ui-blocker',-1);
            this.item=item;
            
            Metro.getPlugin('#category').select.val(this.item.category);
            let mapsLoader=await loader.load();
            let map = new google.maps.Map(document.getElementById("map"), {
                center: settings.googleMapsDefaultView.mapCenter,
                zoom: settings.googleMapsDefaultView.zoom,
            });
            map.addListener('click', (event)=> {
                this.item.lat=event.latLng.lat();
                this.item.lng=event.latLng.lng();
            });
        };
        action();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.rootScreen = from.fullPath;
            if (vm.rootScreen=="/")
                vm.rootScreen=defaultReturnURL;
        });
    },
    methods: {
        setAltImg(event) { 
            event.target.src = `${settings.serviceUrl}public/site/assets/no-image.png`;
        },
        toFullUrl(relativeUrl){
            return settings.serviceUrl+relativeUrl;
        },
        keyMonitor(event){
            validator.validateForm(document.querySelector("form"),false);
        },
        processFileToUpload(event,property) {
            this[property] = event.target.files[0];
        },
        async onUploadFile(fileFor,property){
            let formData = new FormData();
            formData.append('file', this[property]);
            formData.append('dir','organizations/'+this.item.id);
            formData.append('action',fileFor);
            formData.append('organizationId',this.item.id);
            formData.append('tokenValue',globals.getToken());
            let response=await axios.post(`${settings.serviceUrl}upload/file/`,formData,{headers: {'Content-Type': 'multipart/form-data'}});
            if (!response.data.ok){
                Metro.toast.create(`${response.data.message}`, null, null, "alert");
            }
            if (response.data.ok){
                if (fileFor=='ORGANIZATION_LOGO') this.item.logo=response.data.payload.relativePathUrl;
                if (fileFor=='LEGAL_PHOTO') this.item.legalPhotoUrl=response.data.payload.relativePathUrl;
                if (fileFor=='LEGAL_CV') this.item.legalCVUrl=response.data.payload.relativePathUrl;
            }
        },
        async onCancel() {
            if (this.isNew){
                let response=await axios.post(`${deleteItemURL}`,{tokenValue:globals.getToken(),id:this.item.id});
                if (!response.data.ok){
                    Metro.toast.create(`${response.data.message}`, null, null, "alert");
                }
            }
            else
                this.$store.commit('setAdminEditPageId',this.item.id);

            this.$router.push(this.rootScreen);
        },
        async onSave(d) {
            let validationResult=validator.validateForm(document.querySelector("form"),true);
            if (!validationResult.ok) return;

            if (this.isNew){
                // Extra stuff to be done if record is new
            }
            else{

            }

            let response=await axios.post(updateItemURL, {tokenValue: globals.getToken(),item: this.item});
            if (!response.data.ok){
                Metro.toast.create(`${response.data.message}`, null, null, "alert");
            }
            this.$store.commit('setAdminEditPageId',this.item.id);
            this.$router.push(this.rootScreen)
        }
    }
};
</script>

<style lang="less" scoped>
#map{
    height:300px;
    width:100%;
}
</style>