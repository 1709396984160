var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page users-list-page admin-list" }, [
    _c("div", { staticClass: "grid" }, [
      _c(
        "div",
        { staticClass: "filters-area", staticStyle: { padding: "10px" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "cell-12 cell-md-6" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.queryStringData.search.email,
                    expression: "queryStringData.search.email",
                  },
                ],
                attrs: {
                  type: "text",
                  "data-clear-button": "false",
                  "data-role": "input",
                  "data-prepend": "Email: ",
                },
                domProps: { value: _vm.queryStringData.search.email },
                on: {
                  keyup: _vm.keyMonitor,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.queryStringData.search,
                      "email",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.user && _vm.user.roleId == 0
              ? _c("div", { staticClass: "cell-sm-12 cell-md-6" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input",
                      staticStyle: { width: "100%", "max-width": "600px" },
                    },
                    [
                      _c("vue-select", {
                        attrs: {
                          label: "name",
                          reduce: function (v) {
                            return v.id
                          },
                          filterable: false,
                          options: _vm.organizations,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("div", { staticClass: "d-center" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(option.name) +
                                        "\n                            "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "selected d-center" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(option.name) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          88741860
                        ),
                        model: {
                          value: _vm.queryStringData.search.organizationId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryStringData.search,
                              "organizationId",
                              $$v
                            )
                          },
                          expression: "queryStringData.search.organizationId",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "prepend" }, [
                        _vm._v("Οργανισμός:"),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "controls-area" }, [
          _c("div", { staticClass: "cell-12" }, [
            _c(
              "button",
              { staticClass: "button primary", on: { click: _vm.onSearch } },
              [_c("span", { staticClass: "mif-refresh mif-lg" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "button primary", on: { click: _vm.onAdd } },
              [_c("span", { staticClass: "mif-add mif-lg" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "button primary", on: { click: _vm.onClear } },
              [_c("span", { staticClass: "mif-cross-light mif-lg" })]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "table-area" }, [
          _c("div", { staticClass: "cell-12" }, [
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("vuetable-pagination", {
                  ref: "pagination",
                  staticStyle: { margin: "0px auto" },
                  attrs: { css: _vm.paginationCss },
                  on: { "vuetable-pagination:change-page": _vm.onChangePage },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.initialized
            ? _c(
                "div",
                { staticClass: "cell-12" },
                [
                  _vm.dataLoading
                    ? _c("div", {
                        attrs: {
                          "data-role": "progress",
                          "data-type": "line",
                          "data-small": "true",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("vuetable", {
                    ref: "vuetable",
                    class: { loading: _vm.dataLoading },
                    attrs: {
                      css: _vm.tableCss,
                      "api-url": _vm.apiURL,
                      "http-fetch": _vm.getData,
                      "http-method": "post",
                      "load-on-start": false,
                      fields: _vm.columns,
                      "sort-order": _vm.queryStringData.sort,
                      "per-page": _vm.dataPerPage,
                      "row-class": _vm.onRowClass,
                      "data-path": "payload.items",
                      "pagination-path": "payload.pagination",
                    },
                    on: {
                      "vuetable:loading": _vm.loading,
                      "vuetable:loaded": _vm.loaded,
                      "vuetable:load-success": _vm.loadSuccess,
                      "vuetable:load-error": _vm.loadError,
                      "vuetable:pagination-data": _vm.onPaginationData,
                      "VuetableRowAction:action-item": _vm.onRowAction,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }