var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-list", {
        ref: "dataList",
        attrs: { settings: _vm.listSettings },
        on: {
          onFilterSpecial: _vm.onFilterSpecial,
          onAddScores: _vm.onAddScores,
          onEditScores: _vm.onEditScores,
        },
      }),
      _vm._v(" "),
      _vm.newScore && _vm.showNewScore
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                height: "100%",
                position: "fixed",
                top: "0px",
                left: "0px,bottom:0px",
                right: "0px",
                "background-color": "rgba(255,255,255,0.7)",
                "z-index": "800",
              },
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.onHideNewScore.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "scrollbar-style",
                  staticStyle: {
                    margin: "70px auto",
                    width: "300px",
                    height: "620px",
                    border: "1px solid #666",
                    "overflow-y": "auto",
                    "overflow-x": "hidden",
                    "background-color": "white",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex", padding: "10px" } },
                    [
                      _vm.newScore.edit
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                flex: "0 0 40px",
                                cursor: "pointer",
                              },
                              on: { click: _vm.onDeleteScores },
                            },
                            [
                              _c("span", {
                                staticClass: "mif-bin mif-2x fg-red",
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "1 1 auto",
                            "font-weight": "bold",
                            "font-size": "14px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v(_vm._s(_vm.newScore.name))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "0 0 30px",
                            "text-align": "right",
                            cursor: "pointer",
                          },
                          on: { click: _vm.onHideNewScore },
                        },
                        [_vm._v("X")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "row", staticStyle: { padding: "10px" } },
                      [
                        _c("div", { staticClass: "cell-full" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newScore.scores[0].date,
                                expression: "newScore.scores[0].date",
                              },
                            ],
                            attrs: {
                              id: "newScore-datepicker",
                              "data-role": "datepicker",
                              disabled: _vm.newScore.edit,
                            },
                            domProps: { value: _vm.newScore.scores[0].date },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newScore.scores[0],
                                  "date",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "cell-full",
                            staticStyle: {
                              "text-align": "center",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.newScore.info.rubrik.name) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.newScore.info.rubrik.rubrikParts,
                          function (sc, idx) {
                            return _c(
                              "div",
                              { key: idx, staticClass: "cell-full" },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      attrs: {
                                        "data-role": "accordion",
                                        "data-one-frame": "true",
                                        "data-show-active": "false",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "frame" }, [
                                        _c("div", { staticClass: "heading" }, [
                                          _vm._v(_vm._s(sc.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "content" },
                                          [
                                            _vm._l(
                                              sc.steps,
                                              function (stepIdx) {
                                                return _c(
                                                  "span",
                                                  { key: stepIdx },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.number",
                                                          value:
                                                            _vm.newScore
                                                              .scores[0].scores[
                                                              idx
                                                            ].score,
                                                          expression:
                                                            "newScore.scores[0].scores[idx].score",
                                                          modifiers: {
                                                            number: true,
                                                          },
                                                        },
                                                      ],
                                                      attrs: {
                                                        name: "radio" + sc.id,
                                                        type: "radio",
                                                        "data-role": "radio",
                                                        "data-caption": stepIdx,
                                                      },
                                                      domProps: {
                                                        value: stepIdx,
                                                        checked: _vm._q(
                                                          _vm.newScore.scores[0]
                                                            .scores[idx].score,
                                                          _vm._n(stepIdx)
                                                        ),
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            _vm.$set(
                                                              _vm.newScore
                                                                .scores[0]
                                                                .scores[idx],
                                                              "score",
                                                              _vm._n(stepIdx)
                                                            )
                                                          },
                                                          function ($event) {
                                                            return _vm.getStudentScore(
                                                              _vm.newScore
                                                                .scores[0],
                                                              _vm.newScore.info
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              _vm._l(
                                                sc.steps,
                                                function (stepDescriptionsIdx) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: stepDescriptionsIdx,
                                                      class: {
                                                        "selected-part":
                                                          stepDescriptionsIdx ==
                                                          _vm.newScore.scores[0]
                                                            .scores[idx].score,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            stepDescriptionsIdx
                                                          ) +
                                                          ". " +
                                                          _vm._s(
                                                            sc.stepDescriptions.split(
                                                              "|"
                                                            )[
                                                              stepDescriptionsIdx -
                                                                1
                                                            ]
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.number",
                                                    value:
                                                      _vm.newScore.scores[0]
                                                        .scores[idx].remarks,
                                                    expression:
                                                      "newScore.scores[0].scores[idx].remarks",
                                                    modifiers: { number: true },
                                                  },
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  "data-role": "input",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.newScore.scores[0]
                                                      .scores[idx].remarks,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.newScore.scores[0]
                                                        .scores[idx],
                                                      "remarks",
                                                      _vm._n(
                                                        $event.target.value
                                                      )
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "cell-full",
                          staticStyle: {
                            "text-align": "center",
                            "font-weight": "bold",
                          },
                          style: {
                            color: _vm.newScore.scores[0].finalScore.incomplete
                              ? "red"
                              : "black",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.newScore.scores[0].finalScore.value) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "cell-full" },
                        [
                          _c("small-bars", {
                            staticStyle: { margin: "0px auto" },
                            attrs: {
                              values: _vm.newScore.scores[0].scores.map(
                                function (x) {
                                  return x.score
                                }
                              ),
                              possibleValues: [1, 2, 3, 4, 5],
                              barAmount: 5,
                              height: "50",
                              width: "200",
                              colors: ["#f00", "#0f0", "#00f", "#ff0", "#0ff"],
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }