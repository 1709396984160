<template>
<div class="grid" :class="[css.tableClass]"> 
    <div class="row">
        <div v-for="(item, itemIndex) in tableData"  :key="itemIndex" :item-index="itemIndex"  :render="onRowChanged(item)" :class="[onRowClass(item, itemIndex),cardCellClass]" @dblclick="onRowDoubleClicked(item, $event)" @click="onRowClicked(item, $event)">
            <div class="card" :style="{opacity:item.disable?'0.6':1}">
                <div class="card-header" style="font-size:14px;padding:7px;background-color:#ccc;color:black">
                    {{item.sirname}} {{item.name}} <span style="font-weight:bold;color:red" v-show="item.keedy || item.sensitiveGroup || item.abilityIssues">(!)</span>
                </div>
                <div class="card-content p-2">
                    <template v-for="(field, fieldIndex) in tableFields">
                        <template v-if="field.visible">
                            <template v-if="isSpecialField(field.name)">
                            <div v-if="extractName(field.name) == '__sequence'" 
                                :key="fieldIndex"
                                :class="['vuetable-sequence', field.dataClass]"
                            >
                            <strong>{{field.title}}</strong>
                            <span v-html="renderSequence(itemIndex)"></span>
                            </div>
                            <div v-if="extractName(field.name) == '__handle'" 
                                :key="fieldIndex"
                                :class="['vuetable-handle', field.dataClass]"
                                v-html="renderIconTag(['handle-icon', css.handleIcon])"
                            ></div>
                            <div v-if="extractName(field.name) == '__checkbox'" 
                                :key="fieldIndex"
                                :class="['vuetable-checkboxes', field.dataClass]"
                            >
                                <input type="checkbox"
                                @change="toggleCheckbox(item, field.name, $event)"
                                :checked="rowSelected(item, field.name)">
                            </div>
                            <div v-if="extractName(field.name) == '__rubriks'" :key="fieldIndex+'|'+item.hash" :class="['vuetable-rubriks', field.dataClass]">
                                <div class="rubrik" v-for="(rs,idx) in item.rubrikScores" :key="idx">
                                  <div class="rubrik-header" :style="{'backgroundColor':getRubrikHeaderBackground(rs.scores.length,rs.max)}">
                                    {{rs.info.rubrik.name}}
                                    <span style="font-size:10px">({{rs.scores.length}} αξιολογήσεις)</span>
                                  </div>
                                  <div class="rubrik-scores" v-if="!rubrikPartsGroupView">
                                    <div class="rubrik-score" v-for="(s,idx2) in rs.scores" :key="idx+'_'+idx2">
                                      <div class="score-date">
                                        {{s.date | toDate}}
                                      </div>
                                      <div style="cursor:pointer" @click="onEditScores(rs.info,item.id,item.sirname+' '+item.name,rs,s,item)">
                                        <small-bars :key="'bars'+fieldIndex+'|'+item.hash"  :values="s.scores.map(x=>x.score)" :possibleValues="[1,2,3,4,5]" :barAmount="5" height="30" width="50" :colors="['#f00','#0f0','#00f','#ff0','#0ff']"></small-bars>
                                      </div>
                                      <div style="text-align:center;font-size:10px;font-weight:bold">
                                        <span :style="{'color':s.finalScore.incomplete?'red':'black'}">
                                          {{s.finalScore.value}}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="rubrik-score" style="flex:1 1 auto;align-self:center;cursor:pointer">
                                      <span class="mif-add mif-3x" style="color:#999" @click="onAddScores(rs.info,item.id,item.sirname+' '+item.name,rs,item)"></span>
                                    </div>
                                  </div>
                                  <div class="rubrik-scores" v-else>
                                    <div class="rubrik-score" v-for="(s,idx2) in rs.info.rubrik.rubrikParts" :key="idx+'_'+idx2">
                                      <div class="score-date">
                                        K{{s.code}}
                                      </div>
                                      <div style="cursor:pointer">
                                        <apexchart width="120" type="donut" :options="options" :series="getPartScorePercentage(rs.scores,s)"></apexchart>
                                        <!-- <small-bars :key="'sb_'+s.id+'_'+item.hash" :values="getPartFromDates(s.id,rs.scores)" :possibleValues="[1,2,3,4,5]" :barAmount="getPartFromDates(s.id,rs.scores).length" height="30" width="100" borders="1px solid #999" colors="rgb(194 194 228)"></small-bars> -->
                                      </div>
                                      <div style="text-align:center;font-size:10px;font-weight:bold">
                                        <span>
                                          {{getPartFromDates(s.id,rs.scores).length}} αξιολογήσεις
                                        </span>
                                      </div>
                                    </div>
                                    <div class="rubrik-score" style="flex:1 1 auto;align-self:center;cursor:pointer">
                                      <span class="mif-add mif-3x" style="color:#999" @click="onAddScores(rs.info,item.id,item.sirname+' '+item.name,rs,item)"></span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <!-- <div v-if="extractName(field.name) === '__component'" 
                                :key="fieldIndex"
                                :class="['vuetable-component', field.dataClass]"
                            >
                                <component :is="extractArgs(field.name)"
                                :row-data="item" :row-index="itemIndex" :row-field="field.sortField"
                                ></component>
                            </div> -->
                            <!-- <div v-if="extractName(field.name) === '__slot'" 
                                :key="fieldIndex"
                                :class="['vuetable-slot', field.dataClass]"
                            >
                                <slot :name="extractArgs(field.name)"
                                :row-data="item" :row-index="itemIndex" :row-field="field.sortField"
                                ></slot>
                            </div> -->
                            </template>
                            <template v-else>
                                <div v-if="hasCallback(field) && field.showInRubrikView" 
                                    :key="fieldIndex"
                                    :class="field.dataClass"
                                    @click="onCellClicked(item, field, $event)"
                                    @dblclick="onCellDoubleClicked(item, field, $event)"
                                    @contextmenu="onCellRightClicked(item, field, $event)"
                                >
                                    <strong>{{field.title}}:</strong>
                                    <span v-html="callCallback(field, item)"></span>
                                </div>
                                <div v-if="!hasCallback(field) && field.showInRubrikView"  
                                    :key="fieldIndex"
                                    :class="field.dataClass"
                                    @click="onCellClicked(item, field, $event)"
                                    @dblclick="onCellDoubleClicked(item, field, $event)"
                                    @contextmenu="onCellRightClicked(item, field, $event)"
                                >
                                    <strong>{{field.title}}:</strong>
                                    <span v-html="getObjectValue(item, field.name)"></span>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>
                <div class="card-footer" style="display:block">
                   <div style="width:100%;display:flex" v-if="absentLikeModeVisible!=itemIndex"  :key="'f'+itemIndex">
                      <div style="display:relative;flex:1 1 auto">
                        <button class="button" @click="onAbsent(item,itemIndex)">ΛΕΙΠΕΙ</button>
                        <div class="like-dislike-absent" v-if="item.absent">
                          {{item.absent?item.absent:'-'}}
                        </div>
                      </div>
                      <div style="display:relative;flex:1 1 auto">
                        <button class="button" @click="onComments(item,itemIndex)">ΣΧΟΛΙΑ</button>
                      </div>
                      <div style="display:relative;flex:0 0 40px">
                        <button class="button" @click="onLike(item,true,itemIndex)"><span class="mif-thumbs-up"></span></button>
                        <div class="like-dislike-absent" v-if="item.likes">
                          {{item.likes?item.likes:'-'}}
                        </div>
                      </div>
                      <div style="display:relative;flex:0 0 40px">
                        <button class="button" @click="onLike(item,false,itemIndex)"><span class="mif-thumbs-down"></span></button> 
                        <div class="like-dislike-absent" v-if="item.dislikes">
                          {{item.dislikes?item.dislikes:'-'}}
                        </div>
                      </div>
                   </div>
                   <div style="width:100%;margin-top:10px;" v-if="absentLikeModeVisible==itemIndex"  :key="'fg'+itemIndex">
                     <div>
                       <date-picker v-model="absentLikeDate" valueType="date"></date-picker>
                     </div>
                     <div style="margin-top:10px" v-if="absentLikeMode.mode=='ABSENT'">
                       <input type="checkbox" data-role="checkbox" data-caption="Απών(ούσα)" v-model="isAbsent"> <br/>
                       
                     </div>
                     <div style="margin-top:10px" v-if="absentLikeMode.mode=='LIKE'">
                       {{absentLikeMode.like?'Καλή διαγωγή':'Απρεπής διαγωγή'}}
                     </div>
                     <div style="margin-top:10px;width:100%">
                       <textarea data-role="textarea" style="width:100%" v-model="absentLikeMode.remarks"></textarea>
                     </div>
                     <div style="margin-top:10px;">
                        <button class="button primary" @click="onSaveAbsentLike(item)"><span class="mif-checkmark mif-lg"></span></button>
                        <button class="button primary" @click="onCancelAbsentLike()"><span class="mif-cancel"></span></button>
                     </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import _ from 'underscore'
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/el';

import smallBars from '../smallBars.vue';
import settings from '../../../settings.js';
import globals from '../../../globals.js';

export default {
  components:{
    smallBars,
    DatePicker
  },
  filters:{
    toDate(v){
      if (!v) return "-";
      return moment(v).format('DD/MM/YYYY');
    }
  },
  props: {
    rubrikPartsGroupView:undefined,
    onAfterDataLoaded:undefined,
    fields: {
      type: Array,
      required: true
    },
    loadOnStart: {
      type: Boolean,
      default: true
    },
    apiUrl: {
        type: String,
        default: ''
    },
    httpMethod: {
        type: String,
        default: 'get',
        validator: (value) => {
          return ['get', 'post'].indexOf(value) > -1
        }
    },
    reactiveApiUrl: {
        type: Boolean,
        default: true
    },
    apiMode: {
      type: Boolean,
      default: true
    },
    data: {
      type: [Array, Object],
      default: null
    },
    dataTotal: {
      type: Number,
      default: 0
    },
    dataManager: {
      type: Function,
      default: null
    },
    dataPath: {
        type: String,
        default: 'data'
    },
    paginationPath: {
        type: [String],
        default: 'links.pagination'
    },
    queryParams: {
      type: [Object, Function],
      default () {
        return {
          sort: 'sort',
          page: 'page',
          perPage: 'per_page'
        }
      }
    },
    appendParams: {
      type: Object,
      default () {
        return {}
      }
    },
    httpOptions: {
      type: Object,
      default () {
        return {}
      }
    },
    httpFetch: {
      type: Function,
      default: null
    },
    perPage: {
        type: Number,
        default: 10
    },
    initialPage: {
      type: Number,
      default: 1
    },
    sortOrder: {
      type: Array,
      default () {
        return []
      }
    },
    multiSort: {
      type: Boolean,
      default () {
        return false
      }
    },
    tableHeight: {
      type: String,
      default: null
    },
    /*
     * physical key that will trigger multi-sort option
     * possible values: 'alt', 'ctrl', 'meta', 'shift'
     * 'ctrl' might not work as expected on Mac
     */
    multiSortKey: {
      type: String,
      default: 'alt'
    },
    /* deprecated */
    rowClassCallback: {
      type: [String, Function],
      default: ''
    },
    rowClass: {
      type: [String, Function],
      default: ''
    },
    detailRowComponent: {
      type: String,
      default: ''
    },
    detailRowTransition: {
      type: String,
      default: ''
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    cardCellClass:{
        type:String,
        default:'cell-lg-3 cell-md-4 cell-sm-12'
    },
    css: {
      type: Object,
      default () {
        return {
          tableClass: 'ui blue selectable celled stackable attached table',
          loadingClass: 'loading',
          ascendingIcon: 'blue chevron up icon',
          descendingIcon: 'blue chevron down icon',
          ascendingClass: 'sorted-asc',
          descendingClass: 'sorted-desc',
          sortableIcon: '',
          detailRowClass: 'vuetable-detail-row',
          handleIcon: 'grey sidebar icon',
          tableBodyClass: 'vuetable-semantic-no-top vuetable-fixed-layout',
          tableHeaderClass: 'vuetable-fixed-layout'
        }
      }
    },
    minRows: {
      type: Number,
      default: 0
    },
    silent: {
      type: Boolean,
      default: false
    },
    noDataTemplate: {
      type: String,
      default() {
        return 'No Data Available'
      }
    },
    showSortIcons: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      remarks:'',
      absentLikeDate:new Date(),
      isAbsent:false,
      absentLikeMode:undefined,
      eventPrefix: 'vuetable:',
      tableFields: [],
      tableData: null,
      tablePagination: null,
      currentPage: this.initialPage,
      selectedTo: [],
      visibleDetailRows: [],
      lastScrollPosition: 0,
      scrollBarWidth: '17px', //chrome default
      scrollVisible: false,
      absentLikeModeVisible:undefined,
      options: {
        fill: {
          colors: ['#ff0000', '#990000', '#ffff00','#009900','#00ff00']
        },
        dataLabels:{
          enabled:false
        },
        legend:{
          show:false
        },
      }
    }
  },
  mounted () {
    this.normalizeFields()
    this.normalizeSortOrder()
    if (this.isFixedHeader) {
      this.scrollBarWidth = this.getScrollBarWidth() + 'px';
    }
    this.$nextTick(function() {
      this.fireEvent('initialized', this.tableFields)
    })

    if (this.loadOnStart) {
      this.loadData()
    }
    if (this.isFixedHeader) {
      let elem = this.$el.getElementsByClassName('vuetable-body-wrapper')[0];
      if (elem != null) {
        elem.addEventListener('scroll', this.handleScroll);
      }
    }

    
  },
  destroyed () {
    let elem = this.$el.getElementsByClassName('vuetable-body-wrapper')[0];
    if (elem != null) {
      elem.removeEventListener('scroll', this.handleScroll);
    }
  },
  computed: {
    version: () => VERSION,
    useDetailRow () {
      if (this.tableData && this.tableData[0] && this.detailRowComponent !== '' && typeof this.tableData[0][this.trackBy] === 'undefined') {
        this.warn('You need to define unique row identifier in order for detail-row feature to work. Use `track-by` prop to define one!')
        return false
      }

      return this.detailRowComponent !== ''
    },
    countVisibleFields () {
      return this.tableFields.filter(function(field) {
        return field.visible
      }).length
    },
    countTableData () {
      if (this.tableData === null) {
        return 0
      }
      return this.tableData.length
    },
    displayEmptyDataRow () {
      return this.countTableData === 0 && this.noDataTemplate.length > 0
    },
    lessThanMinRows () {
      if (this.tableData === null || this.tableData.length === 0) {
        return true
      }
      return this.tableData.length < this.minRows
    },
    blankRows () {
      if (this.tableData === null || this.tableData.length === 0) {
        return this.minRows
      }
      if (this.tableData.length >= this.minRows) {
        return 0
      }

      return this.minRows - this.tableData.length
    },
    isApiMode () {
      return this.apiMode
    },
    isDataMode () {
      return ! this.apiMode
    },
    isFixedHeader () {
      return this.tableHeight != null
    }
  },
  methods: {
    getPartFromDates(partId,scoresPerDate){
      let r=[];
      scoresPerDate.forEach((sd)=>{
        let partScore=_.findWhere(sd.scores,{rubrikPartId:partId});
        if (partScore && partScore.score!=undefined  && partScore.score!=0)
          r.push(partScore.score);
      });
      return r;
    },
    getPartScorePercentage(scoresByDay,rp){
      let ret=[];
      for (let idx=0;idx<rp.steps;idx++){
        let amount=0;
        let total=0;
        for (let s of scoresByDay){
            let score=Math.round(this.getPartScore(s.scores,rp.id,true));
            if (!isNaN(score)) {
                if (score==(idx+1)) amount++;
                total++;
            }
        }
        if (total==0){}
        else{
          ret.push(Math.round((amount/total)*100))
        }
      }
      return ret;
    },
    getPartScore(scores,rpId,onlyValue){
        let score=_.where(scores,{rubrikPartId:rpId});
        if(!score || score.length==0) return onlyValue?undefined:"-";
        if (score.length==1)
            return score[0].score;
        if (score.length>1){
            let mean=0;
            score.forEach((s)=>{
                mean+=s.score;
            });
            mean=(mean/score.length).toFixed(1);
            return onlyValue?parseFloat(mean):mean+`(${score.length})`;
        }
    },
    onLike(item,like,idx){
      this.absentLikeDate=new Date();
      this.absentLikeMode={
        item,
        mode:'LIKE',
        like,
        remarks:''
      },
      this.absentLikeModeVisible=idx;
    },
    async onSaveAbsentLike(item){
      item.rubrikScores.forEach(async (rs)=>{
          let rubrik=rs.info.rubrik;
          let r=await axios.post(`${settings.serviceUrl}studentday/getMany`, {tokenValue:globals.getToken(),item:{studentId:item.id,date:this.absentLikeDate,courseId:rubrik.courseId,courseSectionId:rubrik.courseSectionId}});
          let data=r.data.payload.items;
          if (!data || data.length==0){
            r=await axios.post(`${settings.serviceUrl}studentday/add`, {tokenValue:globals.getToken(),
              item:{
                studentId:item.id,
                date:this.absentLikeDate,
                courseId:rubrik.courseId,
                courseSectionId:rubrik.courseSectionId,
                like:this.absentLikeMode.mode=='LIKE'?(this.absentLikeMode.like?1:0):null,
                absent:this.absentLikeMode.mode=='ABSENT'?(this.isAbsent?1:0):null,
                remarks:this.absentLikeMode.remarks
              }
            });
            if (this.absentLikeMode.mode=='ABSENT'){
              if (!this.isAbsent){
                item.absent--;
                item.disable=false;
              }
              if (this.isAbsent){
                item.absent++;
                item.disable=true;
                r=await axios.post(`${settings.serviceUrl}send-email`, {
                  tokenValue:globals.getToken(),
                  fromName:item.organizationName,
                  to:item.emailOfficial,
                  subject:`Απουσία μαθητή/τριας ${item.sirname} ${item.name}`,
                  content:`Σας ενημερώνουμε ότι ο μαθητής/μαθήτρια ${item.sirname} ${item.name} σήμερα στις ${moment(this.absentLikeDate).format('DD/MM/YYYY')} απουσίαζε από το μάθημα ${rubrik.courseName}`
                });
              }
            }
            if (this.absentLikeMode.mode=='LIKE'){
              if (this.absentLikeMode.like){
                item.likes++;
              }
              if (!this.absentLikeMode.like){
                item.dislikes++;
              }
            }
            this.absentLikeModeVisible=undefined;
            this.absentLikeMode=undefined;
          }
          else{
            let existing=data[0];
            if (this.absentLikeMode.mode=='ABSENT'){
              if (existing.absent==1 && !this.isAbsent){
                item.absent--;
                item.disable=false;
              }
              if (existing.absent==0 && this.isAbsent){
                item.absent++;
                item.disable=true;
                r=await axios.post(`${settings.serviceUrl}send-email`, {
                  tokenValue:globals.getToken(),
                  fromName:item.organizationName,
                  to:item.emailOfficial,
                  subject:`Απουσία μαθητή/τριας ${item.sirname} ${item.name}`,
                  content:`Σας ενημερώνουμε ότι ο μαθητής/μαθήτρια ${item.sirname} ${item.name} σήμερα στις ${moment(this.absentLikeDate).format('DD/MM/YYYY')} απουσίαζε από το μάθημα ${rubrik.courseName}`
                });
              }
              existing.absent=this.isAbsent?1:0;
            }
            if (this.absentLikeMode.mode=='LIKE'){
              if (existing.like==0 && this.absentLikeMode.like){
                item.likes++;
                item.dislikes--;
              }
              if (existing.like==null && this.absentLikeMode.like){
                item.likes++;
              }
              if (existing.like==1 && !this.absentLikeMode.like){
                item.likes--;
                item.dislikes++;
              }
              if (existing.like==null && !this.absentLikeMode.like){
                item.dislikes++;
              }

              existing.like=this.absentLikeMode.like?1:0;
            }
            existing.remarks=this.absentLikeMode.remarks;
            r=await axios.post(`${settings.serviceUrl}studentday/update`, {tokenValue:globals.getToken(),item:existing});
            this.absentLikeModeVisible=undefined;
            this.absentLikeMode=undefined;
          }
      });
    },
    onCancelAbsentLike(){
      this.absentLikeMode=undefined;
      this.absentLikeModeVisible=undefined;
    },
    onAbsent(item,idx){
      this.absentLikeDate=new Date();
      this.absentLikeMode={
        item,
        mode:'ABSENT',
        remarks:''
      }
      this.absentLikeModeVisible=idx;
    },
    onComments(item,idx){
      this.absentLikeDate=new Date();
      this.absentLikeMode={
        item,
        mode:'COMMENTS',
        remarks:''
      }
      this.absentLikeModeVisible=idx;
    },
    getRubrikHeaderBackground(value,max){
      if (max==0) return undefined;
      let step=max/5;
      let category=Math.ceil(value/step);
      let colorStep=255/5;
      return `rgb(${255-category*colorStep},0,0,0.75)`
    },
    onAddScores(info,studentId,studentName,list,item){
      this.$emit('onAddScores',info,studentId,studentName,list,item);
    },
    onEditScores(info,studentId,studentName,list,s,item){
      this.$emit('onEditScores',info,studentId,studentName,list,s,item);
    },
    getComponentFields(tableFields){
        let that=this;
        return _.filter(tableFields,(field)=>{return that.extractName(field.name) === '__component' })
    },
    getScrollBarWidth () {
      const outer = document.createElement('div');
      const inner = document.createElement('div');

      outer.style.visibility = 'hidden';
      outer.style.width = '100px';

      inner.style.width = '100%';


      outer.appendChild(inner);
      document.body.appendChild(outer);


      const widthWithoutScrollbar = outer.offsetWidth;

      outer.style.overflow = 'scroll';

      const widthWithScrollbar = inner.offsetWidth;


      document.body.removeChild(outer);


      return (widthWithoutScrollbar - widthWithScrollbar);
    },
    handleScroll (e) { //make sure that the header and the body are aligned when scrolling horizontally on a table that is wider than the viewport
      let horizontal = e.currentTarget.scrollLeft;
      if (horizontal != this.lastScrollPosition) { //don't modify header scroll if we are scrolling vertically
        let header = this.$el.getElementsByClassName('vuetable-head-wrapper')[0]
        if (header != null) {
          header.scrollLeft = horizontal;
        }
        this.lastScrollPosition = horizontal;
      }

    },
    normalizeFields () {
      if (typeof(this.fields) === 'undefined') {
        this.warn('You need to provide "fields" prop.')
        return
      }

      this.tableFields = []
      let self = this
      let obj
      this.fields.forEach(function(field, i) {
        if (typeof (field) === 'string') {
          obj = {
            name: field,
            title: self.setTitle(field),
            titleClass: '',
            dataClass: '',
            callback: null,
            visible: true,
            isCardHeader:false
          }
        } else {
          obj = {
            name: field.name,
            width: field.width,
            title: (field.title === undefined) ? self.setTitle(field.name) : field.title,
            sortField: field.sortField,
            titleClass: (field.titleClass === undefined) ? '' : field.titleClass,
            dataClass: (field.dataClass === undefined) ? '' : field.dataClass,
            callback: (field.callback === undefined) ? '' : field.callback,
            visible: (field.visible === undefined) ? true : field.visible,
            isCardHeader:(field.isCardHeader === undefined) ? false : field.isCardHeader,
          }
        }
        self.tableFields.push(obj)
      })
    },
    setData (data) {
      if (data === null || typeof(data) === 'undefined') return

      this.fireEvent('loading')

      if (Array.isArray(data)) {
        this.tableData = data
        this.fireEvent('loaded')
        return
      }

      this.tableData = this.getObjectValue(data, this.dataPath, null)
      this.tablePagination = this.getObjectValue(data, this.paginationPath, null)

      this.$nextTick(function() {
        this.fixHeader()
        this.fireEvent('pagination-data', this.tablePagination)
        this.fireEvent('loaded')
      })
    },
    setTitle (str) {
      if (this.isSpecialField(str)) {
        return ''
      }

      return this.titleCase(str)
    },
    getTitle (field) {
      if (typeof(field.title) === 'function') return field.title()

      return typeof(field.title) === 'undefined'
        ? field.name.replace('.', ' ')
        : field.title
    },
    renderTitle (field) {
      let title = this.getTitle(field)

      if (title.length > 0 && this.isInCurrentSortGroup(field) || this.hasSortableIcon(field)) {
        let style = `opacity:${this.sortIconOpacity(field)};position:relative;float:right`
        let iconTag = this.showSortIcons ? this.renderIconTag(['sort-icon', this.sortIcon(field)], `style="${style}"`) : ''
        return title + ' ' + iconTag
      }

      return title
    },
    renderSequence (index) {
      return this.tablePagination
        ? this.tablePagination.from + index
        : index
    },
    renderNormalField (field, item) {
      return this.hasCallback(field)
        ? this.callCallback(field, item)
        : this.getObjectValue(item, field.name, '')
    },
    isSpecialField (fieldName) {
      return fieldName.slice(0, 2) === '__'
    },
    titleCase (str) {
      return str.replace(/\w+/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },
    camelCase (str, delimiter = '_') {
      let self = this
      return str.split(delimiter).map(function(item) {
        return self.titleCase(item)
      }).join('')
    },
    notIn (str, arr) {
      return arr.indexOf(str) === -1
    },
    loadData (success = this.loadSuccess, failed = this.loadFailed) {
      if (this.isDataMode) {
        this.callDataManager()
        return
      }

      this.fireEvent('loading')

      this.httpOptions['params'] = this.getAppendParams( this.getAllQueryParams() )

      return this.fetch(this.apiUrl, this.httpOptions).then(
          success,
          failed
      ).catch(() => failed())
    },
    fetch (apiUrl, httpOptions) {
      return this.httpFetch
          ? this.httpFetch(apiUrl, httpOptions)
          : axios[this.httpMethod](apiUrl, httpOptions)
    },
    async loadSuccess (response) {
      this.fireEvent('load-success', response);
      let body = this.transform(response.data)
      let items=this.getObjectValue(body, this.dataPath, null);
      if (this.onAfterDataLoaded)
        items=await this.onAfterDataLoaded(items);

      

      this.tableData = items;
      this.tablePagination = this.getObjectValue(body, this.paginationPath, null)

      if (this.tablePagination === null) {
        this.warn('vuetable: pagination-path "' + this.paginationPath + '" not found. '
          + 'It looks like the data returned from the sever does not have pagination information '
          + "or you may have set it incorrectly.\n"
          + 'You can explicitly suppress this warning by setting pagination-path="".'
        )
      }

      this.$nextTick(function() {
        this.fixHeader()
        this.fireEvent('pagination-data', this.tablePagination)
        this.fireEvent('loaded')
      })
    },
    fixHeader() {
      if (!this.isFixedHeader) {
        return;
      }

      let elem = this.$el.getElementsByClassName('vuetable-body-wrapper')[0]
      if (elem != null) {
        if (elem.scrollHeight > elem.clientHeight) {
          this.scrollVisible = true;
        }
        else {
          this.scrollVisible = false;
        }
      }
    },
    loadFailed (response) {
      console.error('load-error', response)
      this.fireEvent('load-error', response)
      this.fireEvent('loaded')
    },
    transform (data) {
      let func = 'transform'

      if (this.parentFunctionExists(func)) {
          return this.$parent[func].call(this.$parent, data)
      }

      return data
    },
    parentFunctionExists (func) {
      return (func !== '' && typeof this.$parent[func] === 'function')
    },
    callParentFunction (func, args, defaultValue = null) {
      if (this.parentFunctionExists(func)) {
        return this.$parent[func].call(this.$parent, args)
      }

      return defaultValue
    },
    fireEvent (eventName, args) {
      this.$emit(this.eventPrefix + eventName, args)
    },
    warn (msg) {
      if (!this.silent) {
        console.warn(msg)
      }
    },
    getAllQueryParams () {
      let params = {}

      if (typeof(this.queryParams) === 'function') {
        params = this.queryParams(this.sortOrder, this.currentPage, this.perPage)
        return typeof(params) !== 'object' ? {} : params
      }

      params[this.queryParams.sort] = this.getSortParam()
      params[this.queryParams.page] = this.currentPage
      params[this.queryParams.perPage] = this.perPage

      return params
    },
    getSortParam () {
      if (!this.sortOrder || this.sortOrder.field == '') {
        return ''
      }

      if (typeof this.$parent['getSortParam'] === 'function') {
        return this.$parent['getSortParam'].call(this.$parent, this.sortOrder)
      }

      return this.getDefaultSortParam()
    },
    getDefaultSortParam () {
      let result = '';

      for (let i = 0; i < this.sortOrder.length; i++) {
        let fieldName = (typeof this.sortOrder[i].sortField === 'undefined')
          ? this.sortOrder[i].field
          : this.sortOrder[i].sortField;

        result += fieldName + '|' + this.sortOrder[i].direction + ((i+1) < this.sortOrder.length ? ',' : '');
      }
      return result;
    },
    getAppendParams (params) {
      for (let x in this.appendParams) {
        params[x] = this.appendParams[x]
      }

      return params
    },
    extractName (string) {
      return string.split(':')[0].trim()
    },
    extractArgs (string) {
      return string.split(':')[1]
    },
    isSortable (field) {
      return !(typeof field.sortField === 'undefined')
    },
    isInCurrentSortGroup (field) {
      return this.currentSortOrderPosition(field) !== false;
    },
    hasSortableIcon (field) {
      return this.isSortable(field) && this.css.sortableIcon != ''
    },
    currentSortOrderPosition (field) {
      if ( ! this.isSortable(field)) {
        return false
      }

      for (let i = 0; i < this.sortOrder.length; i++) {
        if (this.fieldIsInSortOrderPosition(field, i)) {
          return i;
        }
      }

      return false;
    },
    fieldIsInSortOrderPosition (field, i) {
      return this.sortOrder[i].field === field.name && this.sortOrder[i].sortField === field.sortField
    },
    orderBy (field, event) {
      if ( ! this.isSortable(field) ) return

      let key = this.multiSortKey.toLowerCase() + 'Key'

      if (this.multiSort && event[key]) { //adding column to multisort
        this.multiColumnSort(field)
      } else {
        //no multisort, or resetting sort
        this.singleColumnSort(field)
      }

      this.currentPage = 1    // reset page index
      if (this.apiMode || this.dataManager) {
        this.loadData()
      }
    },
    multiColumnSort (field) {
      let i = this.currentSortOrderPosition(field);

      if(i === false) { //this field is not in the sort array yet
        this.sortOrder.push({
          field: field.name,
          sortField: field.sortField,
          direction: 'asc'
        });
      } else { //this field is in the sort array, now we change its state
        if(this.sortOrder[i].direction === 'asc') {
          // switch direction
          this.sortOrder[i].direction = 'desc'
        } else {
          //remove sort condition
          this.sortOrder.splice(i, 1);
        }
      }
    },
    singleColumnSort (field) {
      if (this.sortOrder.length === 0) {
        this.clearSortOrder()
      }

      this.sortOrder.splice(1); //removes additional columns

      if (this.fieldIsInSortOrderPosition(field, 0)) {
        // change sort direction
        this.sortOrder[0].direction = this.sortOrder[0].direction === 'asc' ? 'desc' : 'asc'
      } else {
        // reset sort direction
        this.sortOrder[0].direction = 'asc'
      }
      this.sortOrder[0].field = field.name
      this.sortOrder[0].sortField = field.sortField
    },
    clearSortOrder () {
      this.sortOrder.push({
        field: '',
        sortField: '',
        direction: 'asc'
      });
    },
    sortClass (field) {
      let cls = ''
      let i = this.currentSortOrderPosition(field)

      if (i !== false) {
        cls = (this.sortOrder[i].direction == 'asc') ? this.css.ascendingClass : this.css.descendingClass
      }

      return cls
    },
    sortIcon (field) {
      let cls = this.css.sortableIcon
      let i = this.currentSortOrderPosition(field)

      if (i !== false) {
        cls = (this.sortOrder[i].direction == 'asc') ? this.css.ascendingIcon : this.css.descendingIcon
      }

      return cls;
    },
    sortIconOpacity (field) {
      /*
       * fields with stronger precedence have darker color
       *
       * if there are few fields, we go down by 0.3
       * ex. 2 fields are selected: 1.0, 0.7
       *
       * if there are more we go down evenly on the given spectrum
       * ex. 6 fields are selected: 1.0, 0.86, 0.72, 0.58, 0.44, 0.3
       */
      let max = 1.0,
          min = 0.3,
          step = 0.3

      let count = this.sortOrder.length;
      let current = this.currentSortOrderPosition(field)


      if(max - count * step < min) {
        step = (max - min) / (count-1)
      }

      let opacity = max - current * step

      return opacity
    },
    hasCallback (item) {
      return item.callback ? true : false
    },
    callCallback (field, item) {
      if ( ! this.hasCallback(field)) return

      if(typeof(field.callback) == 'function') {
       return field.callback(this.getObjectValue(item, field.name))
      }

      let args = field.callback.split('|')
      let func = args.shift()

      if (typeof this.$parent[func] === 'function') {
        let value = this.getObjectValue(item, field.name)

        return (args.length > 0)
          ? this.$parent[func].apply(this.$parent, [value].concat(args))
          : this.$parent[func].call(this.$parent, value)
      }

      return null
    },
    getHeaderFieldValue(obj,tableFields){
        let path=_.findWhere(tableFields,{isCardHeader:true});
        if (!path) return "isCardHeader is not defined for any column";
        path=path.name;
        if (path.trim() != '') {
        let keys = path.split('.')
        keys.forEach(function(key) {
          if (obj !== null && typeof obj[key] !== 'undefined' && obj[key] !== null) {
            obj = obj[key]
          } else {
            obj = '-';
            return
          }
        })
      }
      return obj

    },
    getObjectValue (object, path, defaultValue) {
      defaultValue = (typeof defaultValue === 'undefined') ? null : defaultValue

      let obj = object
      if (path.trim() != '') {
        let keys = path.split('.')
        keys.forEach(function(key) {
          if (obj !== null && typeof obj[key] !== 'undefined' && obj[key] !== null) {
            obj = obj[key]
          } else {
            obj = defaultValue
            return
          }
        })
      }
      return obj
    },
    toggleCheckbox (dataItem, fieldName, event) {
      let isChecked = event.target.checked
      let idColumn = this.trackBy

      if (dataItem[idColumn] === undefined) {
        this.warn('__checkbox field: The "'+this.trackBy+'" field does not exist! Make sure the field you specify in "track-by" prop does exist.')
        return
      }

      let key = dataItem[idColumn]
      if (isChecked) {
        this.selectId(key)
      } else {
        this.unselectId(key)
      }
      this.$emit('vuetable:checkbox-toggled', isChecked, dataItem)
    },
    selectId (key) {
      if ( ! this.isSelectedRow(key)) {
        this.selectedTo.push(key)
      }
    },
    unselectId (key) {
      this.selectedTo = this.selectedTo.filter(function(item) {
        return item !== key
      })
    },
    isSelectedRow (key) {
      return this.selectedTo.indexOf(key) >= 0
    },
    rowSelected (dataItem, fieldName){
      let idColumn = this.trackBy
      let key = dataItem[idColumn]

      return this.isSelectedRow(key)
    },
    checkCheckboxesState (fieldName) {
      if (! this.tableData) return

      let self = this
      let idColumn = this.trackBy
      let selector = 'th.vuetable-th-checkbox-' + idColumn + ' input[type=checkbox]'
      let els = document.querySelectorAll(selector)

      //fixed:document.querySelectorAll return the typeof nodeList not array
      if (els.forEach===undefined)
        els.forEach=function(cb){
          [].forEach.call(els, cb);
        }

      // count how many checkbox row in the current page has been checked
      let selected = this.tableData.filter(function(item) {
        return self.selectedTo.indexOf(item[idColumn]) >= 0
      })

      // count == 0, clear the checkbox
      if (selected.length <= 0) {
        els.forEach(function(el) {
          el.indeterminate = false
        })
        return false
      }
      // count > 0 and count < perPage, set checkbox state to 'indeterminate'
      else if (selected.length < this.perPage) {
        els.forEach(function(el) {
          el.indeterminate = true
        })
        return true
      }
      // count == perPage, set checkbox state to 'checked'
      else {
        els.forEach(function(el) {
          el.indeterminate = false
        })
        return true
      }
    },
    toggleAllCheckboxes (fieldName, event) {
      let self = this
      let isChecked = event.target.checked
      let idColumn = this.trackBy

      if (isChecked) {
        this.tableData.forEach(function(dataItem) {
          self.selectId(dataItem[idColumn])
        })
      } else {
        this.tableData.forEach(function(dataItem) {
          self.unselectId(dataItem[idColumn])
        })
      }
      this.$emit('vuetable:checkbox-toggled-all', isChecked)
    },
    gotoPreviousPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.loadData()
      }
    },
    gotoNextPage () {
      if (this.currentPage < this.tablePagination.last_page) {
        this.currentPage++
        this.loadData()
      }
    },
    gotoPage (page) {
      if (page != this.currentPage && (page > 0 && page <= this.tablePagination.last_page)) {
        this.currentPage = page
        this.loadData()
      }
    },
    isVisibleDetailRow (rowId) {
      return this.visibleDetailRows.indexOf( rowId ) >= 0
    },
    showDetailRow (rowId) {
      if (!this.isVisibleDetailRow(rowId)) {
        this.visibleDetailRows.push(rowId)
      }
    },
    hideDetailRow (rowId) {
      if (this.isVisibleDetailRow(rowId)) {
        this.visibleDetailRows.splice(
          this.visibleDetailRows.indexOf(rowId),
          1
        )
      }
    },
    toggleDetailRow (rowId) {
      if (this.isVisibleDetailRow(rowId)) {
        this.hideDetailRow(rowId)
      } else {
        this.showDetailRow(rowId)
      }
    },
    showField (index) {
      if (index < 0 || index > this.tableFields.length) return

      this.tableFields[index].visible = true
    },
    hideField (index) {
      if (index < 0 || index > this.tableFields.length) return

      this.tableFields[index].visible = false
    },
    toggleField (index) {
      if (index < 0 || index > this.tableFields.length) return

      this.tableFields[index].visible = ! this.tableFields[index].visible
    },
    renderIconTag (classes, options = '') {
      return typeof(this.css.renderIcon) === 'undefined'
        ? `<i class="${classes.join(' ')}" ${options}></i>`
        : this.css.renderIcon(classes, options)
    },
    makePagination (total = null, perPage = null, currentPage = null) {
      let pagination = {}
      total = total === null ? this.dataTotal : total
      perPage = perPage === null ? this.perPage : perPage
      currentPage = currentPage === null ? this.currentPage : currentPage

      return {
        'total': total,
        'per_page': perPage,
        'current_page': currentPage,
        'last_page': Math.ceil(total / perPage) || 0,
        'next_page_url': '',
        'prev_page_url': '',
        'from': (currentPage -1) * perPage +1,
        'to': Math.min(currentPage * perPage, total)
      }
    },
    normalizeSortOrder () {
      this.sortOrder.forEach(function(item) {
        item.sortField = item.sortField || item.field
      })
    },
    callDataManager () {
      if (this.dataManager === null && this.data === null) return

      if (Array.isArray(this.data)) {
        return this.setData(this.data)
      } 
      
      this.normalizeSortOrder()

      return this.setData(
        this.dataManager
          ? this.dataManager(this.sortOrder, this.makePagination())
          : this.data
      )
    },
    onRowClass (dataItem, index) {
      if (this.rowClassCallback !== '') {
        this.warn('"row-class-callback" prop is deprecated, please use "row-class" prop instead.')
        return
      }

      if (typeof(this.rowClass) === 'function') {
        return this.rowClass(dataItem, index)
      }

      return this.rowClass
    },
    onRowChanged (dataItem) {
      this.fireEvent('row-changed', dataItem)
      return true
    },
    onRowClicked (dataItem, event) {
      this.$emit(this.eventPrefix + 'row-clicked', dataItem, event)
      return true
    },
    onRowDoubleClicked (dataItem, event) {
      this.$emit(this.eventPrefix + 'row-dblclicked', dataItem, event)
    },
    onDetailRowClick (dataItem, event) {
      this.$emit(this.eventPrefix + 'detail-row-clicked', dataItem, event)
    },
    onCellClicked (dataItem, field, event) {
      this.$emit(this.eventPrefix + 'cell-clicked', dataItem, field, event)
    },
    onCellDoubleClicked (dataItem, field, event) {
      this.$emit(this.eventPrefix + 'cell-dblclicked', dataItem, field, event)
    },
    onCellRightClicked (dataItem, field, event) {
      this.$emit(this.eventPrefix + 'cell-rightclicked', dataItem, field, event)
    },
    /*
     * API for externals
     */
    changePage (page) {
      if (page === 'prev') {
        this.gotoPreviousPage()
      } else if (page === 'next') {
        this.gotoNextPage()
      } else {
        this.gotoPage(page)
      }
    },
    reload () {
      return this.loadData()
    },
    refresh () {
      this.currentPage = 1
      return this.loadData()
    },
    resetData () {
      this.tableData = null
      this.tablePagination = null
      this.fireEvent('data-reset')
    }
  }, // end: methods
  watch: {
    'multiSort' (newVal, oldVal) {
      if (newVal === false && this.sortOrder.length > 1) {
        this.sortOrder.splice(1);
        this.loadData();
      }
    },
    'apiUrl'  (newVal, oldVal) {
      if(this.reactiveApiUrl && newVal !== oldVal)
        this.refresh()
    },
    'data' (newVal, oldVal) {
      this.setData(newVal)
    },
    'tableHeight' (newVal, oldVal) {
      this.fixHeader()
    }
  },
}
</script>

<style lang="less" scoped>
  [v-cloak] {
    display: none;
  }
  .card{
    background-color: #fafafa;
  }
  .rubrik{
    margin-top:5px;
    .rubrik-header{
      margin-bottom:5px;
      margin-top:5px;
      text-align:center;
      background-color:#eaeaea;
      color:white;
      font-weight:bold;
      padding:5px;
    }
    .rubrik-scores{
      display:flex;
      flex-wrap: wrap;
    }
    .rubrik-score{
      
      margin:5px 0px;
      margin-right:5px;
    }
    .score-date{
      font-size:8px;
      text-align: center;
    }
  }
  .vuetable th.sortable:hover {
    color: #2185d0;
    cursor: pointer;
  }
  .vuetable-body-wrapper {
    position:relative;
    overflow-y:auto;
  }
  .vuetable-head-wrapper {
    overflow-x: hidden;
  }
  .vuetable-actions {
    width: 15%;
    padding: 12px 0px;
    text-align: center;
  }
  .vuetable-pagination {
    background: #f9fafb !important;
  }
  .vuetable-pagination-info {
    margin-top: auto;
    margin-bottom: auto;
  }
  .vuetable-empty-result {
    text-align: center;
  }
  .vuetable-clip-text {
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
  .vuetable-semantic-no-top {
    border-top:none !important;
    margin-top:0 !important;
  }
  .vuetable-fixed-layout {
    table-layout: fixed;
  }
  .vuetable-gutter-col {
    padding: 0 !important;
    border-left: none  !important;
    border-right: none  !important;
  }
  .like-dislike-absent{
    position: absolute;
    top: -14px;
    padding: 5px;
    left: -10px;
    background-color: red;
    border-radius: 50%;
    color: white;
    font-size: 10px;
    width: 24px;
    text-align: center;
  }
</style>
