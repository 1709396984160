<template>
  <div class="page home-page  scrollbar-style">
    <div class="widget-container scrollbar-style">
      <section class="widget-container-header">
          <input type="checkbox" data-role="checkbox" data-caption="Auto Arrange" v-model="widgetsAutoArrange">
          <div data-role="progress" data-type="line" data-small="true" v-if="loading"></div>
      </section>
      <section class="widget-container-widgets" :class="{'auto-arrange':widgetsAutoArrange}" v-if="user.roleId==0">
        <widget id="3" title="Active organizations" headerTextColor="#000">
          <google-map></google-map>
        </widget>
        <widget id="4" title="Status" headerTextColor="#000">
          <admin-server-summary></admin-server-summary>
        </widget>
      </section>
      <section class="widget-container-widgets" :class="{'auto-arrange':widgetsAutoArrange}" v-if="user.roleId==3 && !loading">
        <widget :id="`${data.course.id}_${data.course.team.id}_${data.course.team.departmentId}_bar`" :title="`${data.course.name} - ${data.course.team.name} - ${data.course.team.departmentName}`" v-for="(data,idx) in series3" :key="idx"  headerTextColor="rgb(107 107 107)" headerBackColor="#d5deff" :menuButtonVisible="false">
          <teacher-team-view :options="chart3Options[idx].chart3Options" :series="[data.series]"></teacher-team-view>
          <template v-slot:footer>
            <a target="_blank" :href="`/admin/students?search=%7CteamId,${data.course.team.id}%7CrubrikMode,true%7CcourseId,${data.course.id}%7CrubrikPartsGroupView,true&sort=&page=1`">Μάθημα</a>
          </template>
        </widget>
        <!-- <widget :id="`${c.id}_${c.team.id}_${c.team.departmentId}_absent`" :title="``" v-for="(c,idx) in courses" :key="idx"  headerTextColor="rgb(107 107 107)" headerBackColor="rgb(255 227 27)" bodyBackColor="rgb(255 227 27)" :menuButtonVisible="false">
          <number type="SQL" :label="`απουσίες <br/> ${c.name} - ${c.team.name} - ${c.team.departmentName}`" :sqlParams="[]" :sqlQuery="`SELECT COUNT(*) AS absent FROM studentday WHERE absent=1 AND courseId=${c.id} AND studentId IN (${c.students.map(x=>x.id).join(',')})`"></number>
        </widget>
        <widget :id="`${c.id}_${c.team.id}_${c.team.departmentId}_likes`" :title="``" v-for="(c,idx) in courses" :key="idx"  headerTextColor="rgb(151 221 255)" headerBackColor="rgb(151 221 255)" bodyBackColor="rgb(151 221 255)" :menuButtonVisible="false">
          <number type="SQL" :label="`θετικά <br/> ${c.name} - ${c.team.name} - ${c.team.departmentName}`" :sqlParams="[]" :sqlQuery="`SELECT COUNT(*) AS absent FROM studentday WHERE \`like\`=1 AND courseId=${c.id} AND studentId IN (${c.students.map(x=>x.id).join(',')})`"></number>
        </widget>
        <widget :id="`${c.id}_${c.team.id}_${c.team.departmentId}_dislikes`" :title="``" v-for="(c,idx) in courses" :key="idx"  headerTextColor="rgb(151 221 255)" headerBackColor="rgb(251 178 104)" bodyBackColor="rgb(251 178 104)" :menuButtonVisible="false">
          <number type="SQL" :label="`αρνητικά <br/> ${c.name} - ${c.team.name} - ${c.team.departmentName}`" :sqlParams="[]" :sqlQuery="`SELECT COUNT(*) AS absent FROM studentday WHERE \`like\`=0 AND courseId=${c.id} AND studentId IN (${c.students.map(x=>x.id).join(',')})`"></number>
        </widget> -->
        <widget headerTextColor="rgb(0 0 0)" headerBackColor="rgb(251 178 104)" title="ΣΥΓΚΕΝΤΡΩΤΙΚΑ">
          <teacher-summary :courses="courses"></teacher-summary>
        </widget>
      </section>
    </div>
  </div>
</template>

<style lang="less" scoped>
.home-page{
  padding-right:0px !important;
  padding-top:0px !important;
  padding-left:50px !important;
}
@media screen and (max-width:600px){
  .home-page{
    padding-left:0px !important;
    padding-right:0px !important;
  }
}
.widget-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  display:flex;
  flex-direction: column;
  .widget-container-header {
    height: 50px;
    width: 100%;
    padding: 10px 10px;
  }
  .widget-container-widgets {
    height: 100%;
    width:100%;
    .widget{
      position: absolute;
    }
    &.auto-arrange{
      display:flex;
      .widget{
        position:initial;
        margin:10px;
      }
    }

  }
}
</style>

<script>
import axios from 'axios';
import _ from 'underscore';
import globals from '../../globals.js';
import settings from '../../settings.js';

import widget from "../../components/admin/widget.vue";
import adminServerSummary from '../../components/admin/widgets/admin-server-summary.vue';
import adminServerSummaryMenu from '../../components/admin/widgets/admin-server-summary-menu.vue';
import teacherTeamView from '../../components/admin/widgets/teacher-team-view.vue';
import googleMap from '../../components/admin/widgets/googleMap.vue';
import number from '../../components/admin/widgets/number.vue';
import teacherSummary from '../../components/admin/widgets/teacher-summary.vue';

export default {
  components: {
    widget,
    googleMap,
    adminServerSummary,
    adminServerSummaryMenu,
    teacherTeamView,
    number,
    teacherSummary
  },
  data() {
    return {
      loading:true,
      courses:[],
      widgetsAutoArrange:false,
      widgets:[],
      rubriks:[],
      series3:[],
      chart3Options:[],
      chart3OptionsTemplate: {
          chart: {
              height: 350,
              type: 'radar',
          },
          title: {
          text: 'Γενική εικόνα'
          },
          xaxis: {
              categories: []
          },
          yaxis:{
            max:5,
            min:0,
            tickAmount:5  
          }
      },
    };
  },
  computed:{
    user(){
      return this.$store.state.user;
    }
  },
  mounted() {
    const action=async ()=>{
      if (this.user.roleId==3 || this.user.roleId==2){
        let r=await axios.post(`${settings.serviceUrl}sql`,{tokenValue:globals.getToken(),
          sqlQuery:'SELECT * FROM userstocourses WHERE userId=?',
          sqlParams:[this.user.info.id]
        });
        let usersTocourses=r.data.payload;
        if (usersTocourses.length>0){
          r=await axios.post(`${settings.serviceUrl}course/getMany`,{tokenValue:globals.getToken(),
            item:{
              courseIds:usersTocourses.map(x=>x.courseId)
            }
          });
          let courses=r.data.payload.items;
          r=await axios.post(`${settings.serviceUrl}team/getMany`,{tokenValue:globals.getToken(),
            item:{
              teamIds:usersTocourses.map(x=>x.teamId)
            }
          });
          let teams=r.data.payload.items;

          for (let utc of usersTocourses){
            let course=JSON.parse(JSON.stringify(_.findWhere(courses,{id:utc.courseId})));
            let team=JSON.parse(JSON.stringify(_.findWhere(teams,{id:utc.teamId})));
            let existingRubrik=_.findWhere(this.rubriks,{id:course.rubrikId});
            if (!existingRubrik){
                r=await axios.post(`${settings.serviceUrl}rubrik/get`, {tokenValue:globals.getToken(),id:course.rubrikId});
                existingRubrik=r.data.payload;
                r=await axios.post(`${settings.serviceUrl}rubrikPart/getMany`, {tokenValue:globals.getToken(),item:{rubrikId:existingRubrik.id}});
                existingRubrik.rubrikParts=r.data.payload.items;
                this.rubriks.push(existingRubrik);
            }
            course.rubrik=existingRubrik;
            r=await axios.post(`${settings.serviceUrl}rubrikScore/getMany`, {tokenValue:globals.getToken(),
              sort:[{field:'date',direction:'ASC'}],
              item:{courseId:course.id,rubrikId:course.rubrikId}
            });
            let r2=await axios.post(`${settings.serviceUrl}student/getMany`, {tokenValue:globals.getToken(),item:{teamId:team.id}});
            let teamStudentIds=r2.data.payload.items.map(x=>x.id);
            course.team=team;
            course.students=r2.data.payload.items;
            course.scoresRaw=_.filter(r.data.payload.items,(s)=>{return teamStudentIds.indexOf(s.studentId)!=-1});
            course.scoresByDate=[];
              let idx=-1;
              for (let sr of course.scoresRaw){
                  let existingDate=_.findWhere(course.scoresByDate,{date:sr.date});
                  if (!existingDate){
                      idx++;
                      existingDate={
                          idx,
                          date:sr.date,
                          scores:[]
                      }
                      course.scoresByDate.push(existingDate);
                  }
                  sr.rubrik=existingRubrik;
                  sr.rubrikPart=_.findWhere(existingRubrik.rubrikParts,{id:sr.rubrikPartId});
                  existingDate.scores.push(sr);
            }
            this.courses.push(course);
          }


          for (let course of this.courses){
            let rubrik=course.rubrik;
            let chart3Options=JSON.parse(JSON.stringify(this.chart3OptionsTemplate));
            let series={};
            series.name=rubrik.name;
            series.data=[];
            for (let rp of rubrik.rubrikParts){
                chart3Options.xaxis.categories.push(rp.name);
                series.data.push(this.getPartScoreMean(course.scoresByDate,rp.id));
            }

            this.series3.push({
                course,
                courseId:course.id,
                teamId:course.team.id,
                series
            })
            this.chart3Options.push({
                course,
                teamId:course.team.id,
                courseId:course.id,
                chart3Options
            })
          }
          this.loading=false;
          



        }
      }
      else
        this.loading=false;
    };
    action();
  },
  methods: {
    getPartScoreMean(scoresByDay,rpId){
        if (!scoresByDay) return 0;
        let amount=0;
        let count=0;
        for (let s of scoresByDay){
            let score=this.getPartScore(s.scores,rpId,true);
            if (score!=undefined) {
                amount+=score;
                count++;
            }
        }
        if (count==0) return 0;
        return Math.round(amount/count);
    },
    getPartScore(scores,rpId,onlyValue){
        let score=_.where(scores,{rubrikPartId:rpId});
        if(!score || score.length==0) return onlyValue?undefined:"-";
        if (score.length==1)
            return score[0].score;
        if (score.length>1){
            let mean=0;
            score.forEach((s)=>{
                mean+=s.score;
            });
            mean=(mean/score.length).toFixed(1);
            return onlyValue?parseFloat(mean):mean+`(${score.length})`;
        }
    },
  }
};
</script>