<template>
  <section :id="'widget__'+id" class="widget disableSelection">
    <section class="widget-header" :style="{'background-color':headerBackColor?headerBackColor:'transparent'}">
      <section class="widget-header-title" :style="{color:headerTextColor?headerTextColor:''}">{{title}}</section>
      <section class="widget-header-control">
        <span class="mif-enlarge2 mif-2x" @mousedown="onStartResize" style="margin-right:10px"></span>
        <span class="mif-enlarge mif-2x" @mousedown="onStartMove" style="margin-right:10px"></span>
        <span v-if="menuButtonVisible" class="mif-equalizer mif-2x" @click.stop="toggleMenu()" style="margin-right:10px"></span>
        <section class="widget-menu" v-if="menuVisible">
          <slot name="menu">
            Menu
          </slot>
        </section>
      </section>
    </section>
    <section class="widget-content" :style="{'background-color':bodyBackColor?bodyBackColor:'#fff'}">
      <slot>
        Content here
      </slot>
    </section>
    <section class="widget-footer" :style="{'background-color':bodyBackColor?bodyBackColor:'#fff'}">
      <section class="widget-footer-title">
        <slot name="footer">

          </slot>
      </section>
      <section class="widget-footer-control">
        
      </section>
    </section>
  </section>
</template>
<script>
export default {
  props:{
    id:undefined,
    title:undefined,
    menuButtonVisible:undefined,
    headerBackColor:undefined,
    headerTextColor:undefined,
    bodyBackColor:undefined
  },
  data(){
    return{
      menuVisible:false,
      resizing:false,
      moving:false,
      x:0,
      x:0,
      width:400,
      height:400,
      left:0,
      top:0
    }
  },
  mounted(){
    let initialLayout=localStorage.getItem(`widget__${this.id}`);
    if (initialLayout){
      initialLayout=JSON.parse(initialLayout);
      let el=document.querySelector(`#widget__${this.id}`);
      el.style.width=initialLayout.width+"px";
      el.style.height=initialLayout.height+"px";
      el.style.left=initialLayout.left+"px";
      el.style.top=initialLayout.top+"px";
    }
    window.addEventListener('mouseup',(ev)=>{
      this.resizing=false;
      this.moving=false;
      let el=document.querySelector(`#widget__${this.id}`);
      localStorage.setItem(`widget__${this.id}`,JSON.stringify({
        left:el.offsetLeft,
        top:el.offsetTop,
        width:el.clientWidth,
        height:el.clientHeight
      }))
    });

    window.addEventListener('mousemove',(ev)=>{
      if (this.resizing){
        let el=document.querySelector(`#widget__${this.id}`);
        let dx=ev.x-this.x;
        let dy=ev.y-this.y;
        let width=(this.width+dx);
        let height=(this.height+dy);
        if (width>100){
          el.style.width=width+"px";
        }
        if (height>100){
          el.style.height=height+"px";
        }
      }
      if (this.moving){
        let el=document.querySelector(`#widget__${this.id}`);
        let dx=ev.x-this.x;
        let dy=ev.y-this.y;
        let left=(this.left+dx);
        let top=(this.top+dy);
        if (left>0){
          el.style.left=left+"px";
        }
        if (top>0){
        el.style.top=top+"px";
        }
      }
    });
    
    window.addEventListener('click',(ev)=>{
      this.menuVisible=false;
    });
  },
  methods:{
    toggleMenu(){
      this.menuVisible=!this.menuVisible;
    },
    onStartResize(ev){
      this.resizing=true;
      this.x=ev.x;
      this.y=ev.y;
      let el=document.querySelector(`#widget__${this.id}`);
      this.width=el.clientWidth;
      this.height=el.clientHeight;
    },
    onStartMove(ev){
      this.moving=true;
      this.x=ev.x;
      this.y=ev.y;
      let el=document.querySelector(`#widget__${this.id}`);
      this.left=el.offsetLeft;
      this.top=el.offsetTop;
    }
  }
};
</script>
<style lang="less" scoped>
.widget {
  width: 400px;
  height: 400px;
  // border: 1px solid #aaa;
  display: flex;
  flex-direction: column;
  background-color:white;
  // box-shadow: 0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1);
  // border-radius:10px;
  // box-shadow: 0 5px 5px rgba(0,0,0,.25)!important;
  .widget-header {
    padding: 10px;
    height: 40px;
    display: flex;
    flex: 0 0 auto;
    // border-radius:10px 10px 0px 0px;
    width:100%;
    
    .widget-header-title {
      flex: 1 1 100%;
      color:#212529;
      font-size: 12px;
      font-weight:bold;
      
    }
    .widget-header-control {
      flex: 0 0 120px;
      text-align: right;
      color: #aaa;
      cursor: pointer;
      .widget-menu {
        width: 200px;
        height: 200px;
        background-color: #fff;
        border:1px solid #ccc;
        position: relative;
        left: 180px;
        top: 0px;
        z-index: 10000;
      }
    }
  }
  .widget-content {
    flex: 1 1 100%;
    padding: 0px 10px;
    height:~"calc(100% - 100px)";
    width:100%;
  }
  .widget-footer {
    flex: 0 0 auto;
    padding: 10px;
    display: flex;
    width:100%;
    .widget-footer-title {
      flex: 1 1 100%;
      color: #aaa;
      font-size: 14px;
      font-weight: bold;
    }
    .widget-footer-control {
      flex: 0 0 40px;
      text-align: right;
      color: #aaa;
      cursor: pointer;
      display: flex;
    }
  }
}
</style>