var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-actions", staticStyle: { width: "80px" } },
    [
      _c(
        "button",
        {
          staticClass: "button flat-button",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.itemAction("report-item", _vm.rowData, _vm.rowIndex)
            },
          },
        },
        [_c("i", { staticClass: "mif-file-text mif-lg" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }