<template>
    <div class="row" v-if="item">
        <div class="cell-sm-full cell-md-6">
            <input data-validate="required minlength=2" type="text" data-clear-button="false" data-role="input" data-prepend="Name: " v-model="item.name" @keyup="keyMonitor">
        </div>  
        <div class="cell-12" v-show="!isSubList">
            <div class="input">  
            <vue-select  label="name" :reduce="(v)=>v.id" v-model="item.departmentId" :filterable="false" :options="optionsDepartments">
                <template slot="option" slot-scope="option">
                    <div class="d-center">
                        {{ option.name }} ({{option.organizationName}})
                    </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                        {{ option.name }} ({{option.organizationName}})
                    </div>
                </template>
            </vue-select>
            <div class="prepend">Τάξη:</div>
            </div>
        </div>   
        <div class="cell-sm-full cell-md-6">
            <input data-validate="" type="text" data-clear-button="false" data-role="input" data-prepend="E-Learning URL: " v-model="item.eLearningUrl" @keyup="keyMonitor">
        </div>  
        <div class="cell-sm-full cell-md-6">
            <input data-validate="" type="text" data-clear-button="false" data-role="input" data-prepend="Παροχές: " v-model="item.facilities" @keyup="keyMonitor">
        </div>  
        <div class="cell-sm-full cell-md-6">
            <input data-validate="integer" type="text" data-clear-button="false" data-role="input" data-prepend="Χωρητικότητα: " v-model="item.capacity" @keyup="keyMonitor">
        </div>  
        <div class="cell-md-6">
            <div class="input">  
            <vue-select  label="name" :reduce="(v)=>v.id" v-model="item.responsibleUserId" :filterable="false" :options="optionsUsers">
                <template slot="option" slot-scope="option">
                    <div class="d-center">
                        {{ option.sirname }} {{ option.name }} ({{option.email}})
                    </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                        {{ option.sirname }} {{ option.name }}  ({{option.email}})
                    </div>
                </template>
            </vue-select>
            <div class="prepend">Υπεύθυνος:</div>
            </div>
        </div>   
        <div class="cell-12">
            <div class="subListHeader">
                Μαθητές
                <span class="mif-add mif-lg" style="cursor:pointer;background-color:green;color:white;width:20px;height:20px;border-radius:15px;text-align:center;padding-top:2px" @click.prevent="onAddStudent"></span>
            </div>
            <div style="padding:10px">
                <div style="font-weight:bold;font-size:14px">ΠΡΟΣΘΗΚΗ ΗΔΗ ΥΠΑΡΧΟΝΤΩΝ ΜΑΘΗΤΩΝ</div>
                <div data-role="progress" data-type="line" data-small="true" v-if="loadingStudents"></div>
                <div style="max-height:300px;overflow:auto">
                    <div v-for="s in students" :key="s.id" style="display:flex;font-size:14px;align-items:center">
                        <div style="flex:1 1 auto">{{s.sirname}} {{s.name}}</div>
                        <div style="flex:0 0 100px">{{s.teams.map(x=>x.name).join(',')}}</div>
                        <div style="flex:0 0 100px">
                            <input type="checkbox" data-role="checkbox" data-caption="Προσθήκη" v-model="s.selected">
                        </div>
                    </div>
                </div>
                <div>
                    <button class="button primary" @click.prevent="onAddExistingStudents">
                        <span>Προσθήκη</span>
                    </button>
                </div>
            </div>
            <data-list ref="studentsList" :settings="studentListSettings" @onListUpated="onStudentUpdated"></data-list>
        </div> 
        <div class="cell-12 cell-md-6">
            <input type="file" data-role="file" data-prepend="Upload .csv:" @change="processCSVFile($event)">
        </div>
        <div class="cell-12 cell-md-4">
            <button class="button primary" :class="{'outline':isSubList}" @click.prevent="onUploadFile" :disabled="!selectedCSVFile">
                <span>Upload</span>
            </button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import _ from 'underscore';

import settings from '../../settings.js'
import globals from '../../globals.js'

export default {
    props:{
        item:undefined,
        isSubList:undefined
    },
    watch:{
        item: {
            immediate: true,
            async handler(newVal, oldVal) {
                // if (this.item.departmentId && this.item.departmentId!=-1){
                //     let r=await axios.post(`${settings.serviceUrl}department/get`, {tokenValue:globals.getToken(),id:this.item.departmentId});
                //     this.optionsDepartments=[r.data.payload];
                // }
                // if (this.item.responsibleUserId && this.item.responsibleUserId!=-1){
                //     let r=await axios.post(`${settings.serviceUrl}user/get`, {tokenValue:globals.getToken(),id:this.item.responsibleUserId});
                //     this.optionsUsers=[r.data.payload];
                // }
                if (this.item.departmentId==-1)
                    this.item.departmentId=undefined;

                this.studentListSettings.searchParams={
                    teamId:this.item.id
                };
                this.studentListSettings.defaultNewItem={
                    teamId:this.item.id
                };
            },
        },
        departmentId: {
            immediate: true,
            async handler(newVal, oldVal) {
                if (!this.departmentId) this.organizationId=undefined;
                let r=await axios.post(`${settings.serviceUrl}department/get`, {tokenValue:globals.getToken(),id:this.departmentId});
                this.organizationId=r.data.payload.organizationId;
            },
        },

    },
    computed:{
        departmentId(){
            return this.item.departmentId;
        }
    },
    data(){
        return{
            organizationId:undefined,
            optionsDepartments:[],
            optionsUsers:[],
            students:[],
            teams:[],
            selectedCSVFile:undefined,
            studentListSettings:{
                showCards:false,
                hideFilters:true,
                hideControls:true,
                deleteConfirmationField:"id",
                isSubList:true,
                itemName:'student',
                itemNamePlural:'students',
                dataPerPage:10,
                searchParams:{
                },
                sortParams:[],
                tableColumns:[
                    {
                        name: '__component:vuetable-raw-actions-edit',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    },
                    {
                        name: 'id',
                        title:'Id',
                        sortField: 'id',
                        titleClass: 'id-column',
                        dataClass: 'id-column',
                        // callback: 'gender'
                    },
                    // {
                    //     name: 'departmentId',
                    //     title:'Department',
                    //     sortField: 'departmentId',
                    //     callback:((v)=>{
                    //         if (!v || v==-1) return '-';
                    //         let o=_.findWhere(this.optionsDepartments,{id:v});
                    //         if (o) return o.name;
                    //         return "-";
                    //     }).bind(this)
                    // },
                    {
                        name: 'sirname',
                        title:'Επώνυμο',
                        sortField: 'sirname',
                        isCardHeader:true
                    },
                    {
                        name: 'name',
                        title:'Όνομα',
                        sortField: 'name',
                        isCardHeader:true
                    },
                    {
                        name: '__component:vuetable-remove-from-team',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    }
                ],
            },
            loadingStudents:false
        }
    },
    mounted() {
        const action=async ()=>{
            let r=await axios.post(`${settings.serviceUrl}department/getMany`, {tokenValue:globals.getToken(),sort:[{field:'name',direction:'ASC'}]});
            this.optionsDepartments= r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}user/getMany`, {tokenValue:globals.getToken(),sort:[{field:'name',direction:'ASC'}]});
            this.optionsUsers= r.data.payload.items;
            r=await axios.post(`${settings.serviceUrl}team/getMany`, {tokenValue:globals.getToken(),sort:[{field:'name',direction:'ASC'}]});
            this.teams= r.data.payload.items;
            await this.loadStudents();
        };
        action();
    },
    methods:{
        async loadStudents(){
            this.loadingStudents=true;
            let r=await axios.post(`${settings.serviceUrl}student/getMany`, {tokenValue:globals.getToken(),item:{notTeamId:this.item.id},sort:[{field:'sirname',direction:'ASC'}]});
            let students= r.data.payload.items;
            students.forEach((s)=>{
                s.teams=[];
                s.selected=false;
                let teams=s.teamId.split('|');
                teams.forEach((t)=>{
                    if (t){
                        let team=_.findWhere(this.teams,{id:parseInt(t)});
                        s.teams.push(team);
                    }
                });
            });
            this.students=students;
            this.loadingStudents=false;
        },
        async onAddExistingStudents(){
            for (let s of this.students.slice(0)){
                if (s.selected){
                    s.teamId=s.teamId+this.item.id+'|';
                    delete s.selected;
                    delete s.teams;
                    await axios.post(`${settings.serviceUrl}student/update`, {tokenValue:globals.getToken(),item:s});
                    this.students.splice(this.students.indexOf(s),1);
                }
            }
            this.$refs.studentsList.onSearch();
        },
        processCSVFile(event) {
            this.selectedCSVFile = event.target.files[0];
            console.log(this.selectedCSVFile);
        },
        keyMonitor(event){
            this.$emit('keyevent',event);
        },
        onAddStudent(){
            this.$refs.studentsList.onAdd();
        },
        async onStudentUpdated(){
        },
        async onUploadFile(){
            let formData = new FormData();
            formData.append('file', this.selectedCSVFile);
            formData.append('dir',settings.tempUploadFolder);
            formData.append('action','TEAMSTUDENTS');
            formData.append('teamId',this.item.id);
            formData.append('organizationId',this.item.organizationId);
            formData.append('tokenValue',globals.getToken());
            let response=await axios.post(`${settings.serviceUrl}upload/csv/`,formData,{headers: {'Content-Type': 'multipart/form-data'}});
            if (response.data.ok){
                this.$refs.studentsList.onSearch();
            }
        },
    }
}
</script>