var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.queryStringData
    ? _c("div", { staticClass: "filters-area row" }, [
        _c("div", { staticClass: "cell-sm-12 cell-md-6" }, [
          _c(
            "div",
            {
              staticClass: "input",
              staticStyle: { width: "100%", "max-width": "600px" },
            },
            [
              _c("vue-select", {
                attrs: {
                  label: "name",
                  reduce: function (v) {
                    return v.id.toString()
                  },
                  filterable: false,
                  options: _vm.optionsDepartments,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "d-center" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(option.name) +
                                " - " +
                                _vm._s(option.organizationName) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "selected-option",
                      fn: function (option) {
                        return [
                          _c("div", { staticClass: "selected d-center" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(option.name) +
                                " - " +
                                _vm._s(option.organizationName) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3803413476
                ),
                model: {
                  value: _vm.queryStringData.search.departmentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryStringData.search, "departmentId", $$v)
                  },
                  expression: "queryStringData.search.departmentId",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "prepend" }, [_vm._v("Τάξη:")]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.user && _vm.user.roleId == 0
          ? _c("div", { staticClass: "cell-sm-12 cell-md-6" }, [
              _c(
                "div",
                {
                  staticClass: "input",
                  staticStyle: { width: "100%", "max-width": "600px" },
                },
                [
                  _c("vue-select", {
                    attrs: {
                      label: "name",
                      reduce: function (v) {
                        return v.id
                      },
                      filterable: false,
                      options: _vm.optionsOrganizations,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "option",
                          fn: function (option) {
                            return [
                              _c("div", { staticClass: "d-center" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(option.name) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "selected-option",
                          fn: function (option) {
                            return [
                              _c("div", { staticClass: "selected d-center" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(option.name) +
                                    "\n                    "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      566720996
                    ),
                    model: {
                      value: _vm.queryStringData.search.organizationId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.queryStringData.search,
                          "organizationId",
                          $$v
                        )
                      },
                      expression: "queryStringData.search.organizationId",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "prepend" }, [
                    _vm._v("Οργανισμός:"),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }