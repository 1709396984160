<template>
    <data-list :settings="teamsListSettings"></data-list>
</template>

<script>
import _ from 'underscore';
import axios from 'axios';

import globals from '../../globals.js';
import settings from '../../settings.js';

import dataList from '../../components/admin/dataList.vue'

export default {
    computed:{
        user(){
            return this.$store.state.user;
        }
    },
    data(){
        return {
            teamsListSettings:{
                deleteConfirmationField:"id",
                isSubList:false,
                itemName:'team',
                itemNamePlural:'teams',
                searchParams:{

                },
                sortParams:[],
                tableColumns:[
                    {
                        name: '__component:vuetable-raw-actions-edit',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    },
                    {
                        name: 'id',
                        title:'Id',
                        sortField: 'id',
                        titleClass: 'id-column',
                        dataClass: 'id-column',
                        // callback: 'gender'
                    },
                    
                    {
                        name: 'departmentName',
                        title:'Τάξη',
                        sortField: 'departmentName'
                    },
                    {
                        name: 'name',
                        title:'Όνομα',
                        sortField: 'name',
                        isCardHeader:true
                    },
                    {
                        name: '__component:vuetable-raw-actions-delete',  
                        title: '',
                        titleClass: 'btn-td',
                        dataClass: 'btn-td'
                    }
                ],
                defaultNewItem:undefined
            }
        };
    },
    components:{
        dataList
    },
    mounted(){
        if (this.user && this.user.roleId==0)
            this.teamsListSettings.tableColumns.push({
                name: 'organizationName',
                title:'Οργανισμός',
                sortField: 'organizationName'
            });
        const action=async ()=>{
            
        };
        action();
    },
    methods:{
    }
       
}
</script>

<style lang="less">
</style>
