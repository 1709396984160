<template>
<div class="page edit-page">
    <data-edit :isNewItem="isNew" :isSubList="false" :id="id" itemName="rubrik" itemNamePlural="rubriks" :rootScreen="rootScreen"></data-edit>
</div>
</template>

<script>
import axios from 'axios';
import _ from 'underscore';

import dataEdit from '../../components/admin/dataEdit.vue'
import settings from '../../settings.js';
import globals from '../../globals.js';

export default {
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.rootScreen = from.fullPath;
        });
    },
    props: {
        id: {
            type: String,
            default: "-"
        },
    },
    watch:{},
    computed:{},
    components: {
        dataEdit
    },
    data() {
        return {
            rootScreen:'',
            isNew:false,
        }
    },
    mounted() {
        this.isNew=this.$route.query.isNew=='true';
        const action=async ()=>{
            // if (this.isNew){
            //     let r=await axios.post(`${settings.serviceUrl}rubrikPart/getMany`, {tokenValue:globals.getToken(),item:{rubrikId:this.id}});
            //     let parts= r.data.payload.items;    
            //     for (let crp of settings.commonRubrikParts){
            //         let existing=_.findWhere(parts,{'code':crp.code});
            //         if (!existing){
            //             r=await axios.post(`${settings.serviceUrl}rubrikPart/add`, {tokenValue:globals.getToken(),item:{
            //                 code:crp.code,
            //                 description:crp.description,
            //                 name:crp.name,
            //                 stepDescriptions:crp.stepDescriptions,
            //                 steps:crp.steps,
            //                 weight:crp.weight,    
            //                 rubrikId:this.id,
            //                 common:1
            //             }});
            //         }
            //     }
            // }
        };
        action();
    },
    methods: {
        
    }
};
</script>

<style lang="less">
</style>