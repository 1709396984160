<template>
<div class="edit-area">
    <form class="grid" v-if="item" data-interactive-check="true"> 
        <div class="row">
            <div class="cell">
                <div class="edit-id" v-if="!isSubList">
                    {{id}}
                </div>
                <div v-else>
                    Editing Id: {{id}}
                </div>
            </div>
            <div class="stub" style="width: 120px" v-if="!isSubList">
                <button class="button primary" @click.prevent="onSave">
                    <span class="mif-checkmark mif-lg"></span>
                </button>
                <button class="button primary" @click.prevent="onCancel">
                    <span class="mif-cancel mif-lg"></span>
                </button>
            </div>
        </div>
        <data-edit-team v-if="itemName=='team'" :item="item" @keyevent="keyMonitor" :isSubList="isSubList"></data-edit-team>
        <data-edit-rubrik v-if="itemName=='rubrik'" :item="item" @keyevent="keyMonitor" :isSubList="isSubList"></data-edit-rubrik>
        <data-edit-rubrik-part v-if="itemName=='rubrikPart'" :item="item" @keyevent="keyMonitor" :isSubList="isSubList"></data-edit-rubrik-part>
        <data-edit-course ref="dataEditCourse" v-if="itemName=='course'" :item="item" @keyevent="keyMonitor" :isSubList="isSubList"></data-edit-course>
        <data-edit-course-section v-if="itemName=='coursesection'" :item="item" @keyevent="keyMonitor" :isSubList="isSubList"></data-edit-course-section>
        <data-edit-student ref="dataEditStudent" v-if="itemName=='student'" :item="item" @keyevent="keyMonitor" :isSubList="isSubList"></data-edit-student>
        <div class="row" v-if="isSubList">
            
            <div class="cell-12">
                <button class="button primary" :class="{'outline':isSubList}" @click.prevent="onSave">
                    <span class="mif-checkmark mif-lg"></span>
                </button>
                <button class="button primary" :class="{'outline':isSubList}" @click.prevent="onCancel">
                    <span class="mif-cancel mif-lg"></span>
                </button>
            </div>
            
        </div>
    </form>
</div>
</template>

<script>

import axios from "axios";
import moment from 'moment';
import _ from 'underscore';

import globals from '../../globals.js';
import settings from '../../settings.js'
import validator from '../../validator.js';

import dataEditTeam from '../../components/admin/dataEditTeam.vue'
import dataEditRubrik from '../../components/admin/dataEditRubrik.vue'
import dataEditRubrikPart from '../../components/admin/dataEditRubrikPart.vue'
import dataEditCourse from '../../components/admin/dataEditCourse.vue'
import dataEditCourseSection from '../../components/admin/dataEditCourseSection.vue'
import dataEditStudent from '../../components/admin/dataEditStudent.vue'

export default {
    props: {
        id: {
            type: String,
            default: "-"
        },
        itemName:undefined,
        itemNamePlural:undefined,
        isSubList:undefined,
        isNewItem:undefined,
        rootScreen:undefined
    },
    watch:{
        id: {
            immediate: true,
            async handler(newVal, oldVal) {
                this.createUrls();
                if (!this.isSubList){
                    this.isNew=this.$route.query.isNew=='true';
                    
                }
                else{
                    this.isNew=this.isNewItem
                }
                const action=async ()=>{
                    this.$root.$emit('ui-blocker',1);
                    let itemResponse=await axios.post(this.getItemURL, {tokenValue: globals.getToken(),id: this.id});
                    let item=itemResponse.data.payload;
                    this.$root.$emit('ui-blocker',-1);
                    this.item=item;
                };
                action();
            },
        },
    },
    computed:{},
    components: {
        dataEditTeam,
        dataEditRubrik,
        dataEditRubrikPart,
        dataEditCourse,
        dataEditCourseSection,
        dataEditStudent
    },
    data() {
        return {
            item: {},
            isNew:false,   
            getItemURL:'',
            updateItemURL:'',
            deleteItemURL:'',
            defaultReturnURL:'',
            returnRoute:''         
        };
    },
    mounted() {
    },
    methods: {
        createUrls(){
            this.getItemURL=`${settings.serviceUrl}${this.itemName}/get`;
            this.updateItemURL=`${settings.serviceUrl}${this.itemName}/update`;
            this.deleteItemURL=`${settings.serviceUrl}${this.itemName}/remove`;
            this.defaultReturnURL=`/admin/${this.itemNamePlural}`;
            if (!this.rootScreen || this.rootScreen=="/" || this.rootScreen==this.$route.fullPath)
                this.returnRoute=this.defaultReturnURL;
            else
                this.returnRoute=this.rootScreen;
                
        },
        keyMonitor(event){
            validator.validateForm(document.querySelector("form"),false);
        },
        async onCancel() {
            if (this.isNew){
                let response=await axios.post(`${this.deleteItemURL}`,{tokenValue:globals.getToken(),id:this.item.id});
            }
            else
            {
                if (!this.isSubList)
                    this.$store.commit('setAdminEditPageId',this.item.id);
            }
            if (!this.isSubList)
                this.$router.push(this.returnRoute);
            else{
                this.$emit('finishedEdititng','cancel');
            }
        },
        async onSave(d) {
            if (this.itemName=='course') await this.$refs.dataEditCourse.onPreSave();
            if (this.itemName=='student') await this.$refs.dataEditStudent.onPreSave();

            
            let validationResult=validator.validateForm(document.querySelector("form"),true);
            if (!validationResult.ok) return;

            if (this.isNew){
            }
            else{
            }

            let response=await axios.post(this.updateItemURL, {tokenValue: globals.getToken(),item: this.item});
            if (!this.isSubList){
                this.$store.commit('setAdminEditPageId',this.item.id);
                this.$router.push(this.returnRoute)
            }
            else{
                this.$emit('finishedEdititng','save',this.item);
            }
        }
    }
};
</script>

<style lang="less">
</style>