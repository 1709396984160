var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-menu-container" }, [
    _c("div", { staticStyle: { display: "none" } }, [
      _vm._v(_vm._s(_vm.userRole)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "menu-items" },
      _vm._l(_vm.menuItems, function (mi) {
        return _c(
          "div",
          {
            key: mi.id,
            staticClass: "menu-item",
            class: { active: mi.active },
            on: {
              click: function ($event) {
                return _vm.onMenuItem(mi.path)
              },
            },
          },
          [
            _c("div", { staticClass: "menu-item-left" }, [
              mi.icon ? _c("img", { attrs: { src: mi.icon } }) : _vm._e(),
              _vm._v(" "),
              mi.html
                ? _c("div", { domProps: { innerHTML: _vm._s(mi.html) } })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "title" }, [_vm._v(_vm._s(mi.title))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "menu-item-right" }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }