import globals from './globals';

export default {
  localizer:{
    "gr":{
      "Must not be empty":"Δεν μπορεί να είναι κενό",
      "Must be a number":"Πρέπει να είναι ένας αριθμός",
      "Must be at least":"Πρέπει να είναι τουλάχιστον",
      "characters":"χαρακτήρες",
      "There are errors in the form":"Υπάρχουν λάθη στη φόρμα.",
      "Must be a valid email address":"Πρέπει να είναι διεύθυνση email"
    }
  },
  l(val){
    let activeLocalizer=this.localizer[globals.activeLanguage];
    if (!activeLocalizer) return val;
    if (activeLocalizer[val]) return activeLocalizer[val];
    return val;
  },
  resetForm(form) {
    let inputElements = form.querySelectorAll("input");
    inputElements.forEach((el) => {
      el.parentElement.classList.remove("invalid");
      el.parentElement.classList.remove("valid");
      let feedbackElement = el.parentElement.parentElement.querySelector(".invalid_feedback");
      if (feedbackElement) el.parentElement.parentElement.removeChild(feedbackElement);
    });
  },
  validateForm(form, showMessage) {
    this.resetForm(form);
    let inputElements = form.querySelectorAll("input");
    let result = {
      ok: true,
      errors: [],
    };

    inputElements.forEach((el) => {
      let validationStr = el.getAttribute("data-validate");
      if (validationStr) {
        let validationStrArgs = validationStr.split(" ");
        let value = el.value;
        let isValid = true;
        let validationErrors = "";
        for (let i = 0; i < validationStrArgs.length; i++) {
          if (validationStrArgs[i] == "required") {
            if (!value) {
              isValid = false;
              validationErrors += this.l("Must not be empty") + ","

            } else {

            }
          }
          if (validationStrArgs[i] == "email") {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            isValid=re.test(String(value).toLowerCase());
            if (!isValid) {
              validationErrors += `${this.l("Must be a valid email address")},`
            } else {}
          }
          if (validationStrArgs[i] == "integer") {
            let ivalue = parseInt(value);
            if (isNaN(ivalue)) {
              isValid = false;
              validationErrors += `${this.l("Must be a number")},`
            } else {}
          }
          if (validationStrArgs[i].indexOf("minlength")!=-1){
            let minlength=parseInt(validationStrArgs[i].split('=')[1]);
            if (value.length<minlength){
              isValid = false;
              validationErrors += `${this.l("Must be at least")} ${minlength} ${this.l("characters")},`
            }
          }
        }
        if (!isValid) {
          result.ok = false;
          result.errors.push({
            el,
            message: validationErrors.substr(0, validationErrors.length - 1)
          });

          var messageNode = document.createElement("span");
          messageNode.classList.add("invalid_feedback");
          messageNode.innerHTML = validationErrors.substr(0, validationErrors.length - 1);
          el.parentElement.classList.add("invalid");
          el.parentElement.parentElement.appendChild(messageNode);
        } else {
          el.parentElement.classList.add("valid");
        }


      }
    });
    if (!result.ok && showMessage) Metro.toast.create(this.l("There are errors in the form"), null, null, "alert");
    return result;
  },
};