var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid", class: [_vm.css.tableClass] }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.tableData, function (item, itemIndex) {
        return _c(
          "div",
          {
            key: itemIndex,
            class: [_vm.onRowClass(item, itemIndex), _vm.cardCellClass],
            attrs: { "item-index": itemIndex, render: _vm.onRowChanged(item) },
            on: {
              dblclick: function ($event) {
                return _vm.onRowDoubleClicked(item, $event)
              },
              click: function ($event) {
                return _vm.onRowClicked(item, $event)
              },
            },
          },
          [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.getHeaderFieldValue(item, _vm.tableFields)) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-content p-2" },
                [
                  _vm._l(_vm.tableFields, function (field, fieldIndex) {
                    return [
                      field.visible
                        ? [
                            _vm.isSpecialField(field.name)
                              ? [
                                  _vm.extractName(field.name) == "__sequence"
                                    ? _c(
                                        "div",
                                        {
                                          key: fieldIndex,
                                          class: [
                                            "vuetable-sequence",
                                            field.dataClass,
                                          ],
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(field.title)),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.renderSequence(itemIndex)
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.extractName(field.name) == "__handle"
                                    ? _c("div", {
                                        key: fieldIndex,
                                        class: [
                                          "vuetable-handle",
                                          field.dataClass,
                                        ],
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.renderIconTag([
                                              "handle-icon",
                                              _vm.css.handleIcon,
                                            ])
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.extractName(field.name) == "__checkbox"
                                    ? _c(
                                        "div",
                                        {
                                          key: fieldIndex,
                                          class: [
                                            "vuetable-checkboxes",
                                            field.dataClass,
                                          ],
                                        },
                                        [
                                          _c("input", {
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: _vm.rowSelected(
                                                item,
                                                field.name
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.toggleCheckbox(
                                                  item,
                                                  field.name,
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : [
                                  _vm.hasCallback(field)
                                    ? _c(
                                        "div",
                                        {
                                          key: fieldIndex,
                                          class: field.dataClass,
                                          on: {
                                            click: function ($event) {
                                              return _vm.onCellClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                            dblclick: function ($event) {
                                              return _vm.onCellDoubleClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                            contextmenu: function ($event) {
                                              return _vm.onCellRightClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(field.title) + ":"),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.callCallback(field, item)
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          key: fieldIndex,
                                          class: field.dataClass,
                                          on: {
                                            click: function ($event) {
                                              return _vm.onCellClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                            dblclick: function ($event) {
                                              return _vm.onCellDoubleClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                            contextmenu: function ($event) {
                                              return _vm.onCellRightClicked(
                                                item,
                                                field,
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(field.title) + ":"),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getObjectValue(
                                                  item,
                                                  field.name,
                                                  ""
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                ],
                          ]
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-footer" },
                _vm._l(
                  _vm.getComponentFields(_vm.tableFields),
                  function (field, idx) {
                    return _c(
                      "div",
                      { key: idx },
                      [
                        _c(_vm.extractArgs(field.name), {
                          tag: "component",
                          attrs: {
                            "row-data": item,
                            "row-index": itemIndex,
                            "row-field": field.sortField,
                          },
                        }),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }