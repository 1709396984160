<template>
    <div class="row" v-if="item">
        <div class="cell-sm-full cell-md-2">
            <img :src="toFullUrl(item.photoURL)" alt="" style="width:80px;height:80px;" @error="setAltImg" >
        </div>
        <div class="cell-sm-full cell-md-10">
            <input  class="mb-4" type="file" data-role="file" data-prepend="Photo file:" @change="processFileToUpload($event,'selectedPhotoFile')">
            <button class="button primary" @click.prevent="onUploadFile('STUDENT_PHOTO','selectedPhotoFile')" :disabled="!selectedPhotoFile">
                <span>Upload Photo</span>
            </button>
        </div>
        <div class="cell-sm-full cell-md-6">
            <input data-validate="required minlength=3" type="text" data-clear-button="false" data-role="input" data-prepend="Όνομα: " v-model="item.name" @keyup="keyMonitor">
        </div>
        <div class="cell-sm-full cell-md-6">
            <input data-validate="required minlength=3" type="text" data-clear-button="false" data-role="input" data-prepend="Επώνυμο: " v-model="item.sirname" @keyup="keyMonitor">
        </div>
        <div class="cell-sm-full cell-md-6">
            <input type="text" data-clear-button="false" data-role="input" data-prepend="Όνομα πατρός: " v-model="item.fatherName" @keyup="keyMonitor">
        </div>
        <div class="cell-sm-full cell-md-6" style="display:flex">
            <div>Ημερομηνία Γέννησης:</div>
            <input id="dob-datepicker" data-role="datepicker" v-model="item.dob">
        </div>
        <div class="cell-sm-full cell-md-6">
            <input data-validate="required email" type="text" data-clear-button="false" data-role="input" data-prepend="Επίσημο Email: " v-model="item.emailOfficial" @keyup="keyMonitor">
        </div>
        <div class="cell-sm-full cell-md-6">
            <input data-validate="" type="text" data-clear-button="false" data-role="input" data-prepend="Προσωπικό Email: " v-model="item.emailPersonal" @keyup="keyMonitor">
        </div>
        <div class="cell-full">
            <div class="row">
                <div class="cell-sm-full cell-md-6">
                    <input data-validate="" type="text" data-clear-button="false" data-role="input" data-prepend="Διεύθυνση: " v-model="item.address" @keyup="keyMonitor">
                </div>
                <div class="cell-sm-full cell-md-6">
                    <input data-validate="" type="text" data-clear-button="false" data-role="input" data-prepend="Κινητό: " v-model="item.mobile" @keyup="keyMonitor">
                </div>
            </div>
        </div>
        <div class="cell-sm-full cell-md-6">
             <input type="checkbox" data-role="checkbox" data-caption="ΚΕΕΔΥ" v-model="keedy">
        </div>
        <div class="cell-full" v-if="keedy">
            <input type="text" data-clear-button="false" data-role="input" data-prepend="Remarks: " v-model="item.keedyRemarks" @keyup="keyMonitor">    
        </div>
        <div class="cell-sm-full cell-md-6">
             <input type="checkbox" data-role="checkbox" data-caption="Κινητικά προβλήματα" v-model="abilityIssues">
        </div>
        <div class="cell-sm-full cell-md-6">
             <input type="checkbox" data-role="checkbox" data-caption="Ανήκει σε ευπαθής ομάδα" v-model="sensitiveGroup">
        </div>
        <div class="cell-sm-full cell-md-6">
            <input data-validate="" type="text" data-clear-button="false" data-role="input" data-prepend="E-learning url: " v-model="item.elearningPlatformLink" @keyup="keyMonitor">
        </div>
       
        <div class="cell-12" v-show="!isSubList">
            <div class="input">  
                <vue-select  label="name" :reduce="(v)=>v.id" v-model="item.teamId" :filterable="false" :options="optionTeams">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.name }}
                        </div>
                    </template>
                </vue-select>
                <div class="prepend">Τμήμα:</div>
            </div>
        </div>   
    </div>
</template>
<script>
import axios from 'axios';

import settings from '../../settings.js'
import globals from '../../globals.js'

export default {
    props:{
        item:undefined,
        isSubList:undefined
    },
    watch:{
        item: {
            immediate: true,
            async handler(newVal, oldVal) {
                Metro.getPlugin('#dob-datepicker','datepicker').val(this.item.dob);
                
                if (this.item.teamId==-1)
                    this.item.teamId=undefined;
                
                this.keedy=this.item.keedy;
                this.sensitiveGroup=this.item.sensitiveGroup;
                this.abilityIssues=this.item.abilityIssues;

                this.$nextTick(()=>{setTimeout(()=>{
                    Metro.getPlugin('#dob-datepicker','datepicker').options.onSet=(v)=>{this.item.dob=v;}
                },500)})
            },
        },
        keedy(){
            if (this.keedy) this.item.keedy=1;
            else this.item.keedy=0;
        },
        sensitiveGroup(){
            if (this.sensitiveGroup) this.item.sensitiveGroup=1;
            else this.item.sensitiveGroup=0;
        },
        abilityIssues(){
            if (this.abilityIssues) this.item.abilityIssues=1;
            else this.item.abilityIssues=0;
        }
    },
    data(){
        return{
            optionTeams:[],
            selectedPhotoFile:undefined,
            keedy:undefined,
            sensitiveGroup:undefined,
            abilityIssues:undefined
        }
    },
    mounted() {
        const action=async ()=>{
            let r=await axios.post(`${settings.serviceUrl}team/getMany`, {tokenValue:globals.getToken(),item:{}});
            this.optionTeams= r.data.payload.items;
            this.$nextTick(()=>{})
        };
        action();
    },
    methods:{
        setAltImg(event) { 
            event.target.src = `${settings.serviceUrl}public/site/assets/no-image.png`;
        },
        toFullUrl(relativeUrl){
            return settings.serviceUrl+relativeUrl;
        },
        processFileToUpload(event,property) {
            this[property] = event.target.files[0];
        },
        async onPreSave(){
            this.item.teamId='|'+this.item.teamId+'|';
        },
        async onUploadFile(fileFor,property){
            let formData = new FormData();
            formData.append('file', this[property]);
            formData.append('dir','students/');
            formData.append('action',fileFor);
            formData.append('studentId',this.item.id);
            formData.append('tokenValue',globals.getToken());
            let response=await axios.post(`${settings.serviceUrl}upload/file/`,formData,{headers: {'Content-Type': 'multipart/form-data'}});
            if (!response.data.ok){
                Metro.toast.create(`${response.data.message}`, null, null, "alert");
            }
            if (response.data.ok){
                if (fileFor=='STUDENT_PHOTO') this.item.photoURL=response.data.payload.relativePathUrl;
            }
        },
        keyMonitor(event){
            this.$emit('keyevent',event);
        },
    }
}
</script>