export default {
  env:process.env.NODE_ENV || 'development',
  serviceUrl: 'https://app.rubrics.io/',
  // authServiceUrl: 'http://localhost:18100/',
  assetsBaseUrl:'https://app.rubrics.io/public/',
  // serviceUrl: 'http://167.86.108.192:18201/',
  authServiceUrl: 'https://auth.rubrics.io/',
  // assetsBaseUrl:'http://167.86.108.192:18201/public/',


  dataPerPage:100,
  app:'descriptive.assesment',
  sentryKey: 'https://6569222d6054497fa15925134efd4acd@sentry.io/5182501',
  tempUploadFolder:'temp',
  googleMapsAPIKey:'AIzaSyD0HJyJQyDCnvAM1Ivz6n4FcEi1wZkTKkM',
  googleMapsDefaultView:{
    mapCenter: {
      lat: 37.9761972226037,
      lng: 23.734194444418
    },
    zoom:7
  },
  widgetAreaBounds:{
    left:0,
    top:60
  },
  commonRubrikParts:[
    {
      code:'C01',
      name:'Επιμέλεια',
      description:'Επιμέλεια',
      stepDescriptions:"Δεν υλοποιεί εργασίες|Οι εργασίες είναι ημιτελείς|Οι εργασίες είναι εκπρόθεσμες|Οι εργασίες είναι έγκυρες και Έγκαιρες",
      steps:4,
      weight:15
    },
    {
      code:'C02 ',
      name:'Συμμετοχή/συνεργασία',
      description:'Συμμετοχή/συνεργασία',
      stepDescriptions:"αποφεύγει να συμμετέχει σε ομαδικές δράσεις|τυπικά συμμετέχει/συνεργάζεται σε ομαδικές δράσεις|ενεργά συμμετέχει/συνεργάζεται σε ομαδικές δράσεις|δείχνει χαρακτηριστικά ηγέτη στη συμμετοχή/συνεργασία σε ομαδικές δράσεις",
      steps:4,
      weight:15
    },
    {
      code:'C03',
      name:'Δεξιότητες Υπολογιστικής Σκέψης (σε θέματα γνωστικού και μη αντικειμένου)',
      description:'Δεξιότητες Υπολογιστικής Σκέψης (σε θέματα γνωστικού και μη αντικειμένου)',
      stepDescriptions:"δεν χρησιμοποιεί ευέλικτες μεθόδους που βελτιώνουν/επιλύουν προβλήματα|εντοπίζει/αντιλαμβάνεται κάποιο πρόβλημα αλλά δεν προτείνει αποτελεσματικές λύσεις|εντοπίζει/αντιλαμβάνεται κάποιο πρόβλημα και προτείνει αποτελεσματικές λύσεις|σκέφτεται αναλυτικά, αλγοριθμικά και δίνει επικαιροποιημένες λύσεις",
      steps:4,
      weight:15
    },

  ]
};
  
